import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useLanguageContext } from '../LanguageContext';
// import axios from 'axios';
// import { IDENTITY_URL } from '../../config';

interface StayLoggedInProps {
  isLoggedIn: boolean;
  onStayLoggedIn: () => void;
  onLogout: () => void;
}

const StayLoggedIn: React.FC<StayLoggedInProps> = ({ isLoggedIn, onStayLoggedIn, onLogout }) => {
  const [t, i18n] = useTranslation("global");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const [showPopup, setShowPopup] = useState(false);
  const [loginTime, setLoginTime] = useState<number | null>(null);
  const userActivityTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    const resetTimer = () => {
      if (userActivityTimeout.current) {
        clearTimeout(userActivityTimeout.current);
      }
      if (isLoggedIn) {
        setLoginTime(Date.now());
        userActivityTimeout.current = setTimeout(() => setShowPopup(true), 20 * 60 * 1000); // 20 minutes
      }
    };

    resetTimer();

    const resetTimerOnActivity = () => {
      resetTimer();
    };

    // Reset timer on user activity
    // window.addEventListener('mousemove', resetTimerOnActivity);
    window.addEventListener('keypress', resetTimerOnActivity);

    return () => {
      // window.removeEventListener('mousemove', resetTimerOnActivity);
      window.removeEventListener('keypress', resetTimerOnActivity);
      if (userActivityTimeout.current) {
        clearTimeout(userActivityTimeout.current);
      }
    };
  }, [isLoggedIn]);

  useEffect(() => {
    const forceLogoutTimer = setTimeout(() => {
      if (showPopup) {
        onLogout();
      }
    }, 60000)
    return () => clearTimeout(forceLogoutTimer)
  }, [showPopup])

  const handleStayLoggedIn = async () => {
    setShowPopup(false);
    setLoginTime(Date.now());
    onStayLoggedIn();

    try {
      const token = localStorage.getItem("token");

      if (token) {
        // // refresh the token or extend the session duration
        // const response = await axios.post(`${IDENTITY_URL}/refresh-token`, { token });
        // const newToken = response.data.newToken;

        // For demonstration purposes, we'll just update the login time in localStorage
        localStorage.setItem("loginTime", Date.now().toString());
      }
    } catch (error) {
      console.error("Failed to extend session", error);
    }
  };

  const handleLogout = () => {
    setShowPopup(false);
    onLogout(); // Call the logout function
  };

  const footerContentSeesionTimeout = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("global.stayLoggedIn")}
        onClick={handleStayLoggedIn}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("global.logOut")}
        onClick={handleLogout}
        className="button-md"
      />
    </div>
  );

  return (
    <Dialog className="custom-dialog" ariaCloseIconLabel="Close" header={t("global.sessionTimeout")} visible={showPopup} onHide={() => setShowPopup(false)} footer={footerContentSeesionTimeout}>
      <p className='font-normal m-0'>{t("global.wantToStayLoggedIn")}</p>
    </Dialog>
  );
};

export default StayLoggedIn;
