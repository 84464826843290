import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import DonutChart from "../Chart/DonutChart";
import { useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { MenuItem } from "primereact/menuitem";
import FeatherIcon from "../common/FeatherIconComponent";
import Table from "../common/Table";
import { reset } from "../../slices/createCommitteeSlice";
import { fetchData, putData } from "../../services/apiService";
import { usePermission } from "../auth/Authorization";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { clearAllSessionStorage } from "../../utils/storage";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import OpenPositionsListing from "../position/OpenPositionsListing";

interface MemberList {
  [x: string]: string;
  id: string;
  Committee: string;
  MembershipPosition: string;
  CategoryOfInterest: string;
  NumberofBallotsMissed: string;
  NumberofMeetingMissed: string;
  StartDate: string;
  EndDate: string;
  StatusName: string;
}

const DashboardPage: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const [allMember, setAllMember] = useState<MemberList[]>([]);
  const [loading, setLoading] = useState(true);
  const [openPositions, setOpenPositions] = useState<any>([]);
  const [ballotOrMeetingNumber, setBallotOrMeetingNumber] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    terminatedThroughError: "",
    ballotOrMeetingNumberError: "",
    selectedReasonError: "",
    terminateValueError: "",
    remarkError: "",
  });
  const [membersExpiringSoon, setMembersExpiringSoom] = useState<any>([]);
  const [allMembershipStatusData, setAllMemberStatusData] = useState<any>([]);
  const [terminateValue, setTerminateValue] = useState("");
  const [pendingRequest, setPendingRequest] = useState<number>(0);

  const [terminatedThroughValue, setTerminatedThroughValue] = useState<
    string | null
  >(null);
  const [resignValue, setResignValue] = useState("");
  const [memberShipId, setMemberShipId] = useState("");
  const toast = useRef<Toast>(null);
  const [visibleResign, setVisibleResign] = useState<boolean>(false);
  const [expiredMemership, setExiredMembership] = useState<number>(0);
  const [convertToRecord, setConvertToRedord] = useState<number>(0);
  const [totalReqestCount, setTotalRequestCount] = useState<number>(0);
  const [visibleTerminate, setVisibleTerminate] = useState<boolean>(false);
  const [userProfileId, setUserProfileId] = useState<string>("");
  const [validationError, setValidationError] = useState("");
  const [submittedRequestId, setSubmittedRequestId] = useState<string>("");
  const [convertToRecordRequestId, setconvertToRecordRequestId] =
    useState<string>("");
  const [selectedReason, setSelectedReason] = useState("");

  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  const [dognutData, setDognutData] = useState<number[]>([]);
  const [dognutLabel, setDognutLabel] = useState<any>(null);
  const [dognutBackground, setDognutBackground] = useState<any>(null);
  const committeeMembershipId =
    sessionStorage.getItem("committeeMembershipId") || "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isDashboard = true;
  useEffect(() => {
    clearAllSessionStorage();
    dispatch(reset());
  }, []);

  const dropdownOptions = [
    { label: "Direct Termination", value: "DirectTermination" },
    { label: "Meeting", value: "Meeting" },
    { label: "Ballot", value: "Ballot" },
  ];

  const setError = useCallback(
    (field: any, errorMessage: any) => {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [field]: errorMessage,
      }));
    },
    [validationErrors]
  );

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [userData, positionsData, statusData] = await Promise.all([
          fetchData("UserProfile/GetCurrentUserProfile"),
          fetchData("MembershipPosition/GetAll"),
          fetchData("MembershipStatus/GetAll"),
        ]);
        setUserProfileId(userData.Id);
        setAllMemberStatusData(statusData.Collection);
        const submittedStatus = statusData.Collection.find(
          (status: any) => status.StatusName === "Submitted"
        );
        const convertToRecordStatus = statusData.Collection.find(
          (status: any) => status.StatusName === "Convert to Record"
        );
        setSubmittedRequestId(submittedStatus.Id);
        setconvertToRecordRequestId(convertToRecordStatus.Id);
      } catch {
        console.error("");
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  useEffect(() => {
    if (!userProfileId || userRole === "") return;

    const fetchDataAndSetState = async (url: string, stateSetter: any) => {
      try {
        const responseData = await fetchData(url);
        stateSetter(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchAllRequest = () => {
      let url: string = "";
      if (userRole === "Member") {
        url = `DashBoard/GetMembershipRequestCount?Filters[0].Key=UserProfileId&Filters[0].Value=${userProfileId}&PageIndex=-1`;
      } else if (userRole === "Staff") {
        url = `DashBoard/GetMembershipRequestCount?Filters[0].Key=StaffId&Filters[0].Value=${userProfileId}&PageIndex=-1`;
      } else if (userRole === "Admin") {
        url = `DashBoard/GetMembershipRequestCount?&PageIndex=-1`;
      }
      fetchDataAndSetState(url, setTotalRequestCount);
    };

    const fetchConvertToRecord = () => {
      const url = `DashBoard/GetConvertedToRecordCount?Filters[0].Key=UserProfileId&Filters[0].Value=${userProfileId}&PageIndex=-1`;
      fetchDataAndSetState(url, setConvertToRedord);
    };

    const fetchPendingRequest = () => {
      let url: string = "";
      if (userRole === "Member") {
        url = `DashBoard/GetMembershipPendingRequestCount?Filters[0].Key=UserProfileId&Filters[0].Value=${userProfileId}&PageIndex=-1`;
      } else if (userRole === "Staff") {
        url = `DashBoard/GetMembershipPendingRequestCount?Filters[0].Key=StaffId&Filters[0].Value=${userProfileId}&PageIndex=-1`;
      } else if (userRole === "Admin") {
        url = `DashBoard/GetMembershipPendingRequestCount?&PageIndex=-1`;
      }
      fetchDataAndSetState(url, setPendingRequest);
    };

    const fetchMembershipExpiration = () => {
      const url =
        userRole === "Member"
          ? `DashBoard/GetMembershipExpirationingCount?Filters[0].Key=UserProfileId&Filters[0].Value=${userProfileId}&PageIndex=-1`
          : `DashBoard/GetMembershipExpirationingCount?PageIndex=-1`;
      fetchDataAndSetState(url, setExiredMembership);
    };

    fetchAllRequest();
    fetchPendingRequest();
    fetchConvertToRecord();
    fetchMembershipExpiration();
  }, [userRole, userProfileId]);

  useEffect(() => {
    const data = [
      totalReqestCount,
      pendingRequest,
      expiredMemership,
      convertToRecord,
    ];
    const labels = [
      "Total Request",
      "Pending Requests",
      "Expiring soon",
      "Converted to records",
    ];
    const backgroundColors = ["#1459A3", "#C1DEE5", "#E34E4E", "#05225A"];
    setDognutData(data);
    setDognutLabel(labels);
    setDognutBackground(backgroundColors);
  }, [totalReqestCount, pendingRequest, convertToRecord]);

  const handlePandingRequest = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (submittedRequestId) {
      navigate(`/member/${submittedRequestId}`);
    }
  };

  const handleConvertToRecord = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (convertToRecordRequestId) {
      navigate(`/member/${convertToRecordRequestId}`);
    }
  };

  const showSuccessTermination = () => {
    toast.current?.show({
      severity: "success",
      summary: t("global.success"),
      detail: t("global.memberTerminatedSuccessfully"),
      life: 3000,
    });
  };

  const handleTerminateButtonClick = async (committeeMembershipId: string) => {
    setVisibleTerminate(true);
    setValidationError("");
    setMemberShipId(committeeMembershipId);
  };

  const handleResignButtonClick = async (committeeMembershipId: string) => {
    setVisibleResign(true);
    setValidationError("");
    setMemberShipId(committeeMembershipId);
  };

  const actionItems: MenuItem[] = [
    {
      label: t("global.resign"),
      command: (rowData: any) => {
        handleResignButtonClick(rowData.Id);
      },
    },
    ...(userRole === "Member"
      ? [
          {
            label: t("global.requestReappointment"),
            command: () => {
              sessionStorage.setItem("reappointment", "true");
              setTimeout(() => {
                navigate("/member/create");
              }, 100);
            },
          },
        ]
      : []),
    ...(userRole !== "Member"
      ? [
          {
            label: t("global.terminate"),
            command: (rowData: any) => {
              handleTerminateButtonClick(rowData.Id);
            },
          },
        ]
      : []),
  ];
  const actionItemsForStaff: MenuItem[] = [
    {
      label: t("global.terminate"),
      command: (rowData: any) => {
        handleTerminateButtonClick(rowData.Id);
      },
    },
  ];

  const getActionItems = () => {
    return userRole === "Staff" ? actionItemsForStaff : actionItems;
  };

  const handleTerminate = useCallback(async () => {
    let hasError = false;

    if (!terminateValue.trim()) {
      setError("remarkError", t("global.reasonForTermination"));
      hasError = true;
    } else {
      setError("remarkError", "");
    }
    if (!terminatedThroughValue) {
      setError("terminatedThroughError", t("global.terminatedThroughRequired"));
      hasError = true;
    } else {
      setError("terminatedThroughError", "");
    }

    if (
      (terminatedThroughValue === "Ballot" ||
        terminatedThroughValue === "Meeting") &&
      !ballotOrMeetingNumber.trim()
    ) {
      setError(
        "ballotOrMeetingNumberError",
        terminatedThroughValue === "Meeting"
          ? t("global.meetingNumberRequired")
          : t("global.ballotNumberRequired")
      );
      hasError = true;
    } else {
      setError("ballotOrMeetingNumberError", "");
    }

    if (!selectedReason) {
      setError("selectedReasonError", t("global.selectReasonForTermination"));
      hasError = true;
    } else {
      setError("selectedReasonError", "");
    }

    if (hasError) return;
    const membershipTerminateBallotNumber =
      terminatedThroughValue === "Ballot" ? ballotOrMeetingNumber : null;
    const membershipTerminateMeetingNumber =
      terminatedThroughValue === "Meeting" ? ballotOrMeetingNumber : null;

    const reasonForTermination = {
      reasonForTerminate: terminateValue,
      membershipTerminateBallotNumber: membershipTerminateBallotNumber,
      membershipTerminateMeetingNumber: membershipTerminateMeetingNumber,
      membershipTerminatIsLackOfParticipation:
        selectedReason === "lackOfParticipation",
      membershipTerminatIsDisciplinaryAction:
        selectedReason === "disciplinaryAction",
    };
    const putDataResponse = await putData(
      `CommitteeMembership/TerminateCommitteeMembership?committeeMembershipId=${committeeMembershipId}`,
      undefined,
      reasonForTermination
    );

    setVisibleTerminate(false);
    setValidationError("");
    setSelectedReason("");
    setBallotOrMeetingNumber("");
    setTerminatedThroughValue("");
    setTerminateValue("");
    setValidationErrors({
      terminatedThroughError: "",
      ballotOrMeetingNumberError: "",
      selectedReasonError: "",
      terminateValueError: "",
      remarkError: "",
    });
    setTimeout(showSuccessTermination, 1000);
    navigate("/all-membership");
  }, [
    terminateValue,
    terminatedThroughValue,
    ballotOrMeetingNumber,
    selectedReason,
  ]);

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const options: any = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    if (!userProfileId) return;
    const fetchExpiringMembership = async () => {
      try {
        let responseData;
        switch (userRole) {
          case "Staff":
            responseData = await fetchData(
              `DashBoard/GetMembershipExpirationingCollection?PageIndex=-1`
            );
            break;
          case "Admin":
            responseData = await fetchData(
              `DashBoard/GetMembershipExpirationingCollection?PageIndex=-1`
            );
            break;
          case "Member":
            responseData = await fetchData(
              `DashBoard/GetMembershipExpirationingCollection?Filters[0].Key=UserProfileId&Filters[0].Value=${userProfileId}&PageIndex=-1`
            );
            break;
          default:
            break;
        }
        const sorted = responseData.collection.map((item: any) => {
          return {
            ...item,
            statusName: item.membershipStatus,
            startDate: formatDate(item.startDate),
            endDate: formatDate(item.endDate),
            sessionKey: "committeeMembershipId",
            Id: item.committeeMembershipId,
          };
        });
        setMembersExpiringSoom(sorted);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchExpiringMembership();
  }, [userRole, userProfileId]);

  const handleResign = async () => {
    if (!resignValue.trim()) {
      setValidationError(`${t("global.reasonForResignation")}`);
      return;
    }
    const reasonForRejection = { reasonForRejection: resignValue };
    const putDataResponse = await putData(
      `CommitteeMembership/ResignCommitteeMembership?committeeMembershipId=${committeeMembershipId}`,
      undefined,
      reasonForRejection
    );
    // Call API to resign membership
    setVisibleResign(false);
    setTimeout(() => {}, 1000);
    navigate("/all-membership");
  };

  const footerContentTerminate = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("global.cancel")}
        onClick={() => {
          setVisibleTerminate(false);
          setValidationError("");
          setSelectedReason("");
          setBallotOrMeetingNumber("");
          setTerminatedThroughValue("");
          setTerminateValue("");
          setValidationErrors({
            terminatedThroughError: "",
            ballotOrMeetingNumberError: "",
            selectedReasonError: "",
            terminateValueError: "",
            remarkError: "",
          });
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("global.terminate")}
        onClick={handleTerminate}
        className="button-md"
      />
    </div>
  );

  const footerContentResign = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("global.cancel")}
        onClick={() => {
          setVisibleResign(false);
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("global.resign")}
        onClick={handleResign}
        className="button-md"
      />
    </div>
  );

  return (
    <>
      <div className="">
        <div className="flex flex-wrap md:flex-row justify-content-between align-items-center mb-4 gap-3">
          <h1 className="text-title display-xs font-bold m-0">
            {t("global.dashboard")}
          </h1>
          {userRole === "Member" ? (
            <Button
              className="button-md gap-1 justify-content-center w-auto"
              onClick={() => navigate("/member/create")}
            >
              <FeatherIcon name="plus" size={20} color="inherit" />
              <span className="font-bold">{t("global.requestMembership")}</span>
            </Button>
          ) : (
            <div className="flex flex-wrap gap-3">
              <Button
                className="button-md gap-1 justify-content-center w-full sm:w-auto"
                onClick={() => navigate("/positions/add-position")}
              >
                <FeatherIcon name="plus" size={20} color="inherit" />
                <span className="font-bold">Open Positions</span>
              </Button>
              <Button
                className="button-md gap-1 justify-content-center w-full sm:w-auto"
                onClick={() => navigate("/record/volunteer")}
              >
                <FeatherIcon name="plus" size={20} color="inherit" />
                <span className="font-bold">{t("global.createRecord")}</span>
              </Button>
              <Button
                className="button-md gap-1 justify-content-center w-full sm:w-auto"
                onClick={() => navigate("/committee/create")}
              >
                <FeatherIcon name="plus" size={20} color="inherit" />
                <span className="font-bold">{t("global.createCommittee")}</span>
              </Button>
            </div>
          )}
        </div>
        <div className="card bg-white w-full p-2 mb-5">
          <div className="grid align-items-center px-4	py-2">
            {dognutData.some((data) => data !== 0) && (
              <div className="col-12 md:col-4">
                <DonutChart
                  data={dognutData}
                  labels={dognutLabel}
                  backgroundColors={dognutBackground}
                />
              </div>
            )}
            <div className="col font-bold">
              <span className="color-block all-requests mr-2"></span>
              {t("global.allRequest")}
              <p>{totalReqestCount}</p>
              <Link to="/member">{t("global.viewAll")}</Link>
            </div>
            <div className="col font-bold">
              <span className="color-block pending-requests mr-2"></span>
              {t("global.pendingRequest")}
              <p>{pendingRequest}</p>
              <Link to="#" onClick={handlePandingRequest}>
                {t("global.viewAll")}
              </Link>
            </div>
            <div className="col font-bold">
              <span className="color-block expired-memberships mr-2"></span>
              {t("global.expiredMemberships")}
              <p>{expiredMemership}</p>
              <Link
                to="/all-membership/expired-True"
                style={{
                  pointerEvents:
                    membersExpiringSoon?.length === 0 ? "none" : "auto",
                  color: expiredMemership === 0 ? "gray" : "",
                }}
              >
                {t("global.viewAll")}
              </Link>
            </div>
            {userRole === "Member" && (
              <div className="col font-bold">
                <span className="color-block convert-to-record mr-2"></span>
                {t("global.convertToRecord")}

                <p>{convertToRecord}</p>
                <Link to="#" onClick={handleConvertToRecord}>
                  {t("global.viewAll")}
                </Link>
              </div>
            )}
          </div>
        </div>
        {userRole === "Member" ? (
          <div className="mb-5">
            <div className="flex justify-content-between mb-3">
              <div>
                <h2 className="text-title text-lg font-bold text-capitalize m-0">
                  {t("global.openPositions")}
                </h2>
              </div>
              <div>
                <Link to="/positions">
                  <b>{t("global.viewAll")}</b>
                </Link>
              </div>
            </div>
            <OpenPositionsListing
              hideActions={isDashboard}
            ></OpenPositionsListing>
          </div>
        ) : null}
        <div className="mb-5">
          <div className="flex justify-content-between mb-3">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("global.expiringMemberships")}
            </h2>
            <div>
              <Link
                to="/all-membership/expired-True"
                style={{
                  pointerEvents:
                    membersExpiringSoon?.length === 0 ? "none" : "auto",
                  color: membersExpiringSoon?.length === 0 ? "gray" : "",
                }}
              >
                <b>{t("global.viewAll")}</b>
              </Link>
            </div>
          </div>
          <Table
            loading={loading}
            products={membersExpiringSoon}
            columns={[
              { field: "committeeName", header: t("global.committeeName") },
              { field: "memberName", header: t("global.memberName") },
              { field: "memberPosition", header: t("global.position") },
              {
                field: "categoryOfInterest",
                header: t("global.categoryOfInterest"),
              },
              { field: "startDate", header: t("global.startDate") },
              { field: "endDate", header: t("global.endDate") },
              { field: "statusName", header: t("global.status") },
              { field: "action", header: t("global.action") },
            ]}
            showCheckbox={false}
            disablePagination={true}
            actionItems={getActionItems()}
          />
        </div>
        <Dialog
          id="wantToResign"
          header={t("global.wantToResign")}
          visible={visibleResign}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
          onHide={() => setVisibleResign(false)}
          footer={footerContentResign}
          className="custom-dialog hide-close"
          ariaCloseIconLabel="Close"
        >
          <div className="flex flex-column gap-2 w-full">
            <label
              htmlFor="requestResign"
              className={`block font-light font-bold text-capitalize ${
                validationError ? "p-error" : ""
              }`}
            >
              {t("global.reasonForResign")}
            </label>
            <InputTextarea
              id="requestResign"
              value={resignValue}
              onChange={(e) => setResignValue(e.target.value)}
              rows={5}
              cols={30}
              className={validationError ? "p-invalid" : ""}
            />
            <span
              className={`p-error font-bold text-capitalize ${validationError ? "" : "error-hidden"}`}
            >
              {validationError}
            </span>
          </div>
        </Dialog>

        <Dialog
          id="wantToTerminate"
          header={t("global.wantToTerminate")}
          visible={visibleTerminate}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
          onHide={() => {
            setValidationErrors({
              terminatedThroughError: "",
              ballotOrMeetingNumberError: "",
              selectedReasonError: "",
              terminateValueError: "",
              remarkError: "",
            });
            setSelectedReason("");
            setBallotOrMeetingNumber("");
            setTerminatedThroughValue("");
            setTerminateValue("");
            setVisibleTerminate(false);
          }}
          footer={footerContentTerminate}
          className="custom-dialog hide-close"
          ariaCloseIconLabel="Close"
        >
          <div className="flex flex-column gap-3 w-full">
            {/* Terminated Through Dropdown */}
            <div className="grid grid-xl">
              <div className="flex flex-column gap-2 sm:col-6 col-12">
                <label
                  htmlFor="terminatedThrough"
                  className={`block font-light font-bold text-capitalize ${
                    validationErrors.terminatedThroughError ? "p-error" : ""
                  }`}
                >
                  {t("global.terminatedThrough")}
                </label>
                <Dropdown
                  inputId="terminatedThrough"
                  value={terminatedThroughValue}
                  options={dropdownOptions}
                  onChange={(e) => {
                    setBallotOrMeetingNumber("");
                    setTerminatedThroughValue(e.value);
                    setValidationErrors((prevErrors) => ({
                      ...prevErrors,
                      terminatedThroughError: "",
                    }));
                  }}
                  placeholder={t("global.selectTerminatedThrough")}
                  className={`w-full ${validationErrors.terminatedThroughError ? "p-invalid" : ""}`}
                />
                {validationErrors.terminatedThroughError && (
                  <small className="p-error font-bold">
                    {validationErrors.terminatedThroughError}
                  </small>
                )}
              </div>

              {(terminatedThroughValue === "Meeting" ||
                terminatedThroughValue === "Ballot") && (
                <div className="flex flex-column gap-2 sm:col-6 col-12">
                  <label
                    htmlFor="ballotOrMeetingNumber"
                    className={`block font-light font-bold text-capitalize ${
                      validationErrors.ballotOrMeetingNumberError
                        ? "p-error"
                        : ""
                    }`}
                  >
                    {terminatedThroughValue === "Meeting"
                      ? t("global.meetingNumber")
                      : t("global.ballotNumber")}
                  </label>
                  <InputText
                    id="ballotOrMeetingNumber"
                    value={ballotOrMeetingNumber}
                    onChange={(e) => {
                      setBallotOrMeetingNumber(e.target.value);
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        ballotOrMeetingNumberError: "",
                      }));
                    }}
                    className={`w-full ${validationErrors.ballotOrMeetingNumberError ? "p-invalid" : ""}`}
                  />
                  {validationErrors.ballotOrMeetingNumberError && (
                    <small className="p-error font-bold">
                      {validationErrors.ballotOrMeetingNumberError}
                    </small>
                  )}
                </div>
              )}
            </div>

            {/* Radio Buttons */}
            <div className="grid grid-xl">
              <div className="col-12">
                <label
                  htmlFor="requestTerminate"
                  className={`block font-light font-bold text-capitalize ${
                    validationErrors.selectedReasonError ? "p-error" : ""
                  }`}
                >
                  {t("global.reasonForTerminate")}
                </label>
              </div>
              <div className="flex col-12">
                <fieldset className="border-none p-0">
                  <div className="flex flex-wrap gap-3 column-gap-5">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="lackOfParticipation"
                        value="lackOfParticipation"
                        checked={selectedReason === "lackOfParticipation"}
                        onChange={(e) => {
                          setSelectedReason(e.value);
                          setValidationErrors((prevErrors) => ({
                            ...prevErrors,
                            selectedReasonError: "",
                          }));
                        }}
                      />
                      <label
                        htmlFor="lackOfParticipation"
                        className="ml-2 font-bold"
                      >
                        {t("global.basedOnLackOfParticipation")}
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="disciplinaryAction"
                        value="disciplinaryAction"
                        checked={selectedReason === "disciplinaryAction"}
                        onChange={(e) => {
                          setSelectedReason(e.value);
                          setValidationErrors((prevErrors) => ({
                            ...prevErrors,
                            selectedReasonError: "",
                          }));
                        }}
                      />
                      <label
                        htmlFor="disciplinaryAction"
                        className="ml-2 font-bold"
                      >
                        {t("global.basedOnDisciplinaryAction")}
                      </label>
                    </div>
                  </div>
                </fieldset>
              </div>
              {validationErrors.selectedReasonError && (
                <div className="col-12">
                  <small className="p-error font-bold">
                    {validationErrors.selectedReasonError}
                  </small>
                </div>
              )}
            </div>

            {/* Reason for Termination Label and Textarea */}
            <div className="grid grid-xl">
              <div className="flex flex-column gap-2 col-12">
                <label
                  htmlFor="requestTerminate"
                  className={`block font-light font-bold text-capitalize ${
                    validationErrors.remarkError ? "p-error" : ""
                  }`}
                >
                  {t("global.remarks")}
                </label>
                <InputTextarea
                  id="requestTerminate"
                  value={terminateValue}
                  onChange={(e) => {
                    setTerminateValue(e.target.value);
                    setValidationErrors((prevErrors) => ({
                      ...prevErrors,
                      remarkError: "",
                    }));
                  }}
                  className={validationErrors.remarkError ? "p-invalid" : ""}
                  rows={5}
                  cols={30}
                />
                <span
                  className={`p-error font-bold text-capitalize ${
                    validationErrors.remarkError ? "" : "error-hidden"
                  }`}
                >
                  {validationErrors.remarkError}
                </span>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default DashboardPage;
