import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "../common/FeatherIconComponent";
import { RootState } from "../../store/store";
import { fetchData } from "../../services/apiService";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { addCommitteeInRecordRequest } from "../../slices/createRecordSlice";
import CreateRecordHeader from "./createRecordHeader";
import CreateTerminateRecordHeader from "./createTerminateRecordHeader";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
interface Option {
  name: string;
  code: string;
}

interface Committee {
  id: string;
  Name: string;
  Abbreviation: string;
}

const RecordCommitteeSelection: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const toast = useRef<any>(null);
  const navigate = useNavigate();
  const [createRecord, setCreateRecord] = useState<any>({
    committeeId: "",
  });
  const [selectedCommitteeId, setSelectedCommitteeId] = useState("");
  const [selectedCommittee, setSelectedCommittee] = useState<any>(null);
  const [recordData, setRecordData] = useState<any>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [memberShipData, setMemberShipData] = useState<any>(null);
  const [selectedCommitteeIndex, setselectedCommitteeIndex] = useState<
    number | null
  >(null);
  const [staffCommittee, setStaffCommittee] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const createRecordData = useSelector(
    (state: RootState) => state.createRecord
  );

  useEffect(() => {}, [createRecordData]);

  const viewDetails = () => {
    navigate("/record/committee-details");
  };

  const terminationRecord = sessionStorage.getItem("termination");

  React.useEffect(() => {
    let editRecordId: string | null | undefined;
    editRecordId = sessionStorage.getItem("recordId");
    if (editRecordId && !editMode) {
      setEditMode(true);
      const fetchDataMemberRequest = async () => {
        try {
          const responseData = await fetchData("Record", editRecordId);
          if (responseData.IsTerminateRecord == true) {
            sessionStorage.setItem("termination", "true");
          }
          setSelectedCommitteeId(responseData.CommitteeId);
          setRecordData(responseData);
        } catch (error) {
          console.error("Error fetching Category of Interest:", error);
        }
      };
      fetchDataMemberRequest();
    }
  }, []);

  React.useEffect(() => {
    const fetchCommitteeData = async () => {
      try {
        if (selectedCommitteeId) {
          const responseData = await fetchData(
            "Committee",
            selectedCommitteeId
          );
          setSelectedCommittee(responseData);
        }
      } catch (error) {
        console.error("Error fetching Committee data:", error);
      }
    };
    fetchCommitteeData();
  }, [selectedCommitteeId]);

  React.useEffect(() => {
    let editRecordId: string | null | undefined;
    editRecordId = sessionStorage.getItem("recordId");
    if (editRecordId && !editMode) {
      setEditMode(true);
      const fetchDataMemberRequest = async () => {
        try {
          const responseData = await fetchData("Record", editRecordId);
          setSelectedCommitteeId(responseData.CommitteeId);
          setRecordData(responseData);
        } catch (error) {
          console.error("Error fetching Category of Interest:", error);
        }
      };
      fetchDataMemberRequest();
    }
    if (
      createRecordData.addCommitteeInRecordRequest &&
      createRecordData.addCommitteeInRecordRequest.committeeId
    ) {
      setSelectedCommitteeId(
        createRecordData.addCommitteeInRecordRequest.committeeId
      );
    } else {
      const storedCommitteeId: any = sessionStorage.getItem("committeeId");
      setSelectedCommitteeId(storedCommitteeId);
    }
  }, []);

  React.useEffect(() => {
    const fetchAllCommittees = async () => {
      try {
        const responseData = await fetchData("Committee/GetAll");
        const filteredData = responseData.Collection.filter((item: any) => {
          if (item.Status.Name === "Created") {
            return true;
          }
          return false;
        });
        setStaffCommittee(filteredData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching committees:", error);
        setLoading(false);
      }
    };
    fetchAllCommittees();
  }, []);

  const selectCommittee = (committee: any, index: number) => {
    setSelectedCommitteeId(committee.Id);
    setselectedCommitteeIndex(index);
    sessionStorage.setItem("committeeId", committee.Id);
    dispatch(addCommitteeInRecordRequest({ committeeId: committee.Id }));
  };

  const handleSelectCommittee = async () => {
    if (editMode) {
      dispatch(
        addCommitteeInRecordRequest({
          committeeId: selectedCommitteeId,
          recordId: recordData.Id,
        })
      );
      sessionStorage.setItem("committeeId", selectedCommitteeId);
    } else {
      dispatch(
        addCommitteeInRecordRequest({ committeeId: selectedCommitteeId })
      );
      sessionStorage.setItem("committeeId", selectedCommitteeId);
    }
    navigate(`/record/details`);
  };

  const [visibleCommittee, setFindCommittee] = useState<boolean>(false);
  const footerContentFindCommittee = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("record.cancel")}
        onClick={() => {
          setFindCommittee(false);
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("record.sendEmail")}
        onClick={() => {
          setFindCommittee(false);
        }}
        className="button-md"
      />
    </div>
  );

  const handleCancel = () => {
    let navigateFrom = sessionStorage.getItem("redirectionPath");
    if (navigateFrom === "record") {
      let committeeId = sessionStorage.getItem("committeeId");
      navigate(`/committee/details/${committeeId}`);
    } else {
      navigate("/record");
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="">
        {terminationRecord ? (
          <CreateTerminateRecordHeader activeStep={0} />
        ) : (
          <CreateRecordHeader activeStep={1} />
        )}
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5">
              <h2 className="text-title text-lg font-bold m-0">
                {terminationRecord
                  ? t("record.terminateThankyouMsg")
                  : t("record.thankYouMsg")}
              </h2>
              <div className="grid grid-xl align-items-center justify-content-between">
                <div className="flex align-items-center gap-3 xl:col-12 lg:col-12 md:col-12 col-12 bg-brand-secondary">
                  <IconField iconPosition="left" className="w-full">
                    <InputIcon className="pi pi-search"> </InputIcon>
                    <InputText
                      placeholder={t("record.searchKeywordsCommittee")}
                      className="w-full"
                      aria-label={t("record.searchKeywordsCommittee")}
                      value={searchQuery} // Bind the search query value
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </IconField>
                  {/* <Dropdown
                    inputId="standardName"
                    value={selectedOption}
                    onChange={(e: DropdownChangeEvent) =>
                      setSelectedOption(e.value)
                    }
                    options={options}
                    optionLabel="name"
                    placeholder={t("record.selectAnOption")}
                    className="w-full"
                    aria-label="Standard name"
                  /> */}
                </div>
                {/* <Button
                  text
                  label="Can’t Find committee/standard?"
                  onClick={() => {
                    setFindCommittee(true)
                  }}
                  className="underline"
                /> */}
              </div>

              <div className="flex flex-column gap-3">
                {selectedCommitteeId && !loading && (
                  <div>
                    <h2 className="m-0 text-lg font-bold">
                      {t("record.selectedCommittee")}
                    </h2>
                    <ul className="list-none p-0 m-0 shadow-md mt-2">
                      <li className="flex align-items-center justify-content-between pl-4 pr-2 py-3 border-1 border-gray-200 gap-2 md:gap-3 shadow-hover">
                        <div className="flex flex-column gap-2">
                          <h3 className="m-0 text-base font-bold">
                            {selectedCommittee?.Name}
                          </h3>
                          <p className="m-0 text-base font-normal">
                            {selectedCommittee?.CommitteeType?.Description}
                          </p>
                        </div>
                        <Button
                          text
                          className="p-button-plain gap-1 underline"
                          onClick={viewDetails}
                        >
                          <span className="font-bold hidden md:flex">
                            {t("record.viewDetails")}
                          </span>
                          <FeatherIcon name="chevron-right" size={20} />
                        </Button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              <div className="flex flex-column gap-3">
                <h2 className="m-0 text-lg font-bold">
                  {t("record.browseByCommittee")}
                </h2>
                {loading && (
                  <div className="flex flex-column gap-3">
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                      strokeWidth="4"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  </div>
                )}

                {!loading && (
                  <ul className="list-none p-0 m-0 shadow-md">
                    {selectedCommitteeId
                      ? staffCommittee
                          ?.filter((committee: Committee) =>
                            committee.Name.toLowerCase().includes(
                              searchQuery.toLowerCase()
                            )
                          )
                          .map(
                            (committee: Committee, index: number) =>
                              committee.id !== selectedCommitteeId && (
                                <li
                                  key={index}
                                  onClick={() =>
                                    selectCommittee(committee, index)
                                  }
                                  className={`flex align-items-center justify-content-between pl-4 pr-2 py-3 border-1 border-gray-200 gap-2 md:gap-3 shadow-hover ${selectedCommitteeIndex === index ? "selected" : ""}`}
                                >
                                  <div className="flex flex-column gap-2">
                                    <h3 className="m-0 text-base font-bold">
                                      {committee.Name}
                                    </h3>
                                    <p className="m-0 text-base font-normal">
                                      {committee.Abbreviation}
                                    </p>
                                  </div>
                                  <Button
                                    text
                                    className="p-button-plain gap-1 underline"
                                    onClick={viewDetails}
                                  >
                                    <span className="font-bold hidden md:flex">
                                      {t("record.viewDetails")}
                                    </span>
                                    <FeatherIcon
                                      name="chevron-right"
                                      size={20}
                                    />
                                  </Button>
                                </li>
                              )
                          )
                      : // Show the complete list if selectedCommitteeId doesn't exist
                        staffCommittee
                          ?.filter((committee: Committee) =>
                            committee.Name.toLowerCase().includes(
                              searchQuery.toLowerCase()
                            )
                          )
                          .map((committee: Committee, index: number) => (
                            <li
                              key={index}
                              onClick={() => selectCommittee(committee, index)}
                              className={`flex align-items-center justify-content-between pl-4 pr-2 py-3 border-1 border-gray-200 gap-2 md:gap-3 shadow-hover ${selectedCommitteeIndex === index ? "selected" : ""}`}
                            >
                              <div className="flex flex-column gap-2">
                                <h3 className="m-0 text-base font-bold">
                                  {committee.Name}
                                </h3>
                                <p className="m-0 text-base font-normal">
                                  {committee.Abbreviation}
                                </p>
                              </div>
                              <Button
                                text
                                className="p-button-plain gap-1 underline"
                                onClick={viewDetails}
                              >
                                <span className="font-bold hidden md:flex">
                                  {t("record.viewDetails")}
                                </span>
                                <FeatherIcon name="chevron-right" size={20} />
                              </Button>
                            </li>
                          ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white hidden md:flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("record.cancel")}
            className="button-md"
            severity="secondary"
            onClick={handleCancel}
          />
          <div className="flex md:flex-row gap-4">
            {!editMode && (
              <Button
                className="button-md"
                severity="secondary"
                onClick={() => {
                  navigate("/record/volunteer");
                }}
              >
                <FeatherIcon name="chevron-left" size={20} color="inherit" />
                <span className="font-bold text-capitalize">
                  {t("record.back")}
                </span>
              </Button>
            )}
            <Button
              label={t("record.confirmCommitteeContinue")}
              className="button-md"
              onClick={handleSelectCommittee}
              disabled={!selectedCommitteeId}
            />
          </div>
        </div>

        {/* Mobile responsive buttions */}
        <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("record.confirmCommitteeContinue")}
            className="button-md w-full"
            onClick={handleSelectCommittee}
            disabled={!selectedCommitteeId}
          />
          <div className="flex align-items-center gap-3 w-full">
            {!editMode && (
              <Button
                className="button-md"
                severity="secondary"
                onClick={() => {
                  navigate("/record/volunteer");
                }}
                aria-label={t("record.back")}
              >
                <FeatherIcon name="chevron-left" size={20} color="inherit" />
              </Button>
            )}
            <Button
              label={t("record.cancel")}
              className="button-md w-full"
              severity="secondary"
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>

      <Dialog
        id="visibleWithdrawId"
        header="Can’t Find committee/standard?"
        visible={visibleCommittee}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
        onHide={() => setFindCommittee(false)}
        footer={footerContentFindCommittee}
        className="custom-dialog"
        ariaCloseIconLabel="Close"
      >
        <p className="m-0 mb-6">{t("record.writeUsHelpMsg")}</p>
        <div className="flex flex-column gap-5">
          <div className="flex flex-column gap-2 w-full">
            <label
              htmlFor="subject"
              className="block font-bold text-input-label capitalize"
            >
              {t("record.subject")}
            </label>
            <InputText
              id="subject"
              type="text"
              placeholder={t("record.subject")}
              className="w-full"
              value=""
            />
          </div>
          <div className="flex flex-column gap-2 w-full">
            <label
              htmlFor="message"
              className="block font-bold text-input-label capitalize"
            >
              {t("record.message")}
            </label>
            <InputTextarea
              id="message"
              placeholder={t("record.message")}
              value=""
              rows={3}
              cols={30}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default RecordCommitteeSelection;
