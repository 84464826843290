import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ballotSelectRecords } from '../../../slices/ballotSelectRecords';
import { fetchData } from '../../../services/Ballot/apiservice';
import { fetchData as getData } from "../../../services/apiService";
import { BALLOT_CLOSE } from '../common/constants';


const useDropDownOptions = () => {
    const dispatch = useDispatch();
    const ballotStatusList = useSelector((state: any) => state.ballotDetails.ballotStatusList);
    const { primaryCommitteOptions, ballotTypeOptions, ballotSubTypeOptions, ballotIdOptions,
        ballotLevelOptions, recordTypeOptions, recordSubTypeOptions, positionAppliedOptions } = useSelector((state: any) => state.ballotSelectRecords);
    const fetchParentCommittee = useCallback(async () => {
        if (primaryCommitteOptions.length === 0) {
            try {
                const userProfileID = localStorage.getItem('userProfileID');
                const primaryCommitteeResponse = await getData(`Committee/Search?Filters[0].Key=StaffId&Filters[0].Value=${userProfileID}&PageIndex=-1`);
                const primaryCommittee = primaryCommitteeResponse.Collection.filter(
                    (committee: any) => committee.Status.Name === 'Created'
                );
                dispatch(ballotSelectRecords.initialRecordValues({ options: primaryCommittee, label: 'primarycommittee' }));
            } catch (err) {
                console.log(err, 'Failed to fetch parent committee');
            }
        }
    }, [dispatch]);
    const handleDropDownOptions = useCallback(async () => {
        if (ballotTypeOptions.length === 0 || ballotSubTypeOptions === 0 || ballotIdOptions === 0 ||
            ballotLevelOptions.length === 0 || recordTypeOptions.length === 0 || recordSubTypeOptions.length === 0 || positionAppliedOptions.length === 0) {
            const querystr = ``;
            const dispatchRecords = (options: any, label: string) => {
                dispatch(ballotSelectRecords.initialRecordValues({ options, label }));
            };
            try {
                const endpointsFetchData = [
                    'BallotType/GetAll',
                    'BallotSubType/GetAll',
                    `Ballot/GetBallotEligibleForRecurculation?${querystr}`,
                    'BallotLevel/GetAll',
                    'RecordSubType/GetAll',
                ];
                const endpointsGetData = [
                    'AccreditationBody/GetAll',
                    'RecordType/GetAll',
                    'MembershipPosition/GetAll',
                ];
                const [
                    ballotType,
                    ballotSubType,
                    ballotIdOptions,
                    ballotLevel,
                    recordSubType,
                ] = await Promise.all(endpointsFetchData.map(endpoint => fetchData(endpoint)));
                const [
                    accreditationBody,
                    recordType,
                    membershipPosition,
                ] = await Promise.all(endpointsGetData.map(endpoint => getData(endpoint)));
                const recordLabels = [
                    { options: accreditationBody, label: 'accreditationType' },
                    { options: ballotType, label: 'ballottype' },
                    { options: ballotSubType, label: 'ballotsubtype' },
                    { options: ballotIdOptions, label: 'ballotid' },
                    { options: ballotLevel, label: 'ballotlevel' },
                    { options: recordType, label: 'recordtype' },
                    { options: recordSubType, label: 'recordsubtype' },
                    { options: membershipPosition, label: 'positions' },
                ];
                recordLabels.forEach(({ options, label }) => dispatchRecords(options, label));
            } catch (err) {
                console.error('Failed to fetch dropdown options:', err);
            }
        }
    }, [dispatch]);

    return {
        fetchParentCommittee,
        handleDropDownOptions,
    };
};

export default useDropDownOptions;
