import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import FeatherIcon from "../../common/FeatherIconComponent";
import { RootState } from "../../../store/store";
import { useLanguageContext } from "../../LanguageContext";
import { fetchData, putData, postData } from "../../../services/Ballot/apiservice";
import { ballotSelectRecords } from "../../../slices/ballotSelectRecords";
import PreviewItem from "../../common/PreviewItem";
import { formatDate } from "../../../utils/utils";
import { setToast } from "../../../slices/toastSlice";
import { useScrollOnPaginatorClick } from "../../common/useScrollOnPaginatorClick";
import { BALLOT_OPEN, BALLOT_OUT_FOR_BALLOT } from "./constants";


const Preview: React.FC = () => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const { isEdit } = useSelector((state: any) => state.ballotSelectRecords);
  const dispatch = useDispatch();
  const { ballotStatusList, ballotDetailsInfo } = useSelector((state: any) => state.ballotDetails);
  const ballotId = ballotDetailsInfo.id;
  const { id } = useParams();
  const toast = useRef<any>(null);
  const [allballotDetails, setAllBallotDetails] = useState<any>();
  const [forBallot, setForBallot] = useState<any>();
  const [forReview, setForReview] = useState<any>();
  const [selectedRecords, setSelectedRecords] = useState<any>([]);
  const [payloadId, setPayloadId] = useState<string>(id ? id : ballotId);
  const tableRecordData: any = useSelector((state: any) => state.ballotSelectRecords.recordTableData);
  const { accreditationBodySelect, primaryCommitteSelect, ballotTypeSelect, ballotSubTypeSelect,
    ballotLevelSelect, recordTypeSelect, recordSubTypeSelect, positionAppliedSelect } = useSelector((state: any) => state.ballotSelectRecords.selectedRecordValues);
  let disableColumn = ballotTypeSelect?.value === 'Review and Comment Ballot'
  const editMode = id && isEdit;
  const performActionBasedOnDate = (givenDateString: string) => {
    const givenDate = new Date(givenDateString);
    const currentDate = new Date();
    return givenDate > currentDate
  };
  const createBallotPayload = () => {
    return {
      accreditationBodyId: accreditationBodySelect?.id,
      isDirty: editMode,
      isNew: editMode,
      ballotLevelId: ballotLevelSelect?.id,
      ballotTypeId: ballotTypeSelect?.id,
      ballotSubTypeId: ballotSubTypeSelect?.id,
      ballotStatusId: performActionBasedOnDate(ballotDetailsInfo?.openDate)
        ? ballotStatusList[BALLOT_OPEN]?.id
        : ballotStatusList[BALLOT_OUT_FOR_BALLOT]?.id,
      recordSubTypeId: recordSubTypeSelect?.id,
      recordTypeId: recordTypeSelect?.id,
      committeeId: primaryCommitteSelect?.id,
      membershipPositionId: positionAppliedSelect?.id,
      note: ballotDetailsInfo?.note,
      noOfRecords: 0,
      explaination: ballotDetailsInfo?.explaination,
      description: ballotDetailsInfo?.description,
      daysToIssue: ballotDetailsInfo?.daysToIssue,
      openDate: ballotDetailsInfo?.openDate,
      closeDate: ballotDetailsInfo?.closeDate,
      ballotNumber: ballotDetailsInfo?.ballotNumber,
      isActive: true,
      isDeleted: false,
      isSecretBallot: ballotDetailsInfo?.isSecret,
      recirculationBallotId: ballotDetailsInfo?.recirculationBallotId,
      recircultionBallotNumber: ballotDetailsInfo?.recirculationBallotNumber,
    };
  };
  const navigate = useNavigate();
  const fetchAllBallotDetails = async () => {
    try {
      const allBallotDetails = await fetchData(`Ballot/${payloadId}`);
      setAllBallotDetails(allBallotDetails)
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const updateRecordSelectionHandler = (tableRecordData: any) => {
    tableRecordData.forEach((data: any) => {
      dispatch(ballotSelectRecords.tableRecordSelectionHandler({ rowData: data }));
    });
  }
  const ballotRecordhandler = async () => {
    try {
      const selectedRecordsData = await fetchData(`BallotRecord/GetListByParentId?parentId=${payloadId}`);
      const tableProducts = selectedRecordsData.collection.map((record: any) => {
        return {
          id: record?.id,
          recordNumber: record.record?.recordNumber,
          ballotRecordId: record.record?.id,
          applicantName: record.record?.userProfile?.firstname,
          committeeAppliedFor: record.record?.committee?.name,
          positionAppliedFor: record.record?.membershipPosition.positionName,
          categoryOfInterest: record.record?.categoryOfInterest.categoryName,
          requestedOn: formatDate(record.record?.createdDate),
          statusName: record.ballotRecordStatus?.name,
          termYear: record.term,
          createdDate: record.createdDate,
          ModifiedDate: record.modifiedDate
        };
      });
      const filteredData = tableProducts.filter((item: any) => item.statusName === "Open" || item.statusName === "Reopen");
      dispatch(ballotSelectRecords.addRecordTableData({ rowTableData: filteredData }));
      setSelectedRecords(filteredData);
      updateRecordSelectionHandler(filteredData)
    } catch (err) {
      console.log(err, 'err');
    }
  };
  const formattedCommittees = (data: any) => {
    const forBalloting: any = [];
    const forReviewing: any = [];
    data.forEach((item: any) => {
      if (item.ForReviewAndComment) {
        forReviewing.push(item);
      } else {
        forBalloting.push(item);
      }
    });
    setForBallot(forBalloting)
    setForReview(forReviewing)
  }
  const fetchAllBallotCommittees = async () => {
    try {
      const allBallotCommittee = await fetchData(`BallotCommittees/GetBallotInvitedComitteeListByBallotId/${payloadId}`);
      formattedCommittees(allBallotCommittee)
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const emailIssueBallot = async () => {
    try {
      await fetchData(`EmailIssueBallotCommiteeMembers/SendBallotIssueEmailToBallotCommitteeMembers?ballotId=${payloadId}`);

    } catch (err) {
      console.log(err, 'errr')
    }
  }
  const issueBallotHandler = async () => {
    try {
      const ballotPayload = createBallotPayload();
      await putData(`Ballot`, `${payloadId}`, ballotPayload);
      dispatch(setToast({ message: `${t("ballot.ballotIssueMessage")}`, severity: 'success' }));
      navigate(`/ballot/membership`);
      emailIssueBallot();
    } catch (err) {
      console.log(err, 'errr');
    }
  };

  useEffect(() => {
    fetchAllBallotDetails();
    ballotRecordhandler();
    setTimeout(() => {
      fetchAllBallotCommittees()
    }, 500)
  }, [payloadId]);
  // Datatable reference
  const tableContainerRef = useScrollOnPaginatorClick();
  let tableClass = "custom-data-table";
  if (tableRecordData !== null && tableRecordData !== undefined) {
    const isEmpty = tableRecordData.length === 0;
    if (isEmpty) {
      tableClass = "custom-data-table empty";
    }
  }
  return (
    <>
      <Toast ref={toast} />
      <div className="">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
              <h2 className="text-title text-lg font-bold text-capitalize m-0">
                {t("ballot.records")}
              </h2>
              <Button
                text
                className="p-button-plain gap-2 underline"
                onClick={() => dispatch(ballotSelectRecords.addStepCountHandler({ value: 0 }))}
              >
                <FeatherIcon name="edit-2" size={20} />
                <span>{t("ballot.edit")}</span>
              </Button>
            </div>

            <div className="p-5 cardBody flex flex-column gap-7">
              <div className="flex flex-column gap-4">
                <PreviewItem
                  label={t("ballot.ballotType")}
                  value={allballotDetails?.BallotType?.Name}
                />
                <PreviewItem
                  label={t("ballot.ballotId")}
                  value={allballotDetails?.BallotNumber}
                />
                <PreviewItem
                  label={t("ballot.primaryCommitteeResponsible")}
                  value={allballotDetails?.Committee?.Name}
                />
                <PreviewItem
                  label={t("ballot.ballotLevel")}
                  value={allballotDetails?.BallotLevel?.Name}
                />
                <PreviewItem
                  label={t("ballot.recordType")}
                  value={allballotDetails?.RecordType?.TypeName}
                />
                <PreviewItem
                  label={t("ballot.recordSubType")}
                  value={allballotDetails?.RecordSubType?.Type}
                />
                <PreviewItem
                  label={t("ballot.recordSubSubType")}
                  value="Value"
                />
                <PreviewItem
                  label={t("ballot.committeePosition")}
                  value={allballotDetails?.MembershipPosition?.PositionName}
                />
              </div>

              <div className="flex flex-column gap-4">
                <h3 className="text-lg font-bold text-capitalize m-0">
                  {t("ballot.selectedRecords")}
                </h3>
                <div ref={tableContainerRef}>
                  <DataTable
                    className={tableClass}
                    key={selectedRecords}
                    sortOrder={1}
                    stripedRows
                    showGridlines
                    value={selectedRecords}
                    paginator
                    rows={10}
                    emptyMessage="No data found."
                    selectionMode={'checkbox'}
                    selection={selectedRecords}
                    onSelectionChange={(e) => {
                      setSelectedRecords(e.value)
                    }}
                    dataKey="id"
                  >
                    <Column key="recordNumber" field={'recordNumber'} header={t("ballot.recordId")} style={{ cursor: 'pointer' }} body={(rowData) => (
                      <>
                        <span className="p-column-title">{t("ballot.recordId")}</span>
                        <a
                          onClick={() => {
                            sessionStorage.setItem("recordId", rowData.id);
                            navigate('/record/record-preview')
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              sessionStorage.setItem("recordId", rowData.id);
                              navigate('/record/record-preview')
                            }
                          }}
                          className="font-bold table-text-link underline"
                          tabIndex={0}
                        >
                          {rowData.recordNumber}
                        </a>
                      </>
                    )} />
                    <Column key="applicantName" field={'applicantName'} header={t("ballot.applicantName")}
                      body={(rowData) => (
                        <>
                          <span className="p-column-title">{t("ballot.applicantName")}</span>
                          {rowData.applicantName}
                        </>
                      )}
                    />
                    <Column key="committeeAppliedFor" field={'committeeAppliedFor'} header={t("ballot.committeeAppliedFor")}
                      body={(rowData) => (
                        <>
                          <span className="p-column-title">{t("ballot.committeeAppliedFor")}</span>
                          {rowData.committeeAppliedFor}
                        </>
                      )}
                    />
                    <Column key="positionAppliedFor" field={'positionAppliedFor'} header={t("ballot.positionAppliedFor")}
                      body={(rowData) => (
                        <>
                          <span className="p-column-title">{t("ballot.positionAppliedFor")}</span>
                          {rowData.positionAppliedFor}
                        </>
                      )}
                    />
                    <Column key="categoryOfInterest" field={'categoryOfInterest'} header={t("ballot.categoryOfInterest")}
                      body={(rowData) => (
                        <>
                          <span className="p-column-title">{t("ballot.categoryOfInterest")}</span>
                          {rowData.categoryOfInterest}
                        </>
                      )} />

                    <Column key="requestedOn" field={'requestedOn'} header={t("ballot.requestedOn")}
                      body={(rowData) => (
                        <>
                          <span className="p-column-title">{t("ballot.requestedOn")}</span>
                          {rowData.requestedOn}
                        </>
                      )}
                    />
                    <Column key="statusName" field={'statusName'} header={t("ballot.status")}
                      body={(rowData) => (
                        <>
                          <span className="p-column-title">{t("ballot.status")}</span>
                          {rowData.statusName}
                        </>
                      )}
                    />
                  </DataTable>
                </div>
              </div>
            </div>
          </div>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
              <h2 className="text-title text-lg font-bold text-capitalize m-0">
                {t("ballot.basicBallotDetails")}
              </h2>
              <Button
                text
                className="p-button-plain gap-2 underline"
                onClick={() => dispatch(ballotSelectRecords.addStepCountHandler({ value: 1 }))}
              >
                <FeatherIcon name="edit-2" size={20} />
                <span>{t("ballot.edit")}</span>
              </Button>
            </div>

            <div className="p-5 cardBody flex flex-column gap-7">
              <div className="flex flex-column gap-4">
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                    {t("ballot.numberOfVotesTheVoterReceive")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    value here
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                    {t("ballot.confidentiality")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {ballotDetailsInfo?.IsSecretBallot ? 'yes' : 'No'}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                    {t("ballot.ballotStartDate")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {formatDate(allballotDetails?.OpenDate)}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                    {t("ballot.ballotEndDate")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {formatDate(allballotDetails?.CloseDate)}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                    {t("ballot.daysToIssue")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {allballotDetails?.DaysToIssue}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                    {t("ballot.ballotDescription")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {allballotDetails?.Description}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                    {t("ballot.ballotsExplanation")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {allballotDetails?.Explaination}
                  </p>
                </div>
                <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                    {t("ballot.notes")}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {allballotDetails?.Note}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
              <h2 className="text-title text-lg font-bold text-capitalize m-0">
                {t("ballot.committee")}
              </h2>
              <Button
                text
                className="p-button-plain gap-2 underline"
                onClick={() => dispatch(ballotSelectRecords.addStepCountHandler({ value: 2 }))}
              >
                <FeatherIcon name="edit-2" size={20} />
                <span>{t("ballot.edit")}</span>
              </Button>
            </div>

            <div className="p-5 cardBody flex flex-column gap-7">
              {!disableColumn && (<div className="flex flex-column gap-4">
                <h3 className="text-lg font-bold text-capitalize m-0">
                  {t("ballot.committeeForBalloting")}
                </h3>
                {forBallot?.map((data: any, index: string) => (<div key={data.CommitteeName + index} className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                    {data.CommitteeName}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {`${data.CommitteeMembershCount} Members`}
                  </p>
                </div>))}
              </div>)}

              <div className="flex flex-column gap-4">
                <h3 className="text-lg font-bold text-capitalize m-0">
                  {t("ballot.committeeForReviewingAndCommenting")}
                </h3>
                {forReview?.map((data: any, index: string) => (<div key={data.CommitteeName + index} className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                  <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                    {data.CommitteeName}
                  </span>
                  <p className="m-0 font-normal text-base text-title m-0 w-full">
                    {`${data.CommitteeMembershCount} Members`}
                  </p>
                </div>))}
              </div>

            </div>
          </div>

          <div className="bg-white hidden md:flex align-items-center px-5 py-3 gap-4 fixed-footer w-full left-0 shadow">
            <Button
              label={t("ballot.cancel")}
              className="button-md"
              severity="secondary"
              onClick={() => navigate(`/ballot/membership`)}
            />
            <Button
              text
              className="p-button-plain underline ml-auto"
              label={t("ballot.saveAsDraft")}
              onClick={() => navigate(`/ballot/membership`)}
            ></Button>
            <Button
              className="button-md gap-1"
              severity="secondary"
              onClick={() => dispatch(ballotSelectRecords.addStepCountHandler({ value: 2 }))}
            >
              <FeatherIcon
                name="chevron-left"
                size={20}
                color="inherit"
              />
              <span className="font-bold text-capitalize">
                {t("ballot.back")}
              </span>
            </Button>
            <Button
              className="button-md"
              onClick={() => issueBallotHandler()}
            >
              <span className="font-bold text-capitalize">
                {t("ballot.issueBallot")}
              </span>
            </Button>
          </div>

          {/* Mobile responsive buttions */}
          <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 justify-content-between fixed-footer w-full left-0 shadow">
            <Button
              className="button-md w-full justify-content-center"
              onClick={() => issueBallotHandler()}
            >
              <span className="font-bold text-capitalize">
                {t("ballot.issueBallot")}
              </span>
            </Button>
            <div className="flex align-items-center gap-3 w-full">
              <Button
                className="button-md gap-1"
                severity="secondary"
                onClick={() => dispatch(ballotSelectRecords.addStepCountHandler({ value: 2 }))}
                aria-label={t("ballot.back")}
              >
                <FeatherIcon
                  name="chevron-left"
                  size={20}
                  color="inherit"
                />
              </Button>
              <Button
                label={t("ballot.cancel")}
                className="button-md w-full"
                severity="secondary"
                onClick={() => navigate(`/ballot/membership`)}
              />
            </div>
            <Button
              text
              className="p-button-plain underline w-full"
              label={t("ballot.saveAsDraft")}
              onClick={() => navigate(`/ballot/membership`)}
            ></Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Preview;