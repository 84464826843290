import React, {
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import FeatherIcon from "../common/FeatherIconComponent";
import { OverlayPanel } from "primereact/overlaypanel";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Table from "../common/Table";
import { MenuItem } from "primereact/menuitem";
import { fetchData, postData } from "../../services/apiService";
import { Toast } from "primereact/toast";
import { formatOpenPositionResponse } from "../../utils/utils";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { MultiSelect } from "primereact/multiselect";
import axiosInstance from "../../services/axiosInstance";
import { BASE_URL } from "../../config";
import { usePermission } from "../auth/Authorization";
import { InputTextarea } from "primereact/inputtextarea";
import { Menu, Menu as PrimeMenu } from "primereact/menu";
import { Dialog } from "primereact/dialog";
import CustomMenu from "../common/customMenu";
export interface OpenPositionList {
  CommitteeId: string;
  CommitteeName: string;
  StaffId: string;
  StaffName: string;
  CategoryOfInterestId: string;
  CategoryOfInterest: string;
  OpenPositionId: string;
  OpenPositions: string;
  NumberOfPositions: string;
  PositionOpenfrom: string;
  Status: string;
  PositionFilledCount: string;
}

interface OpenPositionsListingProps {
  hideActions?: boolean;
}

const OpenPositionsListing: React.FC<OpenPositionsListingProps> = ({
  hideActions,
}) => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const RowOpenPositionId = sessionStorage.getItem("OpenPositionId") || "";
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
  const navigate = useNavigate();
  const [committeeCheckboxChecked, setCommitteeCheckboxChecked] =
    useState<boolean>(false);
  const [holdValue, setHoldValue] = useState("");
  const [closeValue, setCloseValue] = useState("");
  const [resumeValue, setResumeValue] = useState("");
  const [committeeStatus, setCommitteeStatus] = useState("");
  const toast = useRef<Toast>(null);
  const [coiCheckboxChecked, setCoiCheckboxChecked] = useState<boolean>(false);
  const [selectedCommittees, setSelectedCommittees] = useState<any[]>([]);
  const [committees, setCommittees] = useState<any>([]);
  const [holdPositionStatusId, setHoldPositionStatusId] = useState(null);
  const [resumePositionStatusId, setResumePositionStatusId] = useState(null);
  const [closePositionStatusId, setClosePositionStatusId] = useState(null);
  const [selectedCOIs, setSelectedCOIs] = useState<any[]>([]);
  const [memberPosition, setMemberPosition] = useState([]);
  const [coiData, setCoiData] = useState([]);
  const [openPositionStatus, setOpenPositionStatus] = useState([]);
  const [positionStatusCheckboxChecked, setPositionStatusCheckboxChecked] =
    useState<boolean>(false);
  const [selectedOpenPositionStatuses, setSelectedOpenPositionStatuses] =
    useState<any[]>([]);
  const [positionCheckboxChecked, setPositionCheckboxChecked] =
    useState<boolean>(false);
  const [selectedPositions, setSelectedPositions] = useState<any[]>([]);
  const [validationError, setValidationError] = useState("");
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [dashboardPagination, setDashboardPagination] =
    useState<boolean>(false);
  const [isFilterAppliedYes, setIsFilterAppliedYes] = useState(false);
  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  const menu = useRef<PrimeMenu>(null);
  const [openPositionListing, setOpenPositionListing] = useState<
    OpenPositionList[]
  >([]);
  const [apiResponseData, setApiResponseData] = useState<OpenPositionList[]>(
    []
  ); // Store the raw API response data
  const [filteredOpenPosition, setFilteredOpenPosition] = useState([]);
  const [openPositionId, setOpenPositionId] = useState("");
  const [visibleHold, setVisibleHold] = useState<boolean>(false);
  const [visibleClose, setVisibleClose] = useState<boolean>(false);
  const [visibleResume, setVisibleResume] = useState<boolean>(false);
  const [searchvalue, setSearchValue] = useState("");
  const OpenPositionId: string = sessionStorage.getItem(
    "OpenPositionId"
  ) as string;
  const location = useLocation();
  const handleApplyMembership = (rowData: any) => {
    if (rowData.IsMembershipRequestCreated) {
      toast.current?.show({
        severity: "error",
        summary: t("committee.error"),
        detail: t("committee.closedPosition"),
        life: 3000,
      });
    } else {
      const serializableRowData = JSON.parse(JSON.stringify(rowData));
      navigate("/member/create", { state: { rowData: serializableRowData } });
    }
  };

  const handleOpenPositionListing = async () => {
    setLoading(true);

    try {
      const url = hideActions
        ? "DashBoard/GetOpenPositionTopFiveCount?PageIndex=-1"
        : id
          ? `OpenPosition/SearchOpenPositions?Filters[0].Key=CommitteeId&Filters[0].Value=${id}&PageIndex=-1`
          : `OpenPosition/SearchOpenPositions?PageIndex=-1`;

      const response = await fetchData(url);

      let formattedResponse = !hideActions
        ? formatOpenPositionResponse(response.Collection)
        : formatOpenPositionResponse(response);

      formattedResponse = formattedResponse.map((item: any) => {
        const actions = [
          {
            label: "Apply Membership",
            onClick: () => handleApplyMembership(item),
          },
        ];
        return {
          ...item,
          Action: (
            <div>
              {actions.map((action, index) => (
                <a
                  key={index}
                  href="#"
                  onClick={action.onClick}
                  style={{
                    color: "blue",
                    textDecoration: "underline",
                    marginRight: "10px",
                  }}
                >
                  {action.label}
                </a>
              ))}
            </div>
          ),
        };
      });

      // Apply additional filtering for userRole if needed (non-dashboard case)
      if (!hideActions && userRole === "Member") {
        formattedResponse = formattedResponse.filter(
          (position: any) => position.statusName === "Open"
        );
      }

      setOpenPositionListing(formattedResponse);
    } catch (error) {
      console.error("Error fetching open positions:", error);
    } finally {
      setLoading(false);
    }
  };

  const [loading, setLoading] = useState<boolean>(true);
  const showSuccess = () => {
    toast.current?.show({
      severity: "success",
      summary: t("committee.success"),
      detail: t("committee.positionHoldSuccessfully"),
      life: 3000,
    });
  };

  const showCloseSuccess = () => {
    toast.current?.show({
      severity: "success",
      summary: t("committee.success"),
      detail: t("committee.positionCloseSuccessfully"),
      life: 3000,
    });
  };
  const showResumeSuccess = () => {
    toast.current?.show({
      severity: "success",
      summary: t("committee.success"),
      detail: t("committee.positionResumeSuccessfully"),
      life: 3000,
    });
  };

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);
  const { id } = useParams<{ id: string }>();

  const fetchCommitteeDetails = async () => {
    try {
      let responseData = await fetchData(`Committee/${id}`);
      setCommitteeStatus(responseData?.Status?.Name);
      return responseData;
    } catch (error) {}
  };

  const fetchCommittees = async () => {
    try {
      const response = await fetchData(
        "OpenPosition/FilterOpenPositionsThroughCommittees"
      );
      const committeesData = response.Collection;
      setCommittees(committeesData);
    } catch (error) {
      console.error("Error fetching committees:", error);
    }
  };
  if (!id) {
    sessionStorage.setItem("redirectionPath", "positions");
  }

  const fetchCategoryOfInterest = async () => {
    try {
      const response = await fetchData("CategoryOfInterest/GetAll");
      const categoryOfInterestData = response.Collection;
      setCoiData(categoryOfInterestData);
    } catch (error) {
      console.error("Error fetching COI:", error);
    }
  };

  const fetchOpenPositionStatus = async () => {
    try {
      const response = await fetchData(`OpenPositionStatus/GetAll`);
      const positionstatus = response.Collection;
      setOpenPositionStatus(positionstatus);

      const openPosition = positionstatus.find(
        (item: any) => item.Name.trim() === "Hold"
      );
      setHoldPositionStatusId(openPosition.Id);
      const openPositionClose = positionstatus.find(
        (item: any) => item.Name.trim() === "Closed"
      );
      setClosePositionStatusId(openPositionClose.Id);
      const openPositionResume = positionstatus.find(
        (item: any) => item.Name.trim() === "Open"
      );
      setResumePositionStatusId(openPositionResume.Id);
    } catch (error) {
      console.error("Error fetching OpenPosition Status:", error);
    }
  };

  const fetchMembershipPosition = async () => {
    try {
      const response = await fetchData("MembershipPosition/GetAll");
      const membershipPositionData = response.Collection;
      setMemberPosition(membershipPositionData);
    } catch (error) {
      console.error("Error fetching Membership Position:", error);
    }
  };

  useEffect(() => {
    fetchCommitteeDetails();
    handleOpenPositionListing();
    fetchCommittees();
    fetchCategoryOfInterest();
    fetchOpenPositionStatus();
    fetchMembershipPosition();
    if (hideActions) {
      setDashboardPagination(true);
    }
  }, []);

  const handleClose = async () => {
    if (!closeValue.trim()) {
      setValidationError(`${t("committee.reasonForCloseError")}`);
      return;
    }
    setValidationError("");
    const reasonForClose = {
      comment: closeValue,
      openPositionId: openPositionId,
      openPositionStatusId: closePositionStatusId,
    };
    const postDataResponse = await postData(
      `OpenPositionComment`,
      reasonForClose
    );
    setVisibleClose(false);
    setTimeout(() => {
      showCloseSuccess();
    }, 1000);
    handleOpenPositionListing();
  };

  const handleHold = async () => {
    if (!holdValue.trim()) {
      setValidationError(`${t("committee.reasonForHoldError")}`);
      return;
    }
    setValidationError("");
    const reasonForHold = {
      comment: holdValue,
      openPositionId: openPositionId,
      openPositionStatusId: holdPositionStatusId,
    };
    const postDataResponse = await postData(
      `OpenPositionComment`,
      reasonForHold
    );
    setVisibleHold(false);
    setTimeout(() => {
      showSuccess();
    }, 1000);
    handleOpenPositionListing();
  };

  const handleResume = async () => {
    if (!resumeValue.trim()) {
      setValidationError(`${t("committee.reasonForHoldError")}`);
      return;
    }
    setValidationError("");
    const reasonForResume = {
      comment: resumeValue,
      openPositionId: openPositionId,
      openPositionStatusId: resumePositionStatusId,
    };
    const postDataResponse = await postData(
      `OpenPositionComment`,
      reasonForResume
    );
    setVisibleResume(false);
    setTimeout(() => {
      showResumeSuccess();
    }, 1000);
    handleOpenPositionListing();
  };

  const handleCheckboxChange =
    (checkbox: string) => (e: CheckboxChangeEvent) => {
      const isChecked = e.checked !== undefined ? e.checked : false;
      switch (checkbox) {
        case "committee":
          setCommitteeCheckboxChecked(isChecked);
          break;
        case "coi":
          setCoiCheckboxChecked(isChecked);
          break;
        case "position":
          setPositionCheckboxChecked(isChecked);
          break;
        case "openPositionStatus":
          setPositionStatusCheckboxChecked(isChecked);
          break;
        default:
          break;
      }
    };

  const fetchFilteredData = async (event: any) => {
    const queryParams: string[] = [];
    const filters: { Key: string; Value: string }[] = [];

    // Get the CommitteeId from the URL path if it matches the desired pattern
    const pathRegex = /\/committee\/details\/([0-9a-fA-F-]+)/;
    const match = location.pathname.match(pathRegex);
    if (match) {
      const committeeIdFromUrl = match[1];
      filters.push({ Key: "CommitteeId", Value: committeeIdFromUrl });
    }

    if (committeeCheckboxChecked) {
      const committeeIds = selectedCommittees.map(
        (committee) => committee.CommitteeId
      );
      filters.push({ Key: "CommitteeId", Value: committeeIds.join(",") });
    }
    if (coiCheckboxChecked) {
      const coiIds = selectedCOIs.map((coi) => coi.Id);
      filters.push({ Key: "CategoryOfInterestId", Value: coiIds.join(",") });
    }
    if (positionCheckboxChecked) {
      const positionIds = selectedPositions.map((position) => position.Id);
      filters.push({
        Key: "MembershipPositionId",
        Value: positionIds.join(","),
      });
    }
    if (positionStatusCheckboxChecked) {
      const positionStatusIds = selectedOpenPositionStatuses.map(
        (status) => status.Id
      );
      filters.push({ Key: "statusid", Value: positionStatusIds.join(",") });
    }

    // Build query string
    filters.forEach((filter, index) => {
      queryParams.push(`Filters[${index}].Key=${filter.Key}`);
      queryParams.push(
        `Filters[${index}].Value=${encodeURIComponent(filter.Value)}`
      );
    });

    queryParams.push(`PageIndex=-1`);
    queryParams.push(`SearchText=`);
    queryParams.push(`SortBy=`);
    queryParams.push(`SortDir=asc`);

    const queryString = queryParams.join("&");

    const apiUrl = `${BASE_URL}/OpenPosition/SearchOpenPositions?${queryString}`;

    try {
      const response = await axiosInstance.get(apiUrl);

      if (response.status === 200) {
        const responseData = response.data.Collection;
        const formattedData = formatOpenPositionResponse(responseData);
        if (userRole === "Member") {
          const filteredPositions = formattedData.filter(
            (position: any) =>
              position.statusName?.trim().toLowerCase() === "open"
          );
          setFilteredOpenPosition(filteredPositions);
        } else {
          setFilteredOpenPosition(formattedData);
        }
        setIsFilterApplied(true);
        setIsFilterAppliedYes(true);
      } else {
        console.error("Error fetching data:", response.status);
      }
      if (overlayPanelRef.current) {
        overlayPanelRef.current.hide();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleCloseButtonClick = async (OpenPositionId: string) => {
    setOpenPositionId(OpenPositionId);
    setCloseValue("");
    setValidationError("");
    setVisibleClose(true);
  };

  const handleHoldButtonClick = async (OpenPositionId: string) => {
    setOpenPositionId(OpenPositionId);
    setHoldValue("");
    setValidationError("");
    setVisibleHold(true);
  };

  const handleResumeButtonClick = async (OpenPositionId: string) => {
    setOpenPositionId(OpenPositionId);
    setResumeValue("");
    setValidationError("");
    setVisibleResume(true);
  };

  const actionPositionBodyTemplate = (rowData: any) => {
    const actionItems: MenuItem[] = [
      {
        label: t("committee.viewEdit"),
        command: () => {
          const serializableRowData = JSON.parse(JSON.stringify(rowData));

          navigate("/positions/edit-position", {
            state: {
              rowData: serializableRowData,
              pathname: location.pathname,
            },
          });
        },
      },
    ];

    if (rowData.statusName !== "Closed") {
      actionItems.push({
        label: t("committee.close"),
        command: () => {
          handleCloseButtonClick(rowData.OpenPositionId);
        },
      });

      if (rowData.statusName === "Hold") {
        actionItems.push({
          label: t("committee.resume"),
          command: () => handleResumeButtonClick(rowData.OpenPositionId),
        });
      } else {
        actionItems.push({
          label: t("committee.hold"),
          command: () => handleHoldButtonClick(rowData.OpenPositionId),
        });
      }
    }

    if (userRole === "Member") {
      return (
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            handleApplyMembership(rowData);
          }}
          className="text-link underline font-bold"
        >
          Apply Membership
        </Link>
      );
    } else {
      return <CustomMenu actionItems={actionItems} rowData={rowData} />;
    }
  };

  const renderPositionFilledContent = (rowData: any) => {
    return (
      <a
        onClick={(e) => {
          e.preventDefault();
          handlePositionFilledClick(rowData);
        }}
        className="text-link font-bold"
      >
        {rowData.PositionFilled}
      </a>
    );
  };

  const handleAddOpenPosition = () => {
    navigate("/positions/add-position", {
      state: { pathname: location.pathname },
    });
  };

  const handlePositionFilledClick = (rowData: any) => {
    const serializableRowData = JSON.parse(JSON.stringify(rowData));

    navigate("/positions/count", { state: { rowData: serializableRowData } });
  };

  let filteredColumns = [
    {
      field: "CommitteeName",
      header: t("committee.committeeName"),
      sortable: true,
    },
    {
      field: "StaffName",
      header: t("committee.staffName"),
      sortable: true,
    },
    {
      field: "CategoryOfInterest",
      header: t("committee.categoryOfInterest"),
      sortable: true,
    },
    {
      field: "OpenPositions",
      header: t("committee.openPositions"),
      sortable: true,
    },
    {
      field: "NumberOfPositions",
      header: t("committee.numberOfPositions"),
      sortable: true,
    },
    {
      field: "PositionFilled",
      header: t("committee.positionFilled"),
      sortable: true,
      body: (rowData: any) => renderPositionFilledContent(rowData),
    },
    {
      field: "PositionOpenfrom",
      header: t("committee.positionOpen"),
      sortable: true,
    },
    {
      field: "statusName",
      header: t("committee.status"),
      sortable: true,
    },
    {
      field: "Action",
      header: t("Action"),
      body: actionPositionBodyTemplate,
      sortable: false,
    },
  ];

  // Modify columns if userRole is "Member"
  if (userRole === "Member" && id === undefined) {
    // Remove columns not needed for Member role
    const columnsToRemove = ["statusName", "PositionFilled"];
    filteredColumns = filteredColumns.filter(
      (col) => !columnsToRemove.includes(col.field)
    );
  } else if (userRole === "Member" && id !== undefined) {
    const columnsToRemove = [
      "statusName",
      "CommitteeName",
      "StaffName",
      "PositionFilled",
    ];
    filteredColumns = filteredColumns.filter(
      (col) => !columnsToRemove.includes(col.field)
    );
  } else if (
    (userRole === "Staff" || userRole === "Admin") &&
    id !== undefined
  ) {
    const columnsToRemove = ["CommitteeName", "StaffName"];
    filteredColumns = filteredColumns.filter(
      (col) => !columnsToRemove.includes(col.field)
    );
  }

  const footerContentHold = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("committee.positionCancel")}
        onClick={() => {
          setVisibleHold(false);
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("committee.positionHold")}
        onClick={handleHold}
        className="button-md"
      />
    </div>
  );

  const footerContentClose = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("committee.positionCancel")}
        onClick={() => {
          setVisibleClose(false);
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("committee.positionClose")}
        onClick={handleClose}
        className="button-md"
      />
    </div>
  );

  const footerContentResume = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("committee.positionCancel")}
        onClick={() => {
          setVisibleResume(false);
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("committee.resume")}
        onClick={handleResume}
        className="button-md"
      />
    </div>
  );

  return (
    <>
      <div>
        <Toast ref={toast} />
        {id === undefined && !hideActions && (
          <div className="mb-5 flex flex-column gap-2">
            <h1 className="text-title display-xs font-bold text-capitalize m-0">
              {t("committee.openPositions")}
            </h1>
            <p className="text-base font-normal m-0">
              {t("committee.positionText")}
            </p>
          </div>
        )}

        {!hideActions && (
          <div className="flex flex-wrap md:flex-nowrap md:justify-content-between py-3 gap-3 w-full">
            <IconField iconPosition="left" className="w-full md:w-16rem">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText
                id="recordId"
                placeholder={t("committee.search")}
                className="w-full"
                aria-label={t("committee.search")}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </IconField>
            <div className="flex gap-3 w-full md:w-auto align-items-center">
              <div className="relative">
                <Button
                  className="button-md gap-1"
                  severity="secondary"
                  onClick={toggleFilterPanel}
                  aria-label={t("committee.filter")}
                >
                  <FeatherIcon name="filter" size={20} color="inherit" />
                  <span className="font-bold text-capitalize hidden md:flex">
                    {t("committee.filter")}
                  </span>
                </Button>
                {/* OverlayPanel for Filter */}
                <OverlayPanel
                  ref={overlayPanelRef}
                  onHide={() => setShowFilterPanel(false)}
                  appendTo={"self"}
                  dismissable
                  className="p-overlaypanel-filter left-auto md:right-0 top-100"
                  aria-labelledby="filter-heading"
                  role="dialog"
                >
                  <div className="flex flex-column gap-1" tabIndex={0}>
                    <h3
                      id="filter-heading"
                      className="text-lg font-normal m-0 p-3"
                    >
                      {t("committee.filter")}
                    </h3>
                    <div className="filter-wrap">
                      {id === undefined && (
                        <div>
                          <label
                            htmlFor="committeeNameFilter"
                            className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                          >
                            <Checkbox
                              inputId="committeeNameFilter"
                              onChange={handleCheckboxChange("committee")}
                              checked={committeeCheckboxChecked}
                              className="mr-2"
                              tabIndex={0}
                            />
                            {t("committee.committeeName")}
                          </label>
                          <div className="pr-3 py-2 pl-6 w-full relative">
                            <MultiSelect
                              value={selectedCommittees}
                              onChange={(e) => setSelectedCommittees(e.value)}
                              options={committees}
                              optionLabel="CommitteeName"
                              filter
                              placeholder={t("committee.committeeName")}
                              maxSelectedLabels={3}
                              className="w-full"
                              aria-label={t("committee.committeeName")}
                            />
                          </div>
                        </div>
                      )}
                      <div className="p-0">
                        <label
                          htmlFor="categoryOfInterestFilter"
                          className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                        >
                          <Checkbox
                            inputId="categoryOfInterestFilter"
                            onChange={handleCheckboxChange("coi")}
                            checked={coiCheckboxChecked}
                            className="mr-2"
                            tabIndex={0}
                          />
                          {t("committee.categoryOfInterest")}
                        </label>
                        <div className="pr-3 py-2 pl-6 w-full relative">
                          <MultiSelect
                            value={selectedCOIs}
                            onChange={(e) => setSelectedCOIs(e.value)}
                            options={coiData}
                            optionLabel="CategoryName"
                            filter
                            placeholder={t("committee.categoryOfInterest")}
                            maxSelectedLabels={3}
                            className="w-full"
                            aria-label={t("committee.categoryOfInterest")}
                          />
                        </div>
                      </div>
                      <div className="p-0">
                        <label
                          htmlFor="membershipPositionFilter"
                          className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                        >
                          <Checkbox
                            inputId="membershipPositionFilter"
                            onChange={handleCheckboxChange("position")}
                            checked={positionCheckboxChecked}
                            className="mr-2"
                            tabIndex={0}
                          />
                          {t("committee.membershipPosition")}
                        </label>
                        <div className="pr-3 py-2 pl-6 w-full relative">
                          <MultiSelect
                            value={selectedPositions}
                            onChange={(e) => setSelectedPositions(e.value)}
                            options={memberPosition}
                            optionLabel="PositionName"
                            filter
                            placeholder={t("committee.membershipPosition")}
                            maxSelectedLabels={3}
                            className="w-full"
                            aria-label={t("committee.membershipPosition")}
                          />
                        </div>
                      </div>
                      {userRole !== "Member" && (
                        <div className="p-0">
                          <label
                            htmlFor="recordStatusFilter"
                            className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                          >
                            <Checkbox
                              inputId="recordStatusFilter"
                              onChange={handleCheckboxChange(
                                "openPositionStatus"
                              )}
                              checked={positionStatusCheckboxChecked}
                              className="mr-2"
                              tabIndex={0}
                            />
                            {t("committee.openPositionStatus")}
                          </label>
                          <div className="pr-3 py-2 pl-6 w-full relative">
                            <MultiSelect
                              value={selectedOpenPositionStatuses}
                              onChange={(e) =>
                                setSelectedOpenPositionStatuses(e.value)
                              }
                              options={openPositionStatus}
                              optionLabel="Name"
                              filter
                              placeholder={t("committee.openPositionStatus")}
                              maxSelectedLabels={3}
                              className="w-full"
                              aria-label={t("committee.openPositionStatus")}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <Button
                      className="button-md w-full justify-content-center"
                      onClick={(e: any) => fetchFilteredData(e)}
                    >
                      {t("committee.applyFilter")}
                    </Button>
                  </div>
                </OverlayPanel>
              </div>
              {userRole !== "Member" && (
                <Button
                  className="button-md gap-1 justify-content-center w-full md:w-auto"
                  onClick={handleAddOpenPosition}
                  disabled={committeeStatus === "Draft"}
                >
                  <FeatherIcon name="plus" size={20} color="inherit" />
                  <span className="font-bold text-capitalize">
                    {t("committee.openPosition")}
                  </span>
                </Button>
              )}
            </div>
          </div>
        )}

        <Table
          loading={loading}
          products={
            isFilterApplied
              ? filteredOpenPosition?.filter((item: any) => {
                  const committeeName = item.OpenPositions || "";
                  return (
                    searchvalue === "" ||
                    committeeName
                      .toLowerCase()
                      .includes(searchvalue.toLowerCase())
                  );
                })
              : openPositionListing?.filter((item: any) => {
                  const committeeName = item.OpenPositions || "";
                  return (
                    searchvalue === "" ||
                    committeeName
                      .toLowerCase()
                      .includes(searchvalue.toLowerCase())
                  );
                })
          }
          columns={filteredColumns}
          disablePagination={dashboardPagination}
        />
        <Dialog
          id="visiblePositionHold"
          header={t("committee.wantToHold")}
          visible={visibleHold}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
          onHide={() => setVisibleHold(false)}
          footer={footerContentHold}
          className="custom-dialog hide-close"
          ariaCloseIconLabel="Close"
        >
          <div className="flex flex-column gap-2 w-full">
            <label
              htmlFor="positionHold"
              className={`block font-light font-bold text-capitalize ${
                validationError ? "p-error" : ""
              }`}
            >
              {" "}
              {t("committee.reasonForHold")}
            </label>
            <InputTextarea
              id="positionHold"
              value={holdValue}
              onChange={(e) => {
                const value = e.target.value;
                setHoldValue(value);
                if (value.trim()) {
                  setValidationError("");
                }
              }}
              rows={5}
              cols={30}
              className={validationError ? "p-invalid" : ""}
            />
            <span
              className={`p-error font-bold text-capitalize ${
                validationError ? "" : "error-hidden"
              }`}
            >
              {validationError}
            </span>
          </div>
        </Dialog>

        <Dialog
          id="visiblePositionClose"
          header={t("committee.wantToClose")}
          visible={visibleClose}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
          onHide={() => {
            setVisibleClose(false);
          }}
          footer={footerContentClose}
          className="custom-dialog hide-close"
          ariaCloseIconLabel="Close"
        >
          <div className="flex flex-column gap-2 w-full">
            <label
              htmlFor="positionClose"
              className={`block font-light font-bold text-capitalize ${
                validationError ? "p-error" : ""
              }`}
            >
              {" "}
              {t("committee.reasonForClose")}
            </label>
            <InputTextarea
              id="positionClose"
              value={closeValue}
              onChange={(e) => {
                const value = e.target.value;
                setCloseValue(value);
                if (value.trim()) {
                  setValidationError("");
                }
              }}
              rows={5}
              cols={30}
              className={validationError ? "p-invalid" : ""}
            />
            <span
              className={`p-error font-bold text-capitalize ${
                validationError ? "" : "error-hidden"
              }`}
            >
              {validationError}
            </span>
          </div>
        </Dialog>

        <Dialog
          id="visiblePositionResume"
          header={t("committee.wantToResume")}
          visible={visibleResume}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
          onHide={() => setVisibleResume(false)}
          footer={footerContentResume}
          className="custom-dialog hide-close"
          ariaCloseIconLabel="Close"
        >
          <div className="flex flex-column gap-2 w-full">
            <label
              htmlFor="positionClose"
              className={`block font-light font-bold text-capitalize ${
                validationError ? "p-error" : ""
              }`}
            >
              {" "}
              {t("committee.reasonForResume")}
            </label>
            <InputTextarea
              id="positionResume"
              value={resumeValue}
              onChange={(e) => {
                const value = e.target.value;
                setResumeValue(value);
                if (value.trim()) {
                  setValidationError("");
                }
              }}
              rows={5}
              cols={30}
              className={validationError ? "p-invalid" : ""}
            />
            <span
              className={`p-error font-bold text-capitalize ${
                validationError ? "" : "error-hidden"
              }`}
            >
              {validationError}
            </span>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default OpenPositionsListing;
