import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { BreadCrumb } from "primereact/breadcrumb";
import CommitteeSteps from "./CommitteeSteps";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { Link, useNavigate } from "react-router-dom";
import Table from "../common/Table";
import { MenuItem } from "primereact/menuitem";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { reset } from "../../slices/createCommitteeSlice";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { putData } from "../../services/apiService";
import { formatCongigurationCommitteeData } from "../../utils/utils";
import { Toast } from "primereact/toast";
import PreviewItem from "../common/PreviewItem";
interface Option {
  name: string;
  code: string;
}
const CommitteePreview: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const dispatch = useDispatch();
  const createCommitteeData = useSelector(
    (state: RootState) => state.createCommittee
  );

  const breadcumbItems = [
    { label: t("committee.dashboard"), url: "/dashboard" },
    { label: t("committee.committee"), url: "/committee" },
    { label: t("committee.createNewCommittee") },
  ];

  const [checked, setChecked] = useState<boolean>(false);
  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const [categoryOfInteresetData, setCategoryOfInteresetData] = useState<any>();
  const percent = 70; // Set your percentage here

  // Define the style object as a regular JavaScript object
  const circleStyle = {
    "--percent": `${percent}`, // Set the percentage as a custom CSS variable
  };
  const [dateActivation, setDateActivation] = useState(null);
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(true);
  const [committeeMember, setCommitteeMember] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formatName = (fullname: string) => {
    if (!fullname) {
      return ["", ""];
    }

    const nameParts = fullname.split("(");

    const fullName = nameParts[0]?.trim() || "";
    const email = nameParts[1]
      ? nameParts[1].substring(0, nameParts[1].length - 1).trim()
      : "";

    return [fullName, email];
  };
  const formatCommitteeMemberData = (data: any) => {
    let formatedMemberData = data?.map((val: any) => {
      if (typeof val === "object") {
        return {
          name: formatName(val.name?.name)[0],
          email: formatName(val.name?.name)[1],
          committeePosition: val.membershipPositionId.name,
          categoryInterest: val.categoryOfInterestId.name,
          startDate: val.startDate
            ? new Date(val.startDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            : "",
          endDate: val.endDate
            ? new Date(val.endDate).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })
            : "",
          tenure: val.tenureLength.name,
        };
      }
    });
    return formatedMemberData;
  };
  useEffect(() => {
    if (Object.keys(createCommitteeData.addMembersInCommittee).length > 0) {
      let draftedData: any = Object.values(
        createCommitteeData.addMembersInCommittee
      )
        .filter((val) => typeof val === "object")
        .map((val) => val);
      let formatMemberDataForTable = formatCommitteeMemberData(draftedData);
      setCommitteeMember(formatMemberDataForTable);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(createCommitteeData.categoryOfIntereset)) {
      const categoryOfInteresetArray = Object.values(
        createCommitteeData.categoryOfIntereset
      )
        .map((val) => val)
        ?.map((details: any) => {
          return {
            categoryOfInterest: details.categoryOfInterestValue?.name,
            min: details?.min ? `${details?.min} Members` : "",
            threshold: details?.threshold ? `${details?.threshold}%` : "",
            max: details?.max ? `${details?.max} Members` : "",
          };
        });

      setCategoryOfInteresetData(categoryOfInteresetArray);
      setLoading(false);
    }
    function isEmpty(obj: any) {
      return Object.keys(obj).length === 0;
    }
  }, [createCommitteeData.categoryOfIntereset]);

  const showSuccessCommitteeCreated = (msg: string) => {
    toast.current?.show({
      severity: "success",
      summary: t("committee.success"),
      detail: msg,
      life: 3000,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const changeCommitteeStatus = await formatCongigurationCommitteeData(
      createCommitteeData.configureCommittee,
      createCommitteeData.createCommittee,
      true
    );
    const committeeResponse = await putData(
      "Committee",
      createCommitteeData.createCommittee.committeeId,
      changeCommitteeStatus
    );
    dispatch(reset());
    showSuccessCommitteeCreated(
      `${createCommitteeData.createCommittee.committeeName} Created Successfully`
    );
    setTimeout(() => {
      navigate("/committee");
    }, 3000);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="">
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />

        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">
            {t("committee.createNewCommittee")}
          </h1>
          {/* <p className="text-base font-normal m-0">
            Any supporting text will go here
          </p> */}
        </div>

        <CommitteeSteps activeStep={3} />

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("committee.committeeDetails")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("committee.committeeName")}
                value={createCommitteeData.createCommittee?.committeeName}
              />
              <PreviewItem
                label={t("committee.committeeAbbreviation")}
                value={
                  createCommitteeData.createCommittee?.committeeAbbreviation
                }
              />
              <PreviewItem
                label={t("committee.parentCommittee")}
                value={
                  createCommitteeData.createCommittee?.parentCommittee?.name
                }
              />
              <PreviewItem
                label={t("committee.committeeType")}
                value={
                  createCommitteeData.createCommittee?.hierarchyLevel?.name
                }
              />
              <PreviewItem
                label={t("committee.dateOfActivation")}
                value={
                  createCommitteeData.createCommittee?.activationDate
                    ? new Date(
                        createCommitteeData.createCommittee?.activationDate
                      ).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })
                    : ""
                }
              />
              <PreviewItem
                label={t("committee.committeeCharter")}
                value={
                  createCommitteeData.createCommittee?.charterName
                    ? createCommitteeData.createCommittee?.charterName
                    : "-"
                }
              />
              <PreviewItem
                label={t("committee.charterApproval")}
                value={
                  createCommitteeData.createCommittee?.charterStatus?.name
                    ? createCommitteeData.createCommittee?.charterStatus?.name
                    : "-"
                }
              />
              <PreviewItem
                label={t("committee.recordIdForTheApprovedCharter")}
                value={createCommitteeData.createCommittee?.recordId}
              />
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.basicCommitteeConfiguration")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("committee.defaultFromParentCommittee")}
                value={
                  createCommitteeData.configureCommittee?.defaultParent
                    ? t("committee.yes")
                    : t("committee.no")
                }
              />
              <PreviewItem
                label={t("committee.committeeType")}
                value={createCommitteeData.createCommittee?.committeeType?.name}
              />
              <PreviewItem
                label={t("committee.restrictedCommittee")}
                value={
                  createCommitteeData.configureCommittee
                    ?.isNatureofCommitteeRestricted
                    ? t("committee.yes")
                    : t("committee.no")
                }
              />
              <PreviewItem
                label={t("committee.usTagCommittee")}
                value={t("committee.no")}
              />
              <PreviewItem
                label={t("committee.theBalanceOfInterestToBeMaintained")}
                value={
                  createCommitteeData.configureCommittee
                    ?.isBalanceofInterestRequiredtoMaintained
                    ? t("committee.yes")
                    : t("committee.no")
                }
              />
              {/* <PreviewItem
                label={t("committee.criteriaForBalanceOfInterest")}
                value={
                  createCommitteeData.configureCommittee?.balanceofInterest
                }
              /> */}
              <PreviewItem
                label={t("committee.allowApplications")}
                value={
                  createCommitteeData.configureCommittee
                    ?.acceptApplicationWithoutOpenPositions
                    ? t("committee.yes")
                    : t("committee.no")
                }
              />
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.committeeMembershipConfiguration")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-7">
              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-normal text-base text-title w-full">
                  {t("committee.numberOfMembersInTheCommittee")}
                </h3>
                <div className="flex flex-column gap-4">
                  <PreviewItem
                    label={t("committee.minimum")}
                    value={
                      createCommitteeData.configureCommittee?.committeeMinMember
                    }
                  />
                  <PreviewItem
                    label={t("committee.maximum")}
                    value={
                      createCommitteeData.configureCommittee?.committeeMaxMember
                    }
                  />
                </div>
              </div>
              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-normal text-base text-title w-full">
                  {t("committee.criteriaForMembershipEligiblity")}
                </h3>
                <div className="flex flex-column gap-4">
                  <PreviewItem
                    label={t("committee.subTierCommittee")}
                    value={
                      createCommitteeData.configureCommittee
                        ?.msElig_SubTierCommitte_Months
                    }
                    extension={
                      createCommitteeData.configureCommittee
                        ?.msElig_SubTierCommitte_Months
                        ? t("committee.months")
                        : "-"
                    }
                  />
                  <PreviewItem
                    label={t("committee.subTierCommitteePositions")}
                    value={
                      createCommitteeData.configureCommittee
                        ?.msElig_SameCommitte_Officer_Months
                    }
                    extension={
                      createCommitteeData.configureCommittee
                        ?.msElig_SameCommitte_Officer_Months
                        ? t("committee.months")
                        : "-"
                    }
                  />
                </div>
              </div>
              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-normal text-base text-title w-full">
                  {t("committee.membershipTenure")}
                </h3>
                <div className="flex flex-column gap-4">
                  <PreviewItem
                    label={t("committee.tenureOfOfficerPosition")}
                    value={
                      createCommitteeData.configureCommittee
                        ?.msExpire_Officer_Tenure_Years
                    }
                    extension={t("committee.years")}
                  />
                  <PreviewItem
                    label={t("committee.tenureOfMembership")}
                    value={
                      createCommitteeData.configureCommittee
                        ?.msExpire_Member_Tenure_Years
                    }
                    extension={t("committee.years")}
                  />
                  <PreviewItem
                    label={t("committee.maximumConsecutiveTerms")}
                    value={
                      createCommitteeData.configureCommittee
                        ?.officerMaximumTermYears
                    }
                    extension={t("committee.years")}
                  />
                  <PreviewItem
                    label={t("committee.durationToReconfirm")}
                    value={
                      createCommitteeData.configureCommittee
                        ?.applicationAutoWithdrawalMonths
                        ? createCommitteeData.configureCommittee
                            ?.applicationAutoWithdrawalMonths
                        : ""
                    }
                    extension={
                      createCommitteeData.configureCommittee
                        ?.applicationAutoWithdrawalMonths
                        ? t("committee.months")
                        : "-"
                    }
                  />
                </div>
              </div>
              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-normal text-base text-title w-full">
                  {t("committee.notificationToStaff")}
                </h3>
                <div className="flex flex-column gap-4">
                  <PreviewItem
                    label={t("committee.firstNotification")}
                    value={
                      createCommitteeData.configureCommittee
                        ?.notifyStaffExpirationofMembershipDays_First
                    }
                    extension={
                      createCommitteeData.configureCommittee
                        ?.notifyStaffExpirationofMembershipDays_First
                        ? t("committee.days")
                        : "-"
                    }
                  />
                  <PreviewItem
                    label={t("committee.secondNotification")}
                    value={
                      createCommitteeData.configureCommittee
                        ?.notifyStaffExpirationofMembershipDays_Second
                    }
                    extension={
                      createCommitteeData.configureCommittee
                        ?.notifyStaffExpirationofMembershipDays_Second
                        ? t("committee.days")
                        : "-"
                    }
                  />
                  <PreviewItem
                    label={t("committee.thirdNotification")}
                    value={
                      createCommitteeData.configureCommittee
                        ?.notifyStaffExpirationofMembershipDays_Third
                    }
                    extension={
                      createCommitteeData.configureCommittee
                        ?.notifyStaffExpirationofMembershipDays_Third
                        ? t("committee.days")
                        : "-"
                    }
                  />
                </div>
              </div>
              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-normal text-base text-title w-full">
                  {t("committee.notificationToMember")}
                </h3>
                <div className="flex flex-column gap-4">
                  <PreviewItem
                    label={t("committee.firstNotification")}
                    value={
                      createCommitteeData.configureCommittee
                        ?.notifyMemberExpirationofMembershipDays_First
                    }
                    extension={t("committee.days")}
                  />
                  <PreviewItem
                    label={t("committee.secondNotification")}
                    value={
                      createCommitteeData.configureCommittee
                        ?.notifyMemberExpirationofMembershipDays_Second
                    }
                    extension={
                      createCommitteeData.configureCommittee
                        ?.notifyMemberExpirationofMembershipDays_Second
                        ? t("committee.days")
                        : "-"
                    }
                  />
                  <PreviewItem
                    label={t("committee.thirdNotification")}
                    value={
                      createCommitteeData.configureCommittee
                        ?.notifyMemberExpirationofMembershipDays_Third
                    }
                    extension={
                      createCommitteeData.configureCommittee
                        ?.notifyMemberExpirationofMembershipDays_Third
                        ? t("committee.days")
                        : "-"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.notificationAlertsToOfficers")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("committee.numberMeetingsMissed")}
                value={
                  createCommitteeData.configureCommittee?.numberofMeetingMissed
                    ? createCommitteeData.configureCommittee
                        ?.numberofMeetingMissed
                    : "-"
                }
              />
              <PreviewItem
                label={t("committee.numberBallotsMissed")}
                value={
                  createCommitteeData.configureCommittee?.numberofBallotsMissed
                    ? createCommitteeData.configureCommittee
                        ?.numberofBallotsMissed
                    : "-"
                }
              />
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.committeeStaffAndMembers")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <PreviewItem
                label={t("committee.appointedStaffContact")}
                value={createCommitteeData.createCommittee?.staffContact?.name}
              />
              <div className="flex flex-column gap-3">
                <h3 className="m-0 font-bold text-capitalize text-lg text-title w-full">
                  {t("committee.appointedMembers")}
                </h3>
                <Table
                  products={committeeMember}
                  loading={loading}
                  columns={[
                    { field: "name", header: t("committee.name") },
                    { field: "email", header: t("committee.email") },
                    {
                      field: "committeePosition",
                      header: t("committee.committeeApplied"),
                    },
                    {
                      field: "categoryInterest",
                      header: t("committee.categoryOfInterest"),
                    },
                    { field: "startDate", header: t("committee.startDate") },
                    { field: "endDate", header: t("committee.endDate") },
                    { field: "tenure", header: t("committee.tenure") },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("committee.categoryOfInterest")}
            </h2>
          </div>

          <div className="p-5 cardBody">
            <Table
              products={categoryOfInteresetData}
              loading={loading}
              columns={[
                {
                  field: "categoryOfInterest",
                  header: t("committee.categoryOfInterest"),
                },
                { field: "min", header: t("committee.minimumMembers") },
                { field: "threshold", header: t("committee.balancePercent") },
                {
                  field: "max",
                  header: t("committee.maximumMembers"),
                },
              ]}
            />
          </div>
        </div>

        <div className="bg-white hidden md:flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("committee.cancel")}
            className="button-md"
            severity="secondary"
            onClick={() => navigate("/committee/")}
          />
          <div className="flex md:flex-row gap-3">
            {/* <Button
              text
              className="p-button-plain underline"
              label={t("committee.saveAsDraft")}
            ></Button> */}
            <Button
              className="button-md gap-1"
              severity="secondary"
              onClick={() => navigate("/committee/members")}
            >
              <FeatherIcon name="chevron-left" size={20} color="inherit" />
              <span className="font-bold">{t("committee.back")}</span>
            </Button>
            <Button
              className="button-md gap-1"
              label={t("committee.submit")}
              disabled={isSubmitting}
              onClick={handleSubmit}
            ></Button>
          </div>
        </div>

        {/* Mobile responsive buttions */}
        <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            className="button-md gap-1 w-full justify-content-center"
            label={t("committee.submit")}
            onClick={handleSubmit}
          ></Button>
          <div className="flex align-items-center gap-3 w-full">
            <Button
              className="button-md gap-1"
              severity="secondary"
              onClick={() => navigate("/committee/members")}
              aria-label={t("committee.back")}
            >
              <FeatherIcon name="chevron-left" size={20} color="inherit" />
            </Button>
            <Button
              label={t("committee.cancel")}
              className="button-md w-full"
              severity="secondary"
            />
          </div>
          {/* <Button
            text
            className="p-button-plain underline w-full"
            label={t("committee.saveAsDraft")}
          ></Button> */}
        </div>
      </div>
    </>
  );
};

export default CommitteePreview;
