import React, { useEffect, useState } from "react";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import Table from "../common/Table";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "./Configuration.scss";
import { Editor, EditorTextChangeEvent } from "primereact/editor";
import FeatherIcon from "../common/FeatherIconComponent";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "primereact/checkbox";
interface Option {
  name: string;
  code: string;
}

const BallotApprovaCriteriaTab: React.FC = () => {
  const [t, i18n] = useTranslation("configuration");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const [value, setValue] = useState<string>('');

  const [isBallotApprovalVisible, setIsBallotApprovalVisible] = useState(false);
  const handleBallotApprovalHideShow = () => {
    setIsBallotApprovalVisible(!isBallotApprovalVisible);
  };

  const [applicableAt, setapplicableAt] = useState<string>('');

  const [newRemoveExistingRecordsRecirculatingBallots, setnewRemoveExistingRecordsRecirculatingBallots] = useState<boolean>(false);
  const [newRemoveExistingCommitteeRecirculatingBallots, setnewRemoveExistingCommitteeRecirculatingBallots] = useState<boolean>(false);

  return (
    <>
      <div className="">
        {!isBallotApprovalVisible && (
          <div>
            <div className="flex justify-content-end py-3 gap-3 w-full">
              <Button
                className="button-md gap-1 justify-content-center w-full md:w-auto"
                onClick={handleBallotApprovalHideShow}
              >
                <FeatherIcon name="plus" size={20} color="inherit" />
                <span className="font-bold text-capitalize">
                  {t("configuration.addBallotAdditionalConfiguration")}
                </span>
              </Button>
            </div>
            <Table
              loading={false}
              products={[]}
              columns={[
                {
                  field: "ballotLevel",
                  header: t("configuration.ballotLevel"),
                },
                {
                  field: "ballotType",
                  header: t("configuration.ballotType"),
                },
                {
                  field: "ballotSubType",
                  header: t("configuration.ballotSubType"),
                },
                {
                  field: "recordType",
                  header: t("configuration.recordType"),
                },
                {
                  field: "recordSubType",
                  header: t("configuration.recordSubType"),
                },
                {
                  field: "minBallotDurationWeeks",
                  header: t("configuration.minBallotDurationWeeks"),
                },
                {
                  field: "applicableAccreditationBody",
                  header: t("configuration.applicableAccreditationBody"),
                },
                { field: "action", header: t("configuration.action") },
              ]}
            />
          </div>
        )}

        {isBallotApprovalVisible && (
          <>
            <div className="card bg-white w-full mb-5 shadow-md">
              <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
                <Button
                  text
                  className="p-button-plain p-0"
                  aria-label={t("configuration.back")}
                  onClick={handleBallotApprovalHideShow}>
                  <FeatherIcon name="arrow-left" size={32} />
                </Button>
                <h2 className="text-title text-lg font-bold m-0">
                  {t("configuration.ballotAdditionalConfiguration")}
                </h2>
              </div>

              <div className="p-5 cardBody">
                <div className="flex flex-column gap-3">
                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-3">
                      <fieldset className="border-none p-0">
                        <legend className="block font-bold text-input-label p-0 mb-3">
                          {t("configuration.applicableAt")}
                        </legend>
                        <div className="flex flex-wrap gap-3">
                          <div className="flex align-items-center">
                            <RadioButton inputId="applicableAt1" name="applicableAt" value="Committee" onChange={(e: RadioButtonChangeEvent) => setapplicableAt(e.value)} checked={applicableAt === 'Committee'} />
                            <label htmlFor="applicableAt1" className="ml-2">Committee</label>
                          </div>
                          <div className="flex align-items-center">
                            <RadioButton inputId="applicableAt2" name="applicableAt" value="Organization" onChange={(e: RadioButtonChangeEvent) => setapplicableAt(e.value)} checked={applicableAt === 'Organization'} />
                            <label htmlFor="applicableAt2" className="ml-2">Organization</label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="committee"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.committee")}
                      </label>
                      <Dropdown
                        inputId="committee"
                        name="committee"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="applicableAccreditationBody"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.applicableAccreditationBody")}
                      </label>
                      <Dropdown
                        inputId="applicableAccreditationBody"
                        name="applicableAccreditationBody"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="ballotLevel"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.ballotLevel")}
                      </label>
                      <Dropdown
                        inputId="ballotLevel"
                        name="ballotLevel"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="ballotType"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.ballotType")}
                      </label>
                      <Dropdown
                        inputId="ballotType"
                        name="ballotType"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="ballotSubType"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.ballotSubType")}
                      </label>
                      <Dropdown
                        inputId="ballotSubType"
                        name="ballotSubType"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="recordType"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.recordType")}
                      </label>
                      <Dropdown
                        inputId="recordType"
                        name="recordType"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="recordSubType"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.recordSubType")}
                      </label>
                      <Dropdown
                        inputId="recordSubType"
                        name="recordSubType"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                    {/* <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="ballotType"
                    className="block font-bold text-input-label text-capitalize"
                  >
                    {t("configuration.recordSubSubType")}
                  </label>
                  <Dropdown
                    inputId="recordSubSubType"
                    name="recordSubSubType"
                    value={selectedOption}
                    onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                    options={options}
                    optionLabel="name"
                    placeholder={t("configuration.selectAnOption")}
                  />
                </div> */}
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="minBallotDurationWeeks"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.minBallotDurationWeeks")}
                      </label>
                      <Dropdown
                        inputId="minBallotDurationWeeks"
                        name="minBallotDurationWeeks"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-6 lg:col-8 md:col-12 col-12">
                      <label
                        htmlFor="votingOption"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.votingOption")}
                      </label>
                      <Dropdown
                        inputId="votingOption"
                        name="votingOption"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="defaultVotingOption"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.defaultVotingOption")}
                      </label>
                      <Dropdown
                        inputId="defaultVotingOption"
                        name="defaultVotingOption"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="votingConsensusCalculation"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.votingConsensusCalculation")}
                      </label>
                      <Dropdown
                        inputId="votingConsensusCalculation"
                        name="votingConsensusCalculation"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-6 lg:col-8 md:col-12 col-12">
                      <label
                        htmlFor="commentOption"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.commentOption")}
                      </label>
                      <Dropdown
                        inputId="commentOption"
                        name="commentOption"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.selectAnOption")}
                      />
                    </div>
                  </div>

                  <h3 className="font-bold text-lg m-0">
                    {t("configuration.recirculation")}
                  </h3>
                  <div className="flex flex-column gap-3 mb-3">
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="newRemoveExistingRecordsRecirculatingBallots"
                        name="newRemoveExistingRecordsRecirculatingBallots"
                        onChange={(e) =>
                          setnewRemoveExistingRecordsRecirculatingBallots(e.checked ?? false)
                        }
                        checked={newRemoveExistingRecordsRecirculatingBallots}
                        className="mr-2"
                      />
                      <label
                        htmlFor="newRemoveExistingRecordsRecirculatingBallots"
                        className="text-base font-normal"
                      >
                        {t("configuration.newRemoveExistingRecordsRecirculatingBallots")}
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <Checkbox
                        inputId="newRemoveExistingCommitteeRecirculatingBallots"
                        name="newRemoveExistingCommitteeRecirculatingBallots"
                        onChange={(e) =>
                          setnewRemoveExistingCommitteeRecirculatingBallots(e.checked ?? false)
                        }
                        checked={newRemoveExistingCommitteeRecirculatingBallots}
                        className="mr-2"
                      />
                      <label
                        htmlFor="newRemoveExistingCommitteeRecirculatingBallots"
                        className="text-base font-normal"
                      >
                        {t("configuration.newRemoveExistingCommitteeRecirculatingBallots")}
                      </label>
                    </div>
                  </div>

                  <h3 className="font-bold text-lg m-0">
                    {t("configuration.ballotApprovalCriteria")}
                  </h3>
                  <div className="ballot-approval-criteria-grid mb-3">
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="operator1"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.operator1")}*
                      </label>
                      <Dropdown
                        className="w-full"
                        inputId="operator1"
                        name="operator1"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.operator1")}
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="percent1"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        %*
                      </label>
                      <InputText
                        id="percent1"
                        name="percent1"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        type="number"
                        placeholder="%"
                        className="w-full"
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="votingOptions"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.votingOptions")}*
                      </label>
                      <Dropdown
                        className="w-full"
                        inputId="votingOptions"
                        name="votingOptions"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.votingOptions")}
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="operator2"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.operator2")}
                      </label>
                      <Dropdown
                        className="w-full"
                        inputId="operator2"
                        name="operator2"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.operator2")}
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="operator3"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.operator3")}
                      </label>
                      <Dropdown
                        className="w-full"
                        inputId="operator3"
                        name="operator3"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.operator3")}
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="percent2"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        %
                      </label>
                      <InputText
                        id="percent2"
                        name="percent2"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        type="number"
                        placeholder="%"
                        className="w-full"
                      />
                    </div>
                    <div className="flex flex-column gap-2">
                      <label
                        htmlFor="votingOption1"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.votingOption")}
                      </label>
                      <Dropdown
                        className="w-full"
                        inputId="votingOption1"
                        name="votingOption1"
                        value={selectedOption}
                        onChange={(e: DropdownChangeEvent) => setSelectedOption(e.value)}
                        options={options}
                        optionLabel="name"
                        placeholder={t("configuration.votingOption")}
                      />
                    </div>
                  </div>
                  {/* Threshold points  for weighted ballot */}
                  <div>
                    <div className="grid grid-xl">
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor="thresholdPoints"
                          className="block font-bold text-input-label text-capitalize"
                        >
                          {t("configuration.thresholdPoints")}
                        </label>
                        <InputText
                          id="thresholdPoints"
                          name="thresholdPoints"
                          value={value}
                          onChange={(e) => setValue(e.target.value)}
                          type="number"
                          placeholder={t("configuration.thresholdPoints")}
                          className="w-full"
                          min="1"
                        />
                      </div>
                    </div>
                    <p className="font-normal text-base m-0 mb-3">
                      {t("configuration.note")}: {t("configuration.thresholdPointsNote")}
                    </p>
                  </div>

                  <div className="grid grid-xl">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="durationWithdrawDisapproval"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.durationWithdrawDisapproval")}
                      </label>
                      <InputText
                        id="durationWithdrawDisapproval"
                        name="durationWithdrawDisapproval"
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        type="number"
                        placeholder={t("configuration.durationWithdrawDisapproval")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div className="bg-white flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
              <Button
                label={t("configuration.cancel")}
                className="button-md"
                severity="secondary"
                type="button"
                onClick={() => navigate("/configuration/")}
              />
              <Button
                label={t("configuration.save")}
                className="button-md"
                type="button"
                onClick={() => navigate("/configuration/")}
              />
            </div>
          </>
        )}

      </div>

    </>
  );
};

export default BallotApprovaCriteriaTab;
