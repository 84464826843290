import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TabPanel, TabView } from 'primereact/tabview';
import BallotTabComments from '../comments/BallotTabComments';
import BallotTabReport from '../BallotTabReport';
import BallotTabVoteSummary from '../BallotTabVoteSummary';
import BallotTabCastVote from '../BallotTabCastVote';
import BallotTabDetails from '../listing/BallotTabDetails';
import { BALLOT_CLOSE, BALLOT_DRAFT } from '../common/constants';
import BallotTabClousureReport from './BallotTabs/BallotTabClosureReport';

interface BallotTabsProps {
  setActiveIndex: any,
  activeIndex: any,
  userRole: string,
  ballotStatus?: string
}

const BallotTabs: React.FC<BallotTabsProps> = ({
  setActiveIndex,
  activeIndex,
  userRole,
  ballotStatus = ""
}) => {
  const [t] = useTranslation("ballot");
  const [currentBallotStatus, setCurrentBallotStatus] = useState(ballotStatus);

  // Use effect to watch for changes in ballotStatus prop
  useEffect(() => {
    setCurrentBallotStatus(ballotStatus);
  }, [ballotStatus]);

  return (
    <>
      {/* Show when ballot is in draft */}
      {currentBallotStatus === BALLOT_DRAFT && (
        <TabView scrollable activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header={t("ballot.details")}>
            <BallotTabDetails />
          </TabPanel>
        </TabView>
      )}

      {/* Show when ballot is NOT in draft */}
      {currentBallotStatus !== BALLOT_DRAFT && (
        <TabView scrollable activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <TabPanel header={t("ballot.details")}>
            <BallotTabDetails />
          </TabPanel>
          {currentBallotStatus !== "Closed" && userRole !== "Member" && (
            <TabPanel header={t("ballot.castEditVoteOnBehalf")}>
            <BallotTabCastVote />
            </TabPanel>
          )}
          {currentBallotStatus !== "Closed" && userRole === "Member" && (
            <TabPanel header={t("ballot.castAvote")}>
             <BallotTabCastVote />
           </TabPanel>
          )}
          {currentBallotStatus === "Closed" && (
             <TabPanel header={t("ballot.ballotClosureReport")}>
             <BallotTabClousureReport setActiveIndex={setActiveIndex} />
           </TabPanel>
          )}
          <TabPanel header={t("ballot.voteSummary")}>
            <BallotTabVoteSummary />
          </TabPanel>
          <TabPanel header={t("ballot.comments")}>
            <BallotTabComments />
          </TabPanel>
          {currentBallotStatus !== "Closed" && userRole !== "Member" && (
            <TabPanel header={t("ballot.ballotReport")}>
              <BallotTabReport setActiveIndex={setActiveIndex} />
            </TabPanel>
          )}
        </TabView>
      )}
    </>
  );
};

export default BallotTabs;
