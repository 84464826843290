import React, { useEffect, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import MemberRequestSteps from "./MemberRequestSteps";
import FeatherIcon from "../common/FeatherIconComponent";
import { fetchData, putData } from "../../services/apiService";
import { createMemberRequest } from "../../slices/createMemberRequestSlice";
import { RootState } from "../../store/store";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { Dialog } from "primereact/dialog";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Editor } from "primereact/editor";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { OpenPositionList } from "../position/OpenPositionsListing";

const CommitteeSelection: React.FC = () => {
  const [t, i18n] = useTranslation("member");
  const location = useLocation();
  const rowData = location.state?.rowData as any | undefined;
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const [editMode, setEditMode] = useState<boolean>(false);
  const [memberShipData, setMemberShipData] = useState<any>(null);

  const createCommitteeData = useSelector(
    (state: RootState) => state.createMemberRequest
  );
  const [selectedCommitteeId, setSelectedCommitteeId] = useState<string>("");
  const [selectedCommittee, setSelectedCommittee] = useState<any>(null);
  const [text, setText] = useState("");
  const [selectedCommitteeIndex, setselectedCommitteeIndex] = useState<
    number | null
  >(null);
  const [committees, setCommitteess] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [memberName, setMemberName] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Reappointment = sessionStorage.getItem("reappointment");
  const breadcumbItems = [
    { label: t("member.dashboard"), url: "/dashboard" },
    {
      label: Reappointment
        ? t("member.reappointmentHeading")
        : t("member.membershipRequest"),
    },
  ];
  const initialMessage = `
      <div>${t("member.dearAdmin")}</div>
      <div><br></div>
      <div>${t("member.requestMembershipCommittee")} : [CommitteeName] , ${t("member.notFoundApplication")}</div>
      <div>${t("member.pleaseLookIntoIt")}</div>
      <div><br></div>
      <div>${t("member.thanks")}</div>
      <div>${memberName}</div>
  `;

  const viewDetails = () => {
    if (rowData) {
      navigate("/member/committee", { state: { rowData } });
    } else {
      navigate("/member/committee");
    }
  };

  const fetchCurrentUser = async () => {
    try {
      const response = await fetchData("UserProfile/GetCurrentUserProfile");
      const currentuser = response;
      setMemberName(`${currentuser.Firstname} ${currentuser.Lastname}`);
    } catch (error) {
      console.error("Error fetching COI:", error);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    setText(initialMessage);
  }, [memberName]);

  const handleSendEmail = async () => {
    const pattern = new RegExp(
      `${t("member.requestMembershipCommittee")}\\s*:\\s*(.*?)\\s*,\\s*${t("member.notFoundApplication")}`
    );
    const committeeNameMatch = text.match(pattern);
    const extractedCommitteeName =
      committeeNameMatch && committeeNameMatch[1]
        ? committeeNameMatch[1].trim()
        : "";
    const sendEmailDTO = {
      CommiteeName: extractedCommitteeName,
      memberName: memberName,
      body: text,
    };
    try {
      const response = await putData(
        "MembershipRequest/MembershipRequestSendEmail",
        undefined,
        sendEmailDTO
      );
      setFindCommittee(false);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  React.useEffect(() => {
    const fetchAllCommittees = async () => {
      try {
        if (rowData) {
          const responseData = await fetchData("Committee/GetAll");
          const Data = responseData?.Collection;

          setCommitteess(Data);
          const selectedCommittee = Data.find(
            (committee: any) => committee.Name === rowData.CommitteeName
          );

          if (selectedCommittee) {
            setSelectedCommitteeId(selectedCommittee.Id);
            setselectedCommitteeIndex(Data.indexOf(selectedCommittee));
          }
          setLoading(false);
        } else {
          const responseData = await fetchData("Committee/GetAll");

          const filteredData = responseData.Collection.filter((item: any) => {
            return (
              item.AcceptApplicationWithoutOpenPositions === true &&
              item.Status.Name === "Created"
            );
          });

          setCommitteess(filteredData);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching committees:", error);
        setLoading(false);
      }
    };
    fetchAllCommittees();
  }, []);

  const selectCommittee = (committee: any, index: number) => {
    setSelectedCommitteeId(committee.Id);
    setselectedCommitteeIndex(index);
    sessionStorage.setItem("committeeId", committee.Id);
    dispatch(createMemberRequest({ committeeId: committee.Id }));
  };

  React.useEffect(() => {
    const storedMemberShipId = sessionStorage.getItem("memberShipId");
    if (storedMemberShipId && !editMode) {
      setEditMode(true);
      const fetchMemberData = async () => {
        try {
          const responseData = await fetchData(
            "MembershipRequest",
            storedMemberShipId
          );
          let committeeIdToUse;
          if (createCommitteeData && createCommitteeData.data?.committeeId) {
            committeeIdToUse = createCommitteeData.data?.committeeId;
          } else if (responseData && responseData.CommitteeId) {
            committeeIdToUse = responseData.CommitteeId;
          } else {
            console.error("No committee ID available.");
            return;
          }
          setSelectedCommitteeId(committeeIdToUse);
          setMemberShipData(responseData);
        } catch (error) {
          console.error("Error fetching membership data:", error);
        }
      };
      fetchMemberData();
    }
    if (createCommitteeData.data && createCommitteeData.data.committeeId) {
      setSelectedCommitteeId(createCommitteeData.data.committeeId);
    } else {
      const storedCommitteeId: any = sessionStorage.getItem("committeeId");
      setSelectedCommitteeId(storedCommitteeId);
    }
  }, [createCommitteeData, editMode]);

  useEffect(() => {
    const fetchCommitteeData = async (committeeId: string) => {
      try {
        const responseData = await fetchData("Committee", committeeId);
        setSelectedCommittee(responseData);
      } catch (error) {
        console.error("Error fetching Committee data:", error);
      }
    };

    const committeeIdToFetch = rowData?.CommitteeId || selectedCommitteeId;
    if (committeeIdToFetch) {
      fetchCommitteeData(committeeIdToFetch);
    }

    if (Reappointment) {
      const storedCommitteeId: any = sessionStorage.getItem("committeeId");
      fetchCommitteeData(storedCommitteeId);
    }
  }, [rowData, selectedCommitteeId]);

  const onhandleContinue = () => {
    debugger;
    if (editMode) {
      dispatch(
        createMemberRequest({
          committeeId: selectedCommitteeId,
          membershipRequestid: memberShipData.Id,
        })
      );
      sessionStorage.setItem(
        "committeeId",
        JSON.stringify({
          committeeId: selectedCommitteeId,
          membershipRequestid: memberShipData.Id,
        })
      );
    }
    navigate(`/member/details`, { state: { rowData } });
  };

  const [visibleCommittee, setFindCommittee] = useState<boolean>(false);
  const footerContentFindCommittee = (
    <div className="mt-4 flex justify-content-end">
      <Button
        label={t("member.cancel")}
        onClick={() => {
          setFindCommittee(false);
        }}
        className="button-md"
        severity="secondary"
      />
      <Button
        label={t("member.sendEmail")}
        onClick={handleSendEmail}
        className="button-md"
      />
    </div>
  );

  return (
    <>
      <div className="">
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />

        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {Reappointment
              ? t("member.reappointmentHeading")
              : t("member.membershipRequest")}
          </h1>
          <p className="text-base font-normal m-0">
            {t("member.membershipRequestSubText")}
          </p>
        </div>

        <MemberRequestSteps activeStep={0} />

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5">
              <p className="text-title text-lg font-bold m-0">
                {t("member.thankYouMsg")}
              </p>
              {!Reappointment && (
                <div className="grid grid-xl align-items-center justify-content-between">
                  <div className="flex align-items-center gap-3 xl:col-8 lg:col-8 md:col-8 col-12 bg-brand-secondary">
                    <IconField iconPosition="left" className="w-full">
                      <InputIcon className="pi pi-search"> </InputIcon>
                      <InputText
                        placeholder={t("member.searchByKeywordsCommittee")}
                        className="w-full"
                        aria-label={t("member.searchByKeywordsCommittee")}
                        value={searchQuery} // Bind the search query value
                        onChange={(e) => setSearchQuery(e.target.value)}
                      />
                    </IconField>
                    {/* <Dropdown
                    inputId="standardName"
                    value={selectedOption}
                    onChange={(e: DropdownChangeEvent) =>
                      setSelectedOption(e.value)
                    }
                    options={options}
                    optionLabel="name"
                    placeholder="Select an Option"
                    className="w-full"
                    aria-label="Standard name"
                  /> */}
                  </div>
                  <Button
                    text
                    label={t("member.cannotFindCommitteeStandards")}
                    onClick={() => {
                      setFindCommittee(true);
                    }}
                    className="p-button-plain underline"
                  />
                </div>
              )}

              <div className="flex flex-column gap-3">
                {selectedCommitteeId && !loading && (
                  <div>
                    <h2 className="m-0 text-lg font-bold">
                      {t("member.selectedCommittee")}
                    </h2>
                    <ul className="list-none p-0 m-0 shadow-md mt-3">
                      <li className="flex align-items-center justify-content-between pl-4 pr-2 py-3 border-1 border-gray-200 gap-2 md:gap-3">
                        <div className="flex flex-column gap-2">
                          <p className="m-0 text-base font-bold break-word">
                            {selectedCommittee?.Name}
                          </p>
                          <p className="m-0 text-base font-normal">
                            {selectedCommittee?.CommitteeType?.Description}
                          </p>
                        </div>
                        <Button
                          text
                          className="p-button-plain gap-1 underline"
                          onClick={viewDetails}
                        >
                          <span className="font-bold hidden md:flex">
                            {t("member.viewDetails")}
                          </span>
                          <FeatherIcon name="chevron-right" size={20} />
                        </Button>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
              {!rowData && !Reappointment && (
                <div className="flex flex-column gap-3">
                  <h2 className="m-0 text-lg font-bold">
                    {t("member.browseByCommittee")}
                  </h2>
                  {loading && (
                    <div className="flex flex-column gap-3">
                      <ProgressSpinner
                        style={{ width: "50px", height: "50px" }}
                        strokeWidth="4"
                        fill="var(--surface-ground)"
                        animationDuration=".5s"
                      />
                    </div>
                  )}
                  {!loading && (
                    <ul className="list-none p-0 m-0 shadow-md">
                      {selectedCommitteeId
                        ? // Don't show the selected committee if selectedCommitteeId exists
                          committees
                            ?.filter((committee: any) =>
                              committee.Name.toLowerCase().includes(
                                searchQuery.toLowerCase()
                              )
                            )
                            .map(
                              (committee: any, index: number) =>
                                // Check if the current committee's id is not equal to selectedCommitteeId
                                committee.id !== selectedCommitteeId && (
                                  <li
                                    key={index}
                                    onClick={() =>
                                      selectCommittee(committee, index)
                                    }
                                    className={`flex align-items-center justify-content-between pl-4 pr-2 py-3 border-1 border-gray-200 gap-2 md:gap-3 ${selectedCommitteeIndex === index ? "selected" : ""}`}
                                  >
                                    <div className="flex flex-column gap-2">
                                      <p className="m-0 text-base font-bold break-word">
                                        {committee.Name}
                                      </p>
                                      <p className="m-0 text-base font-normal">
                                        {committee.Abbreviation}
                                      </p>
                                    </div>
                                    <Button
                                      text
                                      className="p-button-plain gap-1 underline"
                                      onClick={viewDetails}
                                    >
                                      <span className="font-bold hidden md:flex">
                                        {t("member.viewDetails")}
                                      </span>
                                      <FeatherIcon
                                        name="chevron-right"
                                        size={20}
                                      />
                                    </Button>
                                  </li>
                                )
                            )
                        : // Show the complete list if selectedCommitteeId doesn't exist
                          committees
                            ?.filter((committee: any) =>
                              committee.Name.toLowerCase().includes(
                                searchQuery.toLowerCase()
                              )
                            )
                            .map((committee: any, index: number) => (
                              <li
                                key={index}
                                onClick={() =>
                                  selectCommittee(committee, index)
                                }
                                className={`flex align-items-center justify-content-between pl-4 pr-2 py-3 border-1 border-gray-200 gap-2 md:gap-3 shadow-hover ${selectedCommitteeIndex === index ? "selected" : ""}`}
                              >
                                <div className="flex flex-column gap-2">
                                  <p className="m-0 text-base font-bold break-word">
                                    {committee.Name}
                                  </p>
                                  <p className="m-0 text-base font-normal">
                                    {committee.Abbreviation}
                                  </p>
                                </div>
                                <Button
                                  text
                                  className="p-button-plain gap-1 underline"
                                  onClick={viewDetails}
                                >
                                  <span className="font-bold hidden md:flex">
                                    {t("member.viewDetails")}
                                  </span>
                                  <FeatherIcon name="chevron-right" size={20} />
                                </Button>
                              </li>
                            ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="bg-white hidden md:flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("member.cancel")}
            className="button-md w-full sm:w-auto"
            severity="secondary"
            onClick={() => {
              if (location.state && location.state.pathname) {
                navigate(location.state.pathname);
              } else {
                navigate(-1);
              }
            }}
          />
          <Button
            label={t("member.confirmCommitteeContinue")}
            className="button-md w-full sm:w-auto"
            onClick={onhandleContinue}
            disabled={!selectedCommitteeId}
          />
        </div>

        {/* Mobile responsive buttions */}
        <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("member.confirmCommitteeContinue")}
            className="button-md w-full"
            onClick={onhandleContinue}
            disabled={!selectedCommitteeId}
          />
          <Button
            label={t("member.cancel")}
            className="button-md w-full"
            severity="secondary"
            onClick={() => {
              navigate("/member");
            }}
          />
        </div>
      </div>

      <Dialog
        id="visibleWithdrawId"
        header={t("member.cannotFindCommitteeStandards")}
        visible={visibleCommittee}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw", "400px": "98vw" }}
        onHide={() => setFindCommittee(false)}
        footer={footerContentFindCommittee}
        className="custom-dialog"
        ariaCloseIconLabel="Close"
      >
        <div className="flex flex-column gap-5">
          <p className="m-0">{t("member.cannotFindCommitteeStandardsMsg")}</p>

          <div className="flex flex-column gap-2 w-full">
            <label
              htmlFor="message"
              className="block font-bold text-input-label capitalize"
            >
              {t("member.message")}
            </label>
            <Editor
              value={text}
              onTextChange={(e) => setText(e.htmlValue || "")}
              style={{ height: "320px" }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CommitteeSelection;
