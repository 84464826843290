import React, { useRef, useEffect, useState } from "react";
import { Toast } from "primereact/toast";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import FeatherIcon from "../common/FeatherIconComponent";
import Input from "../../utils/Input";
import { RootState } from "../../store/store";
import useCommitteesData from "../member/useCommitteesData";
import {
  fetchDataForMemberShipPosition,
  fetchDataForCategoryOfInterest,
} from "../../services/apiUtils";
import { fetchData, postData, putData } from "../../services/apiService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { Button } from "primereact/button";
import pdfIcon from "../../assets/icons/fileIcon.svg";
import { configureRecordRequest } from "../../slices/createRecordSlice";
import CreateRecordHeader from "./createRecordHeader";
import { useLoadingContext } from "../Layout";
import { focusOnErrorField } from "../../utils/focusError";
import { BlobServiceClient } from "@azure/storage-blob";
import RecordListDocument from "../common/RecordListDocument";
import CreateTerminateRecordHeader from "./createTerminateRecordHeader";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";

type DropdownOption = {
  label: string;
  value: string;
};

interface TerminateReason {
  Reason: string;
  Description: string;
  Id: string;
}

interface Record {
  StatusName: string;
}

interface RecordDetails {
  membershipPositionId: string;
  categoryOfInterestId: string;
  remark: string;
  committeeId: string;
  id: string;
  recordTypeId: string;
  MembershipEndReasonId: string;
  userProfileId?: string;
  isDirty?: boolean;
  isNew?: boolean;
}

interface AxiosError extends Error {
  response?: {
    status?: number;
    data?: {
      messages?: string[];
    };
  };
}

const RecordDetails: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const toast = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createRecordData = useSelector(
    (state: RootState) => state.createRecord
  );
  const storedData = sessionStorage.getItem("committeeId");
  const selectedCommitteeID = sessionStorage.getItem("committeeId");
  let parsedData;
  if (storedData) {
    try {
      parsedData = JSON.parse(storedData);
    } catch (error) {
      parsedData = storedData;
    }
  } else {
    parsedData = {};
  }
  const [fileError, setFileError] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [file, setFile] = useState<any | undefined>(undefined);
  const [userDocumentTypeId, setUserDocumentTypeId] = useState<
    string | undefined
  >(undefined);
  const [MemberShipPosition, setMemberShipPosition] = useState<
    DropdownOption[]
  >([]);
  const [MembersName, setMembersName] = useState([]);
  const [CategoryOfInterest, setCategoryOfInterest] = useState<
    DropdownOption[]
  >([]);
  const [saveAsDraftClicked, setSaveAsDraftClicked] = useState(false);
  const [categoryError, setCategoryError] = useState<string>("");
  const [remarkError, setRemarkError] = useState<string>("");
  const [terminationReasonError, setTerminationReasonError] =
    useState<string>("");
  const [positionError, setPositionError] = useState<string>("");
  const [memberNameError, setMemberNameError] = useState<string>("");
  const [editMode, setEditMode] = useState<boolean>(false);
  const [recordData, setRecordData] = useState<any>(null);
  const [blobUrl, setBlobUrl] = useState<string | undefined>(undefined);
  const [recordStatusId, setRecordStatusId] = useState<string>("");
  const [membershipRecordId, setMembershipRecordId] = useState<any>(null);
  const terminationRecord: string | null =
    sessionStorage.getItem("termination");
  const isTerminationRecordTrue = terminationRecord === "true";
  const [terminateReasons, setTerminateReasons] = useState<TerminateReason[]>(
    []
  );
  const [recordDetails, setRecordDetails] = useState<any>({
    membershipPositionId: "",
    categoryOfInterestId: "",
    remark: "",
    committeeId: "",
    id: "",
    recordTypeId: "",
    MembershipEndReasonId: "",
  });

  React.useEffect(() => {
    let recordId: string | null | undefined;
    let committeeId: string | null | undefined;
    let userProfileId: string | null | undefined;
    if (
      createRecordData.addCommitteeInRecordRequest &&
      createRecordData.addCommitteeInRecordRequest.recordId
    ) {
      recordId = createRecordData.addCommitteeInRecordRequest.recordId;
      setMembershipRecordId(recordId);
    } else {
      const storedData = sessionStorage.getItem("recordId");
      setMembershipRecordId(storedData);
      if (storedData) {
        recordId = storedData;
      }
    }

    if (
      createRecordData.addCommitteeInRecordRequest &&
      createRecordData.addCommitteeInRecordRequest.committeeId
    ) {
      committeeId = createRecordData.addCommitteeInRecordRequest.committeeId;
      setRecordDetails((prevState: any) => ({
        ...prevState,
        committeeId: createRecordData.addCommitteeInRecordRequest.committeeId,
      }));
    } else {
      const storedCommitteeId = sessionStorage.getItem("committeeId");
      if (storedCommitteeId) {
        committeeId = storedCommitteeId;
        setRecordDetails((prevState: any) => ({
          ...prevState,
          committeeId: committeeId,
        }));
      }
    }

    if (
      createRecordData.createRecord &&
      createRecordData.createRecord.userProfileId
    ) {
      userProfileId = createRecordData.createRecord.userProfileId;
      setRecordDetails((prevState: any) => ({
        ...prevState,
        userProfileId: userProfileId,
      }));
    } else {
      const storedCommitteeId = sessionStorage.getItem("userProfileId");
      if (storedCommitteeId) {
        userProfileId = storedCommitteeId;
        setRecordDetails((prevState: any) => ({
          ...prevState,
          userProfileId: userProfileId,
        }));
      }
    }

    if (recordId) {
      setEditMode(true);
      const fetchDataMemberRequest = async () => {
        try {
          const responseData = await fetchData("Record", recordId);
          setRecordData(responseData);
          setRecordDetails((prevState: any) => ({
            ...prevState,
            id: responseData.Id,
            remark: responseData.Remark,
            categoryOfInterestId: responseData.CategoryOfInterest.Id,
            membershipPositionId: responseData.MembershipPosition.Id,
            recordTypeId: responseData.RecordTypeId,
            isDirty: true,
            isNew: false,
            userProfileId: responseData.UserProfileId,
            MembershipEndReasonId:
              responseData?.MembershipRemoveRecord?.MembershipEndReasonId,
          }));
        } catch (error) {
          console.error("Error fetching Category of Interest:", error);
        }
      };
      fetchDataMemberRequest();
    } else if (committeeId) {
      setEditMode(false); // Set edit mode to false if only committee ID exists
    }
  }, [createRecordData]);

  const handleRecordsStatus = async () => {
    const committeeResponse = await fetchData("RecordStatus/GetAll");
    const draftRecord = committeeResponse.Collection.find(
      (item: Record) => item.StatusName === "Draft"
    );
    const openRecord = committeeResponse.Collection.find(
      (item: Record) => item.StatusName === "Open"
    );
    setRecordStatusId(draftRecord.Id);
    return {
      draftRecordId: draftRecord?.Id,
      openRecordId: openRecord?.Id,
    };
  };

  useEffect(() => {
    handleRecordsStatus();
    const fetchData = async () => {
      const labelValueArray = await fetchDataForMemberShipPosition();
      setMemberShipPosition(labelValueArray);
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    if (recordDetails.committeeId) {
      const fetchcategoryOfRequest = async () => {
        try {
          const responseData = await fetchData(
            "Committee",
            recordDetails.committeeId
          );
          const categoryOfInterestCommittee =
            responseData.CommitteCategoryofInterests;
          const labelValueArray = categoryOfInterestCommittee.map(
            (category: any) => ({
              label: category.CategoryOfInterest.CategoryName,
              value: category.CategoryOfInterest.Id,
            })
          );
          setCategoryOfInterest(labelValueArray);
        } catch (error) {
          console.error("Error fetching Category of Interest:", error);
        }
      };
      fetchcategoryOfRequest();
    }
  }, [recordDetails.committeeId]);

  React.useEffect(() => {
    if (recordDetails.committeeId) {
      const fetchcategoryOfRequest = async () => {
        try {
          const responseData = await fetchData(
            "Committee",
            recordDetails.committeeId
          );
          const categoryOfInterestCommittee =
            responseData.CommitteCategoryofInterests;
          const labelValueArray = categoryOfInterestCommittee.map(
            (category: any) => ({
              label: category.CategoryOfInterest.CategoryName,
              value: category.CategoryOfInterest.Id,
            })
          );
          setCategoryOfInterest(labelValueArray);
        } catch (error) {
          console.error("Error fetching Category of Interest:", error);
        }
      };
      fetchcategoryOfRequest();
    }
  }, [recordDetails.committeeId]);

  const percent = 70; // Set your percentage here

  const circleStyle = {
    "--percent": `${percent}`, // Set the percentage as a custom CSS variable
  };

  const handleInputChange = (value: string) => {
    setRecordDetails({ ...recordDetails, remark: value });

    if (value.trim()) {
      setRemarkError("");
    }
  };

  useEffect(() => {
    if (recordDetails?.MembershipRemoveRecord?.MembershipEndReasonId) {
      setRecordDetails((prevDetails: RecordDetails) => ({
        ...prevDetails,
        MembershipEndReasonId:
          recordDetails?.MembershipRemoveRecord?.MembershipEndReasonId,
      }));
    }
  }, [recordDetails]);

  const handleReasonChange = (e: RadioButtonChangeEvent) => {
    const selectedReasonId = e.value;
    setRecordDetails((prevDetails: RecordDetails) => ({
      ...prevDetails,
      MembershipEndReasonId: selectedReasonId,
    }));
    if (e.value) {
      setTerminationReasonError("");
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setTimeout(() => {
      focusOnErrorField(".p-invalid");
    }, 100);

    if (!recordDetails.membershipPositionId) {
      setPositionError(`${t("record.selectMembershipPosition")}`);
    } else {
      setPositionError("");
    }

    if (!recordDetails.userProfileId) {
      setMemberNameError(`${t("record.selectMember")}`);
    } else {
      setMemberNameError("");
    }

    if (!recordDetails.categoryOfInterestId) {
      setCategoryError(`${t("record.selectCoi")}`);
    } else {
      setCategoryError("");
    }

    if (isTerminationRecordTrue && !recordDetails.remark) {
      setRemarkError(`${t("record.remarkRequired")}`);
    } else {
      setRemarkError("");
    }

    if (isTerminationRecordTrue && !recordDetails.MembershipEndReasonId) {
      setTerminationReasonError(`${t("record.reasonRequired")}`);
    } else {
      setTerminationReasonError("");
    }
    if (
      !recordDetails.membershipPositionId ||
      !recordDetails.categoryOfInterestId ||
      (isTerminationRecordTrue && !recordDetails.remark)
    ) {
      return;
    }

    let requestData = recordDetails;
    const { draftRecordId, openRecordId } = await handleRecordsStatus();
    const selectedRecordStatusId = saveAsDraftClicked
      ? draftRecordId
      : openRecordId;

    if (editMode) {
      requestData = {
        isDirty: recordDetails.isDirty,
        isNew: recordDetails.isNew,
        recordTypeId: recordDetails.recordTypeId,
        committeeId: recordDetails.committeeId,
        membershipPositionId: recordDetails.membershipPositionId,
        categoryOfInterestId: recordDetails.categoryOfInterestId,
        remark: recordDetails.remark,
        userProfileId: recordDetails.userProfileId,
        approveMembershipRemark: "",
        recordStatusId: recordStatusId,
        isTerminateRecord: isTerminationRecordTrue,
        MembershipEndReasonId: isTerminationRecordTrue
          ? recordDetails.MembershipEndReasonId!
          : null,
      };
    } else {
      requestData = {
        committeeId: recordDetails.committeeId,
        membershipPositionId: recordDetails.membershipPositionId,
        categoryOfInterestId: recordDetails.categoryOfInterestId,
        remark: recordDetails.remark,
        userProfileId: recordDetails.userProfileId,
        isTerminateRecord: isTerminationRecordTrue,
        MembershipEndReasonId: isTerminationRecordTrue
          ? recordDetails.MembershipEndReasonId!
          : null,
        recordStatusId: selectedRecordStatusId,
      };
    }
    if (saveAsDraftClicked) {
      await handlePostData(requestData);
    } else {
      await handlePostData(requestData);
    }
  };

  const initializeBlobService = async (documentResponse: any) => {
    const connectionString = `https://${documentResponse?.storageAccountName}.blob.core.windows.net?${documentResponse?.sasToken}`;

    const blobServiceClient = new BlobServiceClient(connectionString);
    return blobServiceClient;
  };

  const handleFetchUserDocument = async (recordId: string) => {
    try {
      const documentResponse = await fetchData(
        `RecordDocument/GetRecordDocumentValetKey?RecordId=${recordId}&userDocumentType=RecordDocument`
      );
      return documentResponse;
    } catch (error) {
      console.error("Error fetching user document:", error);
    }
  };

  const handlePostData = async (formDetails: any) => {
    try {
      if (editMode) {
        const putDataResponse = await putData(
          "Record",
          recordDetails.id,
          formDetails
        );
        if (putDataResponse) {
          const responseObject = JSON.parse(putDataResponse.content);
          sessionStorage.setItem("recordId", responseObject.Id);
          dispatch(configureRecordRequest({ recordId: responseObject.Id }));
          const recordID = responseObject.Id;
          if (recordID) {
            const documentResponse = await handleFetchUserDocument(recordID);
            if (file) {
              const filesArray = file;
              if (filesArray.length > 0) {
                const blobService =
                  await initializeBlobService(documentResponse);
                if (documentResponse && blobService) {
                  for (let i = 0; i < filesArray.length; i++) {
                    const fileName = filesArray[i];
                    const fileBlob = new Blob([fileName], {
                      type: "application/octet-stream",
                    });

                    const containerName = documentResponse.containerName;
                    let sub = containerName.substring(
                      0,
                      containerName.length - 1
                    );
                    const containerClient = blobService.getContainerClient(sub);
                    const currentTime = Date.now();
                    const blobName = `${currentTime}_${fileName.name}`;
                    const blockBlobClient =
                      containerClient.getBlockBlobClient(blobName);
                    const uploadBlobResponse =
                      await blockBlobClient.uploadBrowserData(fileBlob, {
                        blockSize: 4 * 1024 * 1024,
                        concurrency: 20,
                      });
                    const url = uploadBlobResponse._response.request.url;
                    const parts = url.split("?");
                    const modifiedBlobUrl = parts[0];
                    setBlobUrl(modifiedBlobUrl);
                    if (modifiedBlobUrl) {
                      const userDocumentData = {
                        isDirty: true,
                        isNew: true,
                        isActive: true,
                        isDeleted: false,
                        recordId: recordID,
                        documentName: fileName.name,
                        documentTypeId: userDocumentTypeId,
                        blobURL: modifiedBlobUrl,
                        recordDocumentId:
                          "00000000-0000-0000-0000-000000000000",
                      };
                      await postData("RecordDocument", userDocumentData);
                    }
                  }
                }
              }
            }
            // navigate("/record/preview");
          }
          if (saveAsDraftClicked) {
            if (toast.current) {
              toast.current.show({
                severity: "success",
                summary: t("record.success"),
                detail: t("record.dataUpdatedDraft"),
              });
            }
          } else {
            navigate("/record/preview");
          }
        }
      } else {
        const postDataResponse = await postData(
          "Record/AddRecordMembership",
          formDetails
        );
        if (postDataResponse) {
          dispatch(configureRecordRequest({ recordId: postDataResponse.id }));
          sessionStorage.setItem("recordId", postDataResponse.id);

          const recordID = postDataResponse.id;
          // Upload document
          if (recordID) {
            const documentResponse = await handleFetchUserDocument(recordID);
            if (file) {
              const filesArray = file;
              if (filesArray.length > 0) {
                const blobService =
                  await initializeBlobService(documentResponse);
                //await handleDocumentUpload(filesArray, membershipID);
                if (documentResponse && blobService) {
                  for (let i = 0; i < filesArray.length; i++) {
                    const fileName = filesArray[i];
                    const fileBlob = new Blob([fileName], {
                      type: "application/octet-stream",
                    });

                    const containerName = documentResponse.containerName;
                    let sub = containerName.substring(
                      0,
                      containerName.length - 1
                    );
                    const containerClient = blobService.getContainerClient(sub);
                    const currentTime = Date.now();
                    const blobName = `${currentTime}_${fileName.name}`;
                    const blockBlobClient =
                      containerClient.getBlockBlobClient(blobName);
                    const uploadBlobResponse =
                      await blockBlobClient.uploadBrowserData(fileBlob, {
                        blockSize: 4 * 1024 * 1024,
                        concurrency: 20,
                      });
                    const url = uploadBlobResponse._response.request.url;
                    const parts = url.split("?");
                    const modifiedBlobUrl = parts[0];
                    setBlobUrl(modifiedBlobUrl);
                    if (modifiedBlobUrl) {
                      const userDocumentData = {
                        isDirty: true,
                        isNew: true,
                        isActive: true,
                        isDeleted: false,
                        recordId: recordID,
                        documentName: fileName.name,
                        documentTypeId: userDocumentTypeId,
                        blobURL: modifiedBlobUrl,
                        recordDocumentId:
                          "00000000-0000-0000-0000-000000000000",
                      };
                      await postData("RecordDocument", userDocumentData);
                    }
                  }
                }
              }
            }
            //navigate("/record/preview");
          }

          if (saveAsDraftClicked) {
            if (toast.current) {
              toast.current.show({
                severity: "success",
                summary: t("record.success"),
                detail: t("record.dataSavedDraft"),
              });
            }
          } else {
            navigate("/record/preview");
          }
        }
      }
    } catch (error: any) {
      handleError(error);
    }
  };

  const handleError = (error: unknown) => {
    console.error("Error posting data:", error);

    if (isAxiosError(error)) {
      const { response } = error;

      if (response && response.status === 400) {
        const messages = response.data;

        if (
          Array.isArray(messages) &&
          messages.includes("Membership Request Already Exists")
        ) {
          errorMessageToast(t("record.alreadyExist"));
          return;
        }
      }

      errorMessageToast(t("record.errorPostingData"));
    } else {
      errorMessageToast(t("record.errorPostingData"));
    }
  };

  function isAxiosError(error: unknown): error is AxiosError {
    return (
      typeof error === "object" &&
      error !== null &&
      "response" in error &&
      (error as AxiosError).response !== undefined
    );
  }

  const errorMessageToast = (msg: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };

  const handleCancel = () => {
    let navigateFrom = sessionStorage.getItem("redirectionPath");
    if (navigateFrom === "record") {
      let committeeId = sessionStorage.getItem("committeeId");
      navigate(`/committee/details/${committeeId}`);
    } else {
      navigate("/record");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const validExtensions = [
      ".xlsx",
      ".xls",
      ".doc",
      ".docx",
      ".ppt",
      ".pptx",
      ".txt",
      ".pdf",
      ".csv",
    ];
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      setFileError("");
      let totalSize = 0;
      const filesArray: any[] = [];
      let invalidFiles = false;

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const fileExtension = file.name
          .slice(file.name.lastIndexOf("."))
          .toLowerCase();

        if (!validExtensions.includes(fileExtension)) {
          setErrorMessage(`${t("profile.invalidFileType")}: ${file.name}`);
          invalidFiles = true;
          break;
        }

        totalSize += file.size;
        filesArray.push(file);
      }

      if (!invalidFiles) {
        const totalSizeInMB = totalSize / (1024 * 1024);
        if (totalSizeInMB <= 500) {
          setFile(filesArray);
          setErrorMessage(undefined);
        } else {
          setErrorMessage(`${t("record.totalFileSizeExceed500")}`);
          setFile(undefined);
        }
      } else {
        setFile(undefined);
      }
    } else {
      setFile(undefined);
      setErrorMessage(undefined);
    }
  };

  const handleDelete = (index: number) => {
    if (file) {
      const updatedFiles = [...file];
      updatedFiles.splice(index, 1);
      setFile(updatedFiles);
    }
  };

  const getAll = async () => {
    const fetchAllDocuments = await fetchData("DocumentType/GetAll");
    const documents = fetchAllDocuments.Collection;

    for (const document of documents) {
      if (document.TypeName === "RecordDocument") {
        setUserDocumentTypeId(document.Id);
        break;
      }
    }
  };

  const selectMemberName = async () => {
    if (selectedCommitteeID) {
      try {
        const CommitteeMemberResponse = await fetchData(
          `CommitteeMembership/GetListByParentId?parentId=${selectedCommitteeID}`
        );
        const memberOptions = CommitteeMemberResponse.collection.map(
          (item: any) => ({
            label: item.userProfile.firstname + " " + item.userProfile.lastname,
            value: item.userProfileId,
          })
        );
        setMembersName(memberOptions);
      } catch (error) {
        console.error("Error fetching committee members: ", error);
      }
    }
  };

  const handleMemberChange = async (e: DropdownChangeEvent) => {
    const selectedUserProfileId = e.value;
    setRecordDetails({
      ...recordDetails,
      userProfileId: selectedUserProfileId,
    });
    if (e.value) {
      setMemberNameError("");
      setPositionError("");
      setCategoryError("");
    }
    try {
      const coiResponse = await fetchCOIByUserProfileIdAndCommiteeId(
        selectedUserProfileId
      );
      if (coiResponse && typeof coiResponse === "object") {
        const coiOption: DropdownOption = {
          label: coiResponse.CategoryOfInterest,
          value: coiResponse.CategoryOfInterestId,
        };

        const membershipPositionOption: DropdownOption = {
          label: coiResponse.MembershipPositionName,
          value: coiResponse.MembershipPositionId,
        };

        setCategoryOfInterest([coiOption]);
        setMemberShipPosition([membershipPositionOption]);

        setRecordDetails((prevRecord: any) => ({
          ...prevRecord,
          categoryOfInterestId: coiOption.value,
          membershipPositionId: membershipPositionOption.value,
        }));
      } else {
        console.error("Unexpected COI response format", coiResponse);
        setCategoryOfInterest([]);
        setMemberShipPosition([]);
      }
    } catch (error) {
      console.error("Error handling COI:", error);
      setCategoryOfInterest([]);
      setMemberShipPosition([]);
    }
  };

  const fetchCOIByUserProfileIdAndCommiteeId = async (
    userProfileId: string
  ): Promise<any> => {
    try {
      const CommitteeMemberCOIResponse = await fetchData(
        `CommitteeMembership/GetCategoryOfInterestByUserProfileId?userProfileId=${userProfileId}&commiteeId=${selectedCommitteeID}`
      );
      const response = CommitteeMemberCOIResponse;

      if (
        response &&
        typeof response === "object" &&
        !Array.isArray(response)
      ) {
        return response;
      } else {
        console.error("Unexpected COI response format", response);
        return {};
      }
    } catch (error) {
      console.error("Error fetching COI:", error);
      return {};
    }
  };

  const MembershipTerminateReason = async () => {
    try {
      const terminateReason = await fetchData(`MembershipEndReason/GetAll`);
      if (terminateReason && terminateReason.Collection) {
        setTerminateReasons(terminateReason.Collection);
      }
    } catch (error) {
      console.error("Error fetching termination reasons:", error);
    }
  };

  React.useEffect(() => {
    getAll();
    selectMemberName();
    MembershipTerminateReason();
  }, []);

  return (
    <>
      <Toast ref={toast} />
      {terminationRecord ? (
        <CreateTerminateRecordHeader activeStep={1} />
      ) : (
        <CreateRecordHeader activeStep={2} />
      )}
      <form onSubmit={handleSubmit}>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <div className="flex flex-column gap-1">
              <h2 className="text-title text-lg font-bold m-0">
                {terminationRecord
                  ? t("record.committeeMemberPosition")
                  : t("record.selectCommitteeAndPosition")}
              </h2>
              <p className="text-base font-normal m-0 text-help">
                {t("record.allFieldsRequired")}
              </p>
            </div>
          </div>
          {(isTerminationRecordTrue || recordDetails.IsTerminateRecord) && (
            <div className="grid grid-xl px-5 py-0 pt-2 mt-4">
              <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                <label
                  htmlFor="memberName"
                  className="block font-bold text-input-label capitalize"
                >
                  {t("record.selectMember")}
                </label>
                <Dropdown
                  inputId="memberName"
                  aria-describedby="memberNameError"
                  value={recordDetails.userProfileId}
                  onChange={handleMemberChange}
                  options={MembersName}
                  optionLabel="label"
                  placeholder={t("record.selectAnOption")}
                  className="w-full"
                />
                <span
                  id="positionError"
                  className={`p-error font-bold text-capitalize ${memberNameError ? "" : "error-hidden"}`}
                >
                  {memberNameError}
                </span>
              </div>
            </div>
          )}
          <div className="grid grid-xl px-5 py-0 pt-2 mt-4">
            <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
              <label
                htmlFor="position"
                className="block font-bold text-input-label capitalize"
              >
                {t("record.committeePosition")}
              </label>
              <Dropdown
                inputId="position"
                aria-describedby="positionError"
                value={recordDetails.membershipPositionId}
                onChange={(e: DropdownChangeEvent) => {
                  setRecordDetails({
                    ...recordDetails,
                    membershipPositionId: e.value,
                  });
                }}
                options={MemberShipPosition}
                optionLabel="label"
                placeholder={t("record.selectAnOption")}
                disabled={isTerminationRecordTrue}
                className="w-full"
              />
              <span
                id="positionError"
                className={`p-error font-bold text-capitalize ${positionError ? "" : "error-hidden"}`}
              >
                {positionError}
              </span>
            </div>
          </div>
          <div className="grid grid-xl grid-xl px-5 py-3 py-0">
            <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
              <label
                htmlFor="categoryInterest"
                className="block font-bold text-input-label capitalize"
              >
                {t("record.categoryOfInterest")}
              </label>
              <Dropdown
                inputId="categoryInterest"
                aria-describedby="categoryInterestError"
                value={recordDetails.categoryOfInterestId}
                onChange={(e: DropdownChangeEvent) => {
                  setRecordDetails({
                    ...recordDetails,
                    categoryOfInterestId: e.value,
                  });
                }}
                options={CategoryOfInterest}
                disabled={isTerminationRecordTrue}
                optionLabel="label"
                placeholder={t("record.selectAnOption")}
                className="w-full"
              />
              <span
                id="categoryInterestError"
                className={`p-error font-bold text-capitalize ${categoryError ? "" : "error-hidden"}`}
              >
                {categoryError}
              </span>
            </div>
          </div>
          {(isTerminationRecordTrue || recordDetails.IsTerminateRecord) && (
            <div className="px-5 py-3 py-0">
              <div className="flex flex-column">
                <div>
                  <label className="block font-bold text-input-label capitalize mb-2">
                    {t("record.reasonForTermination")}
                  </label>
                  {terminateReasons.map((reason) => (
                    <div
                      key={reason.Id}
                      className="flex align-items-center mb-2"
                    >
                      <RadioButton
                        inputId={reason.Id}
                        name="terminateReason"
                        value={reason.Id}
                        onChange={handleReasonChange}
                        checked={
                          recordDetails.MembershipEndReasonId === reason.Id
                        }
                      />
                      <label htmlFor={reason.Id} className="ml-2">
                        {reason.Reason}
                      </label>
                    </div>
                  ))}

                  <span
                    id="terminationError"
                    className={`p-error font-bold text-capitalize ${terminationReasonError ? "" : "error-hidden"}`}
                  >
                    {terminationReasonError}
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="grid grid-xl grid-xl px-5 py-3 py-0">
            <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
              <Input
                type="text"
                label={
                  isTerminationRecordTrue || recordDetails.IsTerminateRecord
                    ? `${t("record.remark")}`
                    : `${t("record.remark")} (${t("record.optional")})`
                }
                value={recordDetails.remark}
                name="Remark"
                onChange={(value) => handleInputChange(value)}
                placeholder={t("record.remark")}
              />

              <span
                id="remarkError"
                className={`p-error font-bold text-capitalize ${remarkError ? "" : "error-hidden"}`}
              >
                {remarkError}
              </span>
            </div>
          </div>
        </div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <div className="flex flex-column gap-1">
              <h2 className="text-title text-lg font-bold m-0">
                {t("record.supportingDocuments")}
              </h2>
              <p className="text-base font-normal m-0 text-help">
                {t("record.allFieldsRequired")}
              </p>
            </div>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5">
              <div className="flex flex-column gap-2 w-full">
                <div className="flex flex-column gap-3">
                  <div className="xl:col-6 lg:col-8 md:col-12 col-12">
                    <h2 className="font-bold text-base">
                      {t("record.requestDouments")}
                    </h2>
                    <div className="relative md:w-16rem custom-file-upload mb-2">
                      <input
                        type="file"
                        id="fileInput1"
                        aria-labelledby="fileInput1"
                        accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.csv"
                        multiple
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="fileInput1"
                        className="flex flex-column align-items-center justify-content-center gap-2 p-3"
                      >
                        <span className="bg-brand-500 border-circle flex align-items-center justify-content-center">
                          <FeatherIcon
                            name="plus"
                            size={32}
                            color="var(--icon-white)"
                            onClick={undefined}
                          />
                        </span>
                        <span className="text-sm font-normal">
                          {t("record.browseDosPlacehoder")} <br />
                          {t("record.maxFileSize")}
                        </span>
                      </label>
                    </div>
                    {membershipRecordId && (
                      <RecordListDocument
                        documentType="RecordDocument"
                        membershipRecordID={membershipRecordId}
                        showNoDataMessage={false}
                      />
                    )}

                    <div>
                      {errorMessage && (
                        <div key="errorMessage" className="p-error font-bold">
                          {errorMessage}
                        </div>
                      )}
                      {file && (
                        <div className="flex flex-column gap-3">
                          {file.map((fileName: any, index: number) => (
                            <div
                              key={index}
                              className="flex align-items-center gap-3"
                            >
                              <a className="m-0 flex align-items-center gap-2 underline text-charcoal">
                                <img src={pdfIcon} alt="PDF" />
                                <span>{fileName.name}</span>
                              </a>
                              <Button
                                text
                                className="p-button-plain gap-2 text-delete underline"
                                onClick={() => handleDelete(index)}
                                aria-label={t("record.delete")}
                              >
                                <FeatherIcon
                                  name="trash-2"
                                  size={20}
                                  onClick={undefined}
                                />
                              </Button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white hidden md:flex align-items-center px-5 py-3 gap-4 fixed-footer w-full left-0 shadow">
            <Button
              label={t("record.cancel")}
              className="button-md"
              severity="secondary"
              onClick={() => {
                navigate("/record");
              }}
            />
            <Button
              onClick={() => {
                setSaveAsDraftClicked(true);
              }}
              text
              className="p-button-plain underline ml-auto"
              label={t("record.saveasdraft")}
            ></Button>
            <Button
              className="button-md gap-1"
              severity="secondary"
              onClick={() => {
                navigate("/record/create");
              }}
            >
              <FeatherIcon name="chevron-left" size={20} color="inherit" />
              <span className="font-bold text-capitalize">
                {t("record.back")}
              </span>
            </Button>
            <Button
              onClick={() => {
                setSaveAsDraftClicked(false);
              }}
              label={t("record.preview")}
              className="button-md"
            />
          </div>

          {/* Mobile responsive buttions */}
          <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 fixed-footer w-full left-0 shadow">
            <Button
              onClick={() => {
                setSaveAsDraftClicked(false);
              }}
              label={t("record.preview")}
              className="button-md w-full"
            />
            <div className="flex align-items-center gap-3 w-full">
              <Button
                className="button-md"
                severity="secondary"
                onClick={() => {
                  navigate("/record/create");
                }}
                aria-label={t("record.back")}
              >
                <FeatherIcon name="chevron-left" size={20} color="inherit" />
              </Button>
              <Button
                label={t("record.cancel")}
                className="button-md w-full"
                severity="secondary"
                onClick={handleCancel}
              />
            </div>
            <Button
              onClick={() => {
                setSaveAsDraftClicked(true);
              }}
              text
              className="p-button-plain underline w-full"
              label={t("record.saveasdraft")}
            ></Button>
          </div>
        </div>
      </form>
    </>
  );
};
export default RecordDetails;
