import React, { useEffect, useRef, useState } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../LanguageContext";
import { useLoadingContext } from "../Layout";
import {
  fetchData,
  getListByParentId,
  postData,
  putData,
} from "../../services/apiService";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { fetchAllRecords, fetchRecordRequest } from "./recordUtils";
import { RootState } from "../../store/store";
import { formatApproveMembershipRequest } from "../../utils/utils";
import ToastMessage from "../common/ToastMessage";
import { Toast } from "primereact/toast";
import { focusOnErrorField } from "../../utils/focusError";
interface ApproveMembershipDetail {
  membershipPositionId: string;
  categoryOfInterestId: string;
  tenureLength: any;
  name: string;
  startDate?: any;
  endDate?: any;
  approveMembershipRemark?: string;
  committeeId?: string;
  committeeMaxMember?: any;
  memberName?: any;
  membershipExpires?: any;
}

interface ToastMessage {
  message: string;
  type: string;
}
interface Option {
  PositionName: string;
  code: string;
  value?: string;
}

const Membership = () => {
  const { setLoading } = useLoadingContext();
  const dispatch = useDispatch();
  const toast = useRef<Toast>(null);
  const [t, i18n] = useTranslation("record");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const [error, setError] = useState<Partial<ApproveMembershipDetail>>({
    membershipPositionId: "",
    categoryOfInterestId: "",
    tenureLength: "",
    name: "",
  });
  const [recordDetails, setRecordDetails] = useState<any>(null);
  const createRecordDetails = useSelector(
    (state: RootState) => state.createRecord
  );
  const recordID = sessionStorage.recordId;
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState<string | null>(null);
  const [positionOptions, setPositionOptions] = useState<Option[] | undefined>(
    undefined
  );
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [toasterFlag, setToasterFlag] = useState(false);
  const [tosterMessage, setTosterMessage] = useState<ToastMessage[]>([]);
  // const [tenureList, setTenureList] = useState(["1 year"]);
  const [tenureList, setTenureList] = useState<{ id: number; name: string }[]>([
    { id: 1, name: "1 year" },
  ]);
  const [committeeId, setCommitteeId] = useState<any>(null);
  const [positionOptionFlag, setPositionOptionFlag] = useState(true);

  const handleTenureDropdown = (len: number) => {
    const yearsArray = Array.from({ length: len }, (_, index) => ({
      id: index + 1,
      name: `${index + 1} year`,
    }));

    return yearsArray;
  };
  const committeeMemberLableForValidation = [
    { name: "membershipPositionId", lable: "Membership Position" },
    { name: "categoryOfInterestId", lable: t("record.categoryOfInterest") },
    { name: "tenureLength", lable: "Tenure Length" },
    { name: "name", lable: "Name" },
    { name: "endDate", lable: "End Date" },
    { name: "startDate", lable: "Start Date" },
  ];

  const [approveMembershipDetails, setApproveMembershipDetails] =
    useState<ApproveMembershipDetail>({
      membershipPositionId: "",
      categoryOfInterestId: "",
      tenureLength: "",
      name: "",
      startDate: undefined,
      endDate: undefined,
      approveMembershipRemark: "",
      committeeMaxMember: undefined,
      memberName: "",
      membershipExpires: "",
    });

  const messagesType = (type: string) => {
    if (type.toLowerCase() === "warning") {
      return "warn";
    } else if (type.toLowerCase() === "error") {
      return "error";
    } else if (type.toLowerCase() === "info") {
      return "info";
    }
  };

  const handleToasterMessage = (toaster: any) => {
    const toasterShow = toaster.map((data: any) => ({
      severity: data.type,
      summary: data.type,
      detail: data.message,
      life: 8000,
    }));
    toast.current?.show(toasterShow);
  };

  const handleInputChange = (e: any) => {
    setError({});
    let { name, value } = e.target;
    setApproveMembershipDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDropdownChange = (e: DropdownChangeEvent) => {
    const { value } = e;
    setApproveMembershipDetails((prevData) => ({
      ...prevData,
      membershipPositionId: value,
    }));
    setError((prevError) => ({
      ...prevError,
      membershipPositionId: "",
    }));
  };

  const handleTenureDropdownChange = (e: DropdownChangeEvent) => {
    const { value } = e;
    setApproveMembershipDetails((prevData) => ({
      ...prevData,
      tenureLength: {
        id: value,
        name: tenureList.find((item) => item.id === value)?.name || "",
      },
    }));
  };

  const handleDropdownCategoryChange = (e: DropdownChangeEvent) => {
    const { value } = e;
    setApproveMembershipDetails((prevData) => ({
      ...prevData,
      categoryOfInterestId: value,
    }));

    setError((prevError) => ({
      ...prevError,
      categoryOfInterestId: "",
    }));
  };

  useEffect(() => {
    const fetchDataCategory = async () => {
      try {
        const postDataResponse = await fetchData("CategoryOfInterest/getAll");
        const formattedCategoryOptions = postDataResponse.Collection.map(
          (item: { CategoryName: any; Id: any }) => ({
            CategoryName: item.CategoryName,
            value: item.Id,
          })
        );
        setCategoryOptions(formattedCategoryOptions);
      } catch (error) {
        console.error("Error fetching Category of Interest:", error);
      }
    };

    const fetchDataPosition = async () => {
      try {
        const postDataResponse = await fetchData("MembershipPosition/GetAll");
        const formattedPositionOptions = postDataResponse.Collection.map(
          (item: { PositionName: any; Id: any }) => ({
            PositionName: item.PositionName,
            value: item.Id,
          })
        );
        setPositionOptions(formattedPositionOptions);
      } catch (error) {
        console.error("Error fetching Membership Position:", error);
      }
    };

    fetchDataCategory();
    fetchDataPosition();
  }, []);

  useEffect(() => {
    if (approveMembershipDetails.committeeMaxMember !== "") {
      const yearsArray = handleTenureDropdown(
        parseInt(approveMembershipDetails.committeeMaxMember)
      );
      setTenureList(yearsArray);
    }
  }, [approveMembershipDetails.committeeMaxMember]);

  React.useEffect(() => {
    let recordId: string | null | undefined;
    const storedData = sessionStorage.recordId;
    if (storedData) {
      recordId = storedData;
    }
    if (recordId) {
      const fetchRecordData = async () => {
        try {
          const labelValueArray = await fetchRecordRequest(recordId);
          setCommitteeId(labelValueArray?.CommitteeId);
          setApproveMembershipDetails((prevState) => ({
            ...prevState,
            membershipPositionId: labelValueArray?.MembershipPosition?.Id || "",
            categoryOfInterestId: labelValueArray?.CategoryOfInterest?.Id || "",
            name: labelValueArray?.Committee?.Name || "",
            committeeMaxMember:
              labelValueArray?.Committee?.CommitteeMaxMember || "",
            memberName:
              (labelValueArray?.UserProfile?.Firstname || "") +
              " " +
              (labelValueArray?.UserProfile?.MiddleName || "") +
              " " +
              (labelValueArray?.UserProfile?.Lastname || ""),
            membershipExpires:
              labelValueArray?.Committee?.MembershipExpiresOn_All || "",
          }));
          setApproveMembershipDetails((prevState) => ({
            ...prevState,
            committeeId: labelValueArray?.Committee?.Id || "",
          }));
          setRecordDetails(labelValueArray);
        } catch (error) {
          console.error("Error fetching Category of Interest:", error);
        }
      };
      fetchRecordData();
    }
  }, [createRecordDetails]);

  const committeeMember = async () => {
    if (committeeId) {
      try {
        const CommitteeMemberResponse = await fetchData(
          `CommitteeMembership/GetListByParentId?parentId=${committeeId}`
        );
        const response = CommitteeMemberResponse.collection;

        let previousAddedPositions = response.map((data: any) => {
          return data.membershipPosition.positionName;
        });

        const chairArray = ["Volunteer Secretary", "Vice Chair", "Chair"];
        let chairPositions = previousAddedPositions.filter((position: any) =>
          chairArray.includes(position)
        );

        const updatedMemberPositionOptions = positionOptions?.filter(
          (data) =>
            !chairPositions.includes(data.PositionName) ||
            data.value === approveMembershipDetails.membershipPositionId
        );

        setPositionOptions(updatedMemberPositionOptions);
      } catch (error) {
        console.error("Error fetching committee members: ", error);
      }
    }
  };

  useEffect(() => {
    if (committeeId && positionOptionFlag) {
      committeeMember();
      setPositionOptionFlag(false);
    }
  }, [committeeId, positionOptionFlag]);

  const positionOptionsFiltered = positionOptions?.filter((option) => {
    if (
      ["Volunteer Secretary", "Chair", "Vice Chair"].includes(
        option.PositionName
      )
    ) {
      return approveMembershipDetails.membershipPositionId === option.value;
    }
    return true;
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setTimeout(() => {
      focusOnErrorField(".p-invalid");
    }, 100);
    const errors: Partial<ApproveMembershipDetail> = {};

    const requiredFields = [
      "membershipPositionId",
      "categoryOfInterestId",
      "tenureLength",
      "startDate",
      "endDate",
      "approveMembershipRemark",
    ];

    requiredFields.forEach((field) => {
      const fieldValue =
        approveMembershipDetails[field as keyof ApproveMembershipDetail];
      if (
        !fieldValue ||
        (typeof fieldValue === "string" && fieldValue.trim() === "")
      ) {
        const label =
          committeeMemberLableForValidation.find((item) => item.name === field)
            ?.lable || field;
        errors[field as keyof ApproveMembershipDetail] = `${label} is required`;
      }
    });

    if (Object.keys(errors).length === 0) {
      let toaster = [];
      if (!toasterFlag) {
        setLoading(true);
        const membershipRequest = formatApproveMembershipRequest(
          approveMembershipDetails
        );
        const membershipResponse = await putData(
          `Record/ApproveMembership/${recordID}`,
          undefined,
          membershipRequest
        );
        setLoading(false);
        toaster = membershipResponse?.SysMessages?.map(
          (message: string, idx: number) => ({
            message: message,
            type: messagesType(membershipResponse?.SysMessagesType[idx]),
          })
        );

        if (toaster && toaster.length > 0) {
          handleToasterMessage(toaster);
          setToasterFlag(true);
        }
        setTosterMessage(toaster);
        navigate("/record");
      }
    } else {
      errors["approveMembershipRemark"] = "Remarks is required";
      setError(errors);
    }
  };

  const handleStartDateChange = (e: any) => {
    const { value } = e.target;
    setApproveMembershipDetails((prevData) => ({
      ...prevData,
      startDate: value,
    }));

    if (approveMembershipDetails.endDate) {
      const tenureLength = calculateTenureLength(
        value,
        approveMembershipDetails.endDate
      );
      setApproveMembershipDetails((prevData) => ({
        ...prevData,
        tenureLength: tenureLength, // Update tenure length in state
      }));
    }

    setError((prevError) => ({
      ...prevError,
      startDate: "",
    }));
  };

  const handleEndDateChange = (e: any) => {
    const { value } = e.target;
    setApproveMembershipDetails((prevData) => ({
      ...prevData,
      endDate: value,
    }));

    if (approveMembershipDetails.startDate) {
      const tenureLength = calculateTenureLength(
        approveMembershipDetails.startDate,
        value
      );
      setApproveMembershipDetails((prevData) => ({
        ...prevData,
        tenureLength: tenureLength, // Update tenure length in state
      }));
    }

    setError((prevError) => ({
      ...prevError,
      endDate: "",
    }));
  };

  useEffect(() => {
    const startDate = approveMembershipDetails.startDate;
    const tenureLength = approveMembershipDetails.tenureLength;
    const membershipExpires = approveMembershipDetails.membershipExpires;
    if (startDate && tenureLength) {
      let startDateObj = getEndDateMax(startDate, tenureLength);
      const membershipExpiresDate = new Date(membershipExpires);
      if (
        membershipExpires &&
        startDate.getMonth() >= membershipExpiresDate.getMonth()
      ) {
        if (
          startDate.getMonth() == membershipExpiresDate.getMonth() &&
          startDate.getDate() < membershipExpiresDate.getDate()
        ) {
          startDateObj = getEndDateMax(startDate, tenureLength);
        } else {
          const date = membershipExpiresDate.getDate();
          const month = membershipExpiresDate.getMonth();
          startDateObj.setMonth(month, date);
        }
      }
      setApproveMembershipDetails((prevData) => ({
        ...prevData,
        endDate: startDateObj,
      }));
    }
  }, [
    approveMembershipDetails.startDate,
    approveMembershipDetails.tenureLength,
  ]);

  const calculateTenureLength = (startDate: string, endDate: string) => {
    if (!startDate || !endDate) return "";
    const start = new Date(startDate);
    const end = new Date(endDate);
    const yearDifference = end.getFullYear() - start.getFullYear();
    return { id: yearDifference, name: `${yearDifference} year` };
  };

  const getEndDateMax = (startDate: string, tenureLength: any): Date => {
    const startDateObj = new Date(startDate);
    startDateObj.setFullYear(startDateObj.getFullYear() + tenureLength.id);
    return startDateObj;
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <Button
              text
              className="p-button-plain p-0 cursor-pointer"
              onClick={() => navigate("/record/record-preview/")}
              aria-label={t("record.back")}
            >
              <FeatherIcon name="arrow-left" size={32} />
            </Button>
            <div className="flex flex-column gap-1">
              <h1 className="text-title text-lg font-bold m-0">
                {t("record.approveMembership")}
              </h1>
              <p className="text-base font-normal m-0 text-help">
                {t("record.allFieldsRequired")}
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="p-5 cardBody">
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="name"
                    className="block font-bold text-input-label capitalize"
                  >
                    {t("record.committeeName")}
                  </label>
                  <InputText
                    id="name"
                    name="name"
                    className="w-full"
                    value={approveMembershipDetails.name}
                    placeholder={t("record.name")}
                    onChange={(e) => handleInputChange(e)}
                    readOnly
                  />
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="name"
                    className="block font-bold text-input-label capitalize"
                  >
                    {t("record.memberName")}
                  </label>
                  <InputText
                    id="name"
                    name="name"
                    className="w-full"
                    value={approveMembershipDetails.memberName}
                    placeholder={t("record.memberName")}
                    onChange={(e) => handleInputChange(e)}
                    readOnly
                  />
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="memberPosition"
                    className={`font-bold ${error.membershipPositionId ? "p-error" : ""}`}
                  >
                    {t("record.memberPosition")}
                  </label>
                  <Dropdown
                    inputId="memberPosition"
                    aria-describedby="memberPositionError"
                    value={approveMembershipDetails.membershipPositionId}
                    onChange={(e) => handleDropdownChange(e)}
                    options={positionOptionsFiltered}
                    optionLabel="PositionName"
                    placeholder={t("record.selectAnOption")}
                    className={error?.membershipPositionId ? "p-invalid" : ""}
                    disabled
                  />

                  <span
                    id="memberPositionError"
                    className={`p-error font-bold text-capitalize ${error?.membershipPositionId ? "" : "error-hidden"}`}
                  >
                    {error?.membershipPositionId}
                  </span>
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="startDate"
                    className={`font-bold ${error.startDate ? "p-error" : ""}`}
                  >
                    {t("record.startDate")}
                  </label>
                  <Calendar
                    appendTo="self"
                    inputId="startDate"
                    aria-describedby="startDateError"
                    minDate={new Date()}
                    onChange={handleStartDateChange}
                    value={approveMembershipDetails.startDate}
                    dateFormat="mm/dd/yy"
                    placeholder={t("record.startDate")}
                    className={`custom-datepicker ${error.startDate ? "p-invalid" : ""}`}
                    name="startDate"
                  />
                  <span
                    id="startDateError"
                    className={`p-error font-bold text-capitalize ${error?.startDate ? "" : "error-hidden"}`}
                  >
                    {error?.startDate}
                  </span>
                </div>
              </div>
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="endDate"
                    className={`font-bold ${error.endDate ? "p-error" : ""}`}
                  >
                    {t("record.endDate")}
                  </label>
                  <Calendar
                    appendTo="self"
                    disabled={approveMembershipDetails.startDate ? false : true}
                    inputId="endDate"
                    aria-describedby="endDateError"
                    onChange={handleEndDateChange}
                    name="endDate"
                    minDate={approveMembershipDetails.startDate}
                    maxDate={
                      approveMembershipDetails.startDate &&
                      approveMembershipDetails.tenureLength
                        ? getEndDateMax(
                            approveMembershipDetails.startDate,
                            approveMembershipDetails.tenureLength
                          )
                        : undefined
                    }
                    value={approveMembershipDetails.endDate}
                    dateFormat="mm/dd/yy"
                    placeholder={t("record.endDate")}
                    className={`custom-datepicker ${error.startDate ? "p-invalid" : ""}`}
                  />
                  <span
                    id="endDateError"
                    className={`p-error font-bold text-capitalize ${error?.endDate ? "" : "error-hidden"}`}
                  >
                    {error?.endDate}
                  </span>
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="categoryOfInterest"
                    className={`font-bold ${error.categoryOfInterestId ? "p-error" : ""}`}
                  >
                    {t("record.categoryOfInterest")}
                  </label>
                  <Dropdown
                    inputId="categoryOfInterest"
                    aria-describedby="categoryOfInterestError"
                    value={approveMembershipDetails.categoryOfInterestId}
                    onChange={(e) => handleDropdownCategoryChange(e)}
                    options={categoryOptions}
                    optionLabel="CategoryName"
                    placeholder={t("record.selectAnOption")}
                    className={error?.categoryOfInterestId ? "p-invalid" : ""}
                  />

                  <span
                    id="categoryOfInterestError"
                    className={`p-error font-bold text-capitalize ${error?.categoryOfInterestId ? "" : "error-hidden"}`}
                  >
                    {error?.categoryOfInterestId}
                  </span>
                </div>
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="tensureLength"
                    className="block font-bold text-input-label capitalize"
                  >
                    {t("record.tensureLength")}
                  </label>

                  <Dropdown
                    inputId="tenureLength"
                    name="tenureLength"
                    value={approveMembershipDetails.tenureLength.id} // Bind dropdown with correct value
                    onChange={(e) => handleTenureDropdownChange(e)}
                    options={tenureList}
                    optionLabel="name"
                    optionValue="id"
                    placeholder={t("record.selectAnOption")}
                    className={error?.tenureLength ? "p-invalid" : ""}
                  />
                </div>
              </div>
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-7 lg:col-7 md:col-7 col-12">
                  <label
                    htmlFor="remarks"
                    className={`font-bold ${error.approveMembershipRemark ? "p-error" : ""}`}
                  >
                    {t("record.remarks")}
                  </label>
                  <InputTextarea
                    id="remarks"
                    aria-describedby="remarksError"
                    name="approveMembershipRemark"
                    value={approveMembershipDetails.approveMembershipRemark}
                    onChange={(e) => handleInputChange(e)}
                    rows={5}
                    cols={30}
                    className={
                      error?.approveMembershipRemark ? "p-invalid" : ""
                    }
                  />
                  <span
                    id="remarksError"
                    className={`p-error font-bold text-capitalize ${error?.approveMembershipRemark ? "" : "error-hidden"}`}
                  >
                    {error?.approveMembershipRemark}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex align-items-center px-5 py-3 border-top-1 border-gray-200 gap-3 justify-content-end cardFooter">
              <div className="flex align-items-center gap-4">
                <Button
                  label={t("record.cancel")}
                  className="button-md"
                  severity="secondary"
                  onClick={() => navigate("/record/record-preview")}
                />
                <Button label={t("record.approve")} className="button-md" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Membership;
