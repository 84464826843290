import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TabPanel, TabView } from "primereact/tabview";
import { useLanguageContext } from "../../LanguageContext";
import { fetchData } from '../../../services/Ballot/apiservice';
import BallotCommentRecordHeader from "./BallotCommentRecordHeader";
import CommentCommitteeHeader from "./CommentCommitteeHeader";
import CommentHierarchy from "./CommentHierarchy";
import { BallotCommentsCollection, BallotCommentsData } from "./type";
import { BallotRecord } from "../types/vote";
import { useParams } from "react-router-dom";

const BallotTabComments: React.FC = () => {
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [ballotComments, setBallotComments] = useState<BallotCommentsData[]>([]);
  const [ballotRecords, setBallotRecords] = useState<BallotRecord[]>([]);
  const { id: ballotId } = useParams();
  // comments action
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const ballotCommentRecords = async () => {
    try {
      const ballotRecordsResponses = await fetchData(`BallotRecord/GetListByParentId?parentId=${ballotId}`);
      setBallotRecords(ballotRecordsResponses.collection);
    } catch (err) {
      console.log(err);
    }
  }
  const ballotSearchComments = async (index: number) => {
    try {
      const ballotCommentsData: BallotCommentsCollection = await fetchData(`BallotComments/BallotCommentsSearch?${`Filters[0].Key=ballotid&Filters[0].Value=${ballotId}&Filters[1].Key=recordid&Filters[1].Value=${ballotRecords[index].record.id}`}&PageIndex=-1`);
      setBallotComments(ballotCommentsData.Collection);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    ballotCommentRecords()
  }, [])
  useEffect(() => {
    ballotSearchComments(activeIndex)
  }, [ballotRecords])
  return (
    <>
      <div className="">
        <div className="flex flex-column md:flex-row gap-4 mb-5">
          <div className="p-0 bg-white flex flex-column gap-0 md:gap-4 shadow-md w-full md:w-10rem flex-shrink-0">
            <h2 className="m-0 p-3 text-title display-xs">
              {t("ballot.records")}
            </h2>
            <div className="tabs-wrap" key={'data'}>
              <TabView scrollable
                className="md:vertical-tabs"
                activeIndex={activeIndex}
                onTabChange={(e) => {
                  setActiveIndex(e.index)
                  ballotSearchComments(e.index)
                }}
              >
                {ballotRecords?.map((data, index: number) => {
                  return (
                    <TabPanel key={index} header={<>{data.record.recordNumber} <br /> {data.record.userProfile.displayName}</>} />
                  )
                })}
              </TabView>
            </div>
          </div>
          <div className="flex flex-column gap-4 w-full">
            <BallotCommentRecordHeader BallotRecordheaderInfo={ballotRecords[activeIndex]} />
            <h4 className="text-title text-base font-bold text-capitalize m-0">
              {t("ballot.committeeForBalloting")}
            </h4>
            {ballotComments?.map((data, index: number) => {
              if (!data?.ForReviewAndComment) {
                return (<>
                  <div className="card bg-white w-full shadow-md">
                    <CommentCommitteeHeader headerInfo={data} />
                    <CommentHierarchy commentsInfo={data} recordsInfo={ballotRecords[index]} onStatusChange={ballotCommentRecords} />
                  </div></>)
              } else {

              }
            })
            }
            {ballotComments?.map((data, index: number) => {
              if (data?.ForReviewAndComment) {
                return (<>
                  <h4 className="text-title text-base font-bold text-capitalize m-0">
                    {t("ballot.committeeForReviewingAndCommenting")}
                  </h4>
                  <div className="card bg-white w-full shadow-md">
                    <CommentCommitteeHeader headerInfo={data} />
                    <CommentHierarchy commentsInfo={data} recordsInfo={ballotRecords[index]} onStatusChange={ballotCommentRecords} />
                  </div></>)
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BallotTabComments;
