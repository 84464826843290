import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import FeatherIcon from "../common/FeatherIconComponent";
import { staffConfigureProfile } from "../../slices/createProfileSlice";
import { useScrollOnPaginatorClick } from "./useScrollOnPaginatorClick";
interface Product {
  id?: string;
  code?: string;
  memberName?: string;
  committeeApplied?: string;
  positionApplied?: string;
  categoryInterest?: string;
  Status?: any;
  status?: string;
  statusName?: string;
  requestDate?: string;
  action?: string;
  name?: string;
  email?: string;
  committeePosition?: string;
  startDate?: string;
  endDate?: string;
  tenure?: string;
  level?: string;
  parentCommittee?: string;
  createdOn?: string;
  activationDate?: string;
  ballotCount?: string;
  inBalance?: string;
  member?: string;
  ballotId?: string;
  approvedIn?: string;
  recordId?: string;
  charter?: string;
  recordCreated?: string;
  charterApproved?: string;
  categoryOfInterest?: string;
  norOfMembers?: string;
  balancePercent?: string;
  balanceStatus?: string;
  actionDate?: string;
  activity?: string;
  remark?: string;
  actionBy?: string;
  date?: string;
  updatedBy?: string;
  changesMade?: string;
  memberId?: string;
  position?: string;
  categoryOf?: string;
  requestNumber?: string;
  recordID?: string;
  recordType?: string;
  recordSubType?: string;
  recordSubsubType?: string;
  ballotID?: string;
  ballotType?: string;
  ballotStatus?: string;
  openDate?: string;
  standardId?: string;
  standardName?: string;
  standardShortName?: string;
  board?: string;
  PrimaryCommittee?: string;
  consensus?: string;
  currentEdition?: string;
  type?: string;
  maintenance?: string;
  nextPublishing?: string;
  lastPublished?: string;
  committeeName?: string;
  hierarchy?: string;
  openPosition?: string;
  expiryDate?: string;
  meetingSubject?: string;
  agenda?: string;
  mom?: string;
  task?: string;
  assignTo?: string;
  assignBy?: string;
  completionDate?: string;
  attendeeName?: string;
  membershipType?: string;
  presence?: string;
  document?: string;
  documentType?: string;
  sharedBy?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  UserProfileId?: string;
  memberType?: string;
  canComment?: boolean;
  canVote?: boolean;
  numberOfRecords?: string;
  primaryCommitteeResponsible?: string;
  ballotLevel?: string;
  applicantName?: string;
  positionAppliedFor?: string;
  requestedOn?: string;
  termYear?: string;
  committeeAppliedFor?: string;
  recordSubSubType?: string;
}

interface ColumnMeta {
  field: string;
  header: string;
  link?: any;
  sortable?: boolean;
  body?: any; // Add this line to allow custom body rendering
}

interface TableProps {
  products: Product[] | any[];
  loading: boolean;
  columns: ColumnMeta[];
  actionItems?: MenuItem[];
  showCheckbox?: boolean;
  showRadioButton?: boolean;
  onActionItemClick?: any;
  onViewDetailsClick?: any;
  onRadioClick?: (rowData: any) => void;
  initialSelectedProduct?: any;
  footerGroup?: any;
  disablePagination?: boolean;
  isPrint?: boolean
}

const Table: React.FC<TableProps> = ({
  products,
  loading,
  columns,
  actionItems,
  showCheckbox,
  showRadioButton,
  onActionItemClick,
  onViewDetailsClick,
  onRadioClick,
  initialSelectedProduct,
  footerGroup,
  disablePagination = false,
  isPrint = false
}) => {
  const [first, setFirst] = useState(0);
  const [sortField, setSortField] = useState<string | undefined>(undefined); // Updated to be string or undefined
  const [sortOrder, setSortOrder] = useState<1 | -1>(1);

  const handlePageChange = (event: any) => {
    setFirst(event.first);
  };

  const handleSort = (e: any) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);
  };
  const dispatch = useDispatch();
  const toggleMenu = (event: any, rowData?: any) => {
    if (rowData.sessionKey === "staffUpdatingMember") {
      dispatch(staffConfigureProfile({ userProfileId: rowData.Id }));
    }
    if (rowData.Id && rowData.sessionKey === "ballotTableDetails") {
      sessionStorage.setItem(rowData.sessionKey, rowData.Id);
    }
    if (rowData.OpenPositionId && rowData.sessionKey === "OpenPositionId") {
      sessionStorage.setItem(rowData.sessionKey, rowData.OpenPositionId);
    }
    if (rowData.Id && rowData.sessionKey) {
      sessionStorage.setItem(rowData.sessionKey, rowData.Id);
      if (rowData.sessionKey === "committeeMembershipId") {
        dispatch(
          staffConfigureProfile({ userProfileId: rowData.UserProfileId })
        );
        sessionStorage.setItem("staffUpdatingMember", rowData.UserProfileId);
        sessionStorage.setItem("committeeId", rowData.committeeId);
        sessionStorage.setItem(
          "reappointmentUserProfileId",
          rowData.userProfileId
        );
      }
    }
    if (rowData.id && rowData.sessionKey) {
      sessionStorage.setItem(rowData.sessionKey, rowData.id);
    }
    if (rowData.id && rowData.sessionKeyStatus) {
      sessionStorage.setItem(rowData.sessionKeyStatus, rowData.statusName);
    }
    if (rowData.Id && rowData.sessionKeyStatus) {
      sessionStorage.setItem(rowData.sessionKeyStatus, rowData.statusName);
    }

    if (menuRight.current) {
      menuRight.current.toggle(event);
    }
    if (rowData.Id && rowData.sessionKey === "formattedTableData") {
      sessionStorage.setItem(rowData.sessionKey, rowData.Id);
    }
  };

  const handleNameClick = (link: string, committeeId: any) => {
    if (link === "/record/record-preview") {
      sessionStorage.setItem("recordId", committeeId);
      navigate(link);
    } else if (link === "/member/request-view") {
      sessionStorage.setItem("memberShipId", committeeId);
      navigate(link);
    } else if (link === "/ballot") {
      sessionStorage.setItem("ballotTableDetails", committeeId);
      navigate(`${link}/${committeeId}`);
    } else {
      sessionStorage.setItem("committeeId", committeeId);
      navigate(`${link}/${committeeId}`);
    }
  };

  const menuRight = useRef<Menu>(null);
  const navigate = useNavigate();
  const [selectedProducts, setSelectedProducts] = useState<any>(null);
  useEffect(() => {
    if (initialSelectedProduct) {
      const selectedProduct = products.find((product: any) => {
        return product.UserProfileId === initialSelectedProduct;
      });
      setSelectedProduct(selectedProduct);
    }
  }, [initialSelectedProduct, products]);

  useEffect(() => {}, [selectedProducts]);

  const statusBodyTemplate = (rowData: Product, col: { field: string }) => {
    let badgeClass = "";
    if (rowData.statusName === "Convert to Record") {
      rowData.statusName = "Converted to record";
    }
    switch (rowData.statusName?.toLocaleLowerCase()) {
      case "draft":
        badgeClass = "draft";
        break;
      case "open":
        badgeClass = "open";
        break;
      case "submitted":
        badgeClass = "open";
        break;
      case "in-progress":
      case "converted to record":
        badgeClass = "active";
        break;
      case "active":
      case "completed":
      case "approved":
        badgeClass = "active";
        break;
      case "current":
        badgeClass = "active";
        break;
      case "created":
        badgeClass = "active";
        break;
      case "disbanded":
      case "on-hold":
      case "withdrawn":
        badgeClass = "disbanded";
        break;
      case "terminated":
        badgeClass = "terminated";
        break;
      case "deactive":
      case "delay":
      case "rejected":
        badgeClass = "rejected";
        break;
      case "closed":
        badgeClass = "closed";
        break;
      case "terminate":
        badgeClass = "deactive";
        break;
      case "retired":
        badgeClass = "deactive";
        break;
      default:
        break;
    }
    return (
      <>
        <span className="p-column-title">{getHeaderLabel(col.field)}</span>
        <Badge
          value={rowData.statusName}
          className={`h-auto text-sm ${badgeClass}`}
        />
      </>
    );
  };

  const dataBodyTemplat = (rowData: any, col: { field: string }) => {
    const createdDate = new Date(rowData.CreatedDate);
    const options: any = { month: "short", day: "2-digit", year: "numeric" };
    const formattedDate = createdDate.toLocaleDateString("en-US", options);
    return (
      <>
        <span className="p-column-title">{getHeaderLabel(col.field)}</span>
        {formattedDate}
      </>
    );
  };

  const dataBodyTemplate = (rowData: any, col: { field: string }) => {
    const createdDate = new Date(rowData.createdDate);
    const options: any = { month: "short", day: "2-digit", year: "numeric" };
    const formattedDate = createdDate.toLocaleDateString("en-US", options);
    return (
      <>
        <span className="p-column-title">{getHeaderLabel(col.field)}</span>
        {formattedDate}
      </>
    );
  };

  const actionBodyTemplate = (rowData: Product, col: { field: string }) => {
    const isClosed = rowData.status === "Closed";
    const isRetired = rowData.statusName === "Retired";
    const isTerminate = rowData.statusName === "Terminated";
    const isResign = rowData.statusName === "Resigned";
    const isPending = rowData.statusName === "Pending";
    return (
      <>
        <span className="p-column-title">{getHeaderLabel(col.field)}</span>
        <Menu
          model={actionItems}
          popup
          ref={menuRight}
          id="popup_menu_right"
          popupAlignment="right"
        />
        <Button
          text
          className="text-body gap-1"
          aria-haspopup="true"
          aria-label="Action"
          onClick={(e) => toggleMenu(e, rowData)}
          disabled={
            isClosed || isRetired || isTerminate || isResign || isPending
          }
        >
          <FeatherIcon name="settings" size={20} color="var(--text-body)" />
          <span className="font-normal text-body">Action</span>
        </Button>
      </>
    );
  };
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const handleRadioClick = (product: Product) => {
    setSelectedProduct(product);
    if (onRadioClick) {
      onRadioClick(product); // Pass the selected product to the parent component
    }
  };

  const [checked, setChecked] = useState<boolean>(false);

  // get header label for responsive
  const getHeaderLabel = (field: string) => {
    const column = columns.find((col) => col.field === field);
    return column ? column.header : "";
  };

  // Datatable reference
  const tableContainerRef = useScrollOnPaginatorClick();
  let tableClass = "custom-data-table";
  if (products !== null && products !== undefined) {
    const isEmpty = products.length === 0;
    if (isEmpty) {
      tableClass = "custom-data-table empty";
    }
  }
  return (
    <div ref={tableContainerRef}>
      <DataTable
        className={`${tableClass} ${`p-0`} ${isPrint ? "text-sm" : ""}`}
        footerColumnGroup={footerGroup}
        value={products}
        loading={loading}
        stripedRows
        paginator={!disablePagination}
        rowsPerPageOptions={[10, 25, 50, 100]}
        rows={10}
        first={first} // Manage the first page index
        onPage={handlePageChange} // Page change event
        onSort={handleSort} // Sort event
        sortField={sortField} // Controlled sort field
        sortOrder={sortOrder} // Controlled sort order
        tableStyle={{ minWidth: "50rem" }}
        selectionMode={showCheckbox ? "multiple" : "checkbox"}
        selection={selectedProducts}
        onSelectionChange={(e: { value: any }) => {
          setSelectedProducts(e.value);
          if (showRadioButton && onRadioClick) {
            onRadioClick(e.value);
            handleRadioClick(e.value); // Call onRadioClick with the selected product
          }
        }}
      >
        {showCheckbox && (
          <Column selectionMode="multiple" style={{ width: "3rem" }} />
        )}

        {showRadioButton && (
          <Column
            style={{ width: "3rem" }}
            field="UserProfileId" // Assuming 'id' is the unique identifier for products
            header="#"
            body={(rowData) => {
              let isChecked;
              if (selectedProduct?.UserProfileId) {
                isChecked =
                  rowData.UserProfileId === selectedProduct.UserProfileId;
              } else {
                isChecked = false;
              }
              return (
                <>
                  <RadioButton
                    inputId="id"
                    name="Record"
                    value=""
                    onChange={() => handleRadioClick(rowData)}
                    checked={isChecked}
                    aria-label="Select Row"
                  />
                </>
              );
            }}
          />
        )}

        {columns
          .filter(
            (item: any) =>
              item.field !== "statusName" &&
              item.field !== "action" &&
              item.field !== "CreatedDate" &&
              item.field !== "createdDate"
          )
          .map((col, i) => (
            <Column headerClassName={`${isPrint ? "p-1 text-sm" : ""}`}  className={`${isPrint ? "p-1" : ""}`}
              key={col.field}
              field={col.field}
              header={col.header}
              sortable={col.sortable !== undefined ? col.sortable : true}
              body={(rowData) => (
                <>
                  <span className="p-column-title">{col.header}</span>
                  {col.field === "canComment" || col.field === "canVote" ? (
                    <Checkbox
                      onChange={(e) => setChecked(e.checked ?? false)}
                      checked={checked}
                      className="mr-2"
                      aria-label={`Checkbox-${rowData[col.field]}`}
                    />
                  ) : col.link ? (
                    <a
                      onClick={() => handleNameClick(col.link, rowData.Id)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleNameClick(col.link, rowData.Id);
                        }
                      }}
                      className="font-bold table-text-link underline"
                      tabIndex={0}
                    >
                      {rowData[col.field]}
                    </a>
                  ) : col.body ? (
                    col.body(rowData)
                  ) : (
                    rowData[col.field]
                  )}
                </>
              )}
            />
          ))}
        {columns.some((col) => col.field === "CreatedDate") && (
          <Column
            field="CreatedDate"
            header={columns.find((col) => col.field === "CreatedDate")?.header}
            sortable={
              columns.find((col) => col.field === "CreatedDate")?.sortable ??
              true
            }
            body={dataBodyTemplat}
          />
        )}
        {columns.some((col) => col.field === "createdDate") && (
          <Column
            field="createdDate"
            header={columns.find((col) => col.field === "createdDate")?.header}
            sortable={
              columns.find((col) => col.field === "createdDate")?.sortable ??
              true
            }
            body={dataBodyTemplate}
          />
        )}
        {columns.some((col) => col.field === "RequestStatus") && (
          <Column
            field="RequestStatus"
            header={
              columns.find((col) => col.field === "RequestStatus")?.header
            }
            sortable={
              columns.find((col) => col.field === "RequestStatus")?.sortable ??
              true
            }
            body={statusBodyTemplate}
          />
        )}
        {columns.some((col) => col.field === "statusName") && (
          <Column
            field="statusName"
            header={columns.find((col) => col.field === "statusName")?.header}
            sortable={
              columns.find((col) => col.field === "statusName")?.sortable ??
              true
            }
            body={statusBodyTemplate}
          />
        )}
        {columns.some((col) => col.field === "action") && (
          <Column
            field="action"
            header={columns.find((col) => col.field === "action")?.header}
            body={actionBodyTemplate}
          />
        )}
      </DataTable>
    </div>
  );
};

export default Table;
