import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import FeatherIcon from '../../common/FeatherIconComponent';
import { ballotDetails } from "../../../slices/ballotDetails";
import { putData } from '../../../services/Ballot/apiservice';
import { useLanguageContext } from '../../LanguageContext';
import { ballotSelectRecords } from "../../../slices/ballotSelectRecords";

const calculateDaysToIssue = (openDateString: string, closeDateString: string): number => {
    const openDate = new Date(openDateString);
    const closeDate = new Date(closeDateString);
    const diffTime = Math.abs(closeDate.getTime() - openDate.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};
const AddBallotDetails = () => {
    const [t, i18n] = useTranslation("ballot");
    const { selectedLanguage } = useLanguageContext();
    const performAction = () => {
        i18n.changeLanguage(selectedLanguage);
    };
    React.useEffect(() => {
        performAction();
    }, [selectedLanguage]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { ballotStatusList, ballotDetailsInfo } = useSelector((state: any) => state.ballotDetails);
    const { accreditationBodySelect, primaryCommitteSelect, ballotTypeSelect, ballotSubTypeSelect, ballotLevelSelect, recordTypeSelect, recordSubTypeSelect, positionAppliedSelect } = useSelector((state: any) => state.ballotSelectRecords.selectedRecordValues);
    const { isEdit } = useSelector((state: any) => state.ballotSelectRecords);
    const tablerecordSelected: any = useSelector((state: any) => state.ballotSelectRecords.selectedTableRecords);
    const [startDate, setStartDate] = useState<any>(ballotDetailsInfo.openDate ? new Date(ballotDetailsInfo.openDate) : new Date());
    const [formSubmit, setFormSubmit] = useState<boolean>(false)
    let ballotId = ballotDetailsInfo.id;
    const { control, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            secret: ballotDetailsInfo.isSecret || false,
            opendate: ballotDetailsInfo.openDate ? new Date(ballotDetailsInfo.openDate) : undefined,
            enddate: ballotDetailsInfo.closeDate ? new Date(ballotDetailsInfo.closeDate) : undefined,
            description: ballotDetailsInfo.description || '',
            explaination: ballotDetailsInfo.explaination || '',
            staffnote: ballotDetailsInfo.note || ''
        }
    });
    const onSubmit = (data: any) => {
        for (const [key, value] of Object.entries(data)) {
            handleChange(value as string, key);
        } 
        setFormSubmit(true)
    };
    const handleChange = (value: string, label: string) => {
        dispatch(ballotDetails.addBallotDetails({ target: value, label }));
    };

    const createBallotPayload = () => {
        return {
        accreditationBodyId: accreditationBodySelect?.id,
        isDirty: isEdit,
        isNew: isEdit,
        ballotLevelId: ballotLevelSelect?.id,
        ballotTypeId: ballotTypeSelect?.id,
        ballotSubTypeId: ballotSubTypeSelect?.id,
        ballotStatusId: ballotDetailsInfo?.ballotStatusId ?? ballotStatusList?.Draft?.id,
        recordSubTypeId: recordSubTypeSelect?.id,
        recordTypeId: recordTypeSelect?.id,
        committeeId: primaryCommitteSelect?.id,
        membershipPositionId: positionAppliedSelect?.id,
        note: ballotDetailsInfo?.note,
        noOfRecords: tablerecordSelected.length,
        explaination: ballotDetailsInfo?.explaination,
        description: ballotDetailsInfo?.description,
        daysToIssue: ballotDetailsInfo?.daysToIssue,
            openDate: ballotDetailsInfo?.openDate ? new Date(ballotDetailsInfo.openDate) : null,
            closeDate: ballotDetailsInfo?.closeDate ? new Date(ballotDetailsInfo.closeDate) : null,
        ballotNumber: ballotDetailsInfo?.ballotNumber,
            isSecretBallot: ballotDetailsInfo?.isSecret,
        isActive: true,
            isDeleted: false,
            recirculationBallotId: ballotDetailsInfo?.recirculationBallotId,
            recircultionBallotNumber: ballotDetailsInfo?.recirculationBallotNumber,
        };
    };

    const createBallotDetailsHandler = async () => {
        try {
            if (ballotDetailsInfo.description) {
                const ballotPayload = createBallotPayload();

                ballotPayload.daysToIssue = calculateDaysToIssue(ballotDetailsInfo?.openDate, ballotDetailsInfo?.closeDate);

                await putData(`Ballot`, `${ballotId}`, ballotPayload);

                if (formSubmit) {
                    dispatch(ballotSelectRecords.addStepCountHandler({ value: 2 }));
                } else {
                    navigate(`/ballot/membership`);
                }
                setFormSubmit(false);
            }
        } catch (err) {
            console.log(err, 'err');
        }
    };

    useEffect(() => {
        if (formSubmit) {
            createBallotDetailsHandler()
        }
    }, [ballotDetailsInfo]);
    return (
        <>
            <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
                <div className="flex flex-column gap-1">
                    <h2 className="text-title text-lg font-bold text-capitalize m-0">
                        {t("ballot.ballotDetails")}
                    </h2>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card bg-white w-full mb-5 shadow-md">
                    <div className="p-5 cardBody">
                        <div className="flex flex-column gap-3 w-full">
                            <div className="grid grid-xl">
                                <div className="flex flex-column gap-3 xl:col-6 lg:col-8 col-12">
                                    <label
                                        htmlFor="secret"
                                        className={`block font-bold text-capitalize text-input-label`}
                                    >
                                        {t("ballot.confidentiality")}
                                    </label>
                                    <div className="flex align-items-center">
                                        <Controller
                                            name="secret"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <Checkbox
                                                        {...field}
                                                        inputId="secret"
                                                        checked={field.value}
                                                        className="mr-2"
                                                    />
                                                    <label htmlFor="secret" className="ml-2">
                                                        {t("ballot.secret")}
                                                    </label>
                                                </>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-xl">
                                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                                    <label
                                        htmlFor="ballotStartdate"
                                        className={`block font-bold text-capitalize ${errors.opendate ? " p-error" : "text-input-label"
                                            }`}
                                    >
                                        {t("ballot.ballotStartDate")}
                                    </label>
                                    <Controller
                                        name="opendate"
                                        control={control}
                                        defaultValue={ballotDetailsInfo.openDate ? new Date(ballotDetailsInfo.openDate) : undefined}
                                        rules={{ required: `${t("ballot.ballotStartDate")} is required` }}
                                        render={({ field }) => (
                                            <Calendar
                                                inputId="ballotStartdate"
                                                aria-describedby="ballotStartdate"
                                                {...field}
                                                value={field.value || null}
                                                minDate={new Date()}
                                                onChange={(e) => {
                                                    field.onChange(e.value);
                                                    setStartDate(e.value);
                                                }}
                                                placeholder={t("ballot.selectDate")}
                                                dateFormat="mm/dd/yy"
                                                className={`custom-datepicker ${errors.opendate ? 'p-invalid' : ''}`}
                                            />
                                        )}
                                    />
                                    {errors.opendate && (
                                        <span className="p-error font-bold text-capitalize">
                                            {`${errors.opendate.message}`}
                                        </span>
                                    )}
                                </div>
                                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                                    <label
                                        htmlFor="ballotEndDate"
                                        className={`block font-bold text-capitalize ${errors.enddate ? " p-error" : "text-input-label"
                                            }`}
                                    >
                                        {t("ballot.ballotEndDate")}
                                    </label>
                                    <Controller
                                        name="enddate"
                                        control={control}
                                        defaultValue={ballotDetailsInfo.closeDate ? new Date(ballotDetailsInfo.closeDate) : undefined}
                                        rules={{ required: `${t("ballot.ballotEndDate")} is required` }}
                                        render={({ field }) => (
                                            <Calendar
                                                inputId="ballotEndDate"
                                                aria-describedby="ballotEndDate"
                                                {...field}
                                                value={field.value || null}
                                                onChange={field.onChange}
                                                minDate={new Date(startDate.getTime() + 86400000)}
                                                placeholder="Select Date"
                                                dateFormat="mm/dd/yy"
                                                className={`custom-datepicker ${errors.enddate ? 'p-invalid' : ''}`}
                                            />
                                        )}
                                    />
                                    {errors.enddate && (
                                        <span className="p-error font-bold text-capitalize">
                                            {`${errors?.enddate?.message}`}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="grid grid-xl">
                                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                                    <label
                                        htmlFor="ballotDescription"
                                        className={`block font-bold text-capitalize ${errors.description ? " p-error" : "text-input-label"
                                            }`}>
                                        {t("ballot.ballotDescription")}*
                                    </label>
                                    <Controller
                                        name="description"
                                        control={control}
                                        defaultValue={ballotDetailsInfo.description || ''}
                                        rules={{ required: `${t("ballot.ballotDescription")} is required` }}
                                        render={({ field }) => (
                                            <InputTextarea
                                                id="ballotDescription"
                                                aria-describedby="ballotDescription"
                                                name="charterName"
                                                placeholder={t("ballot.ballotDescriptionPlaceholder")}
                                                value={field.value}
                                                onChange={field.onChange}
                                                rows={5}
                                                cols={30}
                                                maxLength={500}
                                                className={errors.description ? 'p-invalid' : ''}
                                            />
                                        )}
                                    />
                                    {errors.description && (
                                        <span className="p-error font-bold text-capitalize">
                                            {`${errors.description.message}`}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="grid grid-xl">
                                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                                    <label
                                        htmlFor="ballotExplanation"
                                        className={`block font-bold text-capitalize ${errors.explaination ? " p-error" : "text-input-label"
                                            }`}>
                                        {t("ballot.ballotExplanation")}*
                                    </label>
                                    <Controller
                                        name="explaination"
                                        control={control}
                                        defaultValue={ballotDetailsInfo?.explaination || ''}
                                        rules={{ required: `${t("ballot.ballotExplanation")} is required` }}
                                        render={({ field }) => (
                                            <InputTextarea
                                                id="ballotExplanation"
                                                aria-describedby="ballotExplanation"
                                                name="charterName"
                                                placeholder={t("ballot.ballotDescriptionPlaceholder")}
                                                value={field.value}
                                                onChange={field.onChange}
                                                rows={5}
                                                cols={30}
                                                maxLength={500}
                                                className={errors.explaination ? 'p-invalid' : ''}
                                            />
                                        )}
                                    />
                                    {errors.explaination && (
                                        <span className="p-error font-bold text-capitalize">
                                            {`${errors.explaination.message}`}
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="grid grid-xl">
                                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                                    <label
                                        htmlFor="staffnote"
                                        className="block font-bold text-capitalize text-input-label">
                                        {t("ballot.staffNote")}
                                    </label>
                                    <Controller
                                        name="staffnote"
                                        control={control}
                                        defaultValue={ballotDetailsInfo?.staffnote || ''}
                                        render={({ field }) => (
                                            <InputTextarea
                                                id="staffnote"
                                                aria-describedby="staffnote"
                                                name="charterName"
                                                placeholder={t("ballot.ballotDescriptionPlaceholder")}
                                                value={field.value}
                                                onChange={field.onChange}
                                                rows={5}
                                                cols={30}
                                                maxLength={500}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-white hidden md:flex align-items-stretch px-5 py-3 gap-3 lg:gap-4 fixed-footer w-full left-0 shadow">
                    <Button
                        type="button"
                        label={t("ballot.cancel")}
                        className="button-md flex-shrink-0"
                        severity="secondary"
                        onClick={() => navigate(`/ballot/membership`)}
                    />
                    <Button
                        type="button"
                        text
                        className="p-button-plain underline"
                        label={t("ballot.discardChanges")}
                        onClick={() => reset(undefined, { keepDefaultValues: true })}
                    ></Button>
                    <Button
                        type="submit"
                        text
                        className="p-button-plain underline ml-auto"
                        label={t("ballot.saveAsDraft")}
                        onClick={() => {
                            createBallotDetailsHandler();
                            navigate(`/ballot/membership`);
                        }}
                    ></Button>
                    <Button
                        type="button"
                        className="button-md gap-1 flex-shrink-0"
                        severity="secondary"
                        onClick={() => dispatch(ballotSelectRecords.addStepCountHandler({ value: 0 }))}
                    >
                        <FeatherIcon
                            name="chevron-left"
                            size={20}
                            color="inherit"
                        />
                        <span className="font-bold text-capitalize">
                            {t("ballot.back")}
                        </span>
                    </Button>
                    <Button type='submit'
                        className="button-md gap-1"
                    >
                        <span className="font-bold text-capitalize">
                            {t("ballot.saveContinue")}
                        </span>
                        <FeatherIcon
                            name="chevron-right"
                            size={20}
                            color="inherit"
                        />
                    </Button>
                </div>

                {/* Mobile responsive buttions */}
                <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 justify-content-between fixed-footer w-full left-0 shadow">
                    <Button
                        className="button-md gap-1 w-full justify-content-center"
                        type='submit'
                    >
                        <span className="font-bold text-capitalize">
                            {t("ballot.saveContinue")}
                        </span>
                        <FeatherIcon
                            name="chevron-right"
                            size={20}
                            color="inherit"
                        />
                    </Button>
                    <div className="flex align-items-center gap-3 w-full">
                        <Button
                            type="button"
                            className="button-md gap-1"
                            severity="secondary"
                            onClick={() => dispatch(ballotSelectRecords.addStepCountHandler({ value: 0 }))}
                            aria-label={t("ballot.back")}
                        >
                            <FeatherIcon
                                name="chevron-left"
                                size={20}
                                color="inherit"
                            />
                        </Button>
                        <Button
                            type="button"
                            label={t("ballot.cancel")}
                            className="button-md w-full"
                            severity="secondary"
                            onClick={() => navigate(`/ballot/membership`)}
                        />
                    </div>
                    <div className="flex flex-column sm:flex-row align-items-center gap-3 w-full">
                        <Button
                            type="submit"
                            text
                            className="p-button-plain underline w-full"
                            label={t("ballot.saveAsDraft")}
                            onClick={() => {
                                createBallotDetailsHandler();
                                navigate(`/ballot/membership`);
                            }}
                        ></Button>
                        <Button
                            type="button"
                            text
                            className="p-button-plain underline w-full"
                            label={t("ballot.discardChanges")}
                            onClick={() => reset(undefined, { keepDefaultValues: true })}
                        ></Button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default AddBallotDetails