import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GUID_PlACEHOLDER } from "../components/ballot/common/constants";

interface ballotCommittee {
  ballotCommitteeData: Record<string, any>;
  updatedCommitteeData: any;
}
const initialState: ballotCommittee = {
  ballotCommitteeData: [],
  updatedCommitteeData: [],
};
const selectBallotCommittee = createSlice({
  name: "ballotCommittees",
  initialState,
  reducers: {
    initialBallotCommitteeValues: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.ballotCommitteeData = action.payload.value;
      let isRecirculation = action.payload.isRecirculation;
      let ballotId = action.payload.ballotId;
      const formattedObj = action.payload.value.map((committee: any) => {
        return {
          isDirty: isRecirculation ? false : true,
          isNew: isRecirculation ? true : false,
          ballotId: isRecirculation ? ballotId : committee.BallotId,
          committeeId: committee.CommitteeId,
          ballotRecordStatusId: action.payload.statusId,
          forReviewAndComment: committee.ForReviewAndComment,
          ballotCommitteeId: isRecirculation
            ? GUID_PlACEHOLDER
            : committee.BallotCommitteeId,
          isActive: true,
          isDeleted: false,
        };
      });
      state.updatedCommitteeData = formattedObj;
    },
    updatedCommitteeData: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      // const filterObj = state.updatedCommitteeData.filter((committee: any) => committee.Committee.Id !== action.payload.value)
      const { value, label, isReview } = action.payload;

      switch (label) {
        case "delete":
          state.updatedCommitteeData = state.updatedCommitteeData.map(
            (committee: any) =>
              committee.committeeId === value
                ? {
                    ...committee,
                    isDirty: true,
                    forReviewAndComment: isReview,
                    isNew: false,
                    isActive: false,
                    isDeleted: true,
                  }
                : committee
          );
          break;
        case "update":
          state.updatedCommitteeData = state.updatedCommitteeData.map(
            (committee: any) =>
              committee.committeeId === value
                ? {
                    ...committee,
                    isDirty: true,
                    forReviewAndComment: isReview,
                    isNew: false,
                    isActive: true,
                    isDeleted: false,
                  }
                : committee
          );
          break;
        case "new":
          const exists = state.updatedCommitteeData.some(
            (committee: any) => committee.committeeId === value.committeeId
          );
          if (!exists) {
            state.updatedCommitteeData = [...state.updatedCommitteeData, value];
          }
          break;
        default:
          break;
      }
    },
    reset: (state) => {
      state.ballotCommitteeData = [];
      state.updatedCommitteeData = [];
    },
  },
});

export const ballotCommittees = selectBallotCommittee.actions;
export default selectBallotCommittee.reducer;
