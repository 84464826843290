import { useTranslation } from "react-i18next";
import { BallotCommitteeReports } from "../types/BallotReport";
import Table from "../../common/Table";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";

interface CommitteeCardProps {
  title: string;
  committeeReports: BallotCommitteeReports[] | [] ;
  filterCondition: (report: BallotCommitteeReports) => boolean;
  setActiveIndex?: any;
  primaryCommittee: string | null | undefined;
  columns: any[];
  isPrint?: boolean
}

const BallotReportCommitteeCard: React.FC<CommitteeCardProps> = ({
  title,
  committeeReports = [],
  filterCondition,
  setActiveIndex,
  primaryCommittee,
  columns,
  isPrint = false
}) => {
  const [t] = useTranslation('ballot');
  const navigate = useNavigate();
  const handleViewCommentsClick = useCallback(() => {
    if (setActiveIndex) {
      setActiveIndex(3);
    } else {
      navigate(`/ballot/${committeeReports[0].BallotId}`, { state: { setActiveIndex: 3 } });
    }
  }, [setActiveIndex]);
  return (
    <>
    {committeeReports?.length > 0 && (
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">{title}</h2>
          </div>
          {committeeReports
            .filter(filterCondition)
            .map((ballotreportData, index) => (
              <div   className={`cardBody flex flex-column gap-7 ${isPrint ? "p-2" : "p-5"}`} key={index}>
                <div className="">
                  <div className="flex flex-wrap align-items-center justify-content-between gap-4 mb-4">
                    <h3 className="text-title text-lg text-capitalize m-0">
                      <span className="font-bold mr-2">{ballotreportData.CommitteeName}</span>
                      {ballotreportData.CommitteeName === primaryCommittee && (
                        <span className="font-normal">({t('ballot.primaryResponsibleCommittee')})</span>
                      )}
                    </h3>
                    <h4 className="text-lg font-bold m-0">
                      {ballotreportData.VoteRecivedByCommittee}/
                      {ballotreportData.TotalMemberInvitedByCommittee} {t('ballot.memberVoted')}
                    </h4>
                  </div>
                  <Table isPrint={isPrint }   products={ballotreportData?.BallotCommitteeDetailReportList} loading={false} columns={columns} disablePagination={true} />
                  {!isPrint && (
                    setActiveIndex ? (
                      <Button
                        text
                        className="p-button-plain underline"
                        label={t("ballot.viewComments")}
                        onClick={() => setActiveIndex(3)}
                      />
                    ) : (
                      <Button
                        text
                        className="p-button-plain underline"
                        label={t("ballot.viewComments")}
                        onClick={handleViewCommentsClick}
                      />
                    )
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default BallotReportCommitteeCard;