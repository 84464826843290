import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { MenuItem } from "primereact/menuitem";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Toast } from "primereact/toast";
import BallotFilter from "./BallotFilter";
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";
import { ballotSelectRecords } from "../../../slices/ballotSelectRecords";
import { ballotDetails } from "../../../slices/ballotDetails";
import { ballotCommittees } from '../../../slices/ballotCommittees';
import { deleteData, fetchData } from "../../../services/Ballot/apiservice";
import { fetchData as getData } from "../../../services/apiService";
import { usePermission } from "../../auth/Authorization";
import { allBallotListings } from "../../../slices/ballotListing";
import { formatDate } from "../../../utils/utils";
import { setToast } from "../../../slices/toastSlice";
import { BALLOT_DRAFT, BALLOT_OPEN, BALLOT_OUT_FOR_BALLOT, BALLOT_STATUS_KEY, MEMBERSHIP_PROFILE_KEY } from "../common/constants";
import { BallotStatus, BallotStatusResponse, QueryParamsByRole, TableBallotListing, FormattedBallotStatusList, BallotTextSearch, ToastContentProps, Column } from "../common/type";
import BallotListingTable from "../common/BallotListingTable";
interface Option {
  name: string;
  code: string;
}

const BallotListing: React.FC = () => {
  const [t, i18n] = useTranslation("ballot");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const columnsData: Column[] = [
    {
      field: "ballotId",
      header: t("ballot.ballotId"),
      link: "/ballot",
    },
    {
      field: "numberOfRecords",
      header: t("ballot.numberOfRecords"),
      sortable: true
    },
    { field: "primaryCommitteeResponsible", header: t("ballot.primaryCommitteeResponsible"), sortable: true },
    {
      field: "ballotType",
      header: t("ballot.ballotType"),
      sortable: true
    },
    {
      field: "ballotSubType",
      header: t("ballot.ballotSubType"),
      sortable: true
    },
    { field: "ballotLevel", header: t("ballot.ballotLevel"), sortable: true },
    { field: "statusName", header: t("ballot.status"), sortable: true },
    { field: "openDate", header: t("ballot.openDate"), sortable: true },
    { field: "endDate", header: t("ballot.endDate"), sortable: true },
  ];
  const [columns, setColumns] = useState<Column[]>(columnsData)
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [userRole, setUserRole] = useState<string>("");
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];
  const navigate = useNavigate();
  sessionStorage.clear();
  const dispatch = useDispatch();
  const toast = useRef<any>(null);
  const ballotListings: any = useSelector((state: any) => state.ballotListingData.ballotTableData);
  const [filteredData, setFilteredData] = useState(ballotListings);
  const [allBallotDetails, setAllBallotDetails] = useState();
  const [ballotStatusList, setBallotStatusList] = useState<FormattedBallotStatusList>();
  const [userId, setUserId] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const handleBallotCheckboxChange = (e: CheckboxChangeEvent) => {
    if (e.checked !== undefined) {
      setChecked(e.checked);
    }
  };
  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);
  const getAllBallotStatus = async () => {
    try {
      const ballotStatusResponse: BallotStatusResponse = await fetchData(`BallotStatus/GetAll`);
      const allBallotStatusFormatted = ballotStatusResponse.Collection.map((ballot: BallotStatus) => {
        return {
          id: ballot.Id,
          name: ballot.Name
        }
      });
      const formattedObject: FormattedBallotStatusList = allBallotStatusFormatted.reduce((acc: FormattedBallotStatusList, status) => {
        acc[status.name] = { id: status.id };
        return acc;
      }, {});
      setBallotStatusList(formattedObject)
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getUserProfileId = async () => {
    try {
      const responseData = await getData("UserProfile/GetCurrentUserProfile");
      const userProfileID = responseData.Id;
      setUserId(userProfileID)
    } catch (err) {
      console.log(err)
    }
  }
  const queryParamsByRole: QueryParamsByRole = {
    Admin: { param: `&PageIndex=-1` },
    Staff: { param: `&PageIndex=-1` },
    Member: { param: `Filters[0].Key=${BALLOT_STATUS_KEY}&Filters[0].Value=${ballotStatusList && ballotStatusList[BALLOT_OUT_FOR_BALLOT]?.id}}&Filters[1].Key=${MEMBERSHIP_PROFILE_KEY}&Filters[1].Value=${userId}` }
  };

  const getAllBallot = async () => {
    try {
      const currentQueryParams = queryParamsByRole[userRole as keyof QueryParamsByRole] || { param: '' };
      const allBallotData = await fetchData(`Ballot/BallotSearch?${currentQueryParams.param}&PageIndex=-1&SortDir=desc`);
      setAllBallotDetails(allBallotData.Collection)
      const ballotTableDetails = allBallotData.Collection.map((ballot: TableBallotListing) => {
        return {
          Id: ballot.BallotId,
          ballotId: ballot.BallotNumber,
          numberOfRecords: `${ballot.NoOfRecord} Records`,
          primaryCommitteeResponsible: ballot.PrimaryResponsibleCommittees,
          ballotType: ballot.BallotType,
          ballotSubType: ballot.BallotSubType,
          ballotLevel: ballot.BallotLevel,
          statusName: ballot.Status,
          openDate: ballot.OpenDate !== null ? formatDate(ballot.OpenDate) : '-',
          endDate: ballot.CloseDate !== null ? formatDate(ballot.CloseDate) : '-',
          action: t("ballot.action"),
          sessionKey: 'ballotTableDetails'
        }
      });
      dispatch(allBallotListings.addAllBallotDetails({ value: ballotTableDetails, }))
    } catch (err) {
      console.log(err, 'err fetching data')
    } finally {
      setLoading(false)
    }
  }
  const ballotDeleteHandler = async (ballotId: string | null) => {
    try {
      const deleteBallot = await deleteData(`Ballot`, `${ballotId}`);
      dispatch(setToast({ message: t("ballot.deletedSuccessfully"), severity: 'success' }));
      getAllBallot();
    } catch (err) {
      console.log(err, 'err')
    }
  }
  const getActionItems = (rowData: any): MenuItem[] => [
    {
      label: t("ballot.viewEdit"),
      visible: rowData.statusName === BALLOT_DRAFT || rowData.statusName === BALLOT_OPEN,
      command: () => {
        navigate(`/ballot/edit/${rowData.Id}`);
      },
    },
    {
      label: t("ballot.delete"),
      command: () => {
        ballotDeleteHandler(rowData.Id)
      },
    },
  ];

  useEffect(() => {
    getAllBallotStatus();
    getUserProfileId();
  }, []);

  useEffect(() => {
    getAllBallot();
    if (userRole === 'Admin' || userRole === 'Staff') {
      setColumns((prevData: Column[]) => [...prevData, { field: "action", header: t("ballot.action") }])
    }
  }, [userRole, userId, ballotStatusList]);
  useEffect(() => {
    const lowercasedSearch = search.toLowerCase();
    const filtered = ballotListings.filter((record: BallotTextSearch) =>
      record.ballotId.toLowerCase().includes(lowercasedSearch) ||
      record.primaryCommitteeResponsible.toLowerCase().includes(lowercasedSearch) ||
      record.ballotType.toLowerCase().includes(lowercasedSearch) || record.ballotLevel.toLowerCase().includes(lowercasedSearch)
    );
    setFilteredData(filtered);
  }, [search, ballotListings]);
  return (
    <>
      <div>
        <div className="flex flex-wrap align-items-center md:flex-nowrap md:justify-content-between py-3 gap-3 w-full">
          <IconField iconPosition="left" className="w-full md:w-16rem">
            <InputIcon className="pi pi-search"> </InputIcon>
            <InputText
              id="searchByBallotKeyword"
              placeholder={t("ballot.searchByBallotKeyword")}
              className="w-full"
              aria-label={t("ballot.searchByBallotKeyword")}
              onChange={(e) => setSearch(e.target.value)}
              tooltip={t("ballot.searchByBallotKeywordTooltip")}
              tooltipOptions={{ position: 'top', autoHide: true, event: 'hover' }}
            />
          </IconField>
          <Dropdown
            inputId="myCommitteesBallot"
            value={selectedOption}
            onChange={(e: DropdownChangeEvent) =>
              setSelectedOption(e.value)
            }
            options={options}
            optionLabel="name"
            placeholder={t("ballot.myCommitteesBallot")}
            className="w-full md:w-16rem ml-auto"
            aria-label={t("ballot.myCommitteesBallot")}
          />
          <div className="flex gap-3 w-full md:w-auto align-items-center">
            <div className="relative">
              <BallotFilter />
            </div>
            <Button
              className="button-md gap-1 justify-content-center w-full md:w-auto"
              onClick={() => {
                dispatch(ballotDetails.reset());
                dispatch(ballotSelectRecords.reset());
                dispatch(ballotCommittees.reset());
                navigate("/ballot/create")
              }
              }
              disabled={
                userRole === "Admin" || userRole === "Staff"
                  ? false
                  : true
              }
            >
              <FeatherIcon name="plus" size={20} color="inherit" />
              <span className="font-bold text-capitalize">
                {t("ballot.createNewBallot")}
              </span>
            </Button>
          </div>
        </div>

        <div className="flex flex-wrap row-gap-3 column-gap-5 mb-3">
          <div className="flex align-items-center">
            <Checkbox
              inputId="showClosedBallot"
              onChange={handleBallotCheckboxChange}
              checked={checked}
              className="mr-2"
            />
            <label
              htmlFor="showClosedBallot"
              className="text-base font-normal"
            >
              {t("ballot.showClosedBallot")}
            </label>

          </div>
          <div className="flex align-items-center">
            <Checkbox
              inputId="showCancelledBallot"
              onChange={handleBallotCheckboxChange}
              checked={checked}
              className="mr-2"
            />
            <label
              htmlFor="showCancelledBallot"
              className="text-base font-normal"
            >
              {t("ballot.showCancelledBallot")}
            </label>

          </div>
        </div>
        <Toast ref={toast} />
        <BallotListingTable
          loading={loading}
          products={filteredData}
          columns={columns}
          getActionItems={getActionItems}
        />
      </div>
    </>
  );
};

export default BallotListing;
