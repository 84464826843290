import React, {
  useContext,
  useState,
  useTransition,
  useRef,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge } from "primereact/badge";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Menu } from "primereact/menu";
import { Toast } from "primereact/toast";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useLanguageContext } from "./LanguageContext";
import { Link, useNavigate } from "react-router-dom";
import stanzaLogo from "../assets/stanza-by-infobeans.svg";
import { resetProfile } from "../slices/createProfileSlice";
import { reset } from "../slices/createCommitteeSlice";
import AccessibilityOptions from "./common/AccessibilityOptions";
import { OverlayPanel } from "primereact/overlaypanel";
import us from "../assets/language/usa.svg";
import fr from "../assets/language/france.svg";
import gr from "../assets/language/germany.svg";
import StayLoggedIn from "./common/StayLoggedIn";
import { fetchData, putData } from "../services/apiService";
import { IDENTITY_URL } from "../config";
import { getTenantNameFromDomain } from "../utils/utils";
import {
  getEnvInfo,
  getIsADB2CLoginStatus,
  clearAllSessionStorage,
} from "../utils/storage";

interface LayoutProps {
  onLanguageChange: (lang: string) => void;
}

interface Language {
  label: string;
  value: string;
  id: string;
  icon: any;
}

const Header: React.FC<LayoutProps> = ({ onLanguageChange }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("global");
  const { selectedLanguage, changeLanguage } = useLanguageContext();
  const [selectedLanguageDropdown, setSelectedLanguageDropdown] =
    useState("en");
  const [allLanguage, setAllLanguage] = useState<any>();
  const [languagess, setLanguagess] = useState<Language[]>([]);
  const { instance, inProgress, accounts } = useMsal();

  const itemRenderer = (item: {
    icon: string | undefined;
    label:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | null
      | undefined;
    badge: any;
    shortcut:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | null
      | undefined;
  }) => (
    <a className="flex align-items-center p-menuitem-link">
      <span className={item.icon} />
      <span className="mx-2">{item.label}</span>
      {item.badge && <Badge className="ml-auto" value={item.badge} />}
      {item.shortcut && (
        <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
          {item.shortcut}
        </span>
      )}
    </a>
  );

  const fetchAllLanguages = async () => {
    try {
      const response = await fetchData(`Language/GetAll`);
      const transformedLanguages = response.Collection.map(
        (language: { Name: any; Symbol: any; Id: any }) => ({
          label: language.Name,
          value: language.Symbol,
          id: language.Id,
          icon: getIcon(language.Symbol),
        })
      );
      setLanguagess(transformedLanguages);
    } catch (err) {
      console.log(err, "err");
    }
  };

  const getIcon = (symbol: any) => {
    switch (symbol) {
      case "en":
        return us;
      case "fr":
        return fr;
      case "gr":
        return gr;
      default:
        return null;
    }
  };

  useEffect(() => {
    fetchAllLanguages();
  }, []);

  const selectedOption = languagess.find(
    (option) => option.value === selectedLanguage
  );

  // Set body class when component mounts to maintain language selection after refresh
  useEffect(() => {
    // Retrieve language from local storage
    const storedLanguage = localStorage.getItem("selectedLanguage");
    // If language is retrieved from local storage, update the context
    if (storedLanguage) {
      changeLanguage(storedLanguage);
      setSelectedLanguageDropdown(storedLanguage);
    } else {
      // If no language is set in local storage, set it to 'en' (English) by default
      changeLanguage("en");
      // Also, store the default language in local storage
      localStorage.setItem("selectedLanguage", "en");
    }
  }, [changeLanguage]);
  // Function to handle language change
  const handleChangeLanguage = (e: any) => {
    const selectedLanguage = e.value;
    setSelectedLanguageDropdown(selectedLanguage);
    // Store selected language in local storage
    // localStorage.setItem("selectedLanguage", selectedLanguage);
  };

  const logout = async () => {
    const isADB2CLoginStatus = getIsADB2CLoginStatus();
    if (isADB2CLoginStatus) {
      logoutB2C();
    } else {
      let token = localStorage.getItem("token");
      const logout = await fetch(`${IDENTITY_URL}/Account/UserLogOut`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: `"${token}"`,
      });
      localStorage.removeItem("token");
      localStorage.removeItem("userProfileID");
      dispatch(reset());
      clearAllSessionStorage();
      navigate("/");
    }
  };

  const logoutB2C = () => {
    instance.logoutRedirect();
    localStorage.removeItem("token");
    localStorage.removeItem("userProfileID");
    dispatch(reset());
    clearAllSessionStorage();
  };

  const menuRight = useRef<Menu>(null);
  const toast = useRef<Toast>(null);
  const toggleProfileMenu = (event: any) => {
    if (menuRight.current) {
      menuRight.current.toggle(event);
    }
  };
  const items = [
    {
      label: t("global.myProfile"),
      icon: "pi pi-user",
      command: () => {
        clearAllSessionStorage();
        // Handle click action for Item 1
        dispatch(resetProfile());
        navigate("/profile/");
      },
    },
    {
      label: t("global.logOut"),
      icon: "pi pi-sign-out",
      command: () => {
        logout();
      },
    },
  ];

  const overlayPanelRef = useRef<OverlayPanel>(null); // Ref for OverlayPanel
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false); // State to control visibility of OverlayPanel
  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel); // Toggle visibility of OverlayPanel
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event); // Toggle OverlayPanel
    }
  };

  // Font Size
  const [fontSize, setFontSize] = useState(() => {
    return localStorage.getItem("fontSize") || "font-normal";
  });
  // Set body class when component mounts to maintain font size after refresh
  useEffect(() => {
    // Retrieve font size from local storage
    const storedFontSize = localStorage.getItem("fontSize");
    // If font size is retrieved from local storage, set the body class
    if (storedFontSize) {
      document.body.classList.add(storedFontSize);
      setFontSize(storedFontSize); // Update state with the retrieved font size
    }
  }, []);
  const handleLanguage = async (e: any) => {
    let selectedLanguageObj: any = languagess.filter(
      (val) => val.value === selectedLanguageDropdown
    );
    const userID = localStorage.getItem("userProfileID");
    const userPutData = {
      isDirty: true,
      isNew: false,
      userProfileId: userID,
      languageId: selectedLanguageObj[0].id,
    };
    if (userID) {
      const languageUpdate = await putData(
        "UserProfile/UpdateUserLanguage",
        "",
        userPutData
      );
      changeLanguage(selectedLanguageDropdown);
      localStorage.setItem("selectedLanguage", selectedLanguageDropdown);
    }
    overlayPanelRef.current?.toggle(e);
  };
  const [brandingLogo, setBrandingLogo] = useState<string>(stanzaLogo);

  useEffect(() => {
    async function fetchAndSetLogo() {
      try {
        let TenantDomain = getTenantNameFromDomain();
        let getTenantConfig = await fetch(
          `${IDENTITY_URL}/Tenant/GetTenantbyDomianName/${TenantDomain}`
        );
        let tenantResponseData = await getTenantConfig.json();
        const logo = tenantResponseData.BrandingLogo;
        if (logo) {
          setBrandingLogo(logo);
        }
      } catch (e) {
        console.log("########e", e);
      }
    }
    fetchAndSetLogo();
  }, []);

  return (
    <>
      <StayLoggedIn
        onLogout={logout}
        isLoggedIn={true}
        onStayLoggedIn={() => {}}
      />
      <header className="header">
        {/* <Menubar start={start} end={end} className="px-3" /> */}
        <div className="flex justify-content-between px-3 py-2 shadow-md header-bg">
          {brandingLogo && (
            <Link
              to="/dashboard"
              className="mr-2 ml-6 md:ml-0 flex align-items-center"
            >
              <img src={`/logos/${brandingLogo}`} alt="Logo" className="logo" />
            </Link>
          )}
          <div className="flex align-items-center gap-2 sm:gap-3">
            <div className="relative">
              <Button
                text
                className="button-md gap-1 px-0 sm:px-2"
                onClick={toggleFilterPanel}
              >
                <img
                  alt={selectedOption?.label}
                  src={selectedOption?.icon}
                  height="20"
                />
                <span className="capitalize">{selectedOption?.value}</span>
              </Button>
              {/* OverlayPanel for Filter */}
              <OverlayPanel
                ref={overlayPanelRef}
                onHide={() => setShowFilterPanel(false)}
                appendTo={"self"}
                dismissable
                className="p-overlaypanel-language left-auto right-0 top-100"
                aria-labelledby="languageFontPanel"
                role="dialog"
              >
                <div
                  className="flex flex-column gap-6 p-3"
                  id="languageFontPanel"
                  tabIndex={0}
                >
                  <div className="flex flex-column gap-2">
                    <label
                      htmlFor="languageDrodown"
                      className="block font-bold text-capitalize"
                    >
                      {t("global.chooseLanguage")}
                    </label>
                    <Dropdown
                      inputId="languageDropdown"
                      value={selectedLanguageDropdown}
                      options={languagess}
                      onChange={handleChangeLanguage}
                      placeholder={t("global.selectALanguage")}
                      optionLabel="label"
                      className="button-md"
                    />
                  </div>

                  <div className="flex flex-column gap-2">
                    <span className="block font-bold text-capitalize">
                      {t("global.chooseFontSize")}
                    </span>
                    <AccessibilityOptions />
                  </div>

                  <div className="flex align-items-center gap-4">
                    <Button
                      label={t("global.cancel")}
                      className="w-full button-md px-3"
                      severity="secondary"
                      onClick={(e) => overlayPanelRef.current?.toggle(e)}
                    />
                    <Button
                      label={t("global.save")}
                      onClick={(e) => handleLanguage(e)}
                      className="w-full button-md px-3"
                    />
                  </div>
                </div>
              </OverlayPanel>
            </div>

            <Avatar
              image="https://primefaces.org/cdn/primevue/images/avatar/amyelsner.png"
              onClick={toggleProfileMenu}
              aria-controls="profileMenu"
              aria-haspopup="true"
              aria-expanded="false"
              tabIndex={0}
              aria-label={t("global.openProfileMenu")}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  toggleProfileMenu(e);
                }
              }}
              size="normal"
              role="button"
              className="mx-3"
            >
              <i
                className="pi pi-chevron-down text-xs ml-2 icon-color"
                aria-hidden="true"
              ></i>
            </Avatar>
            <Menu
              model={items}
              popup
              ref={menuRight}
              id="profileMenu"
              popupAlignment="right"
              role="listbox"
            />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
