import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SelectRecords from '../common/SelectRecords'
import BallotDetails from '../common/SelectBallotDetails'
import CreateBallotHeader from '../common/BallotHeader'
import { fetchData } from '../../../services/Ballot/apiservice';
import { ballotSelectRecords } from "../../../slices/ballotSelectRecords";
import { ballotDetails } from "../../../slices/ballotDetails";
import SelectCommittee from '../common/SelectCommittee'
import Preview from '../common/Preview';
import { ballotCommittees } from '../../../slices/ballotCommittees';
import { useParams } from 'react-router-dom';
import { BALLOT_OUT_FOR_BALLOT } from '../common/constants';
import useBallotStatusData from '../common/useBallotStatusData';

const EditBallot = () => {
  useEffect(() => {
    dispatch(ballotDetails.reset());
    dispatch(ballotSelectRecords.reset());
    dispatch(ballotCommittees.reset());
  }, []);
  const { stepCount } = useSelector((state: any) => state.ballotSelectRecords);
  const [activeStep, setActiveSteps] = useState<number>(stepCount);
  const dispatch = useDispatch();
  const { id: ballotId } = useParams();
  const { ballotStatusList: ballotStatusData, ballotRecordStatusList } = useBallotStatusData();
  const { ballotStatusList, ballotDetailsInfo } = useSelector((state: any) => state.ballotDetails);
  const getBallotDetailsHandler = async () => {
    try {
      const allBallotDetails = await fetchData(`Ballot/${ballotId}`);
      onEditBallotHandler(allBallotDetails)
    } catch (err) {
      console.log(err, 'errr');
    }
  }
  const onEditBallotHandler = (ballotDetailsData: any) => {
    if (!ballotDetailsData) return;

    const dispatchSelectedRecord = (target: any, label: string) => {
      dispatch(ballotSelectRecords.selectedRecordValues({ target, label }));
    };

    const dispatchBallotDetails = (target: any, label: string) => {
      dispatch(ballotDetails.addBallotDetails({ target, label }));
    };

    // Ballot record section 1
    const recordDispatches = [
      { target: ballotDetailsData?.AccreditationBody?.Name, label: 'accreditationBodyValue' },
      { target: ballotDetailsData?.Committee?.Name, label: 'primaryCommitteeValue' },
      { target: ballotDetailsData?.BallotType?.Name, label: 'ballotTypeValue' },
      { target: ballotDetailsData?.BallotSubType?.Name, label: 'ballotSubTypeValue' },
      { target: ballotDetailsData?.RecirculationBallotNumber, label: 'ballotIdValue' },
      { target: ballotDetailsData?.BallotLevel?.Name, label: 'ballotLevelValue' },
      { target: ballotDetailsData?.RecordType?.TypeName, label: 'recordTypeValue' },
      { target: ballotDetailsData?.RecordSubType?.Type, label: 'recordSubTypeValue' },
      { target: ballotDetailsData?.MembershipPosition?.Description, label: 'positionAppliedValue' }
    ];

    recordDispatches.forEach(({ target, label }) => dispatchSelectedRecord(target, label));

    // Ballot Details section 2
    const detailsDispatches = [
      { target: ballotDetailsData?.Id, label: 'ballotid' },
      { target: ballotDetailsData?.OpenDate, label: 'startdate' },
      { target: ballotDetailsData?.CloseDate, label: 'enddate' },
      { target: ballotDetailsData?.Description, label: 'description' },
      { target: ballotDetailsData?.Explaination, label: 'explaination' },
      { target: ballotDetailsData?.Note, label: 'staffnote' },
      { target: ballotDetailsData?.BallotNumber, label: 'ballotnumber' },
      { target: ballotDetailsData?.BallotStatus?.Id, label: 'ballotStatusId' }
    ];

    detailsDispatches.forEach(({ target, label }) => dispatchBallotDetails(target, label));
  };

  const fetchAllBallotCommittees = async () => {
    try {
      const allBallotCommittee = await fetchData(`BallotCommittees/GetBallotInvitedComitteeListByBallotId/${ballotId}`);
      dispatch(ballotCommittees.initialBallotCommitteeValues({ value: allBallotCommittee, statusId: ballotStatusList[BALLOT_OUT_FOR_BALLOT]?.id, }))
      if (!ballotDetailsInfo.description) {
        dispatch(ballotSelectRecords.addStepCountHandler({ value: 1 }));
      }
      if (ballotDetailsInfo?.description) {
        dispatch(ballotSelectRecords.addStepCountHandler({ value: 2 }));
      }
      if (allBallotCommittee.length >= 1) {
        dispatch(ballotSelectRecords.addStepCountHandler({ value: 3 }));
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }

  useEffect(() => {
    if (ballotId) {
      dispatch(ballotSelectRecords.isEditHandler({ value: true }))
      getBallotDetailsHandler();
    }
  }, [ballotId]);
  useEffect(() => {
    fetchAllBallotCommittees();
  }, []);

  useEffect(() => {
    setActiveSteps(stepCount)
  }, [stepCount]);
  return (
    <>
      <CreateBallotHeader activeStep={activeStep} />
      {activeStep === 0 && <SelectRecords />}
      {activeStep === 1 && <BallotDetails />}
      {activeStep === 2 && <SelectCommittee />}
      {activeStep === 3 && <Preview />}
    </>
  )
}

export default EditBallot;