import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import FeatherIcon from "../../common/FeatherIconComponent";
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { BALLOT_CLOSE, BALLOT_DRAFT, BALLOT_OPEN } from '../common/constants';
interface BallotDetailsActionsProps {
  ballotId: string;
  ballotStatus: string;
  ballotCancelHandler: () => void; // Pass the cancel handler as a prop
}

const BallotDetailsActions: React.FC<BallotDetailsActionsProps> = ({ballotId, ballotStatus, ballotCancelHandler}) => {
    const [t] = useTranslation("ballot");
    const navigate = useNavigate();
    const menuBallot = useRef<Menu>(null);

    const showActionItem: MenuItem[] = [
      {
        label: "Duplicate Ballot",
      },
      {
        label: "Delete Ballot",
      },
      {
        label: "Cancel",
        command: ballotCancelHandler,
        visible: ballotStatus === BALLOT_DRAFT || ballotStatus === BALLOT_OPEN
      },
    ];

    const toggleBallotMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      menuBallot.current?.toggle(event);
    }, []);  
    return (
     <>
      <div>
        {
          ballotStatus !== BALLOT_CLOSE && ballotStatus !== BALLOT_DRAFT && (
            <>
                <Button
                  className="button-md"
                  onClick={() => navigate(`/ballot/close/${ballotId}`)}
                  label={t("ballot.closeBallot")}
                ></Button>
            </>
          )
        }
            
        <Button className="button-md gap-1 ml-4" severity="secondary" onClick={toggleBallotMenu}
          onKeyDown={(e) => {
          if (e.key === "Enter") {
            toggleBallotMenu(e as unknown as React.MouseEvent<HTMLButtonElement>);
          }}}
        >
        <span className="font-bold">{t("ballot.action")}</span>
        <FeatherIcon name="chevron-down" size={20} color="inherit" />
        </Button>
        <Menu
        model={showActionItem}
        popup
        ref={menuBallot}
        id="profileMenu"
        popupAlignment="right"
        role="listbox"
      />
      </div>
     </>
    )
}

export default BallotDetailsActions;