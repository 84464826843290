import { useState, useEffect } from 'react';
import React from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from "primereact/button";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useLanguageContext } from "../LanguageContext";
import { fetchData, postData, putData } from "../../services/Ballot/apiservice";
import { fetchData as getData } from "../../services/apiService";
import { BallotDetails, BallotRecord, CommiteeMemberData, InvitedCommittee, MemberPositionVotingRights, VotingOption, BallotType, CommentStatus, SubmittedVoteData } from "./types/vote"
import withLoader from "../common/LoaderIntercepter";
import ListDocuments from "../common/ListDocument";
import useUserData from '../../hooks/useUserData';
import { CommentType, CommitteeMembership } from './types/comment';
import VotingOptions from './common/VotingOptions';
import VotingCommentSection from './common/VotingCommentSection';
import { DropdownType } from '../../CommonTypes/utils';
import { setToast } from "../../slices/toastSlice";

const BallotTabCastVote: React.FC = () => {
  const { id: ballotId } = useParams();
  const [t, i18n] = useTranslation("ballot");
  const { selectedLanguage } = useLanguageContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [ selectedCommittee, setSelectedCommittee] = useState<string>("");
  const [ forReviewAndCommentCommittee, setForReviewAndCommentCommittee] = useState<{label: string, value: string} [] | []>([]);
  const [ forVoteCommittees, setVoteCommittee] = useState<{label: string, value: string } [] | []>([]);
  const [invitedCommittees, setInvitedCommitteess] = useState<{label: string, value: string} [] | []>([]);
  const [ votingRightsData, setVotingRightData] = useState<MemberPositionVotingRights [] | []>([]);
  const [ ballotDetails, setBallotDetails] = useState<BallotDetails | null>(null);
  const [ votingOptonsList, setVotingOptionsList] = useState<{label: string, value: string} [] | []>([]);
  const [ recordLists, setRecordList] = useState<BallotRecord [] | []>([]);
  const [ selectedMember, setSelectedMember] = useState<string>("");
  const [ committeeMemberValueWithVotingRight, setCommitteeMemberValueWithVotingRight] = useState<any>([]);
  const [ voteClosureDate, setVoteClosureDate] = useState<any>(null);
  const [ reviewCommentVotingOption, setReviewCommentVotingOption ] = useState<any>([]);
  const [ disapprovedValue, setDisapprovedValue ] = useState<string | undefined>("");
  const [ votingData, setVotingData] = useState<any>([]);
  const [ ballotTypes, setBallotTypes] = useState<BallotType [] | []>([]);
  const [ commentTypeOptions, setCommentTypeOptions] = useState<{label: string, value: string} [] | []>([]);
  const [ reviewAndComment, setReviewAndComment] = useState(false);
  const [ memberAsPerCommittee, setMemberAsPerCommittee ] = useState<any>([]);
  const [reviewcommentBallotTypeId, setReviewBallotTypeId] = useState<string>("");
  const [submittedVote, setSubmittedVote] = useState<SubmittedVoteData[] | []>([]);
  const [commentStatusOpenId, setCommentStatusOpenId] = useState<string>("");
  const { userFirstName, userProfileId, userRole } = useUserData();
  const [selectedMemberNameByStaff, setSelectedMemberNameByStaff] = useState<string>("");
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
 
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  useEffect(() => {
    const fetchDataIntial = async () => {
      try {
        const [ ballotTypesData, commentTypesData, commentStatusData, votingRights ] = await Promise.all([
          fetchData(`BallotType/GetAll`),
          getData(`CommentType/GetAll`),
          getData(`CommentStatus/GetAll`),
          fetchData(`MemberPositionVotingRight/GetAll`)
        ]);
        setBallotTypes(ballotTypesData.Collection);
        setCommentTypeOptions(commentTypesData.Collection.map((item: CommentType) => ({
          label: item.Name,
          value: item.Id,
        })));
        setVotingRightData(votingRights.Collection);
        const openStatus = commentStatusData.Collection.find((status: CommentStatus) => status.Name === "Open");
        setCommentStatusOpenId(openStatus.Id);
      }
      catch(error) {
        console.error("Error fetching data:");
      }
    }
    fetchDataIntial();
  }, []);

  useEffect(() => {
      const fetchReviewAndCommentsData = async () => {
        if (ballotTypes) {
          const reviewAndCommentsItem = ballotTypes.find((item: BallotType) => item.Name === "Review and Comments");
          if (reviewAndCommentsItem) {
              setReviewBallotTypeId(reviewAndCommentsItem.Id);
            try {
              const ballotVotingOptions = await fetchData(`VoteOption/VotingOptionSearch?Filters[0].Key=BallotTypeId&Filters[0].Value=${reviewAndCommentsItem.Id}&PageIndex=-1`);
              setReviewCommentVotingOption(ballotVotingOptions.Collection);
            } catch (error: any) {
              console.error("Error fetching review and comments data:", error);
            }
          }
          }
      }
      fetchReviewAndCommentsData();
  }, [ballotTypes])

  useEffect(() => {
    if (ballotDetails?.BallotType.Name === "Review and Comment") {
      setReviewAndComment(true);
      fetchVotingOptions(ballotDetails.BallotType.Id);
    } 
  }, [ballotDetails]);

  const fetchSubmittedVote = async (ballotId: string | undefined, committeeId?: string | null, memberValue?: string | null) => {
    try {
      let url = "BallotRecordVote/BallotRecordVoteSearch?";
      const filters = [];
      if (committeeId) {
        filters.push({ Key: "committeeid", Value: committeeId });
      }
      if (memberValue) {
        filters.push({ Key: "committeemembersids", Value: memberValue });
      }
      if(ballotId) {
        filters.push({ Key: "ballotid", Value: ballotId });
      }
      url += filters
    .map(
      (filter, index) =>
        `Filters[${index}].Key=${filter.Key}&Filters[${index}].Value=${filter.Value}`
    )
    .join("&");

    url += '&PageIndex=-1';

      const voteSubmitted = await fetchData(url);
      return voteSubmitted;
    } catch {

    }
  }

  const fetchMembersByCommittee = async (committee: string) => {
    try {
      const responseData = await getData(`CommitteeMembership/GetListByParentId?parentId=${committee}`);
      const sortedData = responseData.collection.map((item:  CommitteeMembership) => ({
        label: item.userProfile.firstname + " " +item.userProfile.lastname,
        value: item.userProfileId,
      }));
      return sortedData;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  const fetchVotingOptions = async (ballotTypeId: string | undefined | null) => {
    if (!ballotTypeId || !ballotDetails) return;
    const url = `VoteOption/VotingOptionSearch?Filters[0].Key=BallotTypeId&Filters[0].Value=${ballotTypeId}&Filters[1].Key=BallotSubTypeId&Filters[1].Value=${ballotDetails.BallotSubType.Id}&Filters[2].Key=BallotLevelId&Filters[2].Value=${ballotDetails.BallotLevel.Id}&PageIndex=-1`;
    try {
      const response = await fetchData(url);
      const votingOptionsList = response.Collection.map((item: VotingOption) => ({
        label: item.Name,
        value: item.VotingOptionId,
      }));
      const disapproveOptionId = response.Collection.find((option: any) => option.Name === 'Disapproved')?.VotingOptionId;
      setDisapprovedValue(disapproveOptionId);
      setVotingOptionsList(votingOptionsList);
    } catch (error) {
      console.error('Error fetching voting options:', error);
    }
  }

  const handleCommitteeChange = async (committee: string) => {
    setVotingData([]);
    setSelectedCommittee(committee);
    if (!committee) return;
    const memberList = await fetchMembersByCommittee(committee);
    setMemberAsPerCommittee(memberList);
    if(selectedCommittee) {
      if(ballotDetails?.BallotType.Id === reviewcommentBallotTypeId) {
        setReviewAndComment(true);
        fetchVotingOptions(reviewcommentBallotTypeId);
      } else {
        if(committee) {
          const invitedCommittee = forVoteCommittees.find(
            (item) => item.value === committee
          );
          if (invitedCommittee) {
            setReviewAndComment(false);
            fetchVotingOptions(ballotDetails?.BallotType.Id);
          } else {
            setReviewAndComment(true);
            fetchVotingOptions(reviewcommentBallotTypeId);
          }
        }
      }
    } else {
      if(reviewAndComment) {
        setReviewAndComment(true);
        fetchVotingOptions(reviewcommentBallotTypeId);
      } else {
        if(committee) {
          const invitedCommittee = forVoteCommittees.find((item: DropdownType) => item.value === committee);
          if (invitedCommittee) {
            setReviewAndComment(false);
          } else {
            setReviewAndComment(true);
            fetchVotingOptions(reviewcommentBallotTypeId);
          }
        }
      }
    }
  }
  
  const checkVotingRights = async (memberId: string) => {
    try {
      const committeeMemberships = await getData(`CommitteeMembership/GetCommitteeMembershipByUserProfileID?UserProfileId=${memberId}`);
      const memberInvitedInWhichCommittee = committeeMemberships.Collection.filter((member: CommiteeMemberData) =>
      invitedCommittees.some((committee: {label: string, value: string}) =>
        committee.value === member.CommitteeId
      ));
      const userInwhichCommitteeVotingRight = memberInvitedInWhichCommittee
      .filter((member: CommiteeMemberData) =>
        forVoteCommittees.some((invited: DropdownType) => invited.value === member.CommitteeId)
      ).map((member: CommiteeMemberData) => ({
        ...member,
        forVote: true,
      }));

      // Filter for committees with commenting rights
      const userInwhichCommitteeCommentRight = memberInvitedInWhichCommittee.filter((member: CommiteeMemberData) =>
        forReviewAndCommentCommittee.some((invited: DropdownType) => invited.value === member.CommitteeId)
      ).map((member: CommiteeMemberData) => ({
        ...member,
        forComment: true,
      }));
      const mergedUserRights = memberInvitedInWhichCommittee.map((member: CommiteeMemberData) => {
        const hasVotingRights = userInwhichCommitteeVotingRight.some((voteMember: any) => voteMember.CommitteeId === member.CommitteeId);
        const hasCommentRights = userInwhichCommitteeCommentRight.some((commentMember: any) => commentMember.CommitteeId === member.CommitteeId);
        return {
          ...member,
          forVote: hasVotingRights,
          forComment: hasCommentRights,
        };
      });
      if (reviewAndComment) {
        const userWithReviewCommentRights = mergedUserRights.filter((member: CommiteeMemberData) =>
          votingRightsData.some((right: MemberPositionVotingRights) =>
            right.MemberPositionID === member.MembershipPositionId && right.CanComment
          )
        ).map((member: CommiteeMemberData) => ({
          ...member,
          forVote: false, // Ensure voting is disabled for comment-only rights
          forComment: true,
        }));
        setCommitteeMemberValueWithVotingRight(userWithReviewCommentRights);
      } else {
        const userWithValidVotingRights = mergedUserRights.filter((member: CommiteeMemberData) =>
          votingRightsData.some((right: MemberPositionVotingRights) =>
            right.MemberPositionID === member.MembershipPositionId && right.CanVote
          )
        );
        if (userWithValidVotingRights.length > 0) {
          setCommitteeMemberValueWithVotingRight(userWithValidVotingRights);
          setReviewAndComment(false);
          fetchVotingOptions(ballotDetails?.BallotType?.Id);
        } else {
          console.warn('User does not have voting rights in any invited committees.');
        }
      }
    } catch (error) {
      console.error('Error checking voting rights:', error);
    }
  };
  
  const handleMembersChange = async (member: string) => {
    setVotingData([]); 
    if(memberAsPerCommittee.length > 0) {
      const selectedOption = memberAsPerCommittee.find((option: any) => option.value === member);
      const firstName = selectedOption.label;
      setSelectedMemberNameByStaff(firstName);
    }
    setSelectedMember(member);
    checkVotingRights(member);

  }

  useEffect(() => {
    const getBallotDetailsHandler = async () => {
      try {
        const ballotCommitteessInvited = await fetchData(`BallotCommittees/GetBallotInvitedComitteeListByBallotId/${ballotId}`);
        const forCommentCommittee: DropdownType[] = [];
        const forVoteCommittee: DropdownType[] = [];
        const sortedData = ballotCommitteessInvited.map((item: InvitedCommittee) => ({
          label: item.CommitteeName,
          value: item.CommitteeId,
        }));
        ballotCommitteessInvited.forEach((item: any) => {
          const label = item.CommitteeName ?? "Unknown Committee";
          const value = item.CommitteeId ?? "Unknown Id";
          if (item.ForReviewAndComment) {
            forCommentCommittee.push({ label, value });
          } else {
            forVoteCommittee.push({ label, value });
          }
        });
        setInvitedCommitteess(sortedData);
        setVoteCommittee(forVoteCommittee);
        setForReviewAndCommentCommittee(forCommentCommittee);
      } catch (err) {
        console.error(err, 'Error fetching ballot committee invitations');
      }
    };
    if (ballotId) {
      getBallotDetailsHandler();
      getSubmmittedVoteHandler();
    }
  }, [ballotId, userRole]);

  useEffect(() => {
    const fetchDataMemberRecord = async () => {
      try {
        const responseData = await fetchData(`BallotRecord/GetListByParentId?parentId=${ballotId}`);
        setRecordList(responseData.collection);
      } catch (error) {
        console.error("Error fetching Category of Interest:", error);
      }
    };
    if (ballotId) {
      fetchDataMemberRecord();
    }
  }, [ballotId]);

  const getBallotDetailsHandler = async () => {
    try {
      const ballotDetailsData = await fetchData(`Ballot/${ballotId}`);
      setBallotDetails(ballotDetailsData);
      const closureDate = new Date(ballotDetailsData.CloseDate); 
      setVoteClosureDate(closureDate);
      const submittedVote = await fetchSubmittedVote(ballotId);
    } catch (err) {
      console.error('Error fetching ballot details', err);
    }
  };

  const getSubmmittedVoteHandler = async () => {
    try {
      const ballotDetailsData = await fetchData(`BallotRecordVote/BallotRecordVoteSearch?Filters[0].Key=ballotid&Filters[0].Value=${ballotId}&PageIndex=-1`);
      setSubmittedVote(ballotDetailsData.Collection);
    } catch (err) {
      console.error('Error fetching ballot details', err);
    }
  };

  const fetchUserCommitteesAndRights = async () => {
    try {
      const responseData = await getData(`CommitteeMembership/GetCommitteeMembershipByUserProfileID?UserProfileId=${userProfileId}`);
      const userCommittees = responseData.Collection;
      const memberInvitedInWhichCommittee = userCommittees.filter((member: any) =>
      invitedCommittees.some((committee: any) =>
        committee.value === member.CommitteeId
      ));
      const userInwhichCommitteeVotingRight = memberInvitedInWhichCommittee
      .filter((member: CommiteeMemberData) =>
        forVoteCommittees.some((invited: DropdownType) => invited.value === member.CommitteeId)
      ).map((member: CommiteeMemberData) => ({
        ...member,
        forVote: true,
      }));
    // Filter for committees with commenting rights
    const userInwhichCommitteeCommentRight = memberInvitedInWhichCommittee.filter((member: CommiteeMemberData) =>
        forReviewAndCommentCommittee.some((invited: DropdownType) => invited.value === member.CommitteeId)
      ).map((member: CommiteeMemberData) => ({
        ...member,
        forComment: true,
      }));
      if (ballotDetails?.BallotType?.Name === "Review and Comments") {
        fetchVotingOptions(reviewcommentBallotTypeId);
        setReviewAndComment(true);
        const userWithCommentReviewRights = userInwhichCommitteeCommentRight
        .filter((member: CommiteeMemberData) =>
          votingRightsData.some((right: MemberPositionVotingRights) =>
            right.MemberPositionID === member.MembershipPositionId && right.CanComment
          )
        )
        .map((member: CommiteeMemberData) => ({
          ...member,
          forVote: false, // Adding the forVote property
          forComment: true
        }));
        setCommitteeMemberValueWithVotingRight(userWithCommentReviewRights);
      } else {
        // Normal Ballot: Check for voting rights
        // Filter members who have voting rights and CanVote is true
        const userWithVotingRights = userInwhichCommitteeVotingRight.filter((member: CommiteeMemberData) =>
        votingRightsData.some((right: MemberPositionVotingRights) => right.MemberPositionID === member.MembershipPositionId && right.CanVote)
        );
        // Combine the arrays and merge properties based on CommitteeId
        const mergedUserRights = memberInvitedInWhichCommittee.map((member: CommiteeMemberData) => {
          const hasVotingRights = userWithVotingRights.some((voteMember: any) => voteMember.CommitteeId === member.CommitteeId);
          const hasCommentRights = userInwhichCommitteeCommentRight.some((commentMember: any) => commentMember.CommitteeId === member.CommitteeId);
          return {
            ...member,
            forVote: hasVotingRights,
            forComment: hasCommentRights,
          };
        });
        if (mergedUserRights.length > 0) {
          setCommitteeMemberValueWithVotingRight(mergedUserRights);
          setReviewAndComment(false);
          fetchVotingOptions(ballotDetails?.BallotType?.Id);
        } else {
          console.warn('User does not have voting rights in any invited committees.');
        }
      }
    } catch (error) {
      console.error('Error fetching user committees and rights:', error);
    }
  };

  const fetchStaffReviewCommentRights = async () => {
    if (ballotDetails?.BallotType?.Name === "Review and Comments") {
      setReviewAndComment(true);
      fetchVotingOptions(ballotDetails?.BallotType.Id);
    } else {
      setReviewAndComment(false);
      fetchVotingOptions(ballotDetails?.BallotType.Id);
    }
  }

  useEffect(() => {
    if(userProfileId && userRole === "Member") {
      fetchUserCommitteesAndRights();
      fetchVotingOptions(ballotDetails?.BallotType.Id); 
    } 
    else {
      fetchStaffReviewCommentRights();
    }
  }, [userProfileId, ballotDetails])

  const viewRecord = (recordId: string) => {
    sessionStorage.setItem('recordId', recordId);
    navigate("/record/record-preview");
  }

  const withdrawnVote = () => {
    
  }

  const handleDocumentDetailsAvailable = () => {
  };

  useEffect(() => {
    getBallotDetailsHandler();
  }, [invitedCommittees, userProfileId]);

  const handleVotingOptionChange = (index: number, value: any) => {
    const updatedVotingData: any = [...votingData];
    updatedVotingData[index] = { ...updatedVotingData[index], votingOption: value };
    setVotingData(updatedVotingData);
  };

  const handleCommentChange = (index: number, field: any, value: any) => {
    const updatedVotingData: any = [...votingData];
    updatedVotingData[index] = { ...updatedVotingData[index], [field]: value };
    setVotingData(updatedVotingData);
  };

  const resetCommentFields = (index: number) => {
    updateVotingData(index, {
      comment: '',
      commentType: '',
      paragraphSection: '',
      proposedAction: '',
    });
  };  

  const createVotePayload = (recordData: any, votingOption: string, committeeId: string, committeeMembershipId: string, existingVoteId?: any) => ({
    isDirty: true,
    isNew: true,
    ballotId: ballotId,
    recordId: recordData.record.id,
    committeeId: committeeId,
    commiteeMemberId: committeeMembershipId,
    voteOptionId: votingOption,
    dateOfWithdrawal: null,
    isWithdrawn: false
  });

  const updateVotingData = (index: any, newState: any) => {
    const updatedVotingData = [...votingData];
    updatedVotingData[index] = { ...updatedVotingData[index], ...newState };
    setVotingData(updatedVotingData);
  };

  const submitComment = async (recordData: any, ballotRecordVoteId: any, comment: any, commentType: any, paragraphSection: any, proposedAction: any, membership: any) => {
    const commentPayload = {
      isDirty: true,
      isNew: true,
      ballotId: ballotId,
      recordId: recordData.record.id,
      committeeId: membership.CommitteeId, 
      committeeMembershipId: membership.CommitteeMembershipId,
      commentStatusId: commentStatusOpenId,
      commentTypeId: commentType,
      commentApprovalTypeId: null,
      ballotRecordVoteId: ballotRecordVoteId,
      isResolved: true,
      bookMarked: true,
      paragraphSection: paragraphSection,
      commentText: comment,
      proposedAction: proposedAction
    };
  
    await postData(`BallotComments`, commentPayload);
  };

  const getAlternativeVotingOption = (label: string) => {
    if (["Approved", "approved"].includes(label)) {
      return reviewCommentVotingOption.find((option: any) => option.Name === "Non-Substantive")?.VotingOptionId;
    } else if (["Disapproved", "disapproved"].includes(label)) {
      return reviewCommentVotingOption.find((option: any) => option.Name === "Substantive")?.VotingOptionId;
    } else if (["Abstain", "Not Returned"].includes(label)) {
      return reviewCommentVotingOption.find((option: any) => option.Name === "No Comment")?.VotingOptionId;
    }
    return null; // Default case
  };

  const handleSubmit = async (index: number, recordData: BallotRecord) => {   
    const { votingOption, comment, commentType, paragraphSection, proposedAction } = votingData[index] || {};
    if (votingOption === disapprovedValue && !comment) {
      const updatedVotingData: any = [...votingData];
      dispatch(setToast({ message: 'Comment is required when disapproving.', severity: 'error' }));
      updatedVotingData[index] = { ...updatedVotingData[index], errors: { comment: 'Comment is required when disapproving.' }, isSubmitted: false };
      setVotingData(updatedVotingData);
      return;
    }
    try {
      for(const membership of committeeMemberValueWithVotingRight) {
        let currentVotingOption = votingOption;
        if (!membership.forVote && membership.forComment) {
          let currentVotingOptionObj = reviewCommentVotingOption.find(
            (option: any) => option.VotingOptionId === currentVotingOption
          );
  
          // Fallback to the secondary voting options list if not found
          if (!currentVotingOptionObj) {
            currentVotingOptionObj = votingOptonsList.find(option => option.value === currentVotingOption);
          }
  
          if (currentVotingOptionObj) {
            currentVotingOption = getAlternativeVotingOption(currentVotingOptionObj.Label || currentVotingOptionObj.label) || currentVotingOption;
          }
        }
       const existingVote: any = checkIfVoteExists(recordData, membership.CommitteeId, membership.CommitteeMembershipId);
        if(existingVote) {
          const voteEdit = {
              active: true,
              isDirty: true,
              isNew: false,
              ballotId: ballotId,
              recordId: recordData.record.id,
              committeeId: membership.CommitteeId,
              commiteeMemberId: membership.CommitteeMembershipId,
              voteOptionId: currentVotingOption,
              dateOfWithdrawal: voteClosureDate,
              isWithdrawn: false
          }
          await putData(`BallotRecordVote`, `${existingVote}`, voteEdit);
          if (comment) {
            await submitComment(recordData, existingVote, comment, commentType, paragraphSection, proposedAction, membership);
          }
        } else {
          const votePayload = createVotePayload(recordData, currentVotingOption, membership.CommitteeId, membership.CommitteeMembershipId);
          const voteResponseData = await postData(`BallotRecordVote`, votePayload);
          const ballotRecordVoteId = JSON.parse(voteResponseData.content).Id;
          if (comment) {
            await submitComment(recordData, ballotRecordVoteId, comment, commentType, paragraphSection, proposedAction, membership);
          }
        }
      }
     // Handle success notifications
    if (comment) {
      dispatch(setToast({
        message: `${t("ballot.commentvotePostedSuccess")} #${recordData.record.recordNumber}`,
        severity: 'success'
      }));
      updateVotingData(index, { isSubmitted: true, errors: {} });
      resetCommentFields(index);
    } else if (committeeMemberValueWithVotingRight.length > 0) {
      updateVotingData(index, { isSubmitted: true, errors: {} });
      dispatch(setToast({ message: `${t("ballot.votePostedSuccess")} #${recordData.record.recordNumber}`, severity: 'success' }));
    } else {
      updateVotingData(index, { isSubmitted: false, errors: {} });
      dispatch(setToast({ message: t("ballot.memberNotInvitedError"), severity: 'error' }));
    }
    getSubmmittedVoteHandler();
    }
    catch (error) {
      console.error("Error submitting vote or comment:", error);
      updateVotingData(index, { isSubmitted: false });
    }
  };

  const checkIfVoteExists = (
    recordData: any, 
    committeeId: string, 
    committeeMembershipId: string
  ) => {
    const existingVote = submittedVote.find((vote: SubmittedVoteData) => 
      vote.RecordNumber === recordData.record.recordNumber && 
      vote.CommitteeId === committeeId && 
      vote.CommitteeMemberId === committeeMembershipId
    );
    return existingVote ? existingVote.BallotRecordVoteId : null;
  };

  return (
    <>
      <div>
      {(userRole === "Staff" || userRole === "Admin") && (
        <div className="grid grid-xl my-3">
          <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
            <label
              htmlFor="selectCommittee"
              className="block font-bold text-input-label text-capitalize"
            >
              {t("ballot.selectCommittee")}
            </label>
            <Dropdown
              inputId="selectCommittee"
              name="selectCommittee"
              value={selectedCommittee}
              onChange={(e) => handleCommitteeChange(e.value)}
              options={invitedCommittees}
              optionLabel="label"
              placeholder="Select a Committee"
            />
          </div>
          <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
            <label
              htmlFor="selectMember"
              className="block font-bold text-input-label text-capitalize"
            >
              {t("ballot.selectMember")}
            </label>
            <Dropdown
              inputId="selectMember"
              name="selectMember"
              value={selectedMember}
              onChange={(e: DropdownChangeEvent) => handleMembersChange(e.value)}
              options={memberAsPerCommittee}
              optionLabel="label"
              placeholder="Select a Member"
            />
          </div>
        </div>
      )}
      {recordLists.map((recordData: BallotRecord, index: number) => {
          let selectedVoteOptionId: any;
          if(userRole !== "Member") {
            if(selectedMemberNameByStaff !== "") {
              const currentRecordVote: any [] = submittedVote?.filter(
                (status: SubmittedVoteData) =>
                  status.RecordNumber === recordData?.record?.recordNumber &&
                  status.CommitteeMemberName === selectedMemberNameByStaff
              );
              if(currentRecordVote){
                if(currentRecordVote.length > 0) {
                  selectedVoteOptionId = votingData[index]?.votingOption || currentRecordVote[0].VoteOptionId;
                } else {
                  selectedVoteOptionId = votingData[index]?.votingOption;
                }
              }
            }
          }
          if(userRole === "Member") {
            const currentRecordVote: any [] = submittedVote?.filter(
              (status: SubmittedVoteData) =>
                status.RecordNumber === recordData?.record?.recordNumber &&
                status.CommitteeMemberName === userFirstName
            );
            if(currentRecordVote){
              if(currentRecordVote.length > 0) {
                selectedVoteOptionId = votingData[index]?.votingOption || currentRecordVote[0].VoteOptionId;
              } else {
                selectedVoteOptionId = votingData[index]?.votingOption;
              }
            }
          }
        
        return (
        <div className="card bg-white w-full mb-5 shadow-md" key={index}>
          <div className="flex align-items-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {`Record ID:`} {recordData?.record?.recordNumber}
            </h2>
            <Button
              label="View Record"
              onClick={() => viewRecord(recordData?.record?.id)}
              className="button-md"
              severity="secondary"
            />
          </div>
          <div className="p-5 cardBody flex flex-column gap-4 md:flex-row">
            <div className="flex flex-column gap-5 w-full md:w-15rem lg:w-18rem flex-shrink-0 border-bottom-1 md:border-bottom-none md:border-right-1 border-gray-400 pb-3 md:pb-0 md:pr-2">
              <div className="flex flex-column gap-1">
                <span className="m-0 font-bold text-capitalize text-base">
                  {t("ballot.applicantName")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {recordData?.record?.userProfile?.firstname}
                </p>
              </div>
              <div className="flex flex-column gap-1">
                <span className="m-0 font-bold text-capitalize text-base">
                  {t("ballot.committeeAppliedFor")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {recordData?.record?.committee?.name}
                </p>
              </div>
              <div className="flex flex-column gap-1">
                <span className="m-0 font-bold text-capitalize text-base">
                  {t("ballot.committeePosition")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {recordData.record?.membershipPosition?.positionName}
                </p>
              </div>
              <div className="flex flex-column gap-1">
                <span className="m-0 font-bold text-capitalize text-base">
                  {t("ballot.categoryOfInterest")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {recordData?.record?.categoryOfInterest?.categoryName}
                </p>
              </div>
              <div className="flex flex-column gap-1">
                <span className="m-0 font-bold text-capitalize text-base">
                  {t("ballot.termYears")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {recordData.term}
                </p>
              </div>
              <div className="flex flex-column gap-1">
                <span className="m-0 font-bold text-capitalize text-base">
                  {t("ballot.applicantResume")}
                </span>
                <ListDocuments 
                  isDocumentType={false}
                    documentType={'UserProfile_Resume'}
                    handleDocumentDetailsAvailable={
                      handleDocumentDetailsAvailable
                    }
                    userProfileID={recordData?.record?.userProfile?.id}
                    isDelete={false}
                    isDownload={true}
                  />
              </div>
            </div>
            <div className="flex flex-column gap-7 w-full">
              <div className="flex flex-column gap-3">
                <fieldset className="border-none p-0">
                  <legend className="block font-bold text-input-label p-0 mb-3">
                    {reviewAndComment ? "Comment Option" : "Voting Option"}
                  </legend>
                  <VotingOptions
                    votingOptionsList={votingOptonsList}
                    selectedVoteOptionId={selectedVoteOptionId}
                    userRole={userRole}
                    selectedCommittee={selectedCommittee}
                    selectedMember={selectedMember}
                    index={index}
                    handleVotingOptionChange={handleVotingOptionChange}
                  />
                </fieldset>
              </div>
              {/* Comment Section */}
              <VotingCommentSection selectedVoteOptionId={selectedVoteOptionId}
              disapproveId={disapprovedValue}
                index={index}
                votingData={votingData}
                commentTypeOptions={commentTypeOptions}
                handleCommentChange={handleCommentChange}
              />
            </div>
          </div>
          <div className="bg-white w-full px-5 py-3 gap-4 left-0 shadow text-right">
            <Button
              className="button-md gap-1"
              disabled={
                   !selectedVoteOptionId && (!votingData[index]?.votingOption)
              }
              onClick={() => handleSubmit(index, recordData)}
            >
              <span className="font-bold text-capitalize">
                Submit Vote
              </span>
            </Button>
            </div>
        </div>
        )
              })}
      </div>
    </>
  );
};

export default withLoader(BallotTabCastVote);