import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Button } from "primereact/button";
import { useLanguageContext } from "../../LanguageContext";
import FeatherIcon from "../../common/FeatherIconComponent";
import useFetchBallotRecord from "../common/useFetchBallotRecord";
import { fetchData, postData, putData  as ballotPut} from "../../../services/Ballot/apiservice";
import { putData, fetchData as getData } from "../../../services/apiService";
import { BallotStatus } from "../common/BallotTypes";
import BallotReportRecord from "../common/BallotReportRecord";
import BallotReportVotingCriteria from "../common/BallotReportVotingCriteria";
import BallotCommitteeReport from "../common/BallotCommitteeReport";
import { BallotReport } from "../types/BallotReport";
import BallotDetailHeader from "../BallotDetails/BallotDetailsHeader";
import Input from "../../../utils/Input";
import { setToast } from "../../../slices/toastSlice";
import { BALLOT_RECORD_CLOSE, BALLOT_RECORD_DRAFT, BALLOT_RECORD_OPEN, BALLOT_RECORD_OUT_FOR_BALLOT_CLOSE } from "../common/constants";

const BallotClose: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation("ballot");
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const [ ballotDetails, setBallotDetails] = useState<any>([]);
  const [ ballotClosedStatus, setballotClosedStatus] = useState<string>("");
  const { selectedLanguage } = useLanguageContext();
  const [selectedStatus, setSelectedStatus] = useState<{recordId: string, recordStatusId: string} [] | []>([]);
  const [selectedCommitteeBallotStatus, setSelectedCommitteeBallotStatus] = useState<any[]>([]);
  const [ballotRecordData, setBallotRecordData] = useState<any>(null);
  const [ballotCommitteeStatus, setBallotCommitteeStatus] = useState<{value: string, name: string} [] | []>([]);
  const [ballotReport, setBallotReport] = useState<BallotReport | null>(null);
  const [recordStatus, setRecordStatus] = useState<{value: string, name: string} [] | []>([]);
  const [ ballotStatusList, setBallotStatusList ] = useState<BallotStatus [] | []>([]);
  const [ ballotClosureRemark, setBallotCloserRemark ] = useState<string>("");
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [recordStatus, ballotCommitteeStatus, ballotReport, ballotDetails, ballotStatusList, recordList] = await Promise.all([
          getData("RecordStatus/GetAll"),
          fetchData("BallotCommitteeRecordStatus/GetAll"),
          fetchData(`BallotRecordVote/BallotReports/${id}`),
          fetchData(`Ballot/${id}`),
          fetchData(`BallotStatus/GetAll`),
          fetchData(`BallotRecord/BallotClosureRecordlistDetails/${id}`),
        ]);
      const allBallotRecordStatusFormatted = recordStatus.Collection
      .filter((ballot: any) => 
        ballot.DisplayName !== BALLOT_RECORD_DRAFT &&
        ballot.DisplayName !== BALLOT_RECORD_OUT_FOR_BALLOT_CLOSE &&
        ballot.DisplayName !== BALLOT_RECORD_CLOSE &&
        ballot.DisplayName !== BALLOT_RECORD_OPEN
      )
      .map((ballot: any) => {
          return {
              value: ballot.Id,
              name: ballot.DisplayName
          }
      });
      const allBallotCommitteStatusFormatted = ballotCommitteeStatus.Collection.map((committeeStatus: any) => {
        return {
            value: committeeStatus.Id,
            name: committeeStatus.DisplayName
        }
      });
      setRecordStatus(allBallotRecordStatusFormatted);
      setBallotCommitteeStatus(allBallotCommitteStatusFormatted);
      setBallotDetails(ballotDetails);
      setBallotReport(ballotReport);
      setBallotStatusList(ballotStatusList.Collection);
      if (recordList) {
        const sortedData = recordList.collection.map((item:  any) => ({
          recordNumber: item.recordNumber,
          committeeName: item.committeeName,
          currentStatusName: item.recordStatusName,
          recordId: item.recordId
        }));
        setBallotRecordData(sortedData);
      }
      } catch {
        console.error("Error fetching initial record vote data");
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    if(ballotStatusList) {
      const closedStatus = ballotStatusList.find((status: BallotStatus) => status.Name === "Closed");
      if(closedStatus) {
        setballotClosedStatus(closedStatus.Id);
      }
    }
  }, [ ballotStatusList]);

  const handleStatusChange = useCallback((recordId: string, newStatus: string) => {
    setSelectedStatus((prevStatuses: any) => {
      const updatedStatuses = Array.isArray(prevStatuses) ? [...prevStatuses] : [];
      const recordIndex = updatedStatuses.findIndex(
        (item) => item.recordId === recordId
      );
      const newPayload = {
        recordId: recordId,
        recordStatusId: newStatus,
      };
      if (recordIndex > -1) {
        updatedStatuses[recordIndex] = newPayload;
      } else {
        updatedStatuses.push(newPayload);
      }
      return updatedStatuses;
    });
  }, [setSelectedStatus]);

  const handleCommitteeStatusChange = useCallback((
    BallotRecordId: string,
    BallotCommitteeId: string,
    newStatus: string,
    RecordId: string,
    CommitteeId: string
  ) => {
    setSelectedCommitteeBallotStatus((prevStatuses: any) => {
      const updatedStatuses = Array.isArray(prevStatuses) ? [...prevStatuses] : [];
      const CommitteeIndex = updatedStatuses.findIndex(
        (item) => item.BallotRecordId === BallotRecordId
      );
      const newPayload = {
        ballotCommitteeId: BallotCommitteeId,
        ballotRecordId: BallotRecordId,
        ballotCommitteeRecordStatusId: newStatus,
        committeeId: CommitteeId,
        recordId: RecordId,
        ballotId: id,
        active: true,
        isDirty: true,
        isNew: true
      };
      if (CommitteeIndex > -1) {
        updatedStatuses[CommitteeIndex] = newPayload;
      } else {
        updatedStatuses.push(newPayload);
      }
      return updatedStatuses;
    });
  }, [setSelectedCommitteeBallotStatus]);

  const recordSubmittion = useCallback(async () => {
    try {
      const response = await putData(`Record/RecordStatusBulkChange`, undefined, selectedStatus);
    } catch (error) {
      console.error("Error submitting record statuses:", error);
    }
  }, [selectedStatus]);

  const ballotStatusSubmission = useCallback(async () => {
    try {
      const payload = {
        active: true,
        isDirty: true,
        isNew: false,
        ballotId: id,
        ballotStatusId: ballotClosedStatus,
        BallotCloserRemark: ballotClosureRemark
      }
      const response = await ballotPut('Ballot/BallotStatusChange', id, payload);
      if(response) {
        const emailTriggerd = await fetchData(`EmailIssueBallotCommiteeMembers/SendBallotIssueEmailToBallotCommitteeMembers?ballotId=${id}`);
      }
    } catch (error) {
      console.error("Error occurred during ballot status submission:", error);
    }
  }, [id, ballotClosedStatus, ballotClosureRemark])

  const committeeStatusSubmission = useCallback(async () => {
    try {
      const response = await postData('BallotCommitteeRecord/BulkUpload', selectedCommitteeBallotStatus);
    } catch (error) {
      console.error("Error submitting committee status:", error);
    }
  }, [selectedCommitteeBallotStatus])

  const handleCloseBallot = async () => {
    try {
      await Promise.all([
        committeeStatusSubmission(),
        recordSubmittion(),
        ballotStatusSubmission(),
      ]);
      dispatch(setToast({ message: t("ballot.ballotCloseSuccess"), severity: 'success' }));
      navigate(`/ballot/${id}`);
      fetchData(`EmailIssueBallotCommiteeMembers/SendBallotIssueEmailToBallotCommitteeMembers?ballotId=${id}`)
      .catch((error) => {
        console.error("Error sending ballot issue email:", error);
      });
    } catch (error) {
      console.error("Error closing ballot:", error);
    }
  };

  return (
    <>
      <div>
        <Button
          text
          className="p-button-plain gap-2 mb-3 pl-0 underline"
          onClick={() => navigate("/ballot/membership")}
        >
          <FeatherIcon name="arrow-left" size={20} />
          <span className="">{t("ballot.back")}</span>
        </Button>
        <BallotDetailHeader BallotHeading={t("ballot.ballotClosure")} BallotNumber={ballotReport?.BallotNumber} BallotStatus={ballotDetails?.BallotStatus?.DisplayName ?? ""} />
        <BallotReportVotingCriteria ballotTotalMember={ballotReport?.TotalMemberInvited} ballotVoteReceived={ballotReport?.VoteRecived} />
        <BallotReportRecord recordLists={ballotRecordData} isBallotClosure={true} recordStatusOptions={recordStatus} handleStatusChange={handleStatusChange} selectedStatus={selectedStatus} oldStatusFalse={true}    />
        <BallotCommitteeReport ballotCommitteeStatusOptions={ballotCommitteeStatus}  isBallotClosure={true}  committeeReports={ballotReport?.ballotCommitteeReports ?? []} primaryCommittee={ballotDetails.Committee?.Name ?? ""} handleCommitteeStatusChange={handleCommitteeStatusChange} selectedCommitteeBallotStatus={selectedCommitteeBallotStatus} />
        <div className="card bg-white px-5 py-4">
          <div className="grid grid-xl">
            <div className="xl:col-8 lg:col-8 md:col-12 col-12">
              <Input
                type="text"
                label={
                "Remark"
                }
                value={ballotClosureRemark}
                name="Remark"
                onChange={(value) => setBallotCloserRemark(value)}
                placeholder={"Ramark"}
              />
            </div>
          </div>
        </div>
        <div className="bg-white flex flex-wrap align-items-center px-5 py-3 gap-4 fixed-footer w-full left-0 shadow">
          <Button
            className="button-md gap-1"
            severity="secondary"
            onClick={() => navigate(`/ballot/${id}`)}
          >
            <span className="font-bold text-capitalize">
              {t("ballot.cancel")}
            </span>
          </Button>
          <Button onClick={handleCloseBallot}
            className="button-md gap-1 ml-auto"
            disabled={
              (ballotRecordData?.length || 0) !== (selectedStatus?.length || 0) 
            }
          >
            <span className="font-bold text-capitalize">
              {t("ballot.closeBallot")}
            </span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default BallotClose;