import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { selectRecordsData } from "./ballotSelectRecordType";

const initialState: selectRecordsData = {
  accreditationBodyOptions: [],
  primaryCommitteOptions: [],
  ballotTypeOptions: [],
  ballotSubTypeOptions: [],
  ballotIdOptions: [],
  ballotLevelOptions: [],
  recordTypeOptions: [],
  recordSubTypeOptions: [],
  positionAppliedOptions: [],
  ballotStatusOptions: [],
  selectedRecordValues: {
    accreditationBodySelect: [],
    primaryCommitteSelect: [],
    ballotTypeSelect: [],
    ballotSubTypeSelect: [],
    ballotIdSelect: [],
    ballotLevelSelect: [],
    recordTypeSelect: [],
    recordSubTypeSelect: [],
    positionAppliedSelect: [],
  },
  selectedTableRecords: [],
  preservedSelectedTableRecords: [],
  recordTableData: [],
  recordTableFilterData: [],
  postBallotRecord: false,
  stepCount: 0,
  isEdit: false,
};

const selectRecords = createSlice({
  name: "ballotSelectRecords",
  initialState,
  reducers: {
    initialRecordValues: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      const formatOptions = (
        options: any[],
        labelKey: string,
        valueKey: string,
        idKey: string
      ) =>
        options.map((item: any) => ({
          label: item[labelKey],
          value: item[valueKey],
          id: item[idKey],
        }));

      switch (action.payload.label) {
        case "accreditationType":
          state.accreditationBodyOptions = formatOptions(
            action.payload.options.Collection,
            "Name",
            "Name",
            "Id"
          );
          break;
        case "primarycommittee":
          state.primaryCommitteOptions = formatOptions(
            action.payload.options,
            "Name",
            "Name",
            "Id"
          );
          break;
        case "ballottype":
          state.ballotTypeOptions = formatOptions(
            action.payload.options.Collection,
            "Name",
            "Name",
            "Id"
          );
          break;
        case "ballotsubtype":
          state.ballotSubTypeOptions = formatOptions(
            action.payload.options.Collection,
            "Name",
            "Name",
            "Id"
          );
          break;
        case "ballotid":
          state.ballotIdOptions = formatOptions(
            action.payload.options.Collection,
            "BallotNumber",
            "BallotNumber",
            "BallotId"
          );
          break;
        case "ballotlevel":
          state.ballotLevelOptions = formatOptions(
            action.payload.options.Collection,
            "Name",
            "Name",
            "Id"
          );
          break;
        case "recordtype":
          state.recordTypeOptions = formatOptions(
            action.payload.options.Collection,
            "TypeName",
            "TypeName",
            "Id"
          );
          break;
        case "recordsubtype":
          state.recordSubTypeOptions = formatOptions(
            action.payload.options.Collection,
            "Type",
            "Type",
            "Id"
          );
          break;
        case "positions":
          state.positionAppliedOptions = formatOptions(
            action.payload.options.Collection,
            "PositionName",
            "PositionName",
            "Id"
          );
          break;
        case "ballotStatus":
          state.ballotStatusOptions = formatOptions(
            action.payload.options.Collection,
            "Name",
            "Name",
            "Id"
          );
          break;
        default:
          break;
      }
    },
    selectedRecordValues: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      const optionMappings: any = {
        accreditationBodyValue: state.accreditationBodyOptions,
        primaryCommitteeValue: state.primaryCommitteOptions,
        ballotTypeValue: state.ballotTypeOptions,
        ballotSubTypeValue: state.ballotSubTypeOptions,
        ballotIdValue: state.ballotIdOptions,
        ballotLevelValue: state.ballotLevelOptions,
        recordTypeValue: state.recordTypeOptions,
        recordSubTypeValue: state.recordSubTypeOptions,
        positionAppliedValue: state.positionAppliedOptions,
      };

      const optionFieldMapping: Record<string, string> = {
        accreditationBodyValue: "accreditationBodySelect",
        primaryCommitteeValue: "primaryCommitteSelect",
        ballotTypeValue: "ballotTypeSelect",
        ballotSubTypeValue: "ballotSubTypeSelect",
        ballotIdValue: "ballotIdSelect",
        ballotLevelValue: "ballotLevelSelect",
        recordTypeValue: "recordTypeSelect",
        recordSubTypeValue: "recordSubTypeSelect",
        positionAppliedValue: "positionAppliedSelect",
      };
      const selectedOption = optionMappings[action.payload.label]?.find(
        (option: any) => option.value === action.payload.target
      );
      if (selectedOption) {
        const selectedField = optionFieldMapping[action.payload.label];
        (state.selectedRecordValues as any)[selectedField] = selectedOption;
      }
      if (action.payload.target === "") {
        const placeholderOption = { value: "", label: "Select an option" };
        const selectedField = optionFieldMapping[action.payload.label];
        (state.selectedRecordValues as any)[selectedField] = placeholderOption;
        return;
      }
    },
    tableRecordSelectionHandler: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      let index = state.selectedTableRecords.findIndex(
        (obj: any) => obj.id === action.payload.rowData.id
      );
      if (index === -1) {
        const newRecord = action.payload.rowData;
        const recordExistsInSelected = state.selectedTableRecords.some(
          (record: any) => record.id === newRecord.id
        );
        const recordExistsInPreserved =
          state.preservedSelectedTableRecords.some(
            (record: any) => record.id === newRecord.id
          );
        if (!recordExistsInSelected) {
          state.selectedTableRecords.push(newRecord);
        }
        if (!recordExistsInPreserved) {
          state.preservedSelectedTableRecords.push(newRecord);
        }
      } else {
        state.selectedTableRecords.splice(index, 1);
      }
    },
    addRecordTableData: (state, action: PayloadAction<Record<string, any>>) => {
      state.recordTableData = action.payload.rowTableData;
      state.selectedTableRecords = [];
      state.preservedSelectedTableRecords = [];
      if (action.payload.isRecirculation) {
        state.selectedTableRecords = action.payload.rowTableData;
      }
    },
    addRecordTableFilterData: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.recordTableFilterData = action.payload.rowTableData;
    },
    postBallotRecord: (state, action: PayloadAction<Record<string, any>>) => {
      state.postBallotRecord = action.payload.value;
    },
    addStepCountHandler: (
      state,
      action: PayloadAction<Record<string, any>>
    ) => {
      state.stepCount = action.payload.value;
    },
    isEditHandler: (state, action: PayloadAction<Record<string, any>>) => {
      state.isEdit = action.payload.value;
    },
    reset: (state) => {
      state.selectedRecordValues = {
        accreditationBodySelect: [],
        primaryCommitteSelect: [],
        ballotTypeSelect: [],
        ballotSubTypeSelect: [],
        ballotIdSelect: [],
        ballotLevelSelect: [],
        recordTypeSelect: [],
        recordSubTypeSelect: [],
        positionAppliedSelect: [],
      };
      state.selectedTableRecords = [];
      state.preservedSelectedTableRecords = [];
      state.recordTableData = [];
      state.postBallotRecord = false;
      state.stepCount = 0;
      state.isEdit = false;
      state.recordTableFilterData = [];
    },
  },
});

export const ballotSelectRecords = selectRecords.actions;
export default selectRecords.reducer;
