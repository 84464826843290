import React, { useEffect, useState } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { fetchData as getData } from "../../services/Ballot/apiservice";
import {
  putData,
  fetchData,
  getListByParentId,
} from "../../services/apiService";
import { Navigate, useNavigate } from "react-router-dom";
import PreviewItem from "../common/PreviewItem";
import Table from "../common/Table";

interface CommitteeConfigurationTabViewProps {
  setActiveIndex: (index: number) => void;
  onEditButtonClick: () => void;
}

const CommitteeConfigurationTabView: React.FC<
  CommitteeConfigurationTabViewProps
> = ({ setActiveIndex, onEditButtonClick }) => {
  const [t, i18n] = useTranslation("configuration");
  const navigate = useNavigate();

  const [committeeData, setCommitteeData] = useState<CommitteeDataItem[]>([]);
  const [committeeCOIData, setCommitteeCOIData] = useState<CommitteeCOIItem[]>(
    []
  );
  const [defaultBallotConfigurationData,setDefaultBallotConfigurationData] = useState<any>(null);
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  interface CommitteeDataItem {
    Id: string;
    IsDirty: true;
    IsNew: true;
    IsDefaultParent: boolean;
    IsNatureofCommitteeRestricted: boolean;
    CreatedDate: string;
    IsBalanceBasedOnOverAllThreshold: boolean;
    MinOverallThreshold: number;
    MaxOverallThreshold: number;
    ThresholdPercentage: number;
    IsBalanceBasedOnCategoryofInterests: boolean;
    IsBalanceBasedOnRepresentationByOrg: boolean;
    OrganisationMinMember: number;
    OrganisationMaxMember: number;
    OrganisationMemberThreshold: number;
    IsShowCommitteemembers: boolean;
    CommitteeMinMember: number;
    CommitteeMaxMember: number;
    IsCriteriaOfmembershipEligibility: boolean;
    MSElig_SubTierCommitte_Months: number;
    MSElig_SameCommitte_Officer_Months: number;
    IsTenure_ExpirationsRules: boolean;
    MSExpire_Officer_Tenure_Years: number;
    MSExpire_Member_Tenure_Years: number;
    IsShowAllMembershipExpires: boolean;
    SendNotificationforMembershipExpiration: boolean;
    NotifyStaffExpirationofMembership: boolean;
    NotifyStaffExpirationofMembershipDays_First: number;
    NotifyStaffExpirationofMembershipDays_Second: number;
    NotifyStaffExpirationofMembershipDays_Third: number;
    NotifyMemberExpirationofMembership: boolean;
    NotifyMemberExpirationofMembershipDays_First: number;
    NotifyMemberExpirationofMembershipDays_Second: number;
    NotifyMemberExpirationofMembershipDays_Third: number;
    OfficerMaximumTermYears: number;
    ApplicationAutoWithdrawalMonths: number;
    NotifyMemberLackofParticipation: boolean;
    NumberofMeetingMissed: number;
    NumberofBallotsMissed: number;
    TotalMeetingMisssedPercentage: number;
    TotalBallotMisssedPercentage: number;
    AcceptApplicationWithoutOpenPositions: boolean;
    IsAllowMemberOtherCommitteAccess: boolean;
    AllowApplicationsWithoutOpenPositions: boolean;
  }

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  interface CommitteeCOIItem {
    id: string;
    IsDirty: true;
    IsNew: true;
    MinMember: number;
    MaxMember: number;
    Threshold: number;
  }

  const fetchCommitteeConfig = async () => {
    try {
      const responseData = await fetchData("CommitteeConfiguration/GetAll");
      const committeeCollection = responseData?.Collection || [];
      setCommitteeData(committeeCollection);
      if (committeeCollection.length > 0) {
        const CommitteeConfigId = committeeCollection[0].Id;
        const responseCOIData = await getListByParentId(
          "CategoryofInterestConfiguration",
          CommitteeConfigId
        );
        setCommitteeCOIData(responseCOIData?.Collection || []);
      } else {
        console.error("No committee data found.");
      }
    } catch (error) {
      console.error("Error fetching committee configuration:", error);
    }
  };

  const fetchBallotDeafultConfig = async () => {
    try {
      const responseData = await getData("BallotConfiguration/GetDefaultBallotConfiguration");
      setDefaultBallotConfigurationData(responseData);
    } catch (error) {
      console.error("Error fetching committee configuration:", error);
    }
  }

  useEffect(() => {
    fetchCommitteeConfig();
    fetchBallotDeafultConfig();
  }, []);

  const handleButtonClick = () => {
    setActiveIndex(2);
  };

  const handleEditButtonClick = () => {
    onEditButtonClick();
  };

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold text-capitalize m-0">
              {t("configuration.committeeConfiguration")}
            </h2>
            <Button
              text
              className="p-button-plain gap-2 underline"
              onClick={handleEditButtonClick}
            >
              <FeatherIcon name="edit-2" size={20} />
              <span>{t("configuration.edit")}</span>
            </Button>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-4">
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                  {t("configuration.defaultConfigurationFromParentCommittee")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {committeeData[0]?.IsDefaultParent ? "Yes" : "No"}
                </p>
              </div>
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                  {t("configuration.natureOfCommittee")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {committeeData[0]?.IsNatureofCommitteeRestricted
                    ? "Yes"
                    : "No"}
                </p>
              </div>
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                  {t("configuration.isMemberAllowedToAccessOtherCommittee")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {committeeData[0]?.IsAllowMemberOtherCommitteAccess
                    ? "Yes"
                    : "No"}
                </p>
              </div>
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                  {t(
                    "configuration.defaultApplicationConfigurationFromParentCommittee"
                  )}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {committeeData[0]?.AllowApplicationsWithoutOpenPositions
                    ? "Yes"
                    : "No"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <div className="flex flex-column gap-3">
              <h2 className="text-title text-lg font-bold m-0">
                {t("configuration.criteriaForBalanceOfInterest")}
              </h2>
              <p className="text-base font-normal m-0">
                {t("configuration.criteriaForBalanceOfInterestHelp")}
              </p>
            </div>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-7">
              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-normal text-capitalize text-lg text-title w-full">
                  {t("configuration.balanceBasedOnOverallThreshold")}
                </h3>
                <div className="flex flex-column gap-4">
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.min")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeData[0]?.MinOverallThreshold}
                    </p>
                  </div>
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.max")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeData[0]?.MaxOverallThreshold}
                    </p>
                  </div>
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.threshold")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeData[0]?.ThresholdPercentage}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-normal text-capitalize text-lg text-title w-full">
                  {t("configuration.balanceBasedOnIndCoi")}
                </h3>
                <div className="flex flex-column gap-4">
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.min")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeCOIData[0]?.MinMember}
                    </p>
                  </div>
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.max")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeCOIData[0]?.MaxMember}
                    </p>
                  </div>
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.threshold")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeCOIData[0]?.Threshold}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-normal text-capitalize text-lg text-title w-full">
                  {t("configuration.balanceRepresentationByOrg")}
                </h3>
                <div className="flex flex-column gap-4">
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.min")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeData[0]?.OrganisationMinMember}
                    </p>
                  </div>
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.max")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeData[0]?.OrganisationMaxMember}
                    </p>
                  </div>
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.threshold")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeData[0]?.OrganisationMemberThreshold}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <div className="flex flex-column gap-3">
              <h2 className="text-title text-lg font-bold m-0">
                {t("configuration.committeeMemberConfiguration")}
              </h2>
              <p className="text-base font-normal m-0">
                {t("configuration.committeeMemberConfigurationHelp")}
              </p>
            </div>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-7">
              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-normal text-capitalize text-lg text-title w-full">
                  {t("configuration.numberOfMemberInTheCommittee")}
                </h3>
                <div className="flex flex-column gap-4">
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.min")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeData[0]?.CommitteeMinMember}
                    </p>
                  </div>
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.max")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeData[0]?.CommitteeMaxMember}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-normal text-capitalize text-lg text-title w-full">
                  {t("configuration.criteriaMembershipEligibilityMonths")}
                </h3>
                <div className="flex flex-column gap-4">
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.atSubTierCommitteeForMembership")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeData[0]?.MSElig_SubTierCommitte_Months}
                    </p>
                  </div>
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.atTheSameCommitteeForOfficerPosition")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeData[0]?.MSElig_SameCommitte_Officer_Months}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-normal text-capitalize text-lg text-title w-full">
                  {t("configuration.membershipTenureAndExpirationsRules")}
                </h3>
                <div className="flex flex-column gap-4">
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.tenureOfOfficerPosition")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeData[0]?.MSExpire_Officer_Tenure_Years}
                    </p>
                  </div>
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.tenureOfOtherMembers")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeData[0]?.MSExpire_Member_Tenure_Years}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                  {t("configuration.allMembershipsExpires")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {committeeData[0]?.IsShowAllMembershipExpires ? "Yes" : "No"}
                </p>
              </div>
              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                  {t("configuration.maximumConsecutiveTerm")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {committeeData[0]?.OfficerMaximumTermYears}
                </p>
              </div>

              <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                  {t("configuration.durationOfReconfirm")}
                </span>
                <p className="m-0 font-normal text-base text-title m-0 w-full">
                  {committeeData[0]?.ApplicationAutoWithdrawalMonths}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <div className="flex flex-column gap-3">
              <h2 className="text-title text-lg font-bold m-0">
                {t("configuration.notificationMembershipExpiration")}
              </h2>
              <p className="text-base font-normal m-0">
                {t("configuration.notificationMembershipExpirationHelp")}
              </p>
            </div>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-7">
              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-normal text-capitalize text-lg text-title w-full">
                  {t("configuration.notificationToStaff")}
                </h3>
                <div className="flex flex-column gap-4">
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.firstNotification")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {
                        committeeData[0]
                          ?.NotifyStaffExpirationofMembershipDays_First
                      }
                    </p>
                  </div>
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.secondNotification")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {
                        committeeData[0]
                          ?.NotifyStaffExpirationofMembershipDays_Second
                      }
                    </p>
                  </div>
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.thirdNotification")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {
                        committeeData[0]
                          ?.NotifyStaffExpirationofMembershipDays_Third
                      }
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-normal text-capitalize text-lg text-title w-full">
                  {t("configuration.notificationToMembers")}
                </h3>
                <div className="flex flex-column gap-4">
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.firstNotification")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {
                        committeeData[0]
                          ?.NotifyMemberExpirationofMembershipDays_First
                      }
                    </p>
                  </div>
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.secondNotification")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {
                        committeeData[0]
                          ?.NotifyMemberExpirationofMembershipDays_Second
                      }
                    </p>
                  </div>
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.thirdNotification")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {
                        committeeData[0]
                          ?.NotifyMemberExpirationofMembershipDays_Third
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <div className="flex flex-column gap-3">
              <h2 className="text-title text-lg font-bold m-0">
                {t("configuration.notificationAlertsToOfficers")}
              </h2>
              <p className="text-base font-normal m-0">
                {t("configuration.notificationAlertsToOfficersHelp")}
              </p>
            </div>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-7">
              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-normal text-capitalize text-lg text-title w-full">
                  {t("configuration.sendNotificationAlert")}
                </h3>
                <div className="flex flex-column gap-4">
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.consecutiveMeetingsMissed")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeData[0]?.NumberofMeetingMissed}
                    </p>
                  </div>
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.consecutiveBallotsMissed")}
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeData[0]?.NumberofBallotsMissed}
                    </p>
                  </div>
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.totalMeetingMissed")} (%)
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeData[0]?.TotalMeetingMisssedPercentage}
                    </p>
                  </div>
                  <div className="flex flex-column md:flex-row column-gap-5 row-gap-2">
                    <span className="m-0 font-bold text-capitalize text-base w-full md:w-18rem flex-shrink-0 text-label">
                      {t("configuration.totalBallotMissed")} (%)
                    </span>
                    <p className="m-0 font-normal text-base text-title m-0 w-full">
                      {committeeData[0]?.TotalBallotMisssedPercentage}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <div className="flex flex-column gap-3">
              <h2 className="text-title text-lg font-bold m-0">
                {t("configuration.defaultBallotConfiguration")}
              </h2>
            </div>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-7">
              <div className="flex flex-column gap-5">
                <div className="flex flex-column gap-4">
                <PreviewItem
            label={t("configuration.allowEditVoteAfterClosed")}
            value={
              defaultBallotConfigurationData?.EnableVotingPostBallotClosed !== undefined
                ? defaultBallotConfigurationData.EnableVotingPostBallotClosed ? 
                "Yes" : "No"
                : "Value"
            }
          />
          <PreviewItem
            label={t("configuration.allowEditVoteBeforeClosed")}
            value={
              defaultBallotConfigurationData?.EnableVotingBeforeBallotClosed !== undefined
                ? defaultBallotConfigurationData.EnableVotingBeforeBallotClosed ? "Yes" : "No"
                : "Value"
            }
          />
          <PreviewItem
            label={t("configuration.allowVotingPostBallotEndDate")}
            value={
              defaultBallotConfigurationData?.EnableVotingAfterBallotEndDate !== undefined
                ? defaultBallotConfigurationData.EnableVotingAfterBallotEndDate 
                  ? "Yes" 
                  : "No"
                : "Value"  // fallback if the value is undefined
            }
          />
          <PreviewItem
            label={t("configuration.firstVotingNotificationDays")}
            value={
              defaultBallotConfigurationData?.VotingFirstRemindersInDays !== undefined
                ? defaultBallotConfigurationData.VotingFirstRemindersInDays
                : "Value"
            }
          />
          <PreviewItem
            label={t("configuration.secondVotingNotificationDays")}
            value={
              defaultBallotConfigurationData?.VotingSecondRemindersInDays !== undefined
                ? defaultBallotConfigurationData.VotingSecondRemindersInDays
                : "Value"
            }
          />
          <PreviewItem
            label={t("configuration.thirdVotingNotificationDays")}
            value={
              defaultBallotConfigurationData?.VotingThirdRemindersInDays !== undefined
                ? defaultBallotConfigurationData.VotingThirdRemindersInDays
                : "Value"
            }
          />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex align-items-center align-self-center justify-content-between px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <div className="flex flex-column gap-3">
              <h2 className="text-title text-lg font-bold m-0">
                {t("configuration.ballotApprovalCriteriaNotDefined")}
              </h2>
            </div>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-7">
              <div className="flex flex-column gap-5">
                <div className="flex flex-column gap-4">
                  <PreviewItem
                    label={t("configuration.minBallotDurationWeeks")}
                    value="Value"
                  />
                  <PreviewItem
                    label={t("configuration.votingOption")}
                    value="Value"
                  />
                  <PreviewItem
                    label={t("configuration.defaultVotingOption")}
                    value="Value"
                  />
                  <PreviewItem
                    label={t("configuration.votingConsensusCalculation")}
                    value="Value"
                  />
                  <PreviewItem
                    label={t("configuration.commentOption")}
                    value="Value"
                  />
                </div>
              </div>

              <div className="flex flex-column gap-5">
                <h3 className="m-0 font-normal text-capitalize text-lg text-title w-full">
                  {t("configuration.recirculation")}
                </h3>
                <div className="flex flex-column gap-4">
                  <PreviewItem
                    label={t(
                      "configuration.newRemoveExistingRecordsRecirculatingBallots"
                    )}
                    value="Value"
                  />
                  <PreviewItem
                    label={t(
                      "configuration.newRemoveExistingCommitteeRecirculatingBallots"
                    )}
                    value="Value"
                  />
                </div>
              </div>

              <div className="flex flex-column gap-3">
                <h3 className="m-0 font-normal text-capitalize text-lg text-title w-full">
                  {t("configuration.ballotApprovalCriteria")}
                </h3>
                <Table
                  loading={false}
                  products={[]}
                  columns={[
                    {
                      field: "operator1",
                      header: t("configuration.operator1"),
                      sortable: false,
                    },
                    { field: "percent1", header: "%", sortable: false },
                    {
                      field: "votingOptions",
                      header: t("configuration.votingOptions"),
                      sortable: false,
                    },
                    {
                      field: "operator2",
                      header: t("configuration.operator2"),
                      sortable: false,
                    },
                    {
                      field: "operator3",
                      header: t("configuration.operator3"),
                      sortable: false,
                    },
                    { field: "percent2", header: "%", sortable: false },
                    {
                      field: "votingOption",
                      header: t("configuration.votingOption"),
                      sortable: false,
                    },
                  ]}
                  disablePagination={true}
                />
              </div>

              <div className="flex flex-column gap-5">
                <div className="flex flex-column gap-4">
                  <PreviewItem
                    label={t("configuration.durationWithdrawDisapproval")}
                    value="Value"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommitteeConfigurationTabView;
function setActiveIndex(arg0: number) {
  throw new Error("Function not implemented.");
}
