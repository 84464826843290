import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "primereact/dropdown";
import { fetchData } from "../../../services/Ballot/apiservice";
import { fetchData as getData } from "../../../services/apiService";
import { AccreditationBody, BallotLevel, BallotSubType, BallotType, RecordSubType, RecordType } from "./BallotConfigurationInterface";

interface BallotConfigurationWeightBallotProps {
 configurationData: any;
 handleDropdownField: any;
 errors: any
}

const BallotConfigurationFields: React.FC<BallotConfigurationWeightBallotProps> = ({
  configurationData,
  handleDropdownField,
  errors
}) => {
  const [t] = useTranslation("configuration");
  const [ballotLevels, setBallotLevels] = useState<BallotLevel[]>([]);
  const [ballotTypes, setBallotTypes] = useState<BallotType[]>([]);
  const [recordTypes, setRecordTypes] = useState<RecordType[]>([]);
  const [recordSubTypes, setRecordSubTypes] = useState<RecordSubType[]>([]);
  const [ballotSubTypes, setBallotSubTypes] = useState<BallotSubType[]>([]);
  const [accreditationBody, setAccreditationBody] = useState<
    AccreditationBody[]
  >([]);

  const mapCollection = useCallback(
    (data: any) => data.Collection.map(({ Id, Name }: any) => ({ Id, Name })),
    []
  );

  const fetchDropdownOptions = useCallback(async () => {
    try {
      const [
        accreditationBodyData,
        ballotTypeData,
        ballotSubTypeData,
        ballotLevelData,
        recordTypeData,
        recordSubTypeData,
      ] = await Promise.all([
        getData("AccreditationBody/GetAll"),
        fetchData("BallotType/GetAll"),
        fetchData("BallotSubType/GetAll"),
        fetchData("BallotLevel/GetAll"),
        getData("RecordType/GetAll"),
        fetchData("RecordSubType/GetAll"),
      ]);

      setAccreditationBody(mapCollection(accreditationBodyData));
      setBallotTypes(mapCollection(ballotTypeData));
      setBallotSubTypes(mapCollection(ballotSubTypeData));
      setBallotLevels(mapCollection(ballotLevelData));

      setRecordTypes(
        recordTypeData.Collection.map((item: RecordType) => ({
          Id: item.Id,
          Name: item.TypeName,
        }))
      );
      setRecordSubTypes(
        recordSubTypeData.Collection.map((item: RecordSubType) => ({
          Id: item.Id,
          Name: item.Type,
        }))
      );
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  }, [mapCollection]);
 
  useEffect(() => {
    fetchDropdownOptions();
  }, [fetchDropdownOptions]);

  const dropdownProps = useMemo(
    () => ({
      accreditationBody,
      ballotLevels,
      ballotTypes,
      ballotSubTypes,
      recordTypes,
      recordSubTypes,
    }),
    [accreditationBody, ballotLevels, ballotTypes, ballotSubTypes, recordTypes, recordSubTypes]
  );

  const DropdownField = ({ id, label, value, options, errorKey }: any) => {
   return (
      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
      <label htmlFor={id} className="block font-bold text-input-label text-capitalize">
        {`${t(label)}*`}
      </label>
      <Dropdown
        inputId={id}
        value={value}
        options={options}
        onChange={(e) => handleDropdownField(e, id)}
        optionLabel="Name"
        optionValue="Id"
        placeholder={t("configuration.selectAnOption")}
      />
      {errors?.[errorKey] && <small className="p-error">{errors?.[errorKey]}</small>}
    </div>
   ) 
  };

  return (
    <>
     <div className="grid grid-xl">
        <DropdownField
          id="accreditationBodyId"
          label="configuration.applicableAccreditationBody"
          value={configurationData.accreditationBodyId}
          options={dropdownProps.accreditationBody}
          errorKey="accreditationBodyId"
        />
      </div>
      <div className="grid grid-xl">
        <DropdownField
          id="ballotLevelId"
          label="configuration.ballotLevel"
          value={configurationData.ballotLevelId}
          options={dropdownProps.ballotLevels}
          errorKey="ballotLevelId"
        />
      </div>
      <div className="grid grid-xl">
        <DropdownField
          id="ballotTypeId"
          label="configuration.ballotType"
          value={configurationData.ballotTypeId}
          options={dropdownProps.ballotTypes}
          errorKey="ballotTypeId"
        />
        <DropdownField
          id="ballotSubTypeId"
          label="configuration.ballotSubType"
          value={configurationData.ballotSubTypeId}
          options={dropdownProps.ballotSubTypes}
          errorKey="ballotSubTypeId"
        />
      </div>
      <div className="grid grid-xl">
        <DropdownField
          id="recordTypeId"
          label="configuration.recordType"
          value={configurationData.recordTypeId}
          options={dropdownProps.recordTypes}
          errorKey="recordTypeId"
        />
        <DropdownField
          id="recordSubTypeId"
          label="configuration.recordSubType"
          value={configurationData.recordSubTypeId}
          options={dropdownProps.recordSubTypes}
          errorKey="recordSubTypeId"
        />
      </div>
    </>
  );
};

export default React.memo(BallotConfigurationFields);