import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
import { MenuItem } from "primereact/menuitem";
import { OverlayPanel } from "primereact/overlaypanel";
import Table from "../common/Table";
import FeatherIcon from "../common/FeatherIconComponent";
import { useLanguageContext } from "../LanguageContext";
import { fetchData, getListByParentId } from "../../services/apiService";
import { formatCommitteeResponse, sortByStartDate } from "../../utils/utils";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { BASE_URL } from "../../config";
import axiosInstance from "../../services/axiosInstance";
import { reset } from "../../slices/createRecordSlice";
import { usePermission } from "../auth/Authorization";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { focusOnField } from "../../utils/focusField";
import { clearAllSessionStorage } from "../../utils/storage";
import { useLoadingContext } from "../Layout";
import RecordResignation from "./RecordResignation";

interface Option {
  name: string;
  code: string;
}
interface RecordList {
  [x: string]: string;
  Committee: any;
  id: string;
  name: string;
  committeeApplied: string;
  recordSubType: string;
  subSubType: string;
  position: string;
  categoryOfInterest: string;
  createdOn: string;
  status: string;
  action: string;
}

const RecordListing: React.FC = () => {
  const [t, i18n] = useTranslation("record");
  const [search, setSearch] = useState("");
  const [searchvalue, setSearchValue] = useState("");
  const [toDateError, setToDateError] = useState("");
  const [toDateErrorForAll, setToDateErrorForAll] = useState("");
  const [committees, setCommittees] = useState<any>([]);
  const [coiData, setCoiData] = useState([]);
  const [memberPosition, setMemberPosition] = useState([]);
  const [recordStatus, setRecordStatus] = useState([]);
  const [selectedCommittees, setSelectedCommittees] = useState<any[]>([]);
  const [userProfileId, setUserProfileId] = useState<string>("");
  const [selectedCOIs, setSelectedCOIs] = useState<any[]>([]);
  const [selectedPositions, setSelectedPositions] = useState<any[]>([]);
  const [selectedRecordStatuses, setSelectedRecordStatuses] = useState<any[]>(
    []
  );
  const [allRecordsFilter, setAllRecordsFilter] = useState<boolean>(false);
  const [selectedCommitteesForAll, setSelectedCommitteesForAll] = useState<
    any[]
  >([]);
  const [selectedCOIsForAll, setSelectedCOIsForAll] = useState<any[]>([]);
  const [selectedPositionsForAll, setSelectedPositionsForAll] = useState<any[]>(
    []
  );
  const [selectedRecordStatusesForAll, setSelectedRecordStatusesForAll] =
    useState<any[]>([]);
  const [committeeCheckboxChecked, setCommitteeCheckboxChecked] =
    useState<boolean>(false);
  const [coiCheckboxChecked, setCoiCheckboxChecked] = useState<boolean>(false);
  const [positionCheckboxChecked, setPositionCheckboxChecked] =
    useState<boolean>(false);
  const [recordStatusCheckboxChecked, setRecordStatusCheckboxChecked] =
    useState<boolean>(false);
  const [dateCheckboxChecked, setDateCheckboxChecked] =
    useState<boolean>(false);
  const [dateActivationFrom, setDateActivationFrom] = useState<Date | null>(
    null
  );
  const [dateActivationTo, setDateActivationTo] = useState<Date | null>(null);
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
  const [recordList, setRecordList] = useState<RecordList[]>([]);
  const [recordListForAll, setRecordListForAll] = useState<RecordList[]>([]);
  const [terminatedRecords, setTerminatedRecords] = useState([]);
  const userProfileID = localStorage.getItem("userProfileID");
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isFilterAppliedYes, setIsFilterAppliedYes] = useState(false);
  const [committeeCheckboxCheckedForAll, setCommitteeCheckboxCheckedForAll] =
    useState<boolean>(false);
  const [coiCheckboxCheckedForAll, setCoiCheckboxCheckedForAll] =
    useState<boolean>(false);
  const [positionCheckboxCheckedForAll, setPositionCheckboxCheckedForAll] =
    useState<boolean>(false);
  const [
    recordStatusCheckboxCheckedForAll,
    setRecordStatusCheckboxCheckedForAll,
  ] = useState<boolean>(false);
  const [dateCheckboxCheckedForAll, setDateCheckboxCheckedForAll] =
    useState<boolean>(false);
  const [dateActivationFromForAll, setDateActivationFromForAll] =
    useState<Date | null>(null);
  const [dateActivationToForAll, setDateActivationToForAll] =
    useState<Date | null>(null);
  const [filteredRecordsForAll, setFilteredRecordsForAll] = useState([]);
  const { userPermissions, fetchUserPermissions, hasPermission } =
    usePermission();
  const [userRole, setUserRole] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    clearAllSessionStorage();
    dispatch(reset());
    performAction();
  }, [selectedLanguage]);

  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  const breadcumbItems = [
    { label: t("record.dashboard"), url: "/dashboard" },
    { label: t("record.records") },
  ];

  const navigate = useNavigate();

  const handleCheckboxChange =
    (checkbox: string) => async (e: CheckboxChangeEvent) => {
      const isChecked = e.checked !== undefined ? e.checked : false;
      switch (checkbox) {
        case "committee":
          setCommitteeCheckboxChecked(isChecked);
          break;
        case "coi":
          setCoiCheckboxChecked(isChecked);
          break;
        case "position":
          setPositionCheckboxChecked(isChecked);
          break;
        case "recordStatus":
          setRecordStatusCheckboxChecked(isChecked);
          break;
        case "date":
          setDateCheckboxChecked(isChecked);
          break;
        case "allViewRecords":
          setAllRecordsFilter(isChecked);
          setLoading(true);

          try {
            let responseData;
            if (isChecked) {
              responseData = await fetchData("Record/Search?PageIndex=-1");
            } else {
              responseData = await fetchData(
                `Record/Search?Filters[0].Key=StaffId&Filters[0].Value=${userProfileId}&PageIndex=-1`
              );
            }

            const modifiedData = responseData.Collection.sort(
              (a: any, b: any) => {
                const dateA: any = new Date(a.CreatedDate as any);
                const dateB: any = new Date(b.CreatedDate as any);
                return dateB - dateA;
              }
            );

            const formattedRecordList = modifiedData.map((record: any) => ({
              ...record,
              recordId:
                record?.RecordNumber === "null" || !record?.RecordNumber
                  ? ""
                  : record?.RecordNumber,
              name: `${record?.UserProfile?.Firstname} ${record?.UserProfile?.MiddleName} ${record?.UserProfile?.Lastname}`,
              sessionKey: "recordId",
              statusName: record?.RecordStatus.StatusName,
              sessionKeyStatus: "statusName",
              committeeName: record?.Committee?.Name,
              recordTypeName: record?.RecordType?.TypeName,
              membershipPosition: record?.MembershipPosition?.PositionName,
              categoryName: record?.CategoryOfInterest?.CategoryName,
            }));

            const filteredRequestList = formattedRecordList?.filter(
              (record: { IsResignRecord: boolean }) =>
                record.IsResignRecord === false
            );
            setRecordList((prev) => {
              const updatedList = sortByStartDate(filteredRequestList);
              return updatedList;
            });
          } catch (err) {
            console.error("Error fetching data:", err);
          } finally {
            setLoading(false);
          }
          break;
        default:
          break;
      }
    };
  const handleCheckboxChangeForAll =
    (checkbox: string) => (e: CheckboxChangeEvent) => {
      const isChecked = e.checked !== undefined ? e.checked : false;
      switch (checkbox) {
        case "committee":
          setCommitteeCheckboxCheckedForAll(isChecked);
          break;
        case "coi":
          setCoiCheckboxCheckedForAll(isChecked);
          break;
        case "position":
          setPositionCheckboxCheckedForAll(isChecked);
          break;
        case "recordStatus":
          setRecordStatusCheckboxCheckedForAll(isChecked);
          break;
        case "date":
          setDateCheckboxCheckedForAll(isChecked);
          break;
        default:
          break;
      }
    };

  const fetchFilteredData = async (event: any) => {
    const queryParams: { [key: string]: any } = {};
    const filters: { Key: string; Value: string }[] = [];
    if (committeeCheckboxChecked) {
      const committeeIds = selectedCommittees.map((committee) => committee.Id);
      filters.push({ Key: "CommitteeId", Value: committeeIds.join(",") });
    }
    if (coiCheckboxChecked) {
      const coiIds = selectedCOIs.map((coi) => coi.Id);
      filters.push({ Key: "CategoryOfInterestId", Value: coiIds.join(",") });
    }
    if (positionCheckboxChecked) {
      const positionIds = selectedPositions.map((position) => position.Id);
      filters.push({
        Key: "MembershipPositionId",
        Value: positionIds.join(","),
      });
    }
    if (recordStatusCheckboxChecked) {
      const recordStatusIds = selectedRecordStatuses.map((status) => status.Id);
      filters.push({ Key: "RecordStatusId", Value: recordStatusIds.join(",") });
    }
    if (dateCheckboxChecked) {
      const fromDateStr = formatDate(dateActivationFrom);
      const toDateStr = formatDate(dateActivationTo);
      filters.push({ Key: "DateFilter", Value: `${fromDateStr}#${toDateStr}` });
    }
    filters.forEach((filter, index) => {
      queryParams[`Filters[${index}].Key`] = filter.Key;
      queryParams[`Filters[${index}].Value`] = filter.Value;
    });
    queryParams["PageIndex"] = -1;
    queryParams["SearchText"] = "";
    queryParams["SortBy"] = "";
    queryParams["SortDir"] = "asc";
    const apiUrl = `${BASE_URL}/Record/Search?${new URLSearchParams(queryParams).toString()}`;
    try {
      const response = await axiosInstance.get(apiUrl);

      if (response.status === 200) {
        const filteredData = response.data;
        const filterRecordIdArray = filteredData.Collection.map(
          (data: any) => data.Id
        );
        const filterRecordListData: any = recordList?.filter((data) =>
          filterRecordIdArray.includes(data.Id)
        );
        setFilteredRecords(filterRecordListData);
        setIsFilterApplied(true);
      } else {
        console.error("Failed to fetch filtered data");
      }
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    } finally {
      setLoading(false);
    }
    overlayPanelRef.current?.toggle(event);
    setCommitteeCheckboxChecked(false);
    setPositionCheckboxChecked(false);
    setCoiCheckboxChecked(false);
    setRecordStatusCheckboxChecked(false);
    setDateCheckboxChecked(false);
    setSelectedCommittees([]);
    setSelectedCOIs([]);
    setSelectedPositions([]);
    setSelectedRecordStatuses([]);
    setDateActivationFrom(null);
    setDateActivationTo(null);
  };

  const fetchFilteredDataForAll = async (event: any) => {
    const queryParams: { [key: string]: any } = {};
    const filters: { Key: string; Value: string }[] = [];
    if (committeeCheckboxCheckedForAll) {
      const committeeIds = selectedCommitteesForAll.map(
        (committees) => committees.Id
      );
      filters.push({ Key: "CommitteeId", Value: committeeIds.join(",") });
    }
    if (coiCheckboxCheckedForAll) {
      const coiIds = selectedCOIsForAll.map((cois) => cois.Id);
      filters.push({ Key: "CategoryOfInterestId", Value: coiIds.join(",") });
    }
    if (positionCheckboxCheckedForAll) {
      const positionIds = selectedPositionsForAll.map(
        (positions) => positions.Id
      );
      filters.push({
        Key: "MembershipPositionId",
        Value: positionIds.join(","),
      });
    }
    if (recordStatusCheckboxCheckedForAll) {
      const recordStatusIds = selectedRecordStatusesForAll.map(
        (statuss) => statuss.Id
      );
      filters.push({ Key: "RecordStatusId", Value: recordStatusIds.join(",") });
    }
    if (dateCheckboxCheckedForAll) {
      const fromDateStr = formatDate(dateActivationFromForAll);
      const toDateStr = formatDate(dateActivationToForAll);
      filters.push({ Key: "DateFilter", Value: `${fromDateStr}#${toDateStr}` });
    }
    filters.forEach((filter, index) => {
      queryParams[`Filters[${index}].Key`] = filter.Key;
      queryParams[`Filters[${index}].Value`] = filter.Value;
    });
    queryParams["PageIndex"] = -1;
    queryParams["SearchText"] = "";
    queryParams["SortBy"] = "";
    queryParams["SortDir"] = "asc";
    const apiUrl = `${BASE_URL}/Record/Search?${new URLSearchParams(queryParams).toString()}`;
    try {
      const response = await axiosInstance.get(apiUrl);

      if (response.status === 200) {
        const filteredData = response.data;
        const filterRecordIdArray = filteredData.Collection.map(
          (data: any) => data.Id
        );
        const filterRecordListData: any = recordListForAll?.filter((data) =>
          filterRecordIdArray.includes(data.Id)
        );
        setFilteredRecordsForAll(filterRecordListData);

        setIsFilterAppliedYes(true);
      } else {
        console.error("Failed to fetch filtered data");
      }
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    } finally {
      setLoading(false);
    }
    overlayPanelRef.current?.toggle(event);
    setCommitteeCheckboxCheckedForAll(false);
    setPositionCheckboxCheckedForAll(false);
    setCoiCheckboxCheckedForAll(false);
    setRecordStatusCheckboxCheckedForAll(false);
    setDateCheckboxCheckedForAll(false);
    setSelectedCommitteesForAll([]);
    setSelectedCOIsForAll([]);
    setSelectedPositionsForAll([]);
    setSelectedRecordStatusesForAll([]);
    setDateActivationFromForAll(null);
    setDateActivationToForAll(null);
  };

  const formatDate = (date: Date | null) => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      return "";
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const handleDateSelect = (date: Date, control: string) => {
    focusOnField(control);
    if (control === "dateActivationFrom") {
      if (dateActivationTo && date > dateActivationTo) {
        setToDateError(`${t("record.fromDateBeforeToDate")}`);
        setDateActivationFrom(null);
      } else {
        setToDateError("");
        setDateActivationFrom(date);
      }
    } else if (control === "dateActivationTo") {
      if (dateActivationFrom && date < dateActivationFrom) {
        setToDateError(`${t("record.toDateAfterFromDate")}`);
        setDateActivationTo(null);
      } else {
        setToDateError("");
        setDateActivationTo(date);
      }
    }
  };

  const handleDateSelectForAll = (date: Date, control: string) => {
    if (control === "dateActivationFromForAll") {
      if (dateActivationToForAll && date > dateActivationToForAll) {
        setToDateErrorForAll(`${t("record.fromDateBeforeToDate")}`);
        setDateActivationFromForAll(null);
      } else {
        setToDateErrorForAll("");
        setDateActivationFromForAll(date);
      }
    } else if (control === "dateActivationToForAll") {
      if (dateActivationFromForAll && date < dateActivationFromForAll) {
        setToDateErrorForAll(`${t("record.toDateAfterFromDate")}`);
        setDateActivationToForAll(null);
      } else {
        setToDateErrorForAll("");
        setDateActivationToForAll(date);
      }
    }
  };

  const toggleFilterPanel = (event: any) => {
    setShowFilterPanel(!showFilterPanel);
    if (overlayPanelRef.current) {
      overlayPanelRef.current.toggle(event);
    }
  };

  const fetchCommittees = async () => {
    try {
      const response = await fetchData("Committee/GetAll");
      const committeesData = response.Collection;
      setCommittees(committeesData);
    } catch (error) {
      console.error("Error fetching committees:", error);
    }
  };

  const fetchCategoryOfInterest = async () => {
    try {
      const response = await fetchData("CategoryOfInterest/GetAll");
      const categoryOfInterestData = response.Collection;
      setCoiData(categoryOfInterestData);
    } catch (error) {
      console.error("Error fetching COI:", error);
    }
  };

  const fetchMembershipPosition = async () => {
    try {
      const response = await fetchData("MembershipPosition/GetAll");
      const membershipPositionData = response.Collection;
      setMemberPosition(membershipPositionData);
    } catch (error) {
      console.error("Error fetching Membership Position:", error);
    }
  };

  const fetchRecordStatus = async () => {
    try {
      const response = await fetchData("RecordStatus/GetAll");
      const recordstatus = response.Collection;
      setRecordStatus(recordstatus);
    } catch (error) {
      console.error("Error fetching Record Status:", error);
    }
  };

  useEffect(() => {
    fetchCommittees();
    fetchCategoryOfInterest();
    fetchMembershipPosition();
    fetchRecordStatus();
  }, []);

  const handleViewMember = () => {
    return () => {
      if (sessionStorage.getItem("statusName") === "Draft") {
        navigate("/record/create");
      } else {
        navigate("/record/record-preview");
      }
    };
  };
  const actionItems: MenuItem[] = [
    {
      label: t("record.viewEditRecord"),
      command: handleViewMember(),
    },
  ];

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const responseData = await fetchData(
          "UserProfile/GetCurrentUserProfile"
        );

        setUserProfileId(responseData.Id);
      } catch (error) {
        console.error("Error fetching current user profile:", error);
      }
    };
    fetchCurrentUser();
  }, []);

  const newTermination = async () => {
    sessionStorage.setItem("termination", "true");
    navigate("/record/create");
  };

  useEffect(() => {
    if (!userProfileId) return;
    const fetchDataByRole = async () => {
      try {
        let responseData;
        switch (userRole) {
          case "Staff":
            responseData = await fetchData(
              `Record/Search?Filters[0].Key=StaffId&Filters[0].Value=${userProfileId}&PageIndex=-1`
            );
            break;
          case "Admin":
            responseData = await fetchData(`Record/Search?&PageIndex=-1`);
            break;
          case "Member":
            responseData = await fetchData(
              `Record/Search?Filters[0].Key=UserProfileId&Filters[0].Value=${userProfileId}&PageIndex=-1`
            );
            break;
          default:
            // Handle default case if necessary
            break;
        }

        const modifiedData = responseData.Collection.sort((a: any, b: any) => {
          const dateA: any = new Date(a.CreatedDate as any);
          const dateB: any = new Date(b.CreatedDate as any);
          return dateB - dateA;
        });

        const responseAllData = await fetchData(`Record/Search?&PageIndex=-1`);
        const modifiedAllData = responseAllData.Collection.sort(
          (a: any, b: any) => {
            const dateA: any = new Date(a.CreatedDate as any);
            const dateB: any = new Date(b.CreatedDate as any);
            return dateB - dateA;
          }
        );

        const formattedRecordList = modifiedData.map((record: any) => ({
          ...record,
          //new
          recordId:
            record?.RecordNumber === "null" || !record?.RecordNumber
              ? ""
              : record?.RecordNumber, //new
          name: `${record?.UserProfile?.Firstname} ${record?.UserProfile?.MiddleName} ${record?.UserProfile?.Lastname}`,
          sessionKey: "recordId",
          statusName: record?.RecordStatus.StatusName,
          sessionKeyStatus: "statusName",
          committeeName: record?.Committee?.Name,
          recordTypeName: record?.RecordType?.TypeName,
          membershipPosition: record?.MembershipPosition?.PositionName,
          categoryName: record?.CategoryOfInterest?.CategoryName,
        }));

        const filteredRequestList = formattedRecordList?.filter(
          (record: { IsResignRecord: boolean }) =>
            record.IsResignRecord === false
        );

        const formattedAllRecordList = modifiedAllData.map((record: any) => ({
          ...record,
          //new
          recordId:
            record?.RecordNumber === "null" || !record?.RecordNumber
              ? ""
              : record?.RecordNumber, //new
          name: `${record?.UserProfile?.Firstname} ${record?.UserProfile?.MiddleName} ${record?.UserProfile?.Lastname}`,
          sessionKey: "recordId",
          statusName: record?.RecordStatus.StatusName,
          sessionKeyStatus: "statusName",
          committeeName: record?.Committee?.Name,
          recordTypeName: record?.RecordType?.TypeName,
          membershipPosition: record?.MembershipPosition?.PositionName,
          categoryName: record?.CategoryOfInterest?.CategoryName,
        }));
        const filteredAllRequestList = formattedAllRecordList?.filter(
          (record: { IsResignRecord: boolean }) =>
            record.IsResignRecord === false
        );

        setRecordList(sortByStartDate(filteredRequestList));
        setRecordListForAll(sortByStartDate(filteredAllRequestList));
        const terminated = modifiedData.filter(
          (record: { IsTerminateRecord: boolean }) =>
            record.IsTerminateRecord === true
        );

        const formattedTerminatedRecords = terminated.map((record: any) => ({
          ...record,
          recordId:
            record?.RecordNumber === "null" || !record?.RecordNumber
              ? ""
              : record?.RecordNumber,
          statusName: record?.RecordStatus.StatusName,
          name: `${record.UserProfile?.Firstname} ${record.UserProfile?.MiddleName} ${record.UserProfile?.Lastname}`,
          sessionKey: "recordId",
          sessionKeyStatus: "statusName",
          committeeName: record?.Committee?.Name,
          recordTypeName: record?.RecordType?.TypeName,
          membershipPosition: record?.MembershipPosition?.PositionName,
          categoryName: record?.CategoryOfInterest?.CategoryName,
        }));
        setTerminatedRecords(sortByStartDate(formattedTerminatedRecords));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchDataByRole();
  }, [userRole, userProfileId]);

  return (
    <>
      <div className="">
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />

        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold m-0">
            {t("record.membershipRecords")}
          </h1>
          <p className="text-base font-normal m-0">
            {t("record.membershipRecordsHelp")}
          </p>
        </div>
        <div className="tabs-wrap">
          <TabView scrollable>
            <TabPanel header={t("record.new")}>
              <div className="flex flex-wrap md:flex-nowrap md:justify-content-between py-3 gap-3 w-full">
                <IconField iconPosition="left" className="w-full md:w-16rem">
                  <InputIcon className="pi pi-search"> </InputIcon>
                  <InputText
                    id="searchCommittee"
                    className="w-full"
                    placeholder={t("record.searchCommittee")}
                    aria-label={t("record.searchCommittee")}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </IconField>
                {/* <Dropdown
                  inputId="myCommittees"
                  value={selectedOption}
                  onChange={(e: DropdownChangeEvent) =>
                    setSelectedOption(e.value)
                  }
                  options={options}
                  optionLabel="name"
                  placeholder={t("record.myCommittees")}
                  className="w-full md:w-16rem ml-auto"
                  aria-label={t("record.myCommittees")}
                /> */}
                <div className="flex gap-3 w-full md:w-auto align-items-center">
                  {userRole === "Staff" ? (
                    <label
                      htmlFor="allRecordsFilter"
                      className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer flex align-items-center"
                    >
                      <Checkbox
                        inputId="allRecordsFilter"
                        onChange={handleCheckboxChange("allViewRecords")}
                        checked={allRecordsFilter}
                        className="mr-2"
                        tabIndex={0}
                      />
                      {t("record.viewallrecords")}
                    </label>
                  ) : null}
                  <div className="relative">
                    <Button
                      className="button-md gap-1"
                      severity="secondary"
                      onClick={toggleFilterPanel}
                      aria-label={t("record.filter")}
                    >
                      <FeatherIcon name="filter" size={20} color="inherit" />
                      <span className="font-bold text-capitalize hidden md:flex">
                        {t("record.filter")}
                      </span>
                    </Button>
                    {/* OverlayPanel for Filter */}
                    <OverlayPanel
                      ref={overlayPanelRef}
                      onHide={() => setShowFilterPanel(false)}
                      appendTo={"self"}
                      dismissable
                      className="p-overlaypanel-filter left-auto md:right-0 top-100"
                      aria-labelledby="filter-heading"
                      role="dialog"
                    >
                      <div className="flex flex-column gap-1" tabIndex={0}>
                        <p
                          id="filter-heading"
                          className="text-lg font-bold m-0 p-3"
                        >
                          {t("record.filter")}
                        </p>
                        <div className="filter-wrap">
                          <div className="">
                            <label
                              htmlFor="committeeNameFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="committeeNameFilter"
                                onChange={handleCheckboxChange("committee")}
                                checked={committeeCheckboxChecked}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("record.committeeName")}
                            </label>
                            <div className="pr-3 py-2 pl-6 w-full relative">
                              <MultiSelect
                                // appendTo="self"
                                value={selectedCommittees}
                                onChange={(e) => setSelectedCommittees(e.value)}
                                options={committees}
                                optionLabel="Name"
                                filter
                                placeholder={t("record.committeeName")}
                                maxSelectedLabels={3}
                                className="w-full"
                                aria-label={t("record.committeeName")}
                              />
                            </div>
                          </div>
                          <div className="p-0">
                            <label
                              htmlFor="categoryOfInterestFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="categoryOfInterestFilter"
                                onChange={handleCheckboxChange("coi")}
                                checked={coiCheckboxChecked}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("record.categoryOfInterest")}
                            </label>
                            <div className="pr-3 py-2 pl-6 w-full relative">
                              <MultiSelect
                                // appendTo="self"
                                value={selectedCOIs}
                                onChange={(e) => setSelectedCOIs(e.value)}
                                options={coiData}
                                optionLabel="CategoryName"
                                filter
                                placeholder={t("record.categoryOfInterest")}
                                maxSelectedLabels={3}
                                className="w-full"
                                aria-label={t("record.categoryOfInterest")}
                              />
                            </div>
                          </div>
                          <div className="p-0">
                            <label
                              htmlFor="membershipPositionFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="membershipPositionFilter"
                                onChange={handleCheckboxChange("position")}
                                checked={positionCheckboxChecked}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("record.membershipPosition")}
                            </label>
                            <div className="pr-3 py-2 pl-6 w-full relative">
                              <MultiSelect
                                // appendTo="self"
                                value={selectedPositions}
                                onChange={(e) => setSelectedPositions(e.value)}
                                options={memberPosition}
                                optionLabel="PositionName"
                                filter
                                placeholder={t("record.membershipPosition")}
                                maxSelectedLabels={3}
                                className="w-full"
                                aria-label={t("record.membershipPosition")}
                              />
                            </div>
                          </div>
                          <div className="p-0">
                            <label
                              htmlFor="recordStatusFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="recordStatusFilter"
                                onChange={handleCheckboxChange("recordStatus")}
                                checked={recordStatusCheckboxChecked}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("record.recordStatus")}
                            </label>
                            <div className="pr-3 py-2 pl-6 w-full relative">
                              <MultiSelect
                                // appendTo="self"
                                value={selectedRecordStatuses}
                                onChange={(e) =>
                                  setSelectedRecordStatuses(e.value)
                                }
                                options={recordStatus}
                                optionLabel="StatusName"
                                filter
                                placeholder={t("record.recordStatus")}
                                maxSelectedLabels={3}
                                className="w-full"
                                aria-label={t("record.recordStatus")}
                              />
                            </div>
                          </div>
                          <div className="p-0">
                            <label
                              htmlFor="requestDateFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="requestDateFilter"
                                onChange={handleCheckboxChange("date")}
                                checked={dateCheckboxChecked}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("record.requestDate")}
                            </label>
                            <div className="flex flex-column gap-3 p-3 pl-6 w-full">
                              <Calendar
                                className="custom-datepicker"
                                inputId="dateActivationFrom"
                                onChange={(e) =>
                                  handleDateSelect(
                                    e.value as Date,
                                    "dateActivationFrom"
                                  )
                                }
                                value={dateActivationFrom}
                                dateFormat="mm/dd/yy"
                                tabIndex={0}
                                ariaLabel={t("record.fromDate")}
                                placeholder={t("record.fromDate")}
                              />
                              <Calendar
                                className="custom-datepicker"
                                inputId="dateActivationTo"
                                onChange={(e) =>
                                  handleDateSelect(
                                    e.value as Date,
                                    "dateActivationTo"
                                  )
                                }
                                value={dateActivationTo}
                                dateFormat="mm/dd/yy"
                                tabIndex={0}
                                ariaLabel={t("record.toDate")}
                                placeholder={t("record.toDate")}
                              />
                              {toDateError && (
                                <div className="p-error font-bold">
                                  {toDateError}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <Button
                          className="button-md w-full justify-content-center"
                          onClick={(e: any) => fetchFilteredData(e)}
                        >
                          {t("record.applyFilter")}
                        </Button>
                      </div>
                    </OverlayPanel>
                  </div>
                  {userRole !== "Member" && (
                    <Button
                      className="button-md gap-1 justify-content-center w-full md:w-auto"
                      onClick={() => navigate("/record/volunteer")}
                    >
                      <FeatherIcon name="plus" size={20} color="inherit" />
                      <span className="font-bold">{t("record.newRecord")}</span>
                    </Button>
                  )}
                </div>
              </div>

              <Table
                loading={loading}
                products={
                  isFilterApplied
                    ? filteredRecords?.filter((item: any) => {
                        const committeeName = item.name || "";
                        const Id = item.recordId || "";
                        return (
                          search === "" ||
                          Id.toLowerCase().includes(search.toLowerCase()) ||
                          committeeName
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        );
                      })
                    : recordList?.filter((item: any) => {
                        const committeeName = item.name || "";
                        const Id = item.recordId || "";
                        return (
                          search === "" ||
                          Id.toLowerCase().includes(search.toLowerCase()) ||
                          committeeName
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        );
                      })
                }
                columns={[
                  {
                    field: "recordId",
                    header: t("record.recordId"),
                    link: "/record/record-preview",
                  },
                  {
                    field: "name",
                    header: t("record.memberName"),
                  },
                  {
                    field: "committeeName",
                    header: t("record.committeeApplied"),
                  },
                  {
                    field: "recordTypeName",
                    header: t("record.recordSubType"),
                  },
                  {
                    field: "membershipPosition",
                    header: t("record.position"),
                  },
                  {
                    field: "categoryName",
                    header: t("record.categoryOfInterest"),
                  },
                  {
                    field: "CreatedDate",
                    header: t("record.createdOn"),
                    sortable: true,
                  },
                  {
                    field: "statusName",
                    header: t("record.status"),
                  },
                  {
                    field: "action",
                    header: t("record.action"),
                    sortable: false,
                  },
                ]}
                actionItems={actionItems}
                showCheckbox={false}
              />
            </TabPanel>
            <TabPanel header={t("record.reappointments")}>
              {t("record.reappointments")}
            </TabPanel>
            <TabPanel header={t("record.termination")}>
              <div className="flex py-3 gap-3 w-full">
                <Button
                  className="ml-auto button-md gap-1 w-auto"
                  onClick={newTermination}
                >
                  <FeatherIcon name="plus" size={20} color="inherit" />
                  <span className="font-bold">{t("record.newTerminte")}</span>
                </Button>
              </div>
              <Table
                loading={loading}
                products={terminatedRecords}
                columns={[
                  {
                    field: "recordId",
                    header: t("record.recordId"),
                    link: "/record/record-preview",
                  },
                  {
                    field: "name",
                    header: t("record.name"),
                  },
                  {
                    field: "committeeName",
                    header: t("record.committeeName"),
                  },
                  {
                    field: "recordTypeName",
                    header: t("record.recordSubType"),
                  },
                  {
                    field: "membershipPosition",
                    header: t("record.position"),
                  },
                  {
                    field: "categoryName",
                    header: t("record.categoryOfInterest"),
                  },
                  { field: "CreatedDate", header: t("record.createdOn") },
                  {
                    field: "statusName",
                    header: t("record.status"),
                  },
                  {
                    field: "action",
                    header: t("record.action"),
                    sortable: false,
                  },
                ]}
                actionItems={actionItems}
                showCheckbox={true}
              />
            </TabPanel>
            <TabPanel header={t("record.resignation")}>
              <RecordResignation />
            </TabPanel>
            <TabPanel header={t("record.all")}>
              <div className="flex flex-wrap md:flex-nowrap md:justify-content-between py-3 gap-3 w-full">
                <IconField iconPosition="left" className="w-full md:w-16rem">
                  <InputIcon className="pi pi-search"> </InputIcon>
                  <InputText
                    id="searchCommittee1"
                    className="w-full"
                    placeholder={t("record.searchCommittee")}
                    aria-label={t("record.searchCommittee")}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </IconField>
                <div className="flex gap-3 w-auto align-items-center">
                  <div className="relative">
                    <Button
                      className="button-md gap-1"
                      severity="secondary"
                      onClick={toggleFilterPanel}
                      aria-label={t("record.filter")}
                    >
                      <FeatherIcon name="filter" size={20} color="inherit" />
                      <span className="font-bold text-capitalize hidden md:flex">
                        {t("record.filter")}
                      </span>
                    </Button>
                    {/* OverlayPanel for Filter */}
                    <OverlayPanel
                      ref={overlayPanelRef}
                      onHide={() => setShowFilterPanel(false)}
                      appendTo={"self"}
                      dismissable
                      className="p-overlaypanel-filter left-auto right-0 top-100"
                      aria-labelledby="filter-heading"
                      role="dialog"
                    >
                      <div className="flex flex-column gap-1" tabIndex={0}>
                        <p
                          id="filter-heading"
                          className="text-lg font-bold m-0 p-3"
                        >
                          {t("record.filter")}
                        </p>
                        <div className="filter-wrap">
                          <div className="">
                            <label
                              htmlFor="committeeNameFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="committeeNameFilter"
                                onChange={handleCheckboxChangeForAll(
                                  "committee"
                                )}
                                checked={committeeCheckboxCheckedForAll}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("record.committeeName")}
                            </label>
                            <div className="pr-3 py-2 pl-6 w-full relative">
                              <MultiSelect
                                // appendTo="self"
                                value={selectedCommitteesForAll}
                                onChange={(e) =>
                                  setSelectedCommitteesForAll(e.value)
                                }
                                options={committees}
                                optionLabel="Name"
                                filter
                                placeholder={t("record.committeeName")}
                                aria-label={t("record.committeeName")}
                                maxSelectedLabels={3}
                                className="w-full"
                              />
                            </div>
                          </div>
                          <div className="p-0">
                            <label
                              htmlFor="categoryOfInterestFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="categoryOfInterestFilter"
                                onChange={handleCheckboxChangeForAll("coi")}
                                checked={coiCheckboxCheckedForAll}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("record.categoryOfInterest")}
                            </label>
                            <div className="pr-3 py-2 pl-6 w-full relative">
                              <MultiSelect
                                // appendTo="self"
                                value={selectedCOIsForAll}
                                onChange={(e) => setSelectedCOIsForAll(e.value)}
                                options={coiData}
                                optionLabel="CategoryName"
                                filter
                                placeholder={t("record.categoryOfInterest")}
                                maxSelectedLabels={3}
                                className="w-full"
                                aria-label={t("record.categoryOfInterest")}
                              />
                            </div>
                          </div>
                          <div className="p-0">
                            <label
                              htmlFor="membershipPositionFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="membershipPositionFilter"
                                onChange={handleCheckboxChangeForAll(
                                  "position"
                                )}
                                checked={positionCheckboxCheckedForAll}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("record.membershipPosition")}
                            </label>
                            <div className="pr-3 py-2 pl-6 w-full relative">
                              <MultiSelect
                                // appendTo="self"
                                value={selectedPositionsForAll}
                                onChange={(e) =>
                                  setSelectedPositionsForAll(e.value)
                                }
                                options={memberPosition}
                                optionLabel="PositionName"
                                filter
                                placeholder={t("record.membershipPosition")}
                                maxSelectedLabels={3}
                                className="w-full"
                                aria-label={t("record.membershipPosition")}
                              />
                            </div>
                          </div>
                          <div className="p-0">
                            <label
                              htmlFor="recordStatusFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="recordStatusFilter"
                                onChange={handleCheckboxChangeForAll(
                                  "recordStatus"
                                )}
                                checked={recordStatusCheckboxCheckedForAll}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("record.recordStatus")}
                            </label>
                            <div className="pr-3 py-2 pl-6 w-full relative">
                              <MultiSelect
                                // appendTo="self"
                                value={selectedRecordStatusesForAll}
                                onChange={(e) =>
                                  setSelectedRecordStatusesForAll(e.value)
                                }
                                options={recordStatus}
                                optionLabel="StatusName"
                                filter
                                placeholder={t("record.recordStatus")}
                                maxSelectedLabels={3}
                                className="w-full"
                                aria-label={t("record.recordStatus")}
                              />
                            </div>
                          </div>
                          <div className="p-0">
                            <label
                              htmlFor="requestDateFilter"
                              className="p-checkbox-label font-normal block px-3 py-2 cursor-pointer"
                            >
                              <Checkbox
                                inputId="requestDateFilter"
                                onChange={handleCheckboxChangeForAll("date")}
                                checked={dateCheckboxCheckedForAll}
                                className="mr-2"
                                tabIndex={0}
                              />
                              {t("record.requestDate")}
                            </label>
                            <div className="flex flex-column gap-3 p-3 pl-6 w-full">
                              <Calendar
                                className="custom-datepicker"
                                inputId="dateActivationFromForAll"
                                onChange={(e) =>
                                  handleDateSelectForAll(
                                    e.value as Date,
                                    "dateActivationFromForAll"
                                  )
                                }
                                value={dateActivationFromForAll}
                                dateFormat="mm/dd/yy"
                                tabIndex={0}
                                ariaLabel={t("record.fromDate")}
                                placeholder={t("record.fromDate")}
                              />
                              <Calendar
                                className="custom-datepicker"
                                inputId="dateActivationToForAll"
                                onChange={(e) =>
                                  handleDateSelectForAll(
                                    e.value as Date,
                                    "dateActivationToForAll"
                                  )
                                }
                                value={dateActivationToForAll}
                                dateFormat="mm/dd/yy"
                                tabIndex={0}
                                ariaLabel={t("record.toDate")}
                                placeholder={t("record.toDate")}
                              />
                              {toDateErrorForAll && (
                                <div className="p-error font-bold">
                                  {toDateErrorForAll}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <Button
                          className="button-md w-full justify-content-center"
                          onClick={(e: any) => fetchFilteredDataForAll(e)}
                        >
                          {t("record.applyFilter")}
                        </Button>
                      </div>
                    </OverlayPanel>
                  </div>
                </div>
              </div>
              <Table
                loading={loading}
                products={
                  isFilterAppliedYes
                    ? filteredRecordsForAll?.filter((item: any) => {
                        const committeeName = item.name || "";
                        const Id = item.recordId || "";
                        return (
                          searchvalue === "" ||
                          Id.toLowerCase().includes(
                            searchvalue.toLowerCase()
                          ) ||
                          committeeName
                            .toLowerCase()
                            .includes(searchvalue.toLowerCase())
                        );
                      })
                    : recordListForAll?.filter((item: any) => {
                        const committeeName = item.name || "";
                        const Id = item.recordId || "";
                        return (
                          searchvalue === "" ||
                          Id.toLowerCase().includes(
                            searchvalue.toLowerCase()
                          ) ||
                          committeeName
                            .toLowerCase()
                            .includes(searchvalue.toLowerCase())
                        );
                      })
                }
                columns={[
                  {
                    field: "recordId",
                    header: t("record.recordId"),
                    link: "/record/record-preview",
                  },
                  {
                    field: "name",
                    header: t("record.name"),
                  },
                  {
                    field: "committeeName",
                    header: t("record.committeeApplied"),
                  },
                  {
                    field: "recordTypeName",
                    header: t("record.recordSubType"),
                  },
                  {
                    field: "membershipPosition",
                    header: t("record.position"),
                  },
                  {
                    field: "categoryName",
                    header: t("record.categoryOfInterest"),
                  },
                  { field: "CreatedDate", header: t("record.createdOn") },
                  {
                    field: "statusName",
                    header: t("record.status"),
                  },
                  {
                    field: "action",
                    header: t("record.action"),
                    sortable: false,
                  },
                ]}
                actionItems={actionItems}
                showCheckbox={true}
              />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
};

export default RecordListing;
