import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Committee } from './ballotCommitte';
import { fetchCommittees } from './common/utils';
import { ballotCommittees } from '../../slices/ballotCommittees';
import { BALLOT_RECORD_OUT_FOR_BALLOT, BALLOT_REVIEW_AND_COMMENT } from './common/constants';
import { useLanguageContext } from '../LanguageContext';

const HierarchyTableData: any = (props: any) => {
    const [t, i18n] = useTranslation("ballot");
    const { selectedLanguage } = useLanguageContext();
    const performAction = () => {
        i18n.changeLanguage(selectedLanguage);
    };
    React.useEffect(() => {
        performAction();
    }, [selectedLanguage]);
    const dispatch = useDispatch();
    const [nodes, setNodes] = useState<Committee[]>([]);
    const [selectedNodeKey, setSelectedNodeKey] = useState<string>('');
    const { isEdit } = useSelector((state: any) => state.ballotSelectRecords);
    const { ballotRecordStatusList, ballotDetailsInfo } = useSelector((state: any) => state.ballotDetails);
    const { primaryCommitteSelect, ballotTypeSelect, ballotLevelSelect } = useSelector((state: any) => state.ballotSelectRecords.selectedRecordValues);
    let ballotId = ballotDetailsInfo?.id;
    let disableColumn = ballotTypeSelect?.value === BALLOT_REVIEW_AND_COMMENT;
    const { ballotCommitteeData, updatedCommitteeData } = useSelector((state: any) => state.ballotCommittee);
    let ballotRecordStatusId = ballotRecordStatusList[BALLOT_RECORD_OUT_FOR_BALLOT]?.id;
    const fetchAllCommitteeData = async () => {
        const committeeTableData = await fetchCommittees(ballotId, ballotCommitteeData, disableColumn, ballotLevelSelect, ballotRecordStatusId, primaryCommitteSelect?.id);
        setNodes(committeeTableData)
    }
    useEffect(() => {
        fetchAllCommitteeData()
    }, [ballotLevelSelect])
    const payloadHandler = async (rowData: any, field: any, isChecked: boolean) => {
        if (ballotId && isEdit) {
            const matchedObj = ballotCommitteeData.find((committee: any) => committee.CommitteeName === rowData.data.Name)

            if (matchedObj) {
                // uncheck both 
                if (isChecked && !matchedObj.ForReviewAndComment && field === 'balloting') {
                    dispatch(ballotCommittees.updatedCommitteeData({ value: matchedObj.CommitteeId, label: 'delete', isReview: false }))
                }
                if (isChecked && matchedObj.ForReviewAndComment && field === 'review') {
                    dispatch(ballotCommittees.updatedCommitteeData({ value: matchedObj.CommitteeId, label: 'delete', isReview: true }))
                }
                // Update either
                if (!isChecked && field === 'review') {
                    dispatch(ballotCommittees.updatedCommitteeData({ value: matchedObj.CommitteeId, label: 'update', isReview: true }))
                }
                if (!isChecked && field === 'balloting') {
                    dispatch(ballotCommittees.updatedCommitteeData({ value: matchedObj.CommitteeId, label: 'update', isReview: false }))
                }
            } else {
                const formattedData = {
                    isDirty: false,
                    isNew: true,
                    ballotId: rowData.data.ballotId,
                    committeeId: rowData.data.committeeId,
                    ballotRecordStatusId: rowData.data.ballotRecordStatusId,
                    forReviewAndComment: field === 'review' ? true : false,
                    ballotCommitteeId: rowData.data.ballotCommitteeId,
                    isActive: true,
                    isDeleted: false
                }
                dispatch(ballotCommittees.updatedCommitteeData({ value: formattedData, label: 'new' }))
            }
        } else {
            const formattedData = {
                isDirty: false,
                isNew: true,
                ballotId: rowData.data.ballotId,
                committeeId: rowData.data.committeeId,
                ballotRecordStatusId: rowData.data.ballotRecordStatusId,
                forReviewAndComment: field === 'review' ? true : false,
                ballotCommitteeId: rowData.data.ballotCommitteeId,
                isActive: true,
                isDeleted: false
            }
            dispatch(ballotCommittees.updatedCommitteeData({ value: formattedData, label: 'new' }))
        }
    }
    const handleCheckboxChange = (nodeKey: string, field: keyof Committee['data'], rowData: any, isChecked: boolean) => {
        const updatedNodes = updateNodes(nodes, nodeKey, field);
        setSelectedNodeKey(nodeKey);
        setNodes(updatedNodes);
        payloadHandler(rowData, field, isChecked)
    };

    const updateNodes = (nodes: any[], nodeKey: string, field: keyof Committee['data']) => {
        return nodes.map((node: any) => {
            if (node.key === nodeKey) {
                const newData = { ...node.data, [field]: !node.data[field] };
                if (field === 'balloting' && newData[field]) {
                    newData['review'] = false;
                } else if (field === 'review' && newData[field]) {
                    newData['balloting'] = false;
                }
                return {
                    ...node,
                    data: newData,
                };
            }
            if (node.children && node.children.length > 0) {
                const updatedChildren: any = updateNodes(node.children, nodeKey, field);
                if (updatedChildren !== node.children) {
                    return {
                        ...node,
                        children: updatedChildren,
                    };
                }
            }
            return node;
        });
    };

    const checkboxBodyTemplate = (rowData: any, field: keyof Committee['data']) => {
        const isChecked = rowData.data ? rowData.data[field] : false;
        const isDisabled = rowData.data.Id === primaryCommitteSelect?.id;
        return (
            <Checkbox
                checked={isChecked}
                onChange={() => {
                    if (isDisabled || disableColumn && field === 'balloting') return
                    handleCheckboxChange(rowData.key, field, rowData, isChecked)
                }}
            />
        );
    };
    const updateCommitteFunction = (rowData: any) => {
        const formattedData = {
            isDirty: false,
            isNew: true,
            ballotId: rowData.data.ballotId,
            committeeId: rowData.data.committeeId,
            ballotRecordStatusId: rowData.data.ballotRecordStatusId,
            forReviewAndComment: false,
            ballotCommitteeId: rowData.data.ballotCommitteeId,
            isActive: true,
            isDeleted: false
        }
        dispatch(ballotCommittees.updatedCommitteeData({ value: formattedData, label: 'new' }))
    };
    const checkNodeAndChildren = (node: any, matchId: string) => {
        if (node.data.Id === matchId) {
            updateCommitteFunction(node);
        }
        if (node.children && node.children.length > 0) {
            node.children.forEach((childNode: any) => {
                checkNodeAndChildren(childNode, matchId);
            });
        }
    };
    const checkAllNodes = (nodes: any[], matchId: string) => {
        nodes.forEach((node) => {
            checkNodeAndChildren(node, matchId);
        });
    };
    useEffect(() => {
        const matchId = primaryCommitteSelect?.id;
        checkAllNodes(nodes, matchId);
    }, [nodes]);
    return (
        <div className="card">
            <TreeTable value={nodes} tableStyle={{ minWidth: '50rem' }} paginator rows={10}>
                <Column field="Name" header={t("ballot.committeeName")} expander sortable={true} style={{ width: '60%' }} />
                <Column field="Count" header={t("ballot.members")} sortable={true} />
                <Column field="balloting" header={t("ballot.forBalloting")} style={{ pointerEvents: disableColumn ? 'none' : 'auto', background: disableColumn ? '#bababa' : 'transparent' }} body={(rowData) => checkboxBodyTemplate(rowData, 'balloting')} />
                <Column field="review" header={t("ballot.forReviewcomments")} body={(rowData) => checkboxBodyTemplate(rowData, 'review')} />
            </TreeTable>
        </div>
    );
};

export default HierarchyTableData;
