import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'primereact/dropdown';
import Table from '../../common/Table';

interface BallotReportRecordProps {
  recordLists: any[];
  isBallotClosure: boolean;
  recordStatusOptions?: {value: string, name: string}[] | [];
  selectedStatus?: {recordId: string, recordStatusId: string} [] | [];
  handleStatusChange?: (recordId: string, value: any) => void;
  textSize?: string;
  isPrint?: boolean,
  oldStatusFalse?: boolean
}

const BallotReportRecord: React.FC<BallotReportRecordProps> = ({
  recordLists,
  isBallotClosure,
  recordStatusOptions,
  selectedStatus = [],
  handleStatusChange,
  isPrint = false,
  oldStatusFalse = true
}) => {
    const [t] = useTranslation("ballot");
    const getRecordStatusId = (recordId: string) => {
      if(selectedStatus.length > 0) {
        const status = selectedStatus.find((status: {recordId: string, recordStatusId: string }) => status.recordId === recordId);
        return status ? status.recordStatusId : null;
      } else {
        return null
      }
    };

    const columns: any = [
      {
        field: 'recordNumber',
        header: t('ballot.recordId'),
        link: '/ballot/',
      },
      { field: 'committeeName', header: t('ballot.committeeName') },
    ];
    if (oldStatusFalse && !isBallotClosure) {
      columns.push({ field: 'oldStatusName', header: t('ballot.oldRecordStatus') });
    }

    if (oldStatusFalse && isBallotClosure) {
      columns.push({ field: 'currentStatusName', header: t('ballot.recordStatus') });
    }

    if(!isBallotClosure) {
      columns.push({
        field: oldStatusFalse ? 'newStatusName' : 'currentStatusName',
        header: oldStatusFalse  ? t('ballot.newRecordStatus') : t('ballot.recordStatus'),
      });
    }
   
    if (isBallotClosure && handleStatusChange) {
      columns.push({
        field: "newRecordStatus",
        header: t("ballot.newRecordStatus"),
        body: (rowData: any) => (
          <div className="card flex justify-content-center">
            <Dropdown
              value={getRecordStatusId(rowData.recordId)}  
              options={recordStatusOptions}
              optionLabel="name"
              placeholder="Select a status"
              className="w-full md:w-14rem"
              onChange={(e) => handleStatusChange(rowData.recordId, e.value)}
            />
          </div>
        ),
      });
    }
    return (
     <>
      <div className="card bg-white w-full mb-5 shadow-md">
        <div className={`flex align-items-center align-self-center  border-bottom-1 border-gray-200 gap-3 cardHeader ${isPrint ? "px-2 py-2" : "px-5 py-4"}`} >
          <h2 className="text-title text-lg font-bold text-capitalize m-0">
            {t("ballot.recordStatus")}
          </h2>
        </div>
        <div className="p-5 cardBody">
          <Table isPrint={isPrint} loading={false} products={recordLists} columns={columns} disablePagination={true} />
        </div>
      </div>
     </>
    )
}

export default BallotReportRecord;