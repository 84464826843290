import React, { useEffect, useRef, useState, useCallback } from "react";
import FeatherIcon from "../common/FeatherIconComponent";
import { Button } from "primereact/button";
import CommitteeSteps from "./CommitteeSteps";
import { InputText } from "primereact/inputtext";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import {
  Link,
  unstable_usePrompt,
  useBlocker,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { fetchData, postData, putData } from "../../services/apiService";
import {
  formatCommitteeCharterData,
  formatCongigurationCommitteeData,
  formatCreateCommitteeData,
  getCommitteeData,
  setOptionsFromResponse,
} from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  addMembersInCommitteeRequest,
  categoryOfInteresetRequest,
  configureCommitteeRequest,
  createCommitteeRequest,
} from "../../slices/createCommitteeSlice";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { Dialog } from "primereact/dialog";
import { focusOnErrorField } from "../../utils/focusError";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Toast } from "primereact/toast";
import { MultiSelect } from "primereact/multiselect";
import LoadingOverlay from "../common/LoadingOverlay";

interface Committee {
  committeeName: string;
  committeeAbbreviation: string;
  committeeType: string;
  parentCommittee: string;
  hierarchyLevel: string;
  activationDate?: Date;
  charterName?: string;
  charterStatus: string;
  recordId: string;
  redirect?: string;
  committeeId?: string;
  staffContact: string;
  secondaryStaffContact: Option[];
}

interface Option {
  name: string;
  id: string;
}

interface StaffType {
  Type: string;
  Description: string;
  Id: string;
  TenantId: string;
  Active: boolean;
  Deleted: boolean;
  CreatedBy: string;
  ModifiedBy: string | null;
  CreatedDate: string;
  ModifiedDate: string | null;
  IsDirty: boolean;
  IsNew: boolean;
  Success: boolean;
  SysMessages: string | null;
  SysMessagesKey: string | null;
  SysMessagesType: string | null;
}

interface StaffTypeResponse {
  Collection: StaffType[];
  TotalCount: number;
}
const CreateCommittee: React.FC = () => {
  const [t, i18n] = useTranslation("committee");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [committee, setCommitte] = useState<Committee>({
    committeeName: "",
    committeeAbbreviation: "",
    committeeType: "",
    parentCommittee: "",
    hierarchyLevel: "",
    activationDate: undefined,
    committeeId: "",
    charterStatus: "",
    recordId: "",
    staffContact: "",
    secondaryStaffContact: [],
  });

  const breadcumbItems = [
    { label: t("committee.dashboard"), url: "/dashboard" },
    { label: t("committee.committee"), url: "/committee" },
    { label: t("committee.createNewCommittee") },
  ];
  const intialCreateCommitteeLableForValidation = [
    { name: "committeeName", lable: t("committee.committeeName") },
    {
      name: "committeeAbbreviation",
      lable: t("committee.committeeAbbreviation"),
    },
    { name: "committeeType", lable: t("committee.committeeSubType") },
    { name: "parentCommittee", lable: t("committee.parentCommittee") },
    { name: "hierarchyLevel", lable: t("committee.committeeType") },
    { name: "activationDate", lable: t("committee.activationDate") },
    { name: "staffContact", lable: t("committee.staffContact") },
  ];
  const [
    createCommitteeLableForValidation,
    setCreateCommitteeLableForValidation,
  ] = useState(intialCreateCommitteeLableForValidation);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const createCommitteeData = useSelector(
    (state: RootState) => state.createCommittee
  );

  const [checked, setChecked] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const [hierarchyLevelOption, setHierarchyLevelOption] = useState<
    Option[] | undefined
  >(undefined);
  const [committeeTypeOption, setCommitteeTypeOption] = useState<
    Option[] | undefined
  >(undefined);
  const [charterStatusOption, setCharterStatusOption] = useState<
    Option[] | undefined
  >(undefined);
  const [parentCommitteeOption, setParentCommitteeOption] = useState<
    Option[] | undefined
  >(undefined);
  const [staffContactOption, setStaffContactOption] = useState<
    Option[] | undefined
  >(undefined);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(true);
  const loction = useLocation();

  const [error, setError] = useState<Partial<Committee>>({});
  const [filteredSecondaryStaffOptions, setFilteredSecondaryStaffOptions] =
    useState<Option[]>([]);
  const [filteredPrimaryStaffOptions, setFilteredPrimaryStaffOptions] =
    useState<Option[]>([]);
  const [isLoading, setIsLoding] = useState(true);
  const [isReduxData, setIsReduxData] = useState(false);
  const percent = 70; // Set your percentage here
  const [editModeForCommitte, setEditModeForCommitte] = useState(false);
  const [saveAsDraft, setSaveAsDraft] = useState(false);
  // Define the style object as a regular JavaScript object
  const circleStyle = {
    "--percent": `${percent}`, // Set the percentage as a custom CSS variable
  };
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const handleRouteChanges = () => {
    if (unsavedChanges) {
      setVisibleDialog(true);
      return false;
    }
    return true;
  };

  const [isDirty, setIsDirty] = useState<boolean>(false);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isDirty) {
        const confirmationMessage = t("committee.unsavedChangeMsg");
        event.returnValue = confirmationMessage; // For older browsers
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty]);

  useEffect(() => {
    const unlisten = () => {
      window.removeEventListener("beforeunload", handleRouteChanges);
    };
    window.addEventListener("beforeunload", handleRouteChanges);
    return unlisten;
  }, [handleRouteChanges]);
  const handleInputChange = (e: any) => {
    setError({});
    const { name, value } = e.target;
    setCommitte((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsDirty(true);
  };

  const fetchAllStaff = async (): Promise<string | null> => {
    try {
      const response = await fetchData("StaffType/GetAll");
      const staffData = (response as StaffTypeResponse).Collection;
      const secondaryStaffType = staffData.find(
        (staff: StaffType) => staff.Type === "Secondary"
      );
      return secondaryStaffType ? secondaryStaffType.Id : null;
    } catch (error) {
      console.error("Error fetching staff types:", error);
      return null;
    }
  };

  const handleCommitteeDropdownOptions = async () => {
    try {
      setLoading(true);
      const [
        HierarchyLevelResponse,
        CommitteeTypeResponse,
        CharterStatusResponse,
        ParentCommitteeResponse,
        StaffPersonResponse,
      ] = await Promise.all([
        fetchData("HierarchyLevel/GetAll"),
        fetchData("CommitteeType/GetAll"),
        fetchData("CharterStatus/GetAll"),
        fetchData("Committee/GetAll"),
        fetchData("UserProfile/SearchStaffPerson"),
      ]);
      const CreatedParentCommitteeResponse =
        ParentCommitteeResponse.Collection.filter(
          (committee: any) => committee.Status.Name === "Created"
        );
      const HierarchyLevelObject: Option[] = [
        ...setOptionsFromResponse(HierarchyLevelResponse, "Name"),
      ];
      const CommitteeTypes: Option[] = [
        ...setOptionsFromResponse(CommitteeTypeResponse, "TypeName"),
      ];
      const CommitteeTypeObject: Option[] = CommitteeTypes.filter(
        (option) => option.name === "Standard Committee"
      );
      const CharterStatusObject: Option[] = [
        ...setOptionsFromResponse(CharterStatusResponse, "StatusName"),
      ];
      const ParentCommitteeObject: Option[] = [
        ...setOptionsFromResponse(CreatedParentCommitteeResponse, "Name", true),
      ];
      const StaffContactObject: Option[] = [
        ...setOptionsFromResponse(StaffPersonResponse, "Email"),
      ];
      let currentCommitteeID = sessionStorage.getItem("committeeId");
      const filteredParentCommitteeObject = ParentCommitteeObject.filter(
        (options: any) => options.id !== currentCommitteeID
      );
      setHierarchyLevelOption(HierarchyLevelObject);
      setCommitteeTypeOption(CommitteeTypeObject);
      setCharterStatusOption(CharterStatusObject);
      setParentCommitteeOption(filteredParentCommitteeObject);
      setStaffContactOption(StaffContactObject);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrimaryStaffChange = useCallback(
    (e: any) => {
      const selectedPrimaryStaff = e.value;
      handleInputChange(e); // Update form state

      // Filter secondary staff options to exclude selected primary staff
      const updatedSecondaryStaffOptions = staffContactOption?.filter(
        (staff) => staff.id !== selectedPrimaryStaff?.id
      );
      setFilteredSecondaryStaffOptions(updatedSecondaryStaffOptions || []);
    },
    [staffContactOption, handleInputChange]
  );

  const handleSecondaryStaffChange = useCallback(
    (e: any) => {
      const selectedSecondaryStaff = e.value;
      handleInputChange(e);

      const updatedPrimaryStaffOptions = staffContactOption?.filter(
        (staff) =>
          !selectedSecondaryStaff?.some(
            (secondaryStaff: Option) => secondaryStaff.id === staff.id
          )
      );
      setFilteredPrimaryStaffOptions(updatedPrimaryStaffOptions || []);
    },
    [staffContactOption, handleInputChange]
  );

  useEffect(() => {
    setFilteredPrimaryStaffOptions(staffContactOption || []);
    setFilteredSecondaryStaffOptions(staffContactOption || []);
  }, [staffContactOption]);

  useEffect(() => {
    setFilteredSecondaryStaffOptions(staffContactOption || []);
  }, [staffContactOption]);

  const initialCharterValidation = [
    { name: "charterStatus", lable: t("committee.charterStatus") },
    { name: "recordId", lable: t("committee.recordId") },
    { name: "charterName", lable: t("committee.committeeCharter") },
  ];

  const addCommitteeCharter = async (committeeId: string) => {
    const charterStatusRequest = formatCommitteeCharterData(
      committee.charterStatus,
      committeeId,
      committee.recordId,
      committee.charterName
    );
    const addCharterStatusResponse = await postData(
      "CommitteeCharter",
      charterStatusRequest
    );
    const charterStatusParse = JSON.parse(addCharterStatusResponse.content);
    setCommitte((prevData) => ({
      ...prevData,
      committeeCharter: charterStatusParse?.Id,
    }));
  };

  const errorMessageToast = (msg: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: msg,
      life: 3000,
    });
  };

  const handleSaveAndContinue = async (
    event: any,
    isDraft: string = "false"
  ) => {
    if (isDraft === "saveAsDraft") {
      setSaveAsDraft(true);
    }
    event.preventDefault();
    let committeeId: string | null = sessionStorage.getItem("committeeId");

    let validatationLabels = [...intialCreateCommitteeLableForValidation];

    if (
      committee.charterStatus !== "" ||
      committee.recordId !== "" ||
      committee.charterName
    ) {
      validatationLabels = [
        ...intialCreateCommitteeLableForValidation,
        ...initialCharterValidation,
      ];
    }

    let isValid = validatationLabels
      .map((data: any) =>
        validationForCommitteeCreation(committee, data.name, data.lable)
      )
      .every((val: any) => val === true);
    if (isValid) {
      if (!createCommitteeData.createCommittee.committeeId && !committeeId) {
        const createCommitteeRequest =
          await formatCreateCommitteeData(committee);
        try {
          const createCommitteeResponse = await postData(
            "Committee",
            createCommitteeRequest
          );
          if (committee.charterStatus) {
            await addCommitteeCharter(createCommitteeResponse.Id);
          }
          setCommitte((prevData) => ({
            ...prevData,
            committeeId: createCommitteeResponse.Id,
          }));
          committeeId = createCommitteeResponse.Id;
          if (committeeId) {
            sessionStorage.setItem("committeeId", committeeId);
          }
        } catch (error: any) {
          if (
            error.response?.data?.errors?.requestModel[0]?.includes(
              "limit has reached"
            )
          ) {
            errorMessageToast(t("committee.allowedMemberLimitReached"));
          }
          if (
            error.response?.data?.messages?.includes(
              "Committee already exists."
            )
          ) {
            errorMessageToast(t("committee.committeeExist"));
          }
        }
      } else {
        let status = false;
        const today = new Date();
        if (
          createCommitteeData.createCommittee.activationDate > today &&
          createCommitteeData.createCommittee.status == "Draft"
        ) {
          status = true;
        } else if (
          createCommitteeData.createCommittee.activationDate > today &&
          createCommitteeData.createCommittee.status == "Created"
        ) {
          status = true;
        }

        const committeeRequest = await formatCongigurationCommitteeData(
          createCommitteeData.configureCommittee,
          committee,
          status
        );
        const committeeResponse = await putData(
          "Committee",
          createCommitteeData.createCommittee.committeeId,
          committeeRequest
        );
        if (
          committee.charterStatus &&
          createCommitteeData.createCommittee.committeeCharter
        ) {
          const charterStatusRequest = formatCommitteeCharterData(
            committee.charterStatus,
            createCommitteeData.createCommittee.committeeId,
            committee.recordId,
            committee.charterName,
            false
          );
          const editCharterStatusResponse = await putData(
            "CommitteeCharter",
            createCommitteeData.createCommittee.committeeCharter,
            charterStatusRequest
          );
        } else {
          if (committee.charterStatus) {
            await addCommitteeCharter(
              createCommitteeData.createCommittee.committeeId
            );
          }
        }
      }

      // Fetch the Secondary Staff Type ID
      const secondaryStaffTypeId = await fetchAllStaff();
      if (!secondaryStaffTypeId) {
        console.error("Secondary Staff Type ID not found.");
        return;
      }

      // Additional API call for CommitteeStaff/BulkUpload if secondary staff contact is provided
      if (
        committee.secondaryStaffContact &&
        committee.secondaryStaffContact.length > 0
      ) {
        if (committeeId) {
          const staffData = committee.secondaryStaffContact.map(
            (staff: Option) => ({
              isDirty: true,
              isNew: true,
              staffId: staff.id,
              committeeId: committeeId,
              staffTypeId: secondaryStaffTypeId,
              committeeStaffId: staff.id,
              isActive: true,
              isDeleted: false,
            })
          );

          try {
            await postData("CommitteeStaff/BulkUpload", staffData);
          } catch (error: any) {
            console.error("Error uploading committee staff data", error);
          }
        } else {
          console.error("Committee ID is null or undefined.");
        }
      }

      setIsDirty(false);
      setIsLoding(false);
    } else {
      setTimeout(() => {
        focusOnErrorField(".p-invalid");
      }, 100);
      console.log(error);
    }
  };

  const validationForCommitteeCreation = (
    data: any,
    name: string,
    label: string
  ) => {
    let valid = true;
    const newError: any = {};
    if (data[name] === "" || !data[name]) {
      newError[name] = `${label} ${t("committee.isRequired")}`;
      valid = false;
    }
    if (data.committeeName?.length > 50) {
      newError.committeeName = t("committee.committeeNameLength");
      valid = false;
    }
    if (data.committeeAbbreviation?.length > 10) {
      newError.committeeAbbreviation = t(
        "committee.committeeAbbreviationLength"
      );
      valid = false;
    }
    setError((prev) => ({ ...prev, ...newError }));
    return valid;
  };

  useEffect(() => {
    if (!hierarchyLevelOption) {
      handleCommitteeDropdownOptions();
    }
    handleRefershPage();
    fetchAllStaff();
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(createCommitteeData.createCommittee).length > 0) {
      let draftedData: any = createCommitteeData.createCommittee;
      setCommitte(draftedData);
    }
  }, [isReduxData]);

  const handleRefershPage = async () => {
    let committeeId = sessionStorage.getItem("committeeId");
    if (
      Object.keys(createCommitteeData.createCommittee).length < 1 &&
      committeeId
    ) {
      const [
        createCommitteeData,
        configureCommitteeData,
        committeeMemberData,
        categoryOfInterestData,
      ] = await getCommitteeData();
      dispatch(createCommitteeRequest(createCommitteeData));
      dispatch(configureCommitteeRequest(configureCommitteeData));
      dispatch(addMembersInCommitteeRequest(committeeMemberData));
      dispatch(categoryOfInteresetRequest(categoryOfInterestData));
      setIsReduxData(true);
    }
  };

  useEffect(() => {
    if (committee.committeeId && !isLoading) {
      sessionStorage.setItem("committeeId", committee.committeeId);

      dispatch(createCommitteeRequest(committee));
      if (editModeForCommitte) {
        sessionStorage.removeItem("committeeEdit");
        let committeeId = sessionStorage.getItem("committeeId");
        navigate(`/committee/details/${committeeId}`);
      } else if (saveAsDraft) {
        navigate("/committee");
      } else {
        navigate("/committee/configuration");
      }
    }
  }, [committee.committeeId, isLoading]);

  useEffect(() => {
    let editMode = sessionStorage.getItem("committeeEdit");
    if (editMode === "On") {
      setEditModeForCommitte(true);
    }
  }, []);

  const handleCancel = () => {
    let committeeId = sessionStorage.getItem("committeeId");
    navigate(`/committee/details/${committeeId}`);
  };

  return (
    <>
      <Toast ref={toast} />
      {loading && <LoadingOverlay visible={loading} />}
      <div className="">
        {!editModeForCommitte && (
          <CustomBreadcrumb
            model={breadcumbItems}
            className="bg-transparent mb-5 px-0 py-2 border-none"
          />
        )}
        <div className="mb-5 flex flex-column gap-2">
          <h1 className="text-title display-xs font-bold text-capitalize m-0">
            {editModeForCommitte
              ? t("committee.editCommittee")
              : t("committee.createNewCommittee")}
          </h1>
          {/* <p className="text-base font-normal m-0">
            Any supporting text for request listing page can go here
          </p> */}
        </div>
        {!editModeForCommitte && <CommitteeSteps activeStep={0} />}
        <form>
          <div className="card bg-white w-full mb-5 shadow-md">
            <div className="flex align-items-center align-self-center px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
              <div className="flex flex-column gap-1">
                <h2 className="text-title text-lg font-bold text-capitalize m-0">
                  {t("committee.committeeDetails")}
                </h2>
                <p className="text-base font-normal m-0 text-help">
                  {t("committee.allFieldsRequired")}
                </p>
              </div>
            </div>

            <div className="p-5 cardBody">
              <div className="flex flex-column gap-3">
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-9 col-12">
                    <label
                      htmlFor="committeeName"
                      className={`block font-bold text-capitalize ${
                        error.committeeName ? " p-error" : "text-input-label"
                      }`}
                    >
                      {t("committee.committeeName")}
                    </label>
                    <InputText
                      id="committeeName"
                      aria-describedby="committeeNameError"
                      name="committeeName"
                      onChange={handleInputChange}
                      type="text"
                      placeholder={t("committee.committeeName")}
                      className={`w-full ${
                        error.committeeName ? "p-invalid" : ""
                      }`}
                      value={committee.committeeName}
                    />
                    <span
                      id="committeeNameError"
                      className={`p-error font-bold text-capitalize ${error.committeeName ? "" : "error-hidden"}`}
                    >
                      {error.committeeName}
                    </span>
                  </div>
                </div>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="committeeAbbreviation"
                      className={`block font-bold text-capitalize ${
                        error.committeeAbbreviation
                          ? " p-error"
                          : "text-input-label"
                      }`}
                    >
                      {t("committee.committeeAbbreviation")}
                    </label>
                    <InputText
                      id="committeeAbbreviation"
                      aria-describedby="committeeAbbreviationError"
                      name="committeeAbbreviation"
                      onChange={handleInputChange}
                      type="text"
                      placeholder={t("committee.committeeAbbreviation")}
                      className={`w-full ${
                        error.committeeAbbreviation ? "p-invalid" : ""
                      }`}
                      value={committee.committeeAbbreviation}
                    />
                    <span
                      id="committeeAbbreviationError"
                      className={`p-error font-bold text-capitalize ${error.committeeAbbreviation ? "" : "error-hidden"}`}
                    >
                      {error.committeeAbbreviation}
                    </span>
                  </div>

                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="parentCommittee"
                      className={`block font-bold text-capitalize ${
                        error.parentCommittee ? " p-error" : "text-input-label"
                      }`}
                    >
                      {t("committee.parentCommittee")}
                    </label>
                    <Dropdown
                      filter
                      name="parentCommittee"
                      inputId="parentCommittee"
                      aria-describedby="parentCommitteeError"
                      value={committee.parentCommittee}
                      onChange={handleInputChange}
                      options={parentCommitteeOption}
                      optionLabel="name"
                      placeholder={t("committee.selectAnOption")}
                      className={`w-full ${
                        error.parentCommittee ? "p-invalid" : ""
                      }`}
                    />
                    <span
                      id="parentCommitteeError"
                      className={`p-error font-bold text-capitalize ${error.parentCommittee ? "" : "error-hidden"}`}
                    >
                      {error.parentCommittee}
                    </span>
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="staffContact"
                      className={`block font-bold text-capitalize ${
                        error.staffContact ? " p-error" : "text-input-label"
                      }`}
                    >
                      {t("committee.staffContact")}
                    </label>
                    <Dropdown
                      filter
                      inputId="staffContact"
                      aria-describedby="staffContactError"
                      name="staffContact"
                      value={committee.staffContact}
                      onChange={handlePrimaryStaffChange}
                      options={filteredPrimaryStaffOptions}
                      optionLabel="name"
                      placeholder={t("committee.selectAnOption")}
                      className={`w-full ${
                        error.staffContact ? "p-invalid" : ""
                      }`}
                    />
                    <span
                      id="staffContactError"
                      className={`p-error font-bold text-capitalize ${error.staffContact ? "" : "error-hidden"}`}
                    >
                      {error.staffContact}
                    </span>
                  </div>
                </div>

                <div className="grid grid-xl align-items-end">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="secondaryStaffContact"
                      className={`block font-bold text-capitalize`}
                    >
                      {t("committee.secondaryStaffContact")} (
                      {t("committee.optional")})
                    </label>
                    <MultiSelect
                      filter
                      inputId="secondaryStaffContact"
                      name="secondaryStaffContact"
                      value={committee.secondaryStaffContact}
                      onChange={handleSecondaryStaffChange}
                      options={filteredSecondaryStaffOptions}
                      optionLabel="name"
                      placeholder={t("committee.selectAnOption")}
                      className="w-full"
                    />
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="committeeHierarchy"
                      className={`block font-bold text-capitalize ${
                        error.hierarchyLevel ? " p-error" : "text-input-label"
                      }`}
                    >
                      {t("committee.committeeType")}
                    </label>
                    <Dropdown
                      inputId="committeeHierarchy"
                      aria-describedby="committeeHierarchyError"
                      name="hierarchyLevel"
                      value={committee.hierarchyLevel}
                      onChange={handleInputChange}
                      options={hierarchyLevelOption}
                      optionLabel="name"
                      placeholder={t("committee.selectAnOption")}
                      className={`w-full ${
                        error.hierarchyLevel ? "p-invalid" : ""
                      }`}
                    />
                    <span
                      id="committeeHierarchyError"
                      className={`p-error font-bold text-capitalize ${error.hierarchyLevel ? "" : "error-hidden"}`}
                    >
                      {error.hierarchyLevel}
                    </span>
                  </div>
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="dateActivation"
                      className={`block font-bold text-capitalize ${
                        error.activationDate ? " p-error" : "text-input-label"
                      }`}
                    >
                      {t("committee.dateOfActivation")}
                    </label>
                    <Calendar
                      appendTo="self"
                      inputId="dateActivation"
                      aria-describedby="dateActivationError"
                      name="activationDate"
                      minDate={new Date()}
                      placeholder={t("committee.dateOfActivation")}
                      onChange={handleInputChange}
                      // onSelect={() => focusOnField("dateActivation")}
                      value={committee.activationDate}
                      dateFormat="mm/dd/yy"
                      className={`custom-datepicker ${
                        error.activationDate ? "p-invalid" : ""
                      }`}
                    />
                    <span
                      id="dateActivationError"
                      className={`p-error font-bold text-capitalize ${error.activationDate ? "" : "error-hidden"}`}
                    >
                      {error?.activationDate?.toString()}
                    </span>
                  </div>
                </div>
                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="committeetype"
                      className={`block font-bold text-capitalize ${
                        error.committeeType ? " p-error" : "text-input-label"
                      }`}
                    >
                      {t("committee.committeeSubType")}
                    </label>
                    <Dropdown
                      inputId="committeetype"
                      aria-describedby="committeetypeError"
                      name="committeeType"
                      value={committee.committeeType}
                      onChange={handleInputChange}
                      options={committeeTypeOption}
                      optionLabel="name"
                      placeholder={t("committee.selectAnOption")}
                      className={`w-full ${
                        error.committeeType ? "p-invalid" : ""
                      }`}
                    />
                    <span
                      id="committeetypeError"
                      className={`p-error font-bold text-capitalize ${error.committeeType ? "" : "error-hidden"}`}
                    >
                      {error.committeeType}
                    </span>
                  </div>
                </div>

                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-6 lg:col-8 col-12">
                    <label
                      htmlFor="committeeCharter"
                      className={`block font-bold text-capitalize ${
                        error.charterName ? " p-error" : "text-input-label"
                      }`}
                    >
                      {t("committee.committeeCharter")} (
                      {t("committee.optional")})
                    </label>
                    <InputTextarea
                      id="committeeCharter"
                      aria-describedby="committeeCharterError"
                      name="charterName"
                      placeholder={t("committee.committeeCharter")}
                      value={committee.charterName}
                      onChange={handleInputChange}
                      rows={5}
                      cols={30}
                      className={`${error.charterName ? "p-invalid" : ""}`}
                    />
                    <span
                      id="committeeCharterError"
                      className={`p-error font-bold text-capitalize ${error.charterName ? "" : "error-hidden"}`}
                    >
                      {error.charterName}
                    </span>
                  </div>
                </div>

                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="charterstatus"
                      className={`block font-bold text-capitalize ${
                        error.charterStatus ? " p-error" : "text-input-label"
                      }`}
                    >
                      {t("committee.charterStatus")}
                    </label>
                    <Dropdown
                      inputId="charterstatus"
                      aria-describedby="charterstatusError"
                      name="charterStatus"
                      value={committee.charterStatus}
                      onChange={handleInputChange}
                      options={charterStatusOption}
                      optionLabel="name"
                      placeholder={t("committee.selectAnOption")}
                      className={`w-full ${
                        error.charterStatus ? "p-invalid" : ""
                      }`}
                    />
                    <span
                      id="charterstatusError"
                      className={`p-error font-bold text-capitalize ${error.charterStatus ? "" : "error-hidden"}`}
                    >
                      {error.charterStatus}
                    </span>
                  </div>
                </div>

                <div className="grid grid-xl">
                  <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                    <label
                      htmlFor="recordId"
                      className={`block font-bold text-capitalize ${
                        error.recordId ? " p-error" : "text-input-label"
                      }`}
                    >
                      {t("committee.recordIdForTheApprovedCharter")}
                    </label>
                    <IconField iconPosition="left" className="w-full">
                      <InputIcon className="pi pi-search"> </InputIcon>
                      <InputText
                        id="recordId"
                        name="recordId"
                        onChange={handleInputChange}
                        value={committee.recordId}
                        placeholder={t(
                          "committee.recordIdForTheApprovedCharter"
                        )}
                        className={`w-full ${error.recordId ? "p-invalid" : ""}`}
                      />
                    </IconField>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!editModeForCommitte ? (
            <>
              <div className="bg-white hidden md:flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
                <Button
                  label={t("committee.cancel")}
                  className="button-md"
                  severity="secondary"
                  type="button"
                  onClick={() => navigate("/committee/")}
                />
                <div className="flex md:flex-row gap-4">
                  <Button
                    text
                    className="p-button-plain underline"
                    label={t("committee.saveAsDraft")}
                    onClick={(e) => handleSaveAndContinue(e, "saveAsDraft")}
                  ></Button>
                  <Button
                    className="button-md gap-1"
                    severity="secondary"
                    onClick={() => navigate("/committee/")}
                  >
                    <FeatherIcon
                      name="chevron-left"
                      size={20}
                      color="inherit"
                    />
                    <span className="font-bold text-capitalize">
                      {t("committee.back")}
                    </span>
                  </Button>
                  <Button
                    type="submit"
                    className="button-md gap-1"
                    onClick={(e) => handleSaveAndContinue(e)}
                  >
                    <span className="font-bold text-capitalize">
                      {t("committee.saveContinue")}
                    </span>
                    <FeatherIcon
                      name="chevron-right"
                      size={20}
                      color="inherit"
                    />
                  </Button>
                </div>
              </div>

              {/* Mobile responsive buttions */}
              <div className="bg-white md:hidden flex flex-wrap align-items-center px-5 py-3 gap-3 justify-content-between fixed-footer w-full left-0 shadow">
                <Button
                  className="button-md gap-1 w-full justify-content-center"
                  onClick={(e) => handleSaveAndContinue(e)}
                >
                  <span className="font-bold text-capitalize">
                    {t("committee.saveContinue")}
                  </span>
                  <FeatherIcon name="chevron-right" size={20} color="inherit" />
                </Button>
                <div className="flex align-items-center gap-3 w-full">
                  <Button
                    className="button-md gap-1"
                    severity="secondary"
                    onClick={() => navigate("/committee/")}
                    aria-label={t("committee.back")}
                  >
                    <FeatherIcon
                      name="chevron-left"
                      size={20}
                      color="inherit"
                    />
                  </Button>
                  <Button
                    label={t("committee.cancel")}
                    className="button-md w-full"
                    severity="secondary"
                    onClick={() => navigate("/committee/")}
                  />
                </div>
                <Button
                  text
                  className="p-button-plain underline w-full"
                  label={t("committee.saveAsDraft")}
                  onClick={(e) => handleSaveAndContinue(e, "saveAsDraft")}
                ></Button>
              </div>
            </>
          ) : (
            <div className="bg-white flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
              <Button
                label={t("committee.cancel")}
                className="button-md"
                severity="secondary"
                onClick={handleCancel}
              />
              <Button
                className="button-md gap-1"
                onClick={(e) => handleSaveAndContinue(e)}
              >
                <span className="font-bold text-capitalize">
                  {t("committee.submit")}
                </span>
                <FeatherIcon name="chevron-right" size={20} color="inherit" />
              </Button>
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default CreateCommittee;
