import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { useLanguageContext } from "../LanguageContext";
import { postData as ballotPost } from "../../services/Ballot/apiservice";
import { fetchData as getData } from "../../services/Ballot/apiservice";
import { putData as ballotPutData } from "../../services/Ballot/apiservice";
import {
  fetchData,
  getListByParentId,
  postData,
  putData,
} from "../../services/apiService";
import TooltipButton from "../common/TooltipButton";
import BallotConfigurationForm from "./ballotConfiguration/BallotConfigurationForm";
import { BallotConfigurationType } from "./ballotConfiguration/BallotConfigurationInterfaces";
import { setToast } from "../../slices/toastSlice";


interface CommitteeConfigurationTabProps {
  onSubmitSuccess: () => void;
}

interface Option {
  name: string;
  code: string;
}

interface CommitteeDataItem {
  IsDirty: true;
  id: string;
  IsNew: true;
  IsDefaultParent: boolean;
  CreatedDate: string;
  IsNatureofCommitteeRestricted: boolean;
  IsBalanceBasedOnOverAllThreshold: boolean;
  MinOverallThreshold: number;
  MaxOverallThreshold: number;
  ThresholdPercentage: number;
  IsBalanceBasedOnCategoryofInterests: boolean;
  IsBalanceBasedOnRepresentationByOrg: boolean;
  OrganisationMinMember: number;
  OrganisationMaxMember: number;
  OrganisationMemberThreshold: number;
  IsShowCommitteemembers: boolean;
  CommitteeMinMember: number;
  CommitteeMaxMember: number;
  IsCriteriaOfmembershipEligibility: boolean;
  MSElig_SubTierCommitte_Months: number;
  MSElig_SameCommitte_Officer_Months: number;
  IsTenure_ExpirationsRules: boolean;
  MSExpire_Officer_Tenure_Years: number;
  MSExpire_Member_Tenure_Years: number;
  IsShowAllMembershipExpires: boolean;
  SendNotificationforMembershipExpiration: boolean;
  NotifyStaffExpirationofMembership: boolean;
  NotifyStaffExpirationofMembershipDays_First: number;
  NotifyStaffExpirationofMembershipDays_Second: number;
  NotifyStaffExpirationofMembershipDays_Third: number;
  NotifyMemberExpirationofMembership: boolean;
  NotifyMemberExpirationofMembershipDays_First: number;
  NotifyMemberExpirationofMembershipDays_Second: number;
  NotifyMemberExpirationofMembershipDays_Third: number;
  OfficerMaximumTermYears: number;
  ApplicationAutoWithdrawalMonths: number;
  NotifyMemberLackofParticipation: boolean;
  NumberofMeetingMissed: number;
  NumberofBallotsMissed: number;
  TotalMeetingMisssedPercentage: number;
  TotalBallotMisssedPercentage: number;
  AcceptApplicationWithoutOpenPositions: boolean;
  IsCheckBoxCheckedForDefaultParent: boolean;
  IsCheckBoxCheckedIsRestricted: boolean;
  AllowApplicationsWithoutOpenPositions: boolean;
  IsAllowMemberOtherCommitteAccess: boolean;
  IsCheckBoxCheckedForAllowMemberOtherCommitteeAccess: boolean;
}

interface CommitteeCOIItem {
  id: string;
  IsDirty: true;
  IsNew: true;
  MinMember: number;
  MaxMember: number;
  Threshold: number;
}

const CommitteeConfigurationTab: React.FC<CommitteeConfigurationTabProps> = ({
  onSubmitSuccess,
}) => {
  const [t, i18n] = useTranslation("configuration");
  const [errors, setErrors] = useState<any>({});
  const [existingData, setExistingData] = useState<CommitteeDataItem | null>(
    null
  );
  const [existingCOIData, setExistingCOIData] =
    useState<CommitteeCOIItem | null>(null);
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);

  const [
    defaultConfigurationFromParentCommittee,
    setDefaultConfigurationFromParentCommittee,
  ] = useState<string>("");

  const [
    isMemberAllowedToAccessOtherCommittee,
    setIsMemberAllowedToAccessOtherCommittee,
  ] = useState<string>("");

  const [
    defaultApplicationConfigurationFromParentCommittee,
    setDefaultApplicationConfigurationFromParentCommittee,
  ] = useState<string>("");
  const [natureOfCommittee, setNatureOfCommittee] = useState<string>("");

  const [allMembershipsExpires, setallMembershipsExpires] =
    useState<string>("");

  const navigate = useNavigate();
  const [valueBalancedThresholdMin, setValueBalancedThresholdMin] =
    useState<number>(0);
  const [valueBalancedThresholdMax, setValueBalancedThresholdMax] =
    useState<number>(0);
  const [valueBalancedThreshold, setValueBalancedThreshold] =
    useState<number>(0);
  const [valueBalancedIndividualCOIMin, setValueBalancedIndividualCOIMin] =
    useState<number>(0);
  const [valueBalancedIndividualCOIMax, setValueBalancedIndividualCOIMax] =
    useState<number>(0);
  const [valueBalancedIndividualCOI, setValueBalancedIndividualCOI] =
    useState<number>(0);
  const [
    valueBalanceRepresentationByOrgMin,
    setValueBalanceRepresentationByOrgMin,
  ] = useState<number>(0);
  const [
    valueBalanceRepresentationByOrgMax,
    setValueBalanceRepresentationByOrgMax,
  ] = useState<number>(0);
  const [
    valueBalanceRepresentationByOrgThreshold,
    setValueBalanceRepresentationByOrgThreshold,
  ] = useState<number>(0);
  const [
    valuenumberOfMemberInTheCommitteeMin,
    setValueNumberOfMemberInTheCommitteeMin,
  ] = useState<number>(0);
  const [
    valuenumberOfMemberInTheCommitteeMax,
    setValueNumberOfMemberInTheCommitteeMax,
  ] = useState<number>(0);
  const [
    valueSubTierCommitteeForMembership,
    setValueSubTierCommitteeForMembership,
  ] = useState<number>(0);
  const [
    valueSameCommitteeForOfficerPosition,
    setValueSameCommitteeForOfficerPosition,
  ] = useState<number>(0);
  const [valueTenureForOfficerPosition, setValueTenureForOfficerPosition] =
    useState<number>(0);
  const [valueTenureForOtherMembers, setValueTenureForOtherMembers] =
    useState<number>(0);
  const [valueFirstNotificationStaff, setValueFirstNotificationStaff] =
    useState<number>(0);
  const [valueSecondNotificationStaff, setValueSecondNotificationStaff] =
    useState<number>(0);
  const [valueThirdNotificationStaff, setValueThirdNotificationStaff] =
    useState<number>(0);
  const [valueFirstNotificationMember, setValueFirstNotificationMember] =
    useState<number>(0);
  const [valueSecondNotificationMember, setValueSecondNotificationMember] =
    useState<number>(0);
  const [valueThirdNotificationMember, setValueThirdNotificationMember] =
    useState<number>(0);
  const [valueDurationOfReconfirm, setValueDurationOfReconfirm] =
    useState<number>(0);
  const [valueMaxConsecutiveTerm, setValueMaxConsecutiveTerm] =
    useState<number>(0);
  const [valueConsecutiveMeeting, setValueConsecutiveMeeting] =
    useState<number>(0);
  const [valueConsecutiveBallots, setValueConsecutiveBallots] =
    useState<number>(0);
  const [valueTotalMeeting, setValueTotalMeeting] = useState<number>(0);
  const [valueTotalBallots, setValueTotalBallots] = useState<number>(0);

  const [checkedBalance, setCheckedBalance] = useState<boolean>(true);
  const [checkedCOI, setCheckedCOI] = useState<boolean>(true);
  const [checkedBalanceBasedOnOrg, setCheckedBalanceBasedOnOrg] =
    useState<boolean>(true);
  const [checkedNoOfMember, setCheckedNoOfMember] = useState<boolean>(true);
  const [checkedCriteriaOfEligibility, setCheckedCriteriaOfEligibility] =
    useState<boolean>(true);
  const [checkedMembershipTenure, setCheckedMembershipTenure] =
    useState<boolean>(true);
  const [checkedMembershipExpire, setCheckedMembershipExpire] =
    useState<boolean>(false);
  const [checkedNotifyStaff, setCheckedNotifyStaff] = useState<boolean>(true);
  const [checkedNotificationStaff, setCheckedNotificationStaff] =
    useState<boolean>(true);
  const [checkedMinConsecutive, setCheckedMinConsecutive] =
    useState<boolean>(true);
  const [checkedDurationReconfirm, setCheckedDurationReconfirm] =
    useState<boolean>(true);
  const [checkedNotificationAlert, setCheckedNotificationAlert] =
    useState<boolean>(true);
  const [
    defaultConfigurationFromParentCommitteeCheckbox,
    setDefaultConfigurationFromParentCommitteeCheckbox,
  ] = useState<boolean>(false);

  const [
    isMemberHasAccessOfOtherCommitteeCheckbox,
    setIsMemberHasAccessOfOtherCommitteeCheckbox,
  ] = useState<boolean>(false);

  const [natureOfCommitteeCheckbox, setNatureOfCommitteeCheckbox] =
    useState<boolean>(false);

  const [
    defaultApplicationConfigurationFromParentCommitteeCheckbox,
    setDefaultApplicationConfigurationFromParentCommitteeCheckbox,
  ] = useState<boolean>(false);
  const [ballotConfigurationData, setBallotConfigurationData] = useState<BallotConfigurationType | any>(
    {
      enableVotingPostBallotClosed: false,
      enableVotingBeforeBallotClosed: null,
      enableVotingAfterBallotEndDate: false,
      accreditationBodyId: null,
      ballotLevelId: null,
      ballotTypeId: null,
      ballotSubTypeId: null,
      recordTypeId: null,
      recordSubTypeId: null,
      votingFirstRemindersInDays: 0,
      votingSecondRemindersInDays: 0,
      votingThirdRemindersInDays: 0,
      allowUsersToAddRemoveRecords: null,
      allowUsersToAddRemoveCommittees: null,
      durationToAllowWithdrawDisapproval: 0,
      isDirty: false,
      isNew: true,
    }
  );
  const dispatch = useDispatch();
  const [defaultEditBallotConfigurationId, setDefaultEditBallotConfigurationId] = useState<any>("")

  const fetchBallotDeafultConfig = async () => {
    try {
      const responseData = await getData("BallotConfiguration/GetDefaultBallotConfiguration");
      setDefaultEditBallotConfigurationId(responseData.Id);
      setBallotConfigurationData({
          enableVotingPostBallotClosed: responseData.EnableVotingPostBallotClosed,
          enableVotingBeforeBallotClosed: responseData.EnableVotingBeforeBallotClosed,
          enableVotingAfterBallotEndDate: responseData.EnableVotingAfterBallotEndDate,
          accreditationBodyId: responseData.AccreditationBodyId || null,
          ballotLevelId: responseData.BallotLevelId || null,
          ballotTypeId:  responseData.BallotTypeId || null,
          ballotSubTypeId: responseData.BallotSubTypeId || null,
          recordTypeId: responseData.RecordTypeId || null,
          recordSubTypeId:  responseData.RecordSubTypeId || null,
          votingFirstRemindersInDays: responseData.VotingFirstRemindersInDays,
          votingSecondRemindersInDays: responseData.VotingSecondRemindersInDays,
          votingThirdRemindersInDays: responseData.VotingThirdRemindersInDays,
          allowUsersToAddRemoveRecords: null,
          allowUsersToAddRemoveCommittees: null,
          durationToAllowWithdrawDisapproval: 0,
          isDirty: true,
          isNew: false,
      });
    } catch (error) {
      console.error("Error fetching committee configuration:", error);
    }
  }

  const validateBallotConfiguration = useCallback(() => {
    let isValid = true;
    let validationErrors: any = {};
    // Field Labels for Error Messages
    const fieldLabels: { [key: string]: string } = {
      enableVotingBeforeBallotClosed: "This Field",
      votingFirstRemindersInDays: "First Reminder",
      votingSecondRemindersInDays: "Second Reminder",
      votingThirdRemindersInDays: "Third Reminder",
    };
  
    // Check if required fields are present
    Object.keys(fieldLabels).forEach((field) => {
      if(field !== "enableVotingBeforeBallotClosed") {
        if (!ballotConfigurationData[field]) {
          validationErrors[field] = `${fieldLabels[field]} is required.`;
          isValid = false;
        }
      }
      if(field === "enableVotingBeforeBallotClosed") {
        if(ballotConfigurationData[field] === null) {
          validationErrors[field] = `${fieldLabels[field]} is required.`;
          isValid = false;
        }
      }
    });

    // Set errors state
    setErrors(validationErrors);
    return isValid;
  }, [ballotConfigurationData]);

  const createPayload = useCallback((isNew: boolean) => ({
    isDirty: !isNew,
    isNew: isNew,
    enableVotingPostBallotClosed: ballotConfigurationData.enableVotingPostBallotClosed ?? isNew,
    enableVotingBeforeBallotClosed: ballotConfigurationData.enableVotingBeforeBallotClosed ?? isNew,
    enableVotingAfterBallotEndDate: ballotConfigurationData.enableVotingAfterBallotEndDate ?? isNew,
    votingFirstRemindersInDays: parseInt(ballotConfigurationData.votingFirstRemindersInDays, 10) || 0,
    votingSecondRemindersInDays: parseInt(ballotConfigurationData.votingSecondRemindersInDays, 10) || 0,
    votingThirdRemindersInDays: parseInt(ballotConfigurationData.votingThirdRemindersInDays, 10) || 0,
    ballotLevelId: ballotConfigurationData.ballotLevelId ?? null,
    ballotTypeId: ballotConfigurationData.ballotTypeId ?? null,
    ballotSubTypeId: ballotConfigurationData.ballotSubTypeId ?? null,
    recordTypeId: ballotConfigurationData.recordTypeId ?? null,
    recordSubTypeId: ballotConfigurationData.recordSubTypeId ?? null,
    accreditationBodyId: ballotConfigurationData.accreditationBodyId ?? null,
    allowUsersToAddRemoveRecords: ballotConfigurationData.allowUsersToAddRemoveRecords ?? isNew,
    allowUsersToAddRemoveCommittees: ballotConfigurationData.allowUsersToAddRemoveCommittees ?? isNew,
    durationToAllowWithdrawDisapproval: ballotConfigurationData.durationToAllowWithdrawDisapproval,
  }), [ballotConfigurationData]);

  const handleSubmit = async () => {
    const responseData = await fetchData("CommitteeConfiguration/GetAll");
    const responseCOIData = await fetchData(
      "CategoryofInterestConfiguration/GetAll"
    );
    const CommitteeConfigurationId = responseData?.Collection[0].Id;
    const CommitteeCOIId = responseCOIData?.Collection[0].Id;
    const CommitteeData = {
      id: CommitteeConfigurationId,
      isDirty: true,
      isNew: !existingData,
      isDefaultParent: defaultConfigurationFromParentCommittee === "Yes",
      isNatureofCommitteeRestricted: natureOfCommittee === "Yes",
      IsAllowMemberOtherCommitteAccess:
        isMemberAllowedToAccessOtherCommittee === "Yes",
      acceptApplicationWithoutOpenPositions:
        defaultApplicationConfigurationFromParentCommitteeCheckbox,

      isBalanceBasedOnOverAllThreshold: checkedBalance,
      minOverallThreshold: valueBalancedThresholdMin,
      maxOverallThreshold: valueBalancedThresholdMax,
      thresholdPercentage: valueBalancedThreshold,
      isBalanceBasedOnCategoryofInterests: checkedCOI,
      isBalanceBasedOnRepresentationByOrg: checkedBalanceBasedOnOrg,
      organisationMinMember: valueBalanceRepresentationByOrgMin,
      organisationMaxMember: valueBalanceRepresentationByOrgMax,
      organisationMemberThreshold: valueBalanceRepresentationByOrgThreshold,
      isShowCommitteemembers: checkedNoOfMember,
      committeeMinMember: valuenumberOfMemberInTheCommitteeMin,
      committeeMaxMember: valuenumberOfMemberInTheCommitteeMax,
      isCriteriaOfmembershipEligibility: checkedCriteriaOfEligibility,
      msElig_SubTierCommitte_Months: valueSubTierCommitteeForMembership,
      msElig_SameCommitte_Officer_Months: valueSameCommitteeForOfficerPosition,
      isTenure_ExpirationsRules: checkedMembershipTenure,
      msExpire_Officer_Tenure_Years: valueTenureForOfficerPosition,
      msExpire_Member_Tenure_Years: valueTenureForOtherMembers,
      isShowAllMembershipExpires: checkedMembershipExpire,
      sendNotificationforMembershipExpiration: allMembershipsExpires === "Yes",
      notifyStaffExpirationofMembership: checkedNotifyStaff,
      notifyStaffExpirationofMembershipDays_First: valueFirstNotificationStaff,
      notifyStaffExpirationofMembershipDays_Second:
        valueSecondNotificationStaff,
      notifyStaffExpirationofMembershipDays_Third: valueThirdNotificationStaff,
      notifyMemberExpirationofMembership: checkedNotificationStaff,
      notifyMemberExpirationofMembershipDays_First:
        valueFirstNotificationMember,
      notifyMemberExpirationofMembershipDays_Second:
        valueSecondNotificationMember,
      notifyMemberExpirationofMembershipDays_Third:
        valueThirdNotificationMember,
      officerMaximumTermYears: valueMaxConsecutiveTerm,
      applicationAutoWithdrawalMonths: valueDurationOfReconfirm,
      notifyMemberLackofParticipation: checkedNotificationAlert,
      numberofMeetingMissed: valueConsecutiveMeeting,
      numberofBallotsMissed: valueConsecutiveBallots,
      totalMeetingMisssedPercentage: valueTotalMeeting,
      totalBallotMisssedPercentage: valueTotalBallots,
      IsCheckBoxCheckedForDefaultParent:
        defaultConfigurationFromParentCommitteeCheckbox,
      IsCheckBoxCheckedIsRestricted: natureOfCommitteeCheckbox,
      AllowApplicationsWithoutOpenPositions:
        defaultApplicationConfigurationFromParentCommittee === "Yes",
      IsCheckBoxCheckedForAllowMemberOtherCommitteeAccess:
        isMemberHasAccessOfOtherCommitteeCheckbox,
    };
    const COIData = {
      id: CommitteeCOIId,
      committeeConfigurationId: CommitteeConfigurationId,
      isDirty: true,
      isNew: !existingCOIData,
      minMember: valueBalancedIndividualCOIMin,
      maxMember: valueBalancedIndividualCOIMax,
      threshold: valueBalancedIndividualCOI,
    };
    try {
      const isValid = validateBallotConfiguration();
      if (!isValid) {
        console.error("Validation failed. Please correct the errors.");
        return;
      }  
      if(ballotConfigurationData.isNew) {
        //edit mode
        const payload = createPayload(ballotConfigurationData.isNew);
        const response = await ballotPost("BallotConfiguration", payload);
        dispatch(setToast({
          message: t("configuration.configurationPostedSuccess"),
          severity: "success"
        }));
      } else {
         //fresh Mode
         const payload = createPayload(ballotConfigurationData.isNew);
         const response = await ballotPutData("BallotConfiguration", defaultEditBallotConfigurationId, payload);
         dispatch(setToast({
           message: t("configuration.configurationUpdatedSuccess"),
           severity: "success"
         }));
      }
      if (existingData && existingCOIData) {
        await putData(
          `CommitteeConfiguration`,
          CommitteeData.id,
          CommitteeData
        );
        onSubmitSuccess();
        await putData(`CategoryofInterestConfiguration`, COIData.id, COIData);
        onSubmitSuccess();
      } else {
        const response = await postData(
          "CommitteeConfiguration",
          CommitteeData
        );
        const responseCOI = await postData(
          "CategoryofInterestConfiguration",
          COIData
        );
        navigate("/configuration");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCancel = () => {
    onSubmitSuccess();
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const responseData = await fetchData("CommitteeConfiguration/GetAll");
        const CommitteeConfigId = responseData?.Collection[0].Id;
        const responseDataforCOI = await getListByParentId(
          "CategoryofInterestConfiguration",
          CommitteeConfigId
        );

        const CommitteeConfigCOIData: CommitteeCOIItem[] =
          responseDataforCOI?.Collection;
        const CommitteeConfigData: CommitteeDataItem[] =
          responseData?.Collection;
        if (CommitteeConfigCOIData && CommitteeConfigCOIData.length > 0) {
          setExistingCOIData(CommitteeConfigCOIData[0]);
          setValueBalancedIndividualCOIMin(CommitteeConfigCOIData[0].MinMember);
          setValueBalancedIndividualCOIMax(CommitteeConfigCOIData[0].MaxMember);
          setValueBalancedIndividualCOI(CommitteeConfigCOIData[0].Threshold);
        }
        if (CommitteeConfigData && CommitteeConfigData.length > 0) {
          setExistingData(CommitteeConfigData[0]);
          setDefaultConfigurationFromParentCommitteeCheckbox(
            CommitteeConfigData[0].IsCheckBoxCheckedForDefaultParent
          );
          setNatureOfCommitteeCheckbox(
            CommitteeConfigData[0].IsCheckBoxCheckedIsRestricted
          );
          setIsMemberHasAccessOfOtherCommitteeCheckbox(
            CommitteeConfigData[0]
              .IsCheckBoxCheckedForAllowMemberOtherCommitteeAccess
          );
          setDefaultApplicationConfigurationFromParentCommitteeCheckbox(
            CommitteeConfigData[0].AcceptApplicationWithoutOpenPositions
          );
          setValueTotalBallots(
            CommitteeConfigData[0].TotalBallotMisssedPercentage
          );
          setValueTotalMeeting(
            CommitteeConfigData[0].TotalMeetingMisssedPercentage
          );
          setValueConsecutiveBallots(
            CommitteeConfigData[0].NumberofBallotsMissed
          );
          setValueConsecutiveMeeting(
            CommitteeConfigData[0].NumberofMeetingMissed
          );
          setValueDurationOfReconfirm(
            CommitteeConfigData[0].ApplicationAutoWithdrawalMonths
          );
          setValueMaxConsecutiveTerm(
            CommitteeConfigData[0].OfficerMaximumTermYears
          );
          setValueThirdNotificationMember(
            CommitteeConfigData[0].NotifyMemberExpirationofMembershipDays_Third
          );
          setValueSecondNotificationMember(
            CommitteeConfigData[0].NotifyMemberExpirationofMembershipDays_Second
          );
          setValueFirstNotificationMember(
            CommitteeConfigData[0].NotifyMemberExpirationofMembershipDays_First
          );
          setValueFirstNotificationStaff(
            CommitteeConfigData[0].NotifyStaffExpirationofMembershipDays_First
          );
          setValueSecondNotificationStaff(
            CommitteeConfigData[0].NotifyStaffExpirationofMembershipDays_Second
          );
          setValueThirdNotificationStaff(
            CommitteeConfigData[0].NotifyStaffExpirationofMembershipDays_Third
          );
          setValueTenureForOfficerPosition(
            CommitteeConfigData[0].MSExpire_Officer_Tenure_Years
          );
          setValueTenureForOtherMembers(
            CommitteeConfigData[0].MSExpire_Member_Tenure_Years
          );
          setValueSubTierCommitteeForMembership(
            CommitteeConfigData[0].MSElig_SubTierCommitte_Months
          );
          setValueSameCommitteeForOfficerPosition(
            CommitteeConfigData[0].MSElig_SameCommitte_Officer_Months
          );
          setValueNumberOfMemberInTheCommitteeMax(
            CommitteeConfigData[0].CommitteeMaxMember
          );
          setValueNumberOfMemberInTheCommitteeMin(
            CommitteeConfigData[0].CommitteeMinMember
          );
          setValueBalancedThresholdMin(
            CommitteeConfigData[0].MinOverallThreshold
          );
          setValueBalancedThresholdMax(
            CommitteeConfigData[0].MaxOverallThreshold
          );
          setValueBalancedThreshold(CommitteeConfigData[0].ThresholdPercentage);
          setValueBalanceRepresentationByOrgMin(
            CommitteeConfigData[0].OrganisationMinMember
          );
          setValueBalanceRepresentationByOrgMax(
            CommitteeConfigData[0].OrganisationMaxMember
          );
          setValueBalanceRepresentationByOrgThreshold(
            CommitteeConfigData[0].OrganisationMemberThreshold
          );
          const defaultConfigValue = CommitteeConfigData[0].IsDefaultParent
            ? "Yes"
            : "No";
          setDefaultConfigurationFromParentCommittee(defaultConfigValue);

          const defaultConfigValueOne = CommitteeConfigData[0]
            .IsShowAllMembershipExpires
            ? "Yes"
            : "No";
          setallMembershipsExpires(defaultConfigValueOne);

          const defaultConfigValueTwo = CommitteeConfigData[0]
            .AllowApplicationsWithoutOpenPositions
            ? "Yes"
            : "No";
          setDefaultApplicationConfigurationFromParentCommittee(
            defaultConfigValueTwo
          );
          const defaultConfigValueThree = CommitteeConfigData[0]
            .IsNatureofCommitteeRestricted
            ? "Yes"
            : "No";
          setNatureOfCommittee(defaultConfigValueThree);
          const defaultConfigValueFour = CommitteeConfigData[0]
            .IsAllowMemberOtherCommitteAccess
            ? "Yes"
            : "No";
          setIsMemberAllowedToAccessOtherCommittee(defaultConfigValueFour);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetch();
    fetchBallotDeafultConfig();
  }, []);

  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const options: Option[] = [
    { name: "Option 1", code: "1" },
    { name: "Option 2", code: "2" },
  ];

  const [value, setValue] = useState<string>("");

  const [
    newRemoveExistingRecordsRecirculatingBallots,
    setnewRemoveExistingRecordsRecirculatingBallots,
  ] = useState<boolean>(false);
  const [
    newRemoveExistingCommitteeRecirculatingBallots,
    setnewRemoveExistingCommitteeRecirculatingBallots,
  ] = useState<boolean>(false);

  const handleRadioButtonChange = useCallback((e: RadioButtonChangeEvent, field: keyof typeof ballotConfigurationData) => {
    setBallotConfigurationData((prevState: any) => ({
      ...prevState,
      [field]: e.value === "Yes" ,
    }));
  }, []);

  const handleDropdownField = useCallback((event: DropdownChangeEvent, field: keyof typeof ballotConfigurationData) => {
    setBallotConfigurationData((prevState: any) => ({
      ...prevState,
      [field]: event.value,
    }));
   
  }, []);

  return (
    <>
      <div className="">
        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex flex-column px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("configuration.committeeConfiguration")}
            </h2>
            <p className="text-base font-normal m-0">
              {t("configuration.committeeConfigurationHelp")}
            </p>
          </div>

          <div className="p-5 cardBody">
            <div className="flex flex-column gap-5">
              <fieldset className="border-none p-0">
                <legend className="block font-bold text-input-label mb-3">
                  <Checkbox
                    inputId="defaultConfigurationFromParentCommitteeCheckbox"
                    name="defaultConfigurationFromParentCommitteeCheckbox"
                    onChange={(e: any) => {
                      setDefaultConfigurationFromParentCommitteeCheckbox(
                        e.checked
                      );
                      setDefaultConfigurationFromParentCommittee("No");
                    }}
                    checked={defaultConfigurationFromParentCommitteeCheckbox}
                    className="mr-2"
                    aria-label={t(
                      "configuration.defaultConfigurationFromParentCommittee"
                    )}
                  />
                  {t("configuration.defaultConfigurationFromParentCommittee")}
                </legend>
                {defaultConfigurationFromParentCommitteeCheckbox && (
                  <div className="flex flex-wrap gap-3 pl-4">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="defaultConfigurationFromParentCommittee1"
                        name="defaultConfigurationFromParentCommittee"
                        value="Yes"
                        onChange={(e: RadioButtonChangeEvent) =>
                          setDefaultConfigurationFromParentCommittee(e.value)
                        }
                        checked={
                          defaultConfigurationFromParentCommittee === "Yes"
                        }
                      />
                      <label
                        htmlFor="defaultConfigurationFromParentCommittee1"
                        className="ml-2"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="defaultConfigurationFromParentCommittee2"
                        name="defaultConfigurationFromParentCommittee"
                        value="No"
                        onChange={(e: RadioButtonChangeEvent) =>
                          setDefaultConfigurationFromParentCommittee(e.value)
                        }
                        checked={
                          defaultConfigurationFromParentCommittee === "No"
                        }
                      />
                      <label
                        htmlFor="defaultConfigurationFromParentCommittee2"
                        className="ml-2"
                      >
                        No
                      </label>
                    </div>
                  </div>
                )}
              </fieldset>

              <fieldset className="border-none p-0">
                <legend className="block font-bold text-input-label mb-3">
                  <Checkbox
                    inputId="natureOfCommitteeCheckbox"
                    name="natureOfCommitteeCheckbox"
                    onChange={(e: any) => {
                      setNatureOfCommitteeCheckbox(e.checked);
                      setNatureOfCommittee("No");
                    }}
                    checked={natureOfCommitteeCheckbox}
                    className="mr-2"
                    aria-label={t("configuration.natureOfCommittee")}
                  />
                  {t("configuration.natureOfCommittee")}{" "}
                  <TooltipButton
                    icon="pi pi-info-circle"
                    className="text-body"
                    ariaLabel="Tooltip"
                    tooltip="Mark a committee as secret or restricted, thereby ensuring its ballots, records, and requests are not visible to members and staff of other committees."
                  />
                </legend>
                {natureOfCommitteeCheckbox && (
                  <div className="flex flex-wrap gap-3 pl-4">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="natureOfCommittee1"
                        name="natureOfCommittee"
                        value="Yes"
                        onChange={(e: RadioButtonChangeEvent) =>
                          setNatureOfCommittee(e.value)
                        }
                        checked={natureOfCommittee === "Yes"}
                      />
                      <label htmlFor="natureOfCommittee1" className="ml-2">
                        Yes
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="natureOfCommittee2"
                        name="natureOfCommittee"
                        value="No"
                        onChange={(e: RadioButtonChangeEvent) =>
                          setNatureOfCommittee(e.value)
                        }
                        checked={natureOfCommittee === "No"}
                      />
                      <label htmlFor="natureOfCommittee2" className="ml-2">
                        No
                      </label>
                    </div>
                  </div>
                )}
              </fieldset>
              <fieldset className="border-none p-0">
                <legend className="block font-bold text-input-label mb-3">
                  <Checkbox
                    inputId="isMemberAllowedToAccessOtherCommitteeCheckbox"
                    name="isMemberAllowedToAccessOtherCommitteeCheckbox"
                    onChange={(e: any) => {
                      setIsMemberHasAccessOfOtherCommitteeCheckbox(e.checked);
                      setIsMemberAllowedToAccessOtherCommittee("No");
                    }}
                    checked={isMemberHasAccessOfOtherCommitteeCheckbox}
                    className="mr-2"
                    aria-label={t(
                      "configuration.isMemberAllowedToAccessOtherCommittee"
                    )}
                  />
                  {t("configuration.isMemberAllowedToAccessOtherCommittee")}
                </legend>
                {isMemberHasAccessOfOtherCommitteeCheckbox && (
                  <div className="flex flex-wrap gap-3 pl-4">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="isMemberAllowedToAccessOtherCommittee1"
                        name="isMemberAllowedToAccessOtherCommittee"
                        value="Yes"
                        onChange={(e: RadioButtonChangeEvent) =>
                          setIsMemberAllowedToAccessOtherCommittee(e.value)
                        }
                        checked={
                          isMemberAllowedToAccessOtherCommittee === "Yes"
                        }
                      />
                      <label
                        htmlFor="isMemberAllowedToAccessOtherCommittee1"
                        className="ml-2"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="isMemberAllowedToAccessOtherCommittee2"
                        name="isMemberAllowedToAccessOtherCommittee"
                        value="No"
                        onChange={(e: RadioButtonChangeEvent) =>
                          setIsMemberAllowedToAccessOtherCommittee(e.value)
                        }
                        checked={isMemberAllowedToAccessOtherCommittee === "No"}
                      />
                      <label
                        htmlFor="isMemberAllowedToAccessOtherCommittee2"
                        className="ml-2"
                      >
                        No
                      </label>
                    </div>
                  </div>
                )}
              </fieldset>

              <fieldset className="border-none p-0">
                <legend className="block font-bold text-input-label p-0 mb-3">
                  <Checkbox
                    inputId="defaultApplicationConfigurationFromParentCommitteeCheckbox"
                    name="defaultApplicationConfigurationFromParentCommitteeCheckbox"
                    onChange={(e: any) => {
                      setDefaultApplicationConfigurationFromParentCommitteeCheckbox(
                        e.checked
                      );
                      setDefaultApplicationConfigurationFromParentCommittee(
                        "No"
                      );
                    }}
                    checked={
                      defaultApplicationConfigurationFromParentCommitteeCheckbox
                    }
                    className="mr-2"
                    aria-label={t(
                      "configuration.defaultApplicationConfigurationFromParentCommittee"
                    )}
                  />
                  {t(
                    "configuration.defaultApplicationConfigurationFromParentCommittee"
                  )}
                </legend>
                {defaultApplicationConfigurationFromParentCommitteeCheckbox && (
                  <div className="flex flex-wrap gap-3 pl-4">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="defaultApplicationConfigurationFromParentCommittee1"
                        name="defaultApplicationConfigurationFromParentCommittee"
                        value="Yes"
                        onChange={(e: RadioButtonChangeEvent) =>
                          setDefaultApplicationConfigurationFromParentCommittee(
                            e.value
                          )
                        }
                        checked={
                          defaultApplicationConfigurationFromParentCommittee ===
                          "Yes"
                        }
                      />
                      <label
                        htmlFor="defaultApplicationConfigurationFromParentCommittee1"
                        className="ml-2"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="defaultApplicationConfigurationFromParentCommittee2"
                        name="defaultApplicationConfigurationFromParentCommittee"
                        value="No"
                        onChange={(e: RadioButtonChangeEvent) =>
                          setDefaultApplicationConfigurationFromParentCommittee(
                            e.value
                          )
                        }
                        checked={
                          defaultApplicationConfigurationFromParentCommittee ===
                          "No"
                        }
                      />
                      <label
                        htmlFor="defaultApplicationConfigurationFromParentCommittee2"
                        className="ml-2"
                      >
                        No
                      </label>
                    </div>
                  </div>
                )}
              </fieldset>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex flex-column px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("configuration.criteriaForBalanceOfInterest")}
            </h2>
            <p className="text-base font-normal m-0">
              {t("configuration.criteriaForBalanceOfInterestHelp")}
            </p>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-6 w-full">
              <div className="flex flex-column gap-4">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="balanceBasedOnOverallThreshold"
                    name="balanceBasedOnOverallThreshold"
                    onChange={(e) => setCheckedBalance(e.checked ?? false)}
                    checked={checkedBalance}
                    className="mr-2"
                  />
                  <label
                    htmlFor="balanceBasedOnOverallThreshold"
                    className="text-base font-normal"
                  >
                    {t("configuration.balanceBasedOnOverallThreshold")}
                  </label>
                </div>
                {checkedBalance && (
                  <div className="grid grid-xl align-items-end pl-4">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="balanceBasedOnOverallThresholdMin"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.min")}
                      </label>
                      <InputText
                        id="balanceBasedOnOverallThresholdMin"
                        name="balanceBasedOnOverallThresholdMin"
                        value={valueBalancedThresholdMin.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueBalancedThresholdMin(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.min")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="balanceBasedOnOverallThresholdMax"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.max")}
                      </label>
                      <InputText
                        id="balanceBasedOnOverallThresholdMax"
                        name="balanceBasedOnOverallThresholdMax"
                        value={valueBalancedThresholdMax.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueBalancedThresholdMax(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.max")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="balanceBasedOnOverallThresholdTrehshold"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.threshold")}*
                      </label>
                      <InputText
                        id="balanceBasedOnOverallThresholdTrehshold"
                        name="balanceBasedOnOverallThresholdTrehshold"
                        value={valueBalancedThreshold.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueBalancedThreshold(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.threshold")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-column gap-4">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="balanceBasedOnIndCoi"
                    name="balanceBasedOnIndCoi"
                    onChange={(e) => setCheckedCOI(e.checked ?? false)}
                    checked={checkedCOI}
                    className="mr-2"
                  />
                  <label
                    htmlFor="balanceBasedOnIndCoi"
                    className="text-base font-normal"
                  >
                    {t("configuration.balanceBasedOnIndCoi")}
                  </label>
                </div>
                {checkedCOI && (
                  <div className="grid grid-xl align-items-end pl-4">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="balanceBasedOnIndCoiMin"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.min")}*
                      </label>
                      <InputText
                        id="balanceBasedOnIndCoiMin"
                        name="balanceBasedOnIndCoiMin"
                        value={valueBalancedIndividualCOIMin.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueBalancedIndividualCOIMin(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.min")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="balanceBasedOnIndCoiMax"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.max")}*
                      </label>
                      <InputText
                        id="balanceBasedOnIndCoiMax"
                        name="balanceBasedOnIndCoiMax"
                        value={valueBalancedIndividualCOIMax.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueBalancedIndividualCOIMax(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.max")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="balanceBasedOnIndCoiTrehshold"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.threshold")}*
                      </label>
                      <InputText
                        id="balanceBasedOnIndCoiTrehshold"
                        name="balanceBasedOnIndCoiTrehshold"
                        value={valueBalancedIndividualCOI.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueBalancedIndividualCOI(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.threshold")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-column gap-4">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="balanceRepresentationByOrg"
                    name="balanceRepresentationByOrg"
                    onChange={(e) =>
                      setCheckedBalanceBasedOnOrg(e.checked ?? false)
                    }
                    checked={checkedBalanceBasedOnOrg}
                    className="mr-2"
                  />
                  <label
                    htmlFor="balanceRepresentationByOrg"
                    className="text-base font-normal"
                  >
                    {t("configuration.balanceRepresentationByOrg")}
                  </label>
                </div>
                {checkedBalanceBasedOnOrg && (
                  <div className="grid grid-xl align-items-end pl-4">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="balanceRepresentationByOrgMin"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.min")}*
                      </label>
                      <InputText
                        id="balanceRepresentationByOrgMin"
                        name="balanceRepresentationByOrgMin"
                        value={valueBalanceRepresentationByOrgMin.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueBalanceRepresentationByOrgMin(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.min")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="balanceRepresentationByOrgMax"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.max")}*
                      </label>
                      <InputText
                        id="balanceRepresentationByOrgMax"
                        name="balanceRepresentationByOrgMax"
                        value={valueBalanceRepresentationByOrgMax.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueBalanceRepresentationByOrgMax(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.max")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="balanceRepresentationByOrgTrehshold"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.threshold")}*
                      </label>
                      <InputText
                        id="balanceRepresentationByOrgTrehshold"
                        name="balanceRepresentationByOrgTrehshold"
                        value={valueBalanceRepresentationByOrgThreshold.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueBalanceRepresentationByOrgThreshold(
                              numericValue
                            );
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.threshold")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex flex-column px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("configuration.committeeMemberConfiguration")}
            </h2>
            <p className="text-base font-normal m-0">
              {t("configuration.committeeMemberConfigurationHelp")}
            </p>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-6 w-full">
              <div className="flex flex-column gap-4">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="numberOfMemberInTheCommittee"
                    name="numberOfMemberInTheCommittee"
                    onChange={(e) => setCheckedNoOfMember(e.checked ?? false)}
                    checked={checkedNoOfMember}
                    className="mr-2"
                  />
                  <label
                    htmlFor="numberOfMemberInTheCommittee"
                    className="text-base font-normal"
                  >
                    {t("configuration.numberOfMemberInTheCommittee")}
                  </label>
                </div>
                {checkedNoOfMember && (
                  <div className="grid grid-xl align-items-end pl-4">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="numberOfMemberInTheCommitteeMin"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.min")}
                      </label>
                      <InputText
                        id="numberOfMemberInTheCommitteeMin"
                        name="numberOfMemberInTheCommitteeMin"
                        value={valuenumberOfMemberInTheCommitteeMin.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueNumberOfMemberInTheCommitteeMin(
                              numericValue
                            );
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.min")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="numberOfMemberInTheCommitteeMax"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.max")}
                      </label>
                      <InputText
                        id="numberOfMemberInTheCommitteeMax"
                        name="numberOfMemberInTheCommitteeMax"
                        value={valuenumberOfMemberInTheCommitteeMax.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueNumberOfMemberInTheCommitteeMax(
                              numericValue
                            );
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.max")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-column gap-4">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="criteriaMembershipEligibilityMonths"
                    name="criteriaMembershipEligibilityMonths"
                    onChange={(e) =>
                      setCheckedCriteriaOfEligibility(e.checked ?? false)
                    }
                    checked={checkedCriteriaOfEligibility}
                    className="mr-2"
                  />
                  <label
                    htmlFor="criteriaMembershipEligibilityMonths"
                    className="text-base font-normal"
                  >
                    {t("configuration.criteriaMembershipEligibilityMonths")}
                  </label>
                </div>
                {checkedCriteriaOfEligibility && (
                  <div className="grid grid-xl align-items-end pl-4">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="atSubTierCommitteeForMembership"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.atSubTierCommitteeForMembership")}*
                      </label>
                      <InputText
                        id="atSubTierCommitteeForMembership"
                        name="atSubTierCommitteeForMembership"
                        value={valueSubTierCommitteeForMembership.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueSubTierCommitteeForMembership(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.max")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="atTheSameCommitteeForOfficerPosition"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t(
                          "configuration.atTheSameCommitteeForOfficerPosition"
                        )}
                        *
                      </label>
                      <InputText
                        id="atTheSameCommitteeForOfficerPosition"
                        name="atTheSameCommitteeForOfficerPosition"
                        value={valueSameCommitteeForOfficerPosition.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueSameCommitteeForOfficerPosition(
                              numericValue
                            );
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.max")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-column gap-4">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="membershipTenureAndExpirationsRules"
                    name="membershipTenureAndExpirationsRules"
                    onChange={(e) =>
                      setCheckedMembershipTenure(e.checked ?? false)
                    }
                    checked={checkedMembershipTenure}
                    className="mr-2"
                  />
                  <label
                    htmlFor="membershipTenureAndExpirationsRules"
                    className="text-base font-normal"
                  >
                    {t("configuration.membershipTenureAndExpirationsRules")}
                  </label>
                </div>
                {checkedMembershipTenure && (
                  <div className="grid grid-xl align-items-end pl-4">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="tenureOfOfficerPosition"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.tenureOfOfficerPosition")}*
                      </label>
                      <InputText
                        id="tenureOfOfficerPosition"
                        name="tenureOfOfficerPosition"
                        value={valueTenureForOfficerPosition.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueTenureForOfficerPosition(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.max")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="tenureOfOtherMembers"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.tenureOfOtherMembers")}*
                      </label>
                      <InputText
                        id="tenureOfOtherMembers"
                        name="tenureOfOtherMembers"
                        value={valueTenureForOtherMembers.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueTenureForOtherMembers(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.max")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                  </div>
                )}
              </div>

              <fieldset className="border-none p-0">
                <legend className="block font-normal text-input-label p-0 mb-3">
                  {t("configuration.allMembershipsExpires")}
                </legend>
                <div className="grid grid-xl align-items-end">
                  <div className="flex flex-wrap gap-3 xl:col-3 lg:col-4 md:col-6 col-12">
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="allMembershipsExpires1"
                        name="allMembershipsExpires"
                        value="Yes"
                        onChange={(e: RadioButtonChangeEvent) =>
                          setallMembershipsExpires(e.value)
                        }
                        checked={allMembershipsExpires === "Yes"}
                      />
                      <label htmlFor="allMembershipsExpires1" className="ml-2">
                        Yes
                      </label>
                    </div>
                    <div className="flex align-items-center">
                      <RadioButton
                        inputId="allMembershipsExpires2"
                        name="allMembershipsExpires"
                        value="No"
                        onChange={(e: RadioButtonChangeEvent) =>
                          setallMembershipsExpires(e.value)
                        }
                        checked={allMembershipsExpires === "No"}
                      />
                      <label htmlFor="allMembershipsExpires2" className="ml-2">
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div className="flex flex-column gap-3">
                <div className="flex flex-column gap-2">
                  <div className="flex flex-row xl:col-3 lg:col-4 md:col-6 col-12 p-0">
                    <Checkbox
                      inputId="maximumConsecutiveTermCheck"
                      name="maximumConsecutiveTermCheck"
                      onChange={(e) =>
                        setCheckedMinConsecutive(e.checked ?? false)
                      }
                      checked={checkedMinConsecutive}
                      className="mr-2"
                      aria-label={t("configuration.maximumConsecutiveTerm")}
                    />
                    <label
                      htmlFor="maximumConsecutiveTerm"
                      className="block font-bold text-input-label text-capitalize"
                    >
                      {t("configuration.maximumConsecutiveTerm")}
                    </label>
                  </div>
                  {checkedMinConsecutive && (
                    <div className="grid grid-xl align-items-end pl-4">
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <InputText
                          id="maximumConsecutiveTerm"
                          name="maximumConsecutiveTerm"
                          value={valueMaxConsecutiveTerm.toString()}
                          onChange={(e) => {
                            const numericValue = parseFloat(e.target.value);
                            if (!isNaN(numericValue)) {
                              setValueMaxConsecutiveTerm(numericValue);
                            }
                          }}
                          type="number"
                          placeholder={t("configuration.enterHere")}
                          className="w-full"
                          min="1"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-column gap-2">
                  <div className="flex flex-row xl:col-3 lg:col-4 md:col-6 col-12 p-0">
                    <Checkbox
                      inputId="durationOfReconfirmCheck"
                      name="durationOfReconfirmCheck"
                      onChange={(e) =>
                        setCheckedDurationReconfirm(e.checked ?? false)
                      }
                      checked={checkedDurationReconfirm}
                      className="mr-2"
                      aria-label={t("configuration.durationOfReconfirm")}
                    />
                    <label
                      htmlFor="durationOfReconfirm"
                      className="block font-bold text-input-label text-capitalize"
                    >
                      {t("configuration.durationOfReconfirm")}
                    </label>
                  </div>
                  {checkedDurationReconfirm && (
                    <div className="grid grid-xl align-items-end pl-4">
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <InputText
                          id="durationOfReconfirm"
                          name="durationOfReconfirm"
                          value={valueDurationOfReconfirm.toString()}
                          onChange={(e) => {
                            const numericValue = parseFloat(e.target.value);
                            if (!isNaN(numericValue)) {
                              setValueDurationOfReconfirm(numericValue);
                            }
                          }}
                          type="number"
                          placeholder={t("configuration.enterHere")}
                          className="w-full"
                          min="1"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex flex-column px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("configuration.notificationMembershipExpiration")}
            </h2>
            <p className="text-base font-normal m-0">
              {t("configuration.notificationMembershipExpirationHelp")}
            </p>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-6 w-full">
              <div className="flex flex-column gap-4">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="notificationToStaff"
                    name="notificationToStaff"
                    onChange={(e) => setCheckedNotifyStaff(e.checked ?? false)}
                    checked={checkedNotifyStaff}
                    className="mr-2"
                  />
                  <label
                    htmlFor="notificationToStaff"
                    className="text-base font-normal"
                  >
                    {t("configuration.notificationToStaff")}
                  </label>
                </div>
                {checkedNotifyStaff && (
                  <div className="grid grid-xl align-items-end pl-4">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="notificationToStaffFirst"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.firstNotification")}*
                      </label>
                      <InputText
                        id="notificationToStaffFirst"
                        name="notificationToStaffFirst"
                        value={valueFirstNotificationStaff.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueFirstNotificationStaff(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.firstNotification")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="notificationToStaffSecond"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.secondNotification")}*
                      </label>
                      <InputText
                        id="notificationToStaffSecond"
                        name="notificationToStaffSecond"
                        value={valueSecondNotificationStaff.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueSecondNotificationStaff(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.secondNotification")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="notificationToStaffThird"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.thirdNotification")}*
                      </label>
                      <InputText
                        id="notificationToStaffThird"
                        name="notificationToStaffThird"
                        value={valueThirdNotificationStaff.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueThirdNotificationStaff(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.thirdNotification")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-column gap-4">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="notificationToMembers"
                    name="notificationToMembers"
                    onChange={(e) =>
                      setCheckedNotificationStaff(e.checked ?? false)
                    }
                    checked={checkedNotificationStaff}
                    className="mr-2"
                  />
                  <label
                    htmlFor="notificationToMembers"
                    className="text-base font-normal"
                  >
                    {t("configuration.notificationToMembers")}
                  </label>
                </div>
                {checkedNotificationStaff && (
                  <div className="grid grid-xl align-items-end pl-4">
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="notificationToMembersFirst"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.firstNotification")}*
                      </label>
                      <InputText
                        id="notificationToMembersFirst"
                        name="notificationToMembersFirst"
                        value={valueFirstNotificationMember.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueFirstNotificationMember(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.firstNotification")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="notificationToMembersSecond"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.secondNotification")}*
                      </label>
                      <InputText
                        id="notificationToMembersSecond"
                        name="notificationToMembersSecond"
                        value={valueSecondNotificationMember.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueSecondNotificationMember(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.secondNotification")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                    <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                      <label
                        htmlFor="notificationToMembersThird"
                        className="block font-bold text-input-label text-capitalize"
                      >
                        {t("configuration.thirdNotification")}*
                      </label>
                      <InputText
                        id="notificationToMembersThird"
                        name="notificationToMembersThird"
                        value={valueThirdNotificationMember.toString()}
                        onChange={(e) => {
                          const numericValue = parseFloat(e.target.value);
                          if (!isNaN(numericValue)) {
                            setValueThirdNotificationMember(numericValue);
                          }
                        }}
                        type="number"
                        placeholder={t("configuration.thirdNotification")}
                        className="w-full"
                        min="1"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex flex-column px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("configuration.notificationAlertsToOfficers")}
            </h2>
            <p className="text-base font-normal m-0">
              {t("configuration.notificationAlertsToOfficersHelp")}
            </p>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-6 w-full">
              <div className="flex flex-column gap-4">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="sendNotificationAlert"
                    name="sendNotificationAlert"
                    onChange={(e) =>
                      setCheckedNotificationAlert(e.checked ?? false)
                    }
                    checked={checkedNotificationAlert}
                    className="mr-2"
                  />
                  <label
                    htmlFor="sendNotificationAlert"
                    className="text-base font-normal"
                  >
                    {t("configuration.sendNotificationAlert")}
                  </label>
                </div>
                {checkedNotificationAlert && (
                  <div className="flex flex-column gap-3 pl-4">
                    <div className="grid grid-xl align-items-end">
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor="consecutiveMeetingsMissed"
                          className="block font-bold text-input-label text-capitalize"
                        >
                          {t("configuration.consecutiveMeetingsMissed")}*
                        </label>
                        <InputText
                          id="consecutiveMeetingsMissed"
                          name="consecutiveMeetingsMissed"
                          value={valueConsecutiveMeeting.toString()}
                          onChange={(e) => {
                            const numericValue = parseFloat(e.target.value);
                            if (!isNaN(numericValue)) {
                              setValueConsecutiveMeeting(numericValue);
                            }
                          }}
                          type="number"
                          placeholder={t(
                            "configuration.consecutiveMeetingsMissed"
                          )}
                          className="w-full"
                          min="1"
                        />
                      </div>
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor="consecutiveBallotsMissed"
                          className="block font-bold text-input-label text-capitalize"
                        >
                          {t("configuration.consecutiveBallotsMissed")}*
                        </label>
                        <InputText
                          id="consecutiveBallotsMissed"
                          name="consecutiveBallotsMissed"
                          value={valueConsecutiveBallots.toString()}
                          onChange={(e) => {
                            const numericValue = parseFloat(e.target.value);
                            if (!isNaN(numericValue)) {
                              setValueConsecutiveBallots(numericValue);
                            }
                          }}
                          type="number"
                          placeholder={t(
                            "configuration.consecutiveBallotsMissed"
                          )}
                          className="w-full"
                          min="1"
                        />
                      </div>
                    </div>

                    <div className="grid grid-xl align-items-end">
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor="totalMeetingMissed"
                          className="block font-bold text-input-label text-capitalize"
                        >
                          {t("configuration.totalMeetingMissed")} (%)
                        </label>
                        <InputText
                          id="totalMeetingMissed"
                          name="totalMeetingMissed"
                          value={valueTotalMeeting.toString()}
                          onChange={(e) => {
                            const numericValue = parseFloat(e.target.value);
                            if (!isNaN(numericValue)) {
                              setValueTotalMeeting(numericValue);
                            }
                          }}
                          type="number"
                          placeholder={t("configuration.totalMeetingMissed")}
                          className="w-full"
                          min="1"
                        />
                      </div>
                      <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                        <label
                          htmlFor="totalBallotMissed"
                          className="block font-bold text-input-label text-capitalize"
                        >
                          {t("configuration.totalBallotMissed")} (%)
                        </label>
                        <InputText
                          id="totalBallotMissed"
                          name="totalBallotMissed"
                          value={valueTotalBallots.toString()}
                          onChange={(e) => {
                            const numericValue = parseFloat(e.target.value);
                            if (!isNaN(numericValue)) {
                              setValueTotalBallots(numericValue);
                            }
                          }}
                          type="number"
                          placeholder={t("configuration.totalBallotMissed")}
                          className="w-full"
                          min="1"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex flex-column px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("configuration.defaultBallotConfiguration")}
            </h2>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl">
                <BallotConfigurationForm  
                  ballotConfigurationData={ballotConfigurationData}
                  handleDropdownField={handleDropdownField}
                  handleRadioButtonChange={handleRadioButtonChange}
                  errors={errors}
                  setBallotConfigurationData={setBallotConfigurationData}
                  isWeightedBallot={false}
                  isRecirculation={false}
                  isShowFormField={false} 
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card bg-white w-full mb-5 shadow-md">
          <div className="flex flex-column px-5 py-4 border-bottom-1 border-gray-200 gap-3 cardHeader">
            <h2 className="text-title text-lg font-bold m-0">
              {t("configuration.ballotApprovalCriteriaNotDefined")}
            </h2>
          </div>
          <div className="p-5 cardBody">
            <div className="flex flex-column gap-3">
              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="minBallotDurationWeeks"
                    className="block font-bold text-input-label text-capitalize"
                  >
                    {t("configuration.minBallotDurationWeeks")}
                  </label>
                  <Dropdown
                    inputId="minBallotDurationWeeks"
                    name="minBallotDurationWeeks"
                    value={selectedOption}
                    onChange={(e: DropdownChangeEvent) =>
                      setSelectedOption(e.value)
                    }
                    options={options}
                    optionLabel="name"
                    placeholder={t("configuration.selectAnOption")}
                  />
                </div>
              </div>

              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 md:col-12 col-12">
                  <label
                    htmlFor="votingOption"
                    className="block font-bold text-input-label text-capitalize"
                  >
                    {t("configuration.votingOption")}
                  </label>
                  <Dropdown
                    inputId="votingOption"
                    name="votingOption"
                    value={selectedOption}
                    onChange={(e: DropdownChangeEvent) =>
                      setSelectedOption(e.value)
                    }
                    options={options}
                    optionLabel="name"
                    placeholder={t("configuration.selectAnOption")}
                  />
                </div>
              </div>

              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="defaultVotingOption"
                    className="block font-bold text-input-label text-capitalize"
                  >
                    {t("configuration.defaultVotingOption")}
                  </label>
                  <Dropdown
                    inputId="defaultVotingOption"
                    name="defaultVotingOption"
                    value={selectedOption}
                    onChange={(e: DropdownChangeEvent) =>
                      setSelectedOption(e.value)
                    }
                    options={options}
                    optionLabel="name"
                    placeholder={t("configuration.selectAnOption")}
                  />
                </div>
              </div>

              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="votingConsensusCalculation"
                    className="block font-bold text-input-label text-capitalize"
                  >
                    {t("configuration.votingConsensusCalculation")}
                  </label>
                  <Dropdown
                    inputId="votingConsensusCalculation"
                    name="votingConsensusCalculation"
                    value={selectedOption}
                    onChange={(e: DropdownChangeEvent) =>
                      setSelectedOption(e.value)
                    }
                    options={options}
                    optionLabel="name"
                    placeholder={t("configuration.selectAnOption")}
                  />
                </div>
              </div>

              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-6 lg:col-8 md:col-12 col-12">
                  <label
                    htmlFor="commentOption"
                    className="block font-bold text-input-label text-capitalize"
                  >
                    {t("configuration.commentOption")}
                  </label>
                  <Dropdown
                    inputId="commentOption"
                    name="commentOption"
                    value={selectedOption}
                    onChange={(e: DropdownChangeEvent) =>
                      setSelectedOption(e.value)
                    }
                    options={options}
                    optionLabel="name"
                    placeholder={t("configuration.selectAnOption")}
                  />
                </div>
              </div>

              <h3 className="font-bold text-lg m-0">
                {t("configuration.recirculation")}
              </h3>
              <div className="flex flex-column gap-3 mb-3">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="newRemoveExistingRecordsRecirculatingBallots"
                    name="newRemoveExistingRecordsRecirculatingBallots"
                    onChange={(e) =>
                      setnewRemoveExistingRecordsRecirculatingBallots(
                        e.checked ?? false
                      )
                    }
                    checked={newRemoveExistingRecordsRecirculatingBallots}
                    className="mr-2"
                  />
                  <label
                    htmlFor="newRemoveExistingRecordsRecirculatingBallots"
                    className="text-base font-normal"
                  >
                    {t(
                      "configuration.newRemoveExistingRecordsRecirculatingBallots"
                    )}
                  </label>
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="newRemoveExistingCommitteeRecirculatingBallots"
                    name="newRemoveExistingCommitteeRecirculatingBallots"
                    onChange={(e) =>
                      setnewRemoveExistingCommitteeRecirculatingBallots(
                        e.checked ?? false
                      )
                    }
                    checked={newRemoveExistingCommitteeRecirculatingBallots}
                    className="mr-2"
                  />
                  <label
                    htmlFor="newRemoveExistingCommitteeRecirculatingBallots"
                    className="text-base font-normal"
                  >
                    {t(
                      "configuration.newRemoveExistingCommitteeRecirculatingBallots"
                    )}
                  </label>
                </div>
              </div>

              <h3 className="font-bold text-lg m-0">
                {t("configuration.ballotApprovalCriteria")}
              </h3>
              <div className="ballot-approval-criteria-grid mb-3">
                <div className="flex flex-column gap-2">
                  <label
                    htmlFor="operator1"
                    className="block font-bold text-input-label text-capitalize"
                  >
                    {t("configuration.operator1")}*
                  </label>
                  <Dropdown
                    inputId="operator1"
                    className="w-full"
                    name="operator1"
                    value={selectedOption}
                    onChange={(e: DropdownChangeEvent) =>
                      setSelectedOption(e.value)
                    }
                    options={options}
                    optionLabel="name"
                    placeholder={t("configuration.selectAnOption")}
                  />
                </div>
                <div className="flex flex-column gap-2">
                  <label
                    htmlFor="percent1"
                    className="block font-bold text-input-label text-capitalize"
                  >
                    %*
                  </label>
                  <InputText
                    id="percent1"
                    name="percent1"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    type="number"
                    placeholder="%"
                    className="w-full"
                  />
                </div>
                <div className="flex flex-column gap-2">
                  <label
                    htmlFor="votingOptions"
                    className="block font-bold text-input-label text-capitalize"
                  >
                    {t("configuration.votingOptions")}*
                  </label>
                  <Dropdown
                    className="w-full"
                    inputId="votingOptions"
                    name="votingOptions"
                    value={selectedOption}
                    onChange={(e: DropdownChangeEvent) =>
                      setSelectedOption(e.value)
                    }
                    options={options}
                    optionLabel="name"
                    placeholder={t("configuration.selectAnOption")}
                  />
                </div>
                <div className="flex flex-column gap-2">
                  <label
                    htmlFor="operator2"
                    className="block font-bold text-input-label text-capitalize"
                  >
                    {t("configuration.operator2")}
                  </label>
                  <Dropdown
                    className="w-full"
                    inputId="operator2"
                    name="operator2"
                    value={selectedOption}
                    onChange={(e: DropdownChangeEvent) =>
                      setSelectedOption(e.value)
                    }
                    options={options}
                    optionLabel="name"
                    placeholder={t("configuration.selectAnOption")}
                  />
                </div>
                <div className="flex flex-column gap-2">
                  <label
                    htmlFor="operator3"
                    className="block font-bold text-input-label text-capitalize"
                  >
                    {t("configuration.operator3")}
                  </label>
                  <Dropdown
                    className="w-full"
                    inputId="operator3"
                    name="operator3"
                    value={selectedOption}
                    onChange={(e: DropdownChangeEvent) =>
                      setSelectedOption(e.value)
                    }
                    options={options}
                    optionLabel="name"
                    placeholder={t("configuration.selectAnOption")}
                  />
                </div>
                <div className="flex flex-column gap-2">
                  <label
                    htmlFor="percent2"
                    className="block font-bold text-input-label text-capitalize"
                  >
                    %
                  </label>
                  <InputText
                    id="percent2"
                    name="percent2"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    type="number"
                    placeholder="%"
                    className="w-full"
                  />
                </div>
                <div className="flex flex-column gap-2">
                  <label
                    htmlFor="votingOption2"
                    className="block font-bold text-input-label text-capitalize"
                  >
                    {t("configuration.votingOption")}
                  </label>
                  <Dropdown
                    className="w-full"
                    inputId="votingOption2"
                    name="votingOption2"
                    value={selectedOption}
                    onChange={(e: DropdownChangeEvent) =>
                      setSelectedOption(e.value)
                    }
                    options={options}
                    optionLabel="name"
                    placeholder={t("configuration.selectAnOption")}
                  />
                </div>
              </div>

              <div className="grid grid-xl">
                <div className="flex flex-column gap-2 xl:col-3 lg:col-4 md:col-6 col-12">
                  <label
                    htmlFor="durationWithdrawDisapproval"
                    className="block font-bold text-input-label text-capitalize"
                  >
                    {t("configuration.durationWithdrawDisapproval")}
                  </label>
                  <InputText
                    id="durationWithdrawDisapproval"
                    name="durationWithdrawDisapproval"
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    type="number"
                    placeholder={t("configuration.durationWithdrawDisapproval")}
                    className="w-full"
                    min="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white flex align-items-center px-5 py-3 gap-4 justify-content-between fixed-footer w-full left-0 shadow">
          <Button
            label={t("configuration.cancel")}
            className="button-md"
            severity="secondary"
            type="button"
            onClick={handleCancel}
          />
          <Button
            label={t("configuration.save")}
            className="button-md"
            type="button"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
};

export default CommitteeConfigurationTab;
