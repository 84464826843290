import React, { useState, useEffect, ChangeEvent } from "react";
import { DataTable } from 'primereact/datatable';
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import FeatherIcon from "../common/FeatherIconComponent";
import { fetchData } from "../../services/apiService";
import CustomBreadcrumb from "../common/CustomBreadcrumb";
import { CSVLink } from "react-csv";
import { usePermission } from "../auth/Authorization";
import { Column } from "primereact/column";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Badge } from "primereact/badge";
import { useLanguageContext } from "../LanguageContext";
import { useTranslation } from "react-i18next";
import { CommitteeReportList, MemberDetails, ExportReport } from "./types/CommitteeReport"
import { useScrollOnPaginatorClick } from "../common/useScrollOnPaginatorClick";

const CommitteeReport: React.FC = () => {
  const [t, i18n] = useTranslation("report");
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const [searchText, setSearchText] = useState<string>('');
  const [userProfileId, setUserProfileId] = useState<string>("");
  const { userPermissions, fetchUserPermissions, hasPermission } = usePermission();
  const [committeeList, setCommitteeList] = useState<{ label: string; value: string }[]>([]);
  const [committeeValue, setCommitteeValue] = useState<string[] | null>(null);
  const [memberValue, setMembersValue] = useState<string[] | null>(null);
  const [statusValue, setStatusValue] = useState<string[] | null>(null);
  const [memberList, setMemberList] = useState<{ label: string; value: string }[]>([]);
  const [statusList, setStatusList] = useState<{ label: string; value: string }[]>([]);
  const [userRole, setUserRole] = useState<string>("");
  const [requestList, setRequestLists] = useState<CommitteeReportList[] | []>([]);
  const [expandedRows, setExpandedRows] = useState<any>(null);
  const breadcumbItems = [
    { label: t("report.dashboard"), url: "/dashboard" },
    { label: t("report.reports") },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    if (!userPermissions) {
      fetchUserPermissions();
    } else {
      const roleName = hasPermission();
      setUserRole(roleName);
    }
  }, [userPermissions]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const responseData = await fetchData(
          "UserProfile/GetCurrentUserProfile"
        );
        setUserProfileId(responseData.Id);
      } catch (error) {
        console.error("Error fetching current user profile:", error);
      }
    };
    fetchCurrentUser();
  }, []);

  const flattenData = (requestList: CommitteeReportList[]) => {
    const flatData: ExportReport[] = [];
    requestList.forEach((item: CommitteeReportList) => {
      if (item.MemberDetails && item.MemberDetails.length > 0) {
        item.MemberDetails.forEach((member: MemberDetails) => {
          let memberStatus = member.StatusOfMembershipInTheCommittee || "";
          if (memberStatus === "Resign") {
            memberStatus = "Resigned";
          } else if (memberStatus === "Terminate") {
            memberStatus = "Terminated";
          }
          flatData.push({
            "Committee Name": item.CommitteeName || "",
            "Committee Type": item.CommitteeType || "",
            "Created On": item.CreatedDate || "",
            "Status": item.Status || "",
            "Staff Contact": item.StaffContact || "",
            "Total Members": item.TotalMembers || "",
            "Member Name": member.MemberName || "",
            "COI": member.CategoryOfInterest || "",
            "Member Position": member.MemberPosition || "",
            "Membership End Date": member.MembershipEndDate ? formatDate(member.MembershipEndDate) : "",
            "Member Status": memberStatus,
          });
        });
      } else {
        flatData.push({
          "Committee Name": item.CommitteeName || "",
          "Committee Type": item.CommitteeType || "",
          "Created On": item.CreatedDate || "",
          "Status": item.Status || "",
          "Staff Contact": item.StaffContact || "",
          "Total Members": item.TotalMembers || "",
          "Member Name": "",
          "Category Of Interest": "",
          "Member Position": "",
          "Member Status": "",
          "Membership End Date": "",
        });
      }
    });
    return flatData;
  };

  const exportData = () => {
    if (!requestList) {
      return null;
    }
    const csvData = flattenData(requestList);
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const filename = `Committee_Report_${day}-${month}-${year}_${hours}${minutes}${seconds}.csv`;
    return (
      <CSVLink data={csvData} filename={filename} >
        <Button className="button-md gap-1 justify-content-center w-full md:w-auto">
          <span className="font-bold text-capitalize">{t("report.export")}</span>
        </Button>
      </CSVLink>
    );
  };

  const handleChangeCommittee = (e: MultiSelectChangeEvent) => {
    const selectedCommittee = e.value;
    setCommitteeValue(selectedCommittee);
  };

  const handleChangeMembers = (e: MultiSelectChangeEvent) => {
    const selectedMembers = e.value;
    setMembersValue(selectedMembers);
  };

  const handleChangeStatus = (e: MultiSelectChangeEvent) => {
    const selectedStatus = e.value;
    setStatusValue(selectedStatus);
  };

  const buildUrl = (memberValue: string | string[] | null, committeeValue: string | string[] | null, userProfileId: string, statusValue: string[] | null) => {
    let url = 'Committee/CommiteeReports?';
    const filters: any = [];
    if (committeeValue && committeeValue.length > 0) {
      filters.push({ Key: 'CommitteeId', Value: committeeValue });
    }
    if (memberValue && memberValue.length > 0) {
      filters.push({ Key: 'UserProfileId', Value: memberValue });
    }
    if (statusValue && statusValue.length > 0) {
      filters.push({ Key: 'StatusId', Value: statusValue });
    }
    if (userProfileId && userRole !== 'Admin') {
      filters.push({ Key: 'StaffId', Value: userProfileId });
    }
    url += filters.map((filter: any) => `Filters[${filters.indexOf(filter)}].Key=${filter.Key}&Filters[${filters.indexOf(filter)}].Value=${filter.Value}`).join('&');
    url += `&PageIndex=-1`;
    return url;
  }

  const statusBodyTemplate = (rowData: CommitteeReportList) => {
    let badgeClass = "";
    let displayStatus = rowData.Status;
    if (rowData.Status.toLowerCase() === "resign") {
      displayStatus = "Resigned";
    } else if (rowData.Status.toLowerCase() === "terminate") {
      displayStatus = "Terminated";
    }
    switch (rowData.Status?.toLocaleLowerCase()) {
      case "draft":
        badgeClass = "draft";
        break;
      case "current":
        badgeClass = "open";
        break;
      case "approved":
        badgeClass = "active";
        break;
      case "current":
        badgeClass = "active";
        break;
      case "created":
        badgeClass = "active";
        break;
      case "resign":
        badgeClass = "terminated";
        break;
      case "terminate":
        badgeClass = "deactive";
        break;
      default:
        break;
    }
    return (
      <>
        <span className="p-column-title">{t("report.status")}</span>
        <Badge
          value={displayStatus}
          className={`h-auto text-sm ${badgeClass}`}
        />
      </>

    );
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: any = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const fetchDataBasedOnFilters = async (url: string, setRequestLists: React.Dispatch<React.SetStateAction<any[]>>, setMemberList: React.Dispatch<React.SetStateAction<any[]>>) => {
    try {
      const responseData = await fetchData(url);
      const sortedData = responseData.Collection.map((item: CommitteeReportList, index: number) => ({
        ...item,
        indexId: index + 1,
        CreatedDate: formatDate(item.CreatedOn)
      }));
      setRequestLists(sortedData);
      if (!committeeValue && !memberValue) {
        const uniqueUserIds = new Set();
        const memberListData = responseData.Collection.reduce((accumulator: { label: string, value: string }[], item: CommitteeReportList) => {
          const memberDetailsArray = item.MemberDetails;
          if (Array.isArray(memberDetailsArray)) {
            memberDetailsArray.forEach(member => {
              const { MemberName, MemberId } = member;
              if (!uniqueUserIds.has(MemberId)) {
                accumulator.push({
                  label: MemberName,
                  value: MemberId
                });
                uniqueUserIds.add(MemberId);
              }
            });
          }
          return accumulator;
        }, []);
        const committeeListData = responseData.Collection.reduce((accumulator: { label: string, value: string }[], item: CommitteeReportList) => {
          if (!uniqueUserIds.has(item.CommitteeId)) {
            accumulator.push({
              label: item.CommitteeName,
              value: item.CommitteeId
            });
            uniqueUserIds.add(item.CommitteeId);
          }
          return accumulator;
        }, []);
        const StatusListData = responseData.Collection.reduce((accumulator: { label: string, value: string }[], item: CommitteeReportList) => {
          if (!uniqueUserIds.has(item.StatusId)) {
            accumulator.push({
              label: item.Status,
              value: item.StatusId
            });
            uniqueUserIds.add(item.StatusId);
          }
          return accumulator;
        }, []);
        setCommitteeList(committeeListData);
        setMemberList(memberListData);
        setStatusList(StatusListData);
      }
    } catch (error) {
      console.error("Error fetching current user profile:", error);
    }
  }

  useEffect(() => {
    if (!userProfileId) return;
    const url = buildUrl(memberValue, committeeValue, userProfileId, statusValue);
    if (url) {
      fetchDataBasedOnFilters(url, setRequestLists, setMemberList);
    }
  }, [memberValue, committeeValue, userProfileId, statusValue]);

  const allowExpansion = (rowData: CommitteeReportList) => {
    return rowData.MemberDetails.length > 0;
  };

  const rowExpansionTemplate = (data: CommitteeReportList) => {
    const sortedData = data.MemberDetails.map((item: MemberDetails) => ({
      ...item,
      MembershipEndDate: formatDate(item.MembershipEndDate),
      Status: item.StatusOfMembershipInTheCommittee
    }));
    return (
      <div className="p-3">
        <DataTable className="custom-data-table" value={sortedData} showGridlines>
          <Column field="MemberName" header={t("report.memberName")}
            body={(rowData) => (
              <>
                <span className="p-column-title">{t("report.memberName")}</span>
                {rowData.MemberName}
              </>
            )}
          ></Column>
          <Column field="CategoryOfInterest" header={t("report.categoryOfInterest")}
            body={(rowData) => (
              <>
                <span className="p-column-title">{t("report.categoryOfInterest")}</span>
                {rowData.CategoryOfInterest}
              </>
            )}
          ></Column>
          <Column field="MemberPosition" header={t("report.memberPosition")}
            body={(rowData) => (
              <>
                <span className="p-column-title">{t("report.memberPosition")}</span>
                {rowData.MemberPosition}
              </>
            )}
          ></Column>
          <Column field="Status" header={t("report.status")}
            body={statusBodyTemplate}
          />
          <Column field="MembershipEndDate" header={t("report.membershipEndDate")}
            body={(rowData) => (
              <>
                <span className="p-column-title">{t("report.membershipEndDate")}</span>
                {rowData.MembershipEndDate}
              </>
            )}
          ></Column>
        </DataTable>
      </div>
    );
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    const filteredList = requestList?.filter((item: CommitteeReportList) => {
      const committeeName = "" || item.CommitteeName;
      const committeeType = "" || item.CommitteeType;
      return (
        searchText === "" ||
        committeeName.toLowerCase().includes(searchText.toLowerCase()) ||
        committeeType.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setRequestLists(filteredList);
  };

  // Datatable reference
  const tableContainerRef = useScrollOnPaginatorClick();
  let tableClass = "custom-data-table";
  if (requestList !== null && requestList !== undefined) {
    const isEmpty = requestList.length === 0;
    if (isEmpty) {
      tableClass = "custom-data-table empty";
    }
  }

  return (
    <>
      <div className="">
        <CustomBreadcrumb
          model={breadcumbItems}
          className="bg-transparent mb-5 px-0 py-2 border-none"
        />
        <div className="mb-5 flex flex-wrap md:flex-nowrap md:align-items-center gap-3 w-full justify-content-between">
          <h1 className="text-title display-xs font-bold m-0">
            {t("report.committeeReport")}
          </h1>
          <div>
            <IconField iconPosition="left" className="w-full md:w-16rem">
              <InputIcon className="pi pi-search"> </InputIcon>
              <InputText
                id="searchCommittee"
                className="w-full"
                placeholder={t("report.search")}
                aria-label={t("report.search")}
                value={searchText}
                onChange={handleSearchInputChange}
              />
            </IconField>
          </div>
        </div>
        <div className="tabs-wrap">
          <div className="flex flex-wrap py-3 gap-3 w-full">
            <MultiSelect value={committeeValue} onChange={handleChangeCommittee} options={committeeList} optionLabel="label" display="chip" filter
              aria-label={t("report.selectCommittees")} placeholder={t("report.selectCommittees")} maxSelectedLabels={3} className="w-full md:w-20rem" />
            <MultiSelect value={memberValue} onChange={handleChangeMembers} options={memberList} optionLabel="label" display="chip" filter
              aria-label={t("report.selectMembers")} placeholder={t("report.selectMembers")} maxSelectedLabels={3} className="w-full md:w-20rem" />
            <MultiSelect value={statusValue} onChange={handleChangeStatus} options={statusList} optionLabel="label" display="chip" filter
              aria-label={t("report.selectStatus")} placeholder={t("report.selectStatus")} maxSelectedLabels={3} className="w-full md:w-20rem" />
            <Button className="button-md gap-1 justify-content-center p-0 w-full md:w-auto">
              {exportData()}
            </Button>
            {userRole === "Member" && (
              <Button
                className="button-md gap-1 justify-content-center w-full md:w-auto"
                onClick={() => navigate("/member/create")}
              >
                <FeatherIcon name="plus" size={20} color="inherit" />
                <span className="font-bold">
                  {t("report.export")}
                </span>
              </Button>
            )}
          </div>
          <div ref={tableContainerRef}>
            <DataTable className={tableClass} paginator
              rowsPerPageOptions={[10, 25, 50, 100]}
              rows={10} value={requestList} showGridlines expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
              rowExpansionTemplate={rowExpansionTemplate}
              dataKey="indexId" tableStyle={{ minWidth: '60rem' }}>
              <Column expander={allowExpansion} style={{ width: '5rem' }} />
              <Column field="CommitteeName" header={t("report.committeeName")}
                body={(rowData) => (
                  <>
                    <span className="p-column-title">{t("report.committeeName")}</span>
                    {rowData.CommitteeName}
                  </>
                )}
              />
              <Column field="CommitteeType" header={t("report.committeeType")}
                body={(rowData) => (
                  <>
                    <span className="p-column-title">{t("report.committeeType")}</span>
                    {rowData.CommitteeType}
                  </>
                )}
              />
              <Column field="CreatedDate" header={t("report.createdOn")}
                body={(rowData) => (
                  <>
                    <span className="p-column-title">{t("report.createdOn")}</span>
                    {rowData.CreatedDate}
                  </>
                )}
              />
              <Column field="Status" header={t("report.status")}
                body={statusBodyTemplate}
              />
              <Column field="StaffContact" header={t("report.staffContact")}
                body={(rowData) => (
                  <>
                    <span className="p-column-title">{t("report.staffContact")}</span>
                    {rowData.StaffContact}
                  </>
                )}
              />
              <Column field="TotalMembers" header={t("report.totalMembers")}
                body={(rowData) => (
                  <>
                    <span className="p-column-title">{t("report.totalMembers")}</span>
                    {rowData.TotalMembers}
                  </>
                )}
              />
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommitteeReport;
