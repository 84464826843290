import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import FeatherIcon from "../../common/FeatherIconComponent";
import { useLanguageContext } from "../../LanguageContext";
import { fetchData as getData, putData } from "../../../services/Ballot/apiservice";
import { BallotStatusResponse, BallotStatus } from "../common/type";
import { BallotDetails as BallotDetailsType } from "../types/vote"
import { BALLOT_CANCELLED} from "../common/constants";
import useUserData from "../../../hooks/useUserData";
import BallotTabs from "./BallotTabs";
import BallotDetailHeader from "./BallotDetailsHeader";
import BallotDetailsActions from "./BallotDetailsActions";
import { Toast } from "primereact/toast";
import { setToast } from "../../../slices/toastSlice";
import { useDispatch } from "react-redux";

const BallotDetails: React.FC = () => {
  const { id } = useParams();
  const [t, i18n] = useTranslation("ballot");
  const [ballotDetails, setBallotDetails] = useState<BallotDetailsType | null>(null);
  const [ballotStatusData, setBallotStatusData] = useState<any>([])
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const { userRole } = useUserData();
  const { selectedLanguage } = useLanguageContext();
  const performAction = () => {
    i18n.changeLanguage(selectedLanguage);
  };
  React.useEffect(() => {
    performAction();
  }, [selectedLanguage]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: ballotId } = useParams();
  const toast = useRef<any>(null);
  const fetchBallotDetailsAndStatus = useCallback(async () => {
    try {
      const ballotDetailsRequest = getData(`Ballot/${id}`);
      const ballotStatusRequest = getData("BallotStatus/GetAll");

      const [ballotDetailsData, ballotStatusResponse]: [any, BallotStatusResponse] = await Promise.all([
        ballotDetailsRequest,
        ballotStatusRequest,
      ]);
      setBallotDetails(ballotDetailsData);
      const allBallotStatusFormatted = ballotStatusResponse.Collection.map((ballot: BallotStatus) => ({
        id: ballot.Id,
        name: ballot.Name,
      }));
      const transformedObject = allBallotStatusFormatted.reduce((acc: any, status: any) => {
        acc[status.name] = { id: status.id };
        return acc;
      }, {});
      setBallotStatusData(transformedObject);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      //setLoading(false);
    }
  }, [ballotId]);

  useEffect(() => {
    fetchBallotDetailsAndStatus();
  }, [fetchBallotDetailsAndStatus]);

  const createBallotPayload = (details: any, statusData: any) => ({
    accreditationBodyId: details?.AccreditationBody?.Id,
    isDirty: true,
    isNew: false,
    ballotLevelId: details?.BallotLevel?.Id,
    ballotTypeId: details?.BallotType?.Id,
    ballotSubTypeId: details?.BallotSubType?.Id,
    ballotStatusId: statusData[BALLOT_CANCELLED]?.id,
    recordSubTypeId: details?.RecordSubType?.Id,
    recordTypeId: details?.RecordType?.Id,
    committeeId: details?.Committee?.Id,
    membershipPositionId: details?.MembershipPosition?.Id,
    note: details?.Note,
    noOfRecords: details?.NoOfRecords,
    explaination: details?.Explaination,
    description: details?.Description,
    daysToIssue: details?.DaysToIssue,
    openDate: details?.OpenDate,
    closeDate: details?.CloseDate,
    ballotNumber: details?.BallotNumber,
    isActive: true,
    isDeleted: false,
  });
  
  const ballotCancelHandler = useCallback(async () => {
    const ballotPayload = createBallotPayload(ballotDetails, ballotStatusData);
    try {
      await putData(`Ballot`, `${ballotId}`, ballotPayload);
      dispatch(setToast({ message: `${t("ballot.ballotCancelMessage")}`, severity: 'success' }));
      navigate(`/ballot/membership`);
    } catch (err) {
      console.error('Error cancelling the ballot:', err);
    }
  }, [ballotDetails, ballotStatusData, ballotId]);

  return (
    <>
      <Toast ref={toast} />
      <div>
        <Button
          text
          className="p-button-plain gap-2 mb-3 pl-0 underline"
          onClick={() => navigate("/ballot/membership")}
        >
          <FeatherIcon name="arrow-left" size={20} />
          <span className="">{t("ballot.back")}</span>
        </Button>
        <div className="mb-5 flex flex-wrap gap-2">
          <BallotDetailHeader BallotHeading={t("ballot.ballotId")} BallotNumber={ballotDetails?.BallotNumber ?? ""} BallotStatus={ballotDetails?.BallotStatus?.DisplayName ?? ""}  />
          <div className="flex flex-row align-items-center gap-4 ml-auto">
            {
              userRole !== "Member" && (
                <>
                <BallotDetailsActions ballotId={id ? id : ""} ballotStatus={ballotDetails?.BallotStatus?.DisplayName ?? ""}  ballotCancelHandler={ballotCancelHandler} />
                </>
              )
            }
          </div>
        </div>
        <div className="tabs-wrap">
          <BallotTabs activeIndex={activeIndex} setActiveIndex={setActiveIndex} userRole={userRole} ballotStatus={ballotDetails?.BallotStatus?.DisplayName ?? ""} />
        </div>
      </div>
    </>
  );
};

export default BallotDetails;